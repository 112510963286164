export const locale = {
  lang: 'pt',
  data: {
    TESTLANG: 'pt_base',
    LNG_ITEMS: 'Produtos',
    LNG_ITEM: 'Produto',
    LNG_TIENDAS: 'Pontos de Venda',
    LNG_TIENDA: 'Ponto de Venda',
    LNG_PRICES_DECISIONS_FILTER_CATEGORYS: 'Subcategorias',
    GENERAL_DOWNLOAD: null,
    GENERAL_INFO: 'Informação',
    GENERIC_CLEAN: null,
    GENERIC_SELECTED_OPTION: null,
    GENERIC_PUBLISH_CONTENT: null,
    GENERIC_CANCEL_CONTENT: null,
    GENERIC_DOWNLOAD_CONTENT: 'Deseja cancelar os seguintes {{val}} registros selecionados?',
    GENERIC_UNPUBLISH: null,
    GENERIC_WITHOUT_PERMISSION: null,
    GENERIC_ACCESS_PERMISSION: null,
    GENERIC_ACTION_ROW_CONTENT_FAILED: 'A ação não pode ser executada corretamente',
    GENERIC_CANCEL: 'Cancelar',
    GENERIC_ACCEPT: 'aceitar',
    GENERIC_CLOSE: 'Fechar',
    GENERIC_OK: 'Ok',
    GENERIC_CONTINUE: 'Continuar',
    GENERIC_WARNING: 'Aviso',
    GENERIC_REPORT: null,
    GENERIC_ADD: null,
    GENERIC_FILTER: null,
    GENERIC_FULLSCREEN_TTIP: null,
    GENERIC_EXITFULLSCREEN_TTIP: null,
    GENERIC_EXPORT_TTIP: null,
    GENERIC_CLONE: null,
    GENERIC_EDIT: null,
    GENERIC_DELETE: null,
    GENERIC_VIEW: null,
    GENERIC_CONFIRM: 'Confirmar',
    GENERIC_RESET: null,
    GENERIC_RESET_ALT: null,
    GENERIC_SAVE: 'Salvar',
    GENERIC_SAVE_OK: 'Salvo com sucesso.',
    GENERIC_SAVE_AND_GENERATE: null,
    GENERIC_GENERATE: null,
    GENERIC_NONE: 'Nenhum',
    GENERIC_DOWNLOAD_EXCEL: 'download.xlsx',
    GENERIC_ITEMS_NOT_FOUND: 'Nenhum resultado encontrado',
    GENERIC_ITEMS_NOT_ITEMS: null,
    GENERIC_NOTICE: null,
    GENERIC_SOME: '(Várias)',
    GENERIC_YES: null,
    GENERIC_NO: null,
    GENERIC_ALL: null,
    GENERIC_ALL_PAGE: 'A página inteira',
    GENERIC_DESELECT: 'Desmarcar',
    GENERIC_ALL_F: null,
    GENERIC_DAY1: null,
    GENERIC_DAY2: null,
    GENERIC_DAY3: null,
    GENERIC_DAY4: null,
    GENERIC_DAY5: null,
    GENERIC_DAY6: null,
    GENERIC_DAY7: null,
    GENERAL_NAME_DUPLICATE: 'Este nome já existe',
    GENERIC_DELETE_LOGIC: 'Desativar registro',
    GENERIC_DELETE_LOGIC_SINGLE_CONTENT: 'Seguro que desea Desativar {{val}}? solo podra ser desativados si não se encuentra en uso',
    GENERIC_DELETE_MULTIPLE: 'Desativar, registros',
    GENERIC_SUPPORT_CHAT: null,
    GENERIC_DELETE_LOGIC_MULTIPLE_CONTENT:
      'Seguro que desea Desativar {{val}} produtos? solo podran ser Desativados si no se encuentran en uso',
    GENERIC_ERRORS: {
      REQUIRED: 'Este campo é obrigatório',
      EMAIL: 'Deve ser um email válido',
      MIN: 'O valor deve ser maior que {{val}}',
      MAX: 'O valor deve ser menor que {{val}}',
      MINLENGTH: 'Mínimo de {{val}} caracteres',
      MAXLENGTH: 'Máximo de {{val}} caracteres',
      PATTERN: 'Formato inválido',
      MATDATEPICKERMIN: 'A data deve ser posterior a {{val}}',
      MATDATEPICKERMAX: 'A data deve ser anterior a {{val}}',
    },
    LINEAR_SPACE_TYPES: {
      Sales: 'Vendas',
    },
    LNG_PRODUCTS_TYPES: {
      Retail: 'Varejo',
      Ingredient: 'Ingredientes',
      Combo: 'Combo',
      Recipe: 'Receita',
      Supply: 'Abastecimento',
      Item: 'Retail',
    },
    LNG_STORES_TYPES: {
      Store: 'Ponto de Venda',
      DistributionCenter: 'Centro de Distribuição ',
      StoreAndDistributionCenter: 'Ponto de Venda e Centro de Distribuição',
    },
    LNG_STRATEGIES_VARIABLES: {
      Sales: 'Vendas',
      SalesUN: 'Unidades Vendidas',
      Deliveries: 'Entregas',
      DeliveriesUN: 'Entregas em UN',
      Margin: 'Margem',
      MarginWithVendors: null,
      MarketSales: null,
      MarketUN: null,
    },
    LNG_STRATEGIES_EVENTS: {
      First: 'Primeria compra',
      CreationDate: 'Data de Cadastro',
    },
    STATUS_CONNECTION: {
      NO_CONNECTION: 'Sem Conexão',
      BAD_CONNECTION: 'Conexão pobre',
      ACCEPT: 'OK',
    },
    ABM_STATUS: {
      NEW: 'Novo',
      ENDORSED: null,
      INFORMED: null,
      DELETED: null,
      MODIFIED: 'Modificado',
      CURRENT: 'Postado',
      CANCELLED: null,
    },
    PUBLISH_STATUS: {
      PROCESSING: 'Postando',
      PROCESSING_TOOLTIP: 'Atualize os resultados da pesquisa quando notificado sobre o sucesso da publicação',
      ROW_DISABLED_BY_PROCESSING: null,
      DELETING: null,
      DELETING_TOOLTIP: null,
      ABM_WARNING_DELETING: null,
    },
    LNG_ADMINISTRATION_MASTER_SUPPLIER_searchStores: 'Encontrar Pontos de Venda',
    LNG_ADMINISTRATION_MASTER_STORES_newStore: 'Novo Ponto de Venda',
    LNG_ADMINISTRATION_MASTER_STORES_tooltipDeleteStore: 'Apagar Pontos de Venda Selecionados',
    LNG_ADMINISTRATION_MASTER_STORES_storeType: 'Tipo de Ponto de Venda',
    LNG_MASTER_STORETYPES_Store: 'Ponto de Venda',
    LNG_MASTER_STORETYPES_DistributionCenter: 'Centro de Distribuição ',
    LNG_MASTER_STORETYPES_StoreAndDistributionCenter: 'Ponto de Venda e Centro de Distribuição',
    LNG_PRICES_SETUP_PRICERULE_storeScope: 'Escopo do Ponto de Venda',
    LNG_PRICES_SETUP_PRICERULE_step3storeScope: 'Passo 3 - Escopo do Ponto de Venda',
    'LNG_PRICE-RULES-TYPES_Margin': 'Margem',
    'LNG_PRICE-RULES-TYPES_Competitive': 'concorrência',
    'LNG_PRICE-RULES-TYPES_ParentItem': 'Parentesco de Produtos',
    'LNG_PRICE-RULES-TYPES_ParentZone': 'Parentesco do Ponto de Venda',
    LNG_SET_EFFECTIVE_DATES: 'Definir Datas Válidas',
    LNG_SET_EFFECTIVE_DATE: 'Definir Data Válida',
    LNG_PREVIEW: 'Vista Prévia',
    TIME_UNITS_SHORT: {
      seconds: null,
      minutes: null,
      hours: null,
      days: null,
    },
    GLOBAL: {
      DATES: 'Datas',
      brand: 'Marca',
      brands: 'Bandeira',
      manufacturer: 'Fornecedor',
      internalCode: 'Código Interno',
      externalCode: 'Código Externo',
      Vendor: null,
      Imprent: null,
      Region: 'Região',
      Market: 'Mercado',
      Format: 'Tipo de Operação Propria',
      Country: 'País',
      State: 'Estado',
      Department: 'Localidade',
      NAME: 'Nome',
      QUANTITY: 'Quantidade',
      City: 'Cidade',
      ZipCode: 'Código Postal',
      Subcategoría: 'Subcategoria',
      OperationType: 'Tipo de Operação Concorrentes',
      UnCheckedSuggestion: 'Sugestão não verificada',
      PriceRaised: 'Preços que Aumentaram',
      NoSuggestions: 'Sem Sugestōes',
      NotCheckedSimulation: 'Simulação não verificada',
      SuggestionAccepted: 'Sugestão aceita',
      ManualPriceEdited: 'Preço editado manualmente',
      SimulationAcepted: 'Simulação aceita',
      Regular: 'Regular',
      Competition: 'concorrência',
      Current: 'Atual',
      New: 'Novo',
      Endorsed: 'Endossado',
      Informed: 'Informado',
      Historic: 'Histórico',
      Future: 'Futuro',
      Checked: 'Comprovada',
      Unchecked: 'Sem revisar',
      PriceZone: 'Zona de Preços',
      Search: 'Procurar',
      Type: 'Tipo',
      WITHOUT_PERMISSIONS: 'Sem Permissão',
      PLACEHOLDER_START_DATE: 'Data de inicio',
      PLACEHOLDER_END_DATE: 'Data final',
      MAX_DIFF_DATE: null,
      SECOND_DATE_LOWER_THAN_FIRST: null,
      DATE: 'Data',
      PDV: 'Pontos de Venda',
      WITHOUT_CHANGES: null,
      WITHOUT_INVALID_MAIN_DATA: null,
    },
    TYPE_ACTION: {
      approved: 'Aprovar',
      informToERP: 'Publicar Decisões',
      TOOLTIP: {
        approved: 'Aprovar Mudanças',
        informToERP: 'Publicar Decisões',
      },
    },
    NOTIFICATIONS: {
      TITLE: 'Notificações',
      NO_NEW_ITEMS: 'Não tem Novas Notificações',
      NO_OLD_ITEMS: 'Nāo tem Notificações sem ler.',
      MARK_AS_READ: 'Marcar como lida',
      MARK_ALL_AS_READ: 'Marcar Todas como lidas',
      VIEW_LAST: 'Ver últimas lidas',
      CLOSE_LAST: 'Fechar notificações lidas',
      NOT_ENABLED: 'As notificações do seu navegador estão desabilitadas.',
      ENABLE_NOTIFICATIONS: 'Você deve ativar as notificações no seu navegador. Clique para mais info.',
      ENABLE_NOTIFICATIONS_TUTORIAL: 'Tutorial de ativação',
      DOWNLOAD: 'Baixar Arquivo',
      TOGO: 'Ir',
    },
    INSIGHTS: {
      TITLE: 'Insights',
      GO_TO_INSIGHTS: 'Ir para a tela de Insights',
    },
    GLOBAL_CONFIG: {
      TITLE: 'Configuração',
      FONT_SIZE: 'Tamanho da fonte',
      SMALL: 'Pequena',
      NORMAL: 'Normal',
      BIG: 'Grande',
      THEME: null,
      LIGTH: null,
      DARK: null,
      DATE_FORMAT: 'Formato de data',
      RESET_WARNING: 'Para aplicar as alterações é necessário reiniciar o aplicativo',
    },
    HELPS: {
      PRICE: {
        SOON: 'Em breve',
        FAQS: {
          ROUTE: 'Preços',
          TITLE: 'Perguntas Frequentes',
          QUESTION1:
            'E se eu tiver uma regra de margem e uma regra de concorrência para o mesmo produto e no mesmo ponto de venda? O que o Prisma vai fazer?',
          ANSWER1:
            'Na Prisma, as regras mais específicas sempre terão prioridade sobre as outras. Ou seja, se houver uma regra de margem para a subcategoria Eletrodomésticos em São Paulo, e por sua vez houver uma regra de concorrência para a subcategoria Eletrodomésticos, para um determinado ponto de venda na área de São Paulo, então a regra de concorrência terá prioridade naquele determinado ponto de venda, enquanto os demais pontos de venda em São Paulo serão regidos pela regra de margem.',
          ANSWER1_1:
            'Se houver uma regra de concorrência e uma regra de margem, elas têm o mesmo escopo de produto e ponto de venda. Então, a regra da concorrência sempre terá prioridade. Ou seja, o Prisma tentará se adequar ao posicionamento competitivo e caso isso não seja possível serão geradas sugestões a partir da regra de margem.',
          QUESTION2: 'Como funciona a regra de margem da zona? Em que casos vou usá-lo?',
          QUESTION3: 'Qual é a diferença entre regras de margem ou markup? Qual devo usar?',
          ANSWER3:
            'A margem de lucro e o markup são duas maneiras de encarar a mesma situação: quanto eu quero ganhar. Eles são exatamente os mesmos. A diferença está na forma como são obtidos: o Mark Up é calculado com base no custo, enquanto a Margem de Lucro é calculada sobre o preço de venda.',
          ANSWER3_1:
            'Por exemplo, um produto ou serviço que tem um custo final de R$ 100,00 e para o qual você deseja ganhar um Mark Up de 20%, terá um preço final de R$ 120,00 e uma Margem de Lucro de 16,66%.',
          ANSWER3_2: 'Mark Up = 20 / Custo = 20/100 = 0.2 x 100 = 20%',
          ANSWER3_3: 'Margem de Lucro = 20 / Preço = 20/120 = 0.16 x 100 = 16.16%',
          QUESTION4: 'Que tipo de custo o Prisma usa para calcular a margem?',
          ANSWER4:
            'Na Prisma recomendamos trabalhar com o Custo de Reposição, ou seja, o preço que temos que pagar ao fornecedor para repor uma mercadoria ou matéria-prima. Este custo pode ser diferente do custo contábil.',
          QUESTION5:
            'Se eu tiver o produto "Little Coffee", que é o pai do produto "Medium Coffee" em uma regra configurada para que o produto filho custe 20% a mais que o produto pai. Posso ter outra regra, ao mesmo tempo, em que "Little Coffee" é o pai de "Big Coffee" e o produto filho ("Big Coffee") custa 40% a mais que o pai ("Little Coffee")?',
          ANSWER5:
            'Você pode, Prisma permite que você crie várias regras de parentesco de produto onde você define o mesmo item como pai. Nesse caso, você criará uma regra de parentesco onde o item Little Coffee é o pai do café médio com uma diferença de 20% e a seguir criará outra regra de relacionamento onde o produto Little Coffee é o pai do Big Coffee com uma diferença de 40%.',
          QUESTION6:
            'Criei uma regra em que o produto "A" é filho do produto "B". "A" muda seu preço cada vez que o preço "B" muda. Quero criar outra regra em que o produto "C" também seja pai de "A"? Então, "A" mudará o preço sempre que o preço de "B" ou "C" mudar.',
          ANSWER6:
            'As regras de parentesco do produto não podem compartilhar Filhos. Dessa forma, se você definir que um produto será filho, ele só poderá ter um único pai.',
          QUESTION7: 'Criei regras de parentesco e não vejo as sugestões de preço, como posso gerá-las?',
          ANSWER7:
            'As regras de parentesco não geram sugestões. As únicas regras que geram sugestões de preços são as Regras de Margem e as Regras de concorrência.',
          QUESTION8: 'Criei uma regra de concorrência / margem para um produto e o Prisma não sugere um preço, o que devo fazer?',
          ANSWER8:
            'Se a prisma não gerou sugestões, significa que seus preços estão de acordo com o posicionamento e as diretrizes definidas. Portanto, você não precisa gerar nenhuma sugestão.',
          ANSWER8_1:
            'No caso das regras de concorrência, lembre-se que a Prisma se alimenta dos preços de seus concorrentes. Você deve verificar se atualizou os preços dos concorrentes.',
          QUESTION9:
            'Quando estou criando uma regra de concorrência, existem pontos de venda que não estão listados para seleção no escopo.',
          ANSWER9:
            'Se eles não aparecem listados, é porque você tem pontos de venda mal rotulados ou não contêm os atributos corretos, você pode verificar isso no menu Configuração> Maestros> Pontos de Venda',
          QUESTION10: 'Quando estou criando uma regra de concorrência, existem produtos que não estão listados para seleção no escopo.',
          ANSWER10:
            'Se nem todos os produtos estiverem listados, você deve verificar se eles estão bem categorizados ou têm os atributos corretos, você pode verificar isso no módulo Catálogo de Produtos.',
          QUESTION11: 'Quando estou criando uma regra de margem, há pontos de venda que não estão listados para seleção no escopo.',
          ANSWER11:
            'Se eles não aparecem listados, é porque você tem pontos de venda mal rotulados ou não contêm os atributos corretos, você pode verificar isso no menu Configuração> Maestros> Pontos de Venda',
          QUESTION12: 'Quando estou criando uma regra de margem, existem produtos que não estão listados para seleção no escopo.',
          ANSWER12:
            'Se nem todos os produtos estiverem listados, você deve verificar se eles estão bem categorizados ou têm os atributos corretos, você pode verificar isso no módulo Catálogo de Produtos.',
          QUESTION13: 'Quando estou criando uma regra de margem, existem produtos que não estão listados para seleção no escopo.',
          ANSWER13:
            'Se eles não aparecem listados, é porque você tem pontos de venda mal rotulados ou não contêm os atributos corretos, você pode verificar isso no menu Configuração> Maestros> Pontos de Venda',
          QUESTION14: 'Quando estou criando uma regra de margem, existem produtos que não estão listados para seleção no escopo.',
          ANSWER14:
            'Se nem todos os produtos estiverem listados, você deve verificar se eles estão bem categorizados ou têm os atributos corretos, você pode verificar isso no módulo Catálogo de Produtos.',
        },
      },
    },
    GENERIC_COMP: {
      SCOPE: {
        CLIPBOARD_STORE_TITLE: null,
        CLIPBOARD_PRODUCT_TITLE: 'Colar produtos desde a área de transferência',
        STRATEGY_ATTR_TITLE: 'Seleção por atributo',
        STRATEGY_ATTR_MSG: 'Os atributos são selecionados para definir o escopo dos produtos',
        STRATEGY_PROD_TITLE: 'Seleção por produto',
        STRATEGY_PROD_MSG: 'Os produtos devem ser selecionados um por um',
        STRATEGY_ATTR_TITLE_STORE: 'Seleção por atributo',
        STRATEGY_ATTR_MSG_STORE: 'Os atributos são selecionados para definir o escopo dos Pontos de Venda',
        STRATEGY_STORE_TITLE_STORE: 'Seleção por pontos de venda',
        STRATEGY_STORE_MSG_STORE: 'Os pontos de venda devem ser selecionados um a um',
        ITEM_LIST_WITH_ACTIONS: {
          TABLE: {
            ADD: 'Agregar Forecast',
            NAME: 'Nome',
            CODE: 'Código',
            FORECAST: 'Forecast',
            MODAL: {
              TITLE: 'Atribuir Forecast',
              INPUT: 'Forecast',
            },
            DELETE_FORECAST_TITLE: 'Confirmar exclusão de Forecast',
            DELETE_FORECAST_CONTENT: 'Tem certeza que deseja excluir o forecast de {{val}} {{val2}}?',
          },
        },
      },
      LOOKUP: {
        Search: 'Procurar',
        clearAll: 'Desmarcar todos os itens',
        clear: 'Desmarque o item',
        selectAll: 'Selecionar todos os ítens',
        selectAllPage: 'Selecionar todos os ítens na página atual',
        selectAllChilds: 'Selecionar todos os ítens filhos',
        onlySelected: 'Mostrar só ítens selecionados',
        allItemsSelected: 'Todos os ítens selecionados',
        noneItemsSelected: 'Nenhum íten selecionado',
        selectedItemsLabel: 'Ítens Selecionados ({{val}}):',
        Included: null,
        Excluded: null,
        includedAll: null,
        excludedAll: null,
        includedclearAll: null,
        excludedclearAll: null,
        itemsNotFound: 'Nenhum resultado encontrado',
        parent_item_active: null,
        parent_item_message: null,
        parent_store_active: null,
        parent_store_message: null,
      },
      CAT_SELECT_V2: {
        LEVEL2: 'Unidade de Negócio',
        LEVEL3: 'Departamento',
        LEVEL4: 'Categoria',
        LEVEL5: 'Subcategoria',
      },
      WEEKDAY_COMPONENT_DAYS: {
        '0': 'Dom',
        '1': 'Seg',
        '2': 'Ter',
        '3': 'Qua',
        '4': 'Qui',
        '5': 'Sex',
        '6': 'Sab',
      },
      IMPORTERV2: {
        DEFAULT_TITLE: null,
        SELECT_TYPE_PLACEHOLDER: null,
        DEFAULT_DESCRIPTION: null,
        DOWNLOAD_TEMPLATE: null,
        AssortmentMarketData_alternativeDescription: null,
        TITLES: {
          FERRYES_AND_COMISIONS: null,
          COST_AND_TAXES: null,
          VENDOR_COST: null,
          STORE_TAXES: null,
          NEW_PRICES: null,
          COMPETITOR_PRICES: null,
          ITEMS: null,
          IMPORT_VENDORS: null,
          IMPORT_PRICES_TITLE: null,
          MARKET_INFORMATION: null,
          MARGIN_WITH_VENDOR: null,
          STORE_ASSORTMENT: null,
          STORE_COSTS: null,
          STORE_PRICES: null,
          STORE_PRICE_OUTPUT: null,
          COMPETITORS: null,
          STORE_PRICE_OUTPUT_PREVIEW: null,
          CustomItems: null,
          REGULATED_PRICES: null,
          VENDOR_COSTS: null,
          CustomItemsDelete: null,
          CustomItemsUpdatePrice: null,
          CustomItemsUpdateCosts: null,
          VendorCosts: null,
          DailySales: null,
          AssortmentMarketData: null,
          Categories: null,
          Margin2: 'Margem por Acordos',
          MonthlyInventory: null,
          StoreAssortment: null,
          Stores: null,
          MonthlySales: null,
          ItemMatchings: null,
          SpacesAvailability: null,
          None: null,
          CategoriesV2: null,
        },
        RESPONSE_OK: null,
        RESPONSE_OK_TITLE: null,
        TYPES: {
          ALL: null,
          Items: null,
          RegulatedPrices: null,
          PriceRegulated: null,
          ActionExceptionalValue: null,
          ActionExceptionalMixedValue: null,
          StoreCosts: null,
          StorePrices: null,
          StorePriceOutput: null,
          CustomItems: null,
          CustomItemsDelete: null,
          CustomItemsUpdatePrice: null,
          CustomItemsUpdateCosts: null,
          StorePriceOutputPreview: null,
          ItemCodes: null,
          UnitsOfMeasures: null,
          Competitors: null,
          CompetitorPrices: null,
          CompetitorsPrices: null,
          SalesTickets: null,
          Vendors: null,
          VendorItems: null,
          DailyInventory: null,
          DailySales: null,
          AssortmentOutputPreview: null,
          AssortmentOutput: null,
          VendorCosts: null,
          VENDOR_COSTS: null,
          StoreCostOutput: null,
          AssortmentMarketData: null,
          Categories: null,
          Margin2: 'Margem por Acordos',
          ItemTags: null,
          MonthlyInventory: null,
          StoreAssortment: null,
          Stores: null,
          MonthlySales: null,
          None: null,
          DailyMissionReport: null,
          DailyMissionReport_alt: null,
          ItemMatchings: null,
          SpacesAvailability: null,
          PlanogramPopMaterialMarketplaces: 'Material Alugado',
          MarketItemToRegister: 'Produtos de Mercado',
          CategoryTree: 'Árvore de Categorias',
          PromiotonPeriod: null,
          PromiotionPeriod: null,
          BranchesYpfTiendas: 'Filiais',
          DeliveryStoresYpfTienda: 'Locais de entrega',
          ItemsYpfTiendas: 'Itens',
          PlanogramStructure: 'Estrutura de Planograma',
          Planogram: 'Planogramas',
          PopMaterial: 'Materiais POP',
          PlanogramPopMaterial: 'Planogramas materiais',
          PlanogramStore: 'Planogramas lojas',
          PromotionReferencePrice: 'Preços de Referência',
          PromotionSpace: 'Espaços',
          PromotionMaterial: 'Materiais',
          PromotionSpaceStore: 'Espaços lojas',
          PromotionSpaceMaterial: 'Espaços materiais',
          PromotionMaterialStore: 'Materiais lojas',
          TakeAPayBPromotion: 'Promoção leve A pague B',
          DiscountPromotion: 'Promoção de desconto',
          FixedPricePromotion: 'Promoção de preço fixo',
        },
        STATUS: {
          Pending: 'Pendente',
          InProgress: 'Em andamento',
          Succeeded: 'Sucesso',
          SucceededWithWarnings: 'Sucesso com avisos',
          Failed: 'Fracassado',
          Cancelled: 'Cancelado',
          NoDataProcessed: 'Nenhum dado processado',
        },
        STATUS_MSG: {
          information: null,
          warning: null,
          error: null,
        },
        SOURCE: {
          WebApi: null,
          Sftp: null,
          ExternalPull: null,
        },
      },
      INTERFACES_LOG: {
        DEFAULT_TITLE: null,
        LIST_COL_TYPE: null,
        ENTITY: null,
        LIST_COL_SOURCE: null,
        LIST_COL_ID: null,
        LIST_COL_CREATED: 'Criado',
        LIST_COL_STARTED: 'Iniciado',
        LIST_COL_FINISHED: 'Finalizado',
        LIST_COL_DURATION: 'Duração',
        LIST_COL_STATUS: 'Estado',
        LIST_COL_USERNAME: 'Criado',
        LIST_COL_ORIGINAL_FILE_NAME: 'Nome do Arquivo',
        MORE_DETAIL: 'Mais detalhes',
        RETRY: null,
        RETRY_PERMISSION: null,
        RETRY_OK: null,
        LIST_COL_RECORDS: null,
        LIST_COL_BATCHES: null,
        LIST_COL_BATCHES_PROGRESS: null,
        LIST_COL_BATCHES_NUM: null,
        LIST_COL_MESSAGES: null,
        LIST_COL_LINE: 'Linha',
        LIST_COL_BATCHES_COMPLETED: null,
        DATE: null,
        IMPORTER: null,
        EXPORT_MSG: null,
        EXPORT_MSG_SHRT: null,
        SIMPLE_VIEW: null,
        ADVANCED_VIEW: null,
      },
    },
    PRICE_RULES: {
      COMPETENCE: {
        POSITION_AGAINST: {
          Minimum: 'Mínimo',
          Average: 'Média',
          Maximum: 'Máximo',
          Mode: 'Moda',
        },
        POSITION_TREND: {
          HighestMode: 'Moda que mais se repete',
          MinimumPrice: 'Preço Mínimo',
          AveragePrice: 'Preço Médio',
          MaximumPrice: 'Preço Máximo',
        },
        RULE_TYPE: {
          Guideline: 'Diretriz',
          Margin: 'Margem',
          Competitive: 'concorrência',
          ParentItem: 'Parentesco de Produtos',
          ParentZone: 'Parentesco de Pontos de Venda',
          BasePriceList: 'Preço de Lista Base',
        },
      },
    },
    NAV: {
      HOME: {
        TITLE: 'Inicio',
        DASHBOARD: 'Dashboard',
      },
      DASHBOARD: {
        TITLE: 'Dashboard',
      },
      DATA_ENTRY_ADAPTATIONS: {
        TITLE: 'Entrada de Dados',
        TABLE: {
          TYPE: 'Tipo de Entrada',
          ADAPTATION: 'Gestão de Entrada de Dados',
        },
      },
      PRICES: {
        TITLE: 'Preços',
        SETUP: {
          TITLE: 'Configuração',
          PRICEZONES: 'Zonas de Preço',
          COMPETITORS: 'Concorrentes',
          PRICESRULES: 'Regras de Preço',
          BRANDS: 'Bandeiras',
          GUIDELINESANDSTRATEGIES: 'Diretrizes e Estratégias',
          SENSITIVITIES: 'Sensibilidades',
          ROUNDING: 'Regras de Arredondamento',
          STRATEGYANDRULES: 'Estratégias e Regras',
        },
        DECISIONS: {
          TITLE: 'Decisões',
          DECISIONS: 'Decisões',
          INFORM_ERP: 'Publicar Decisões',
          PRICE_ELASTICITY: 'Elasticidade de Preço',
          PRICE_DECISIONS: 'Decisões de Preço',
          DECISIONSSUMMARY: 'Resumo de Decisões',
        },
        REPORTS: {
          TITLE: 'Relatórios',
          PRICE_CHANGES: 'Auditoría de Preço',
          COMPETITIVENESS_INDEX: 'Índice de Competitividade',
          PRICE_REPORTS: 'Relatório de Preços',
          SUGGESTION_REPORTS: null,
          COST_REPORTS: null,
          YPF_REPORTS: 'Relatórios Ypf',
          PRICES_MAP: 'Mapa de Preços',
          PRICE_INCONSISTENCY_REPORT: 'Inconsistencias de Preços',
          DAILY_MARGIN: 'Margens diárias',
          COMPETITOR_PRICES: 'Preços de Concorrentes',
        },
        ADMIN: {
          TITLE: 'Administraçāo',
          TAXES: {
            TITLE: 'Impostos',
            CO2: 'Impostos CO2',
            ICL: 'Impostos ICL',
            IIBB: 'Impostos IIBB',
          },
          IMPORTERS: {
            TITLE: 'Importadores',
            IMPORTPARITY: 'Importar Paridade',
            IMPORTFLETESCOMISIONES: 'Fretes e Comisões',
            IMPORTVENDORCOSTS: 'Custos por Fornecedor',
            IMPORTSTORETAXES: 'Impostos por Ponto de Venda',
            IMPORTSTORECOSTS: null,
            IMPORTNEWPRICES: 'Novos Preços',
            REGULATEDPRICES: null,
            COMPETITORSPRICES: 'Preços de Concorrentes',
            IMPORTCOSTSANDTAXES: 'Custos e Impostos',
          },
        },
      },
      ADMIN: {
        TITLE: 'Administraçāo',
        MASTER: {
          TITLE: 'Catálogos',
          STORES: 'Pontos de Venda',
          STORES_V2: 'Pontos de Venda',
          SUPPLIER: 'Fornecedor',
          SUPPLIER_V2: 'Fornecedor ',
          TAG: 'Etiqueta',
          TAGS: 'Etiquetas',
          INSIGHTS: 'Insights',
          EQUIPMENTS: null,
          GENERAL_IMPORTER: null,
          INTERFACES_LOG: null,
        },
        SECURITY: {
          TITLE: 'Segurança',
          USERS: 'Usuários',
          ROLES: 'Funções',
        },
      },
      MYSTERYSHOPPER: {
        TITLE: 'Mystery Shopper',
        MISSIONS: {
          TITLE: 'Misões',
        },
        USER_ASSIGNMENT: {
          TITLE: null,
        },
      },
      ITEMMASTER: {
        TITLE: 'Catálogo de Produtos',
      },
      ASSORTMENT: {
        TITLE: 'Sortimentos',
        SETUP: {
          TITLE: 'Configuração',
          CLUSTERING: 'Segmentaçāo',
          CATEGORY_SETUP: 'Configurações de categoria',
          TREEOFCATEGORYS: 'Árvore da Categoria',
          ROLES: 'Papéis',
          STRATEGIES: 'Estratégias',
          SEGMENTASSIGNMENTS: 'Designar Segmentos',
          ROLESANDSTRATEGIESASSIGNMENT: null,
          MARKET_INFORMATION: null,
        },
        DECISIONS: {
          TITLE: 'Decisões',
          ASSORTMENTASIGNATION: 'Decisões de Sortimento',
          INFORM_ERP: 'Publicar Decisões',
          SHOW_COLUMNS_TITLE: 'Selecionar Colunas',
          DINAMIC_COLUMNS_TITLE_ORDER: 'Ordenar',
          DINAMIC_COLUMNS_SUB_TITLE_ORDER: 'Selecionar a ordem dentro de cada grupo de colunas',
          MAX_COLUMNS_SELECTED: null,
          MAX_COLUMNS_EXCEED: null,
          WITHOUT_CLUSTERS_FOR_LEVEL: null,
          DINAMIC_COLUMNS_BTN_CANCEL: 'Cancelar',
          DINAMIC_COLUMNS_BTN_APPLY: 'Aplicar',
          DINAMIC_COLUMNS_TITLE: 'Colunas',
          DINAMIC_COLUMNS_SUB_TITLE: 'Selecionar as colunas que você quer ver na tabela',
          TAGS_IN_USE: null,
          DINAMIC_COLUMNS_GROUP: {
            defaultHeader: 'Geral',
            productHeader: 'Dados do Produto',
            ticketsHeader: 'Tickets',
            performanceHeader: 'Performance',
            stocksHeader: 'Estoque',
            exhibitionHeader: 'Exhibição',
            marketHeader: 'Mercado',
            paretoHeader: 'Pareto',
            clasificationHeader: 'Classificação',
            TGENERAL: 'Total Geral',
            TGENERAL_RECALC: null,
          },
          DINAMIC_COLUMNS: {
            itemCode: 'Código',
            itemName: 'Produto',
            isNew: 'Novo',
            status: 'Estatus',
            segmento: 'Segmento',
            tag: 'Etiqueta',
            itemBrand: 'Marca',
            itemManufacturer: 'Fornecedor',
            pareto: 'Pareto',
            sales: 'Venda R$',
            salesShare: 'Participação em Venda %',
            salesRanking: 'Vendas R$ Ranking',
            soldUnits: 'Vendas UN',
            soldUnitsShared: '% Vendas UN',
            soldUnitsRanked: 'Vendas UN Rank',
            margin: 'Margem R$',
            marginPercentage: 'Margem %',
            marginWithVendor: 'Margem com Fornecedor',
            presence: null,
            discounts: 'Descontos R$',
            price: 'Preço',
            tickets: 'Tickets',
            ticketsTotal: 'Total Tickets',
            ticketsShare: 'Tickets %',
            stockCost: 'Estoque R$',
            stockCostShare: '% Estoque R$',
            stockCostCurrent: 'Estoque Atual R$',
            stockUnits: 'Estoque UN',
            stockUnitsShare: '% Estoque UN',
            stockUnitsCurrent: 'Estoque UN Atual',
            wasteUnits: 'Perda UN',
            availability: 'Disponibilidade',
            outOfStock: 'Ruptura',
            daysInStock: 'Días de Estoque',
            daysInStockCurrent: 'Días de Estoque Atual',
            gmroi: 'GMROI',
            planograms: 'Planogramas',
            popMaterial: 'Material POP',
            facings: 'Frentes',
            linearSpace: 'Espaço Lineal (mt)',
            gmros: 'GMROS',
            marketSales: 'Vendas Mercado R$',
            marketUnits: 'Vendas Mercado UN',
            marketPrice: 'Preço do Mercado',
            marketSalesShare: '% Participação de Mercado R$',
            marketSalesRanking: 'Ranking de Vendas do Mercado',
            marketUnitsShare: '% Vendas Mercado UN',
            marketUnitsRanking: 'Ranking de Unidades do Mercado',
            salesShareGap: 'GAP de Vendas',
            unitsShareGap: 'GAP de Unidades',
            weightedIndex: 'IP %',
            weightedIndexPareto: 'Pareto',
            IP: 'IP %',
            UN: 'UN',
            suggestionRoleName: null,
            suggestionRoleAction: null,
          },
        },
        REPORTS: {
          TITLE: 'Relatórios',
          CLUSTERANALYSIS: 'Analise de Cluster',
          REPORT_DECISIONS: 'Relatórios de Decisões',
        },
        ADMINISTRATION: {
          TITLE: 'Administraçāo',
          IMPORTERS: {
            TITLE: 'Importadores',
            MARKET_INFORMATION: 'Informação do Mercado',
            MARGIN_WITH_VENDOR: null,
          },
        },
      },
      PROMOTIONS: {
        TITLE: 'Promoções',
        CAMPAIGN: {
          TITLE: 'Campanhas',
        },
        ACTIONS: {
          TITLE: 'Ações',
        },
      },
      PROMOTIONSV2: {
        TITLE: 'Promoções',
        CONFIGURATIONS: {
          TITLE: 'Configuração',
          RULES: {
            TITLE: 'Regras de Promoções',
          },
          REFERENCE_PRICES: {
            TITLE: 'Preços de Referência',
          },
          PROMOTIONAL_PROCESS: {
            TITLE: 'Processos Promocionais',
          },
        },
        CAMPAIGN: {
          TITLE: 'Campanhas ',
        },
        CALENDAR: {
          TITLE: 'Calendario',
        },
        PERIODS: {
          TITLE: null,
        },
        PROMOTIONAL_PERIOD: {
          TITLE: 'Períodos promocionais',
        },
        ACTIONS: {
          TITLE: 'Ações',
        },
        PRODUCTIONLIST: {
          TITLE: null,
        },
        PRODUCTION_REPORTS: {
          TITLE: 'Relatórios operacionais',
        },
        PROMOTION_OUTPUT: {
          TITLE: 'Output de promoções',
        },
        LOGISTICS_REPORT: {
          TITLE: null,
        },
        AVAILABLE_SPACES: {
          TITLE: 'Material promocional',
        },
        PROMOTION_REPORT: {
          TITLE: null,
        },
        MARKETPLACE: {
          TITLE: null,
        },
        REPORTS: {
          TITLE: null,
        },
      },
      SPACES: {
        REPORTS_TITLE: 'Relatórios',
        DECISIONS_TITLE: 'Decisões',
        ADMIN_TITLE: 'Administração',
        MATERIAL_PROM_TITLE: 'Material promocional',
        CONFIG_TITLE: 'Configuração',
        INCONSISTENCIES: {
          TITLE: 'Inconsistências',
        },
        FLOORPLANS: {
          TITLE: 'Floor Plans',
        },
        SPACES_TEMPLATES: {
          TITLE: 'Templates',
        },
        TITLE: 'Espaços',
        TITLEV2: 'Espaços',
        PLANOGRAMS: {
          TITLE: 'Planogramas',
        },
        STRUCTURE: {
          TITLE: 'Estruturas',
        },
        GENERIC_SPACE: {
          TITLE: 'Espaço Genérico',
        },
        POPMATERIAL: {
          TITLE: 'Material POP',
        },
      },
      REPORTS: {
        TITLE: 'Relatórios',
        LIST: {
          TITLE: null,
        },
      },
      SUPPLY: {
        TITLE: 'Abastecimento Inteligente',
        SETUP: {
          TITLE: 'Configuração',
          ORDER_SCHEDULE: 'Programação de pedido',
          GENERAL: 'Geral',
          HOLIDAYS: 'Feriados',
          CAPACITIES: 'Capacidades',
        },
        DECISIONS: {
          TITLE: 'Decisões',
          ORDER_CONTROL: 'Controle de Pedidos',
        },
        ADMINISTRATION: {
          TITLE: 'Administraçāo',
          IMPORTERS: 'Importadores',
        },
      },
    },
    TEST_COMPONENT: {
      TEST_A: 'Frase de teste A',
      TEST_B: 'Frase de teste B',
      ANIDADA: {
        TEST_C: 'Frase de teste C',
      },
      TEST_D: 'Esta frase tem {{val}} valor.',
      TEST_E: 'Esta frase {{val}} valores, não {{val2}} nem {{val3}}',
      TEST_F: 'Esta outra frase tem {{val}} valor.',
      TEST_G: 'Esta frase tem {{val}}, {{val2}} o {{val3}} valores.',
      TEST_H: 'Frase con {{val}} anidadoes',
      TEST_HB: 'VALORES MULTIPLES',
      TEST_I: 'Esta frase tiene {{val}} {{val2}}',
      TEST_I_sinfular: 'valor',
      TEST_I_plural: 'valores',
      TEST_J_ENUM: {
        '1': 'Designado',
        '2': 'Não Designado',
      },
    },
    DATA_ENTRY_ADAPTATIONS: {
      TITLE: 'Administração de Entrada de Dados',
      NEW_ITEM_BTN: 'Nova Entrada de Dados',
      TABLE: {
        TYPE: 'Tipo de Entrada',
        NAME: 'Nome',
      },
      EDIT: {
        TRANSFORMATION_TYPES: {
          Constant: 'Constante',
          Negative: 'Negativo',
          ToPercentage: 'A Porcentagem',
          Trim: 'Recortar',
          TrimStart: 'Recortar Início',
          TrimEnd: 'Recortar Fim',
          ToBoolean: 'A Booleano',
          ConcatHeadersValues: 'Concatenar Valores',
          SubString: 'Subcadeia',
          PadLeft: 'Preenchimento à Esquerda',
          PadRight: 'Preenchimento à Direita',
        },
        UPLOADED_FILE: 'Arquivo Carregado',
        STANDARD_LOAD: 'Carga Padrão',
        ONLY_FIRST_FIVE: 'Apenas os primeiros 5 registros são mostrados',
        CONFIGURE_TRANSFORMATION: 'Configurar Transformação',
        SOURCE_COLUMN: 'Coluna de Origem',
        TARGET_COLUMN: 'Coluna de Destino',
        TRANSFORMATION: 'Transformação',
        ADD_TRANSFORMATION: 'Adicionar Transformação',
        VALUE: 'Valor',
        CHARACTER: 'Caracter',
        TOTAL_WIDTH: 'Largura Total',
        PADDING_CHAR: 'Caracter de Preenchimento',
        INDEX: 'Índice',
        LENGTH: 'Comprimento',
        VALUE_FOR_TRUE: 'Valor para Verdadeiro',
        TYPE_ENTRY: 'Tipo de Entrada',
        NAME_INPUT: 'Nome',
        TAB1: 'Dados Gerais',
        NEW_DATA_ENTRY: 'Nova Entrada de Dados',
        NO_MAPPING_SUGGESTION: 'Não há uma sugestão de coluna de origem para a coluna destino',
      },
      COMP: {
        FILE_UPLOAD: {
          TITLE: 'Arraste o arquivo aqui ou clique para selecionar',
          SIZE_ERROR: 'O arquivo excede o tamanho máximo permitido',
          TYPE_ERROR: 'Tipo de arquivo não permitido',
          ALL_FILES: 'Todos os arquivos',
          ACCEPTED_TYPES: 'Tipos de arquivo aceitos: {{fileTypes}}',
        },
      },
    },
    HOME: {
      ROUTE: 'Inicio',
      TITLE: 'Bem-vindo',
      PRICING_MODULE: 'Preços',
      ASSORTMENT_MODULE: 'Sortimentos',
      SMART_SUPPLY_MODULE: 'Abastecimento Inteligente',
      PROMOTIONS_MODULE: 'Promoções',
      ITEM_MASTER_MODULE: 'Catálogo de Produtos',
      SPACE_MODULE: 'Espaços',
      QUICK_ACCESS: 'Acesso Rápido',
      PRICING_MODULE_RULES: 'Preços - Regras de Preço',
      PRICING_MODULE_DESICIONS: 'Preços - Decisões de Preço',
      ASSORTMENT_MODULE_ROL: 'Sortimento - Papéis',
      ASSORTMENT_MODULE_STRATEGIES: 'Sortimento - Estratégias',
      ASSORTMENT_MODULE_DECISIONS: 'Sortimento - Decisões de Sortimento',
      ITEM_MASTER_MODULE_ABM: 'Catálogo de Produtos. - ABM',
      HELPS: 'Ajuda',
      HELP_1: 'Como criar uma regra de Margem?',
      ARTICLE_BLOG_TITLE: 'Percepções de preço, escolhas do consumidor e o efeito compromisso',
      ARTICLE_BLOG_TITLE2: 'Como extrair o máximo valor de seus dados de varejo',
      READ_MORE_BTN: 'Ler mais',
      PRISMA_PRICES: 'Prisma Preços',
      COMPETITORS_PRICES: 'Preços dos Concorrentes',
      ARTICLE2_BLOG_TITLE: 'Cestas de Alimentos: pronto para comprar com um único clique',
      ARTICLE3_BLOG_TITLE: 'A sua estratégia de preços não está funcionando? Estas podem ser as 4 razões.',
      PRISMA_SPACES: 'Prisma Espaços',
      PRISMA_SPACES_SUB: 'Para varejistas que desejam tornar seus planogramas mais lucrativos',
    },
    PR: {
      STP: {
        COMP: {
          LIST: {
            TITLE: 'Concorrentes',
            DELETE_TTIP: 'Apagar concorrentes selecionados',
            DELETE: 'Apagar',
            FILTER_TTIP: 'Filtrar',
            VIEW_TTIP: 'Vista',
            VIEW_TABLE: 'Apenas tabelas',
            VIEW_MAP: 'Apenas mapas',
            VIEW_TABLE_MAP: 'Mapa e Tabela',
            NAME: 'Nome',
            CODE: 'Código',
            BRAND: 'Bandeira',
            MARKER: 'Marcador',
            EDIT_ROW: 'Editar',
            EDIT_ROW_DISABLED_TOOLTIP: null,
            DELETE_ROW: 'Apagar',
          },
          ROUTE: 'Preços / Configuração',
          TITLE: 'Concorrentes',
          NEW_COMP_BTN: 'Novo Concorrente',
          IMPORT_BTN: 'Importar',
          IMPORT_OP1_314: 'Preços da 314',
          IMPORT_OP2_COMP: 'Preços dos Concorrentes',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          VIEW_TTIP: 'Vista',
          VIEW_TABLE: 'Apenas tabelas',
          VIEW_MAP: 'Apenas mapas',
          VIEW_TABLE_MAP: 'Mapa e Tabela',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Apagar concorrentes selecionados',
          DELETE: 'Apagar',
          NAME: 'Nome',
          CODE: 'Código',
          BRAND: 'Bandeira',
          MARKER: 'Marcador',
          TYPE: 'Tipo',
          CHANNEL: 'Canal',
          PROVINCE: 'Estado',
          LOCATION: 'Localidade',
          PRICEZONE1: 'Zona de Preço 1',
          PRICEZONE2: 'Zona de Preço 2',
          PRICEZONE3: 'Zona de Preço 3',
          NEW: 'Novo',
          INCOMPLETES: 'Incompletos',
          TITLE_FILTERS: 'Filtros',
          MARKER_FILTER: 'Marcador',
          OP1_ALL: 'Todos',
          OP2_MARKER: 'Marcador',
          OP3_NO_MARKER: 'Sem Marcador',
          BRAND_FILTER: 'Bandeira',
          OPERATION_TYPE_FILTER: 'Tipo de Operação',
          PROVINCES_FILTER: 'Estado',
          LOCATION_FILTER: 'Localidade',
          CLEAN_FILTER_BTN: 'Limpar',
          APPLY_FILTER_BTN: 'Aplicar',
          MODAL_314: 'Os 314 dados foram importados corretamente',
          TITLE_FORM: 'Novo Concorrente',
          SAVE_BTN: 'Salvar',
          BASIC_INFORMATION_TAB: 'Informação Básica',
          CODE_INPUT: 'Código Interno',
          EXTERNALCODE_INPUT: 'Código Externo',
          VALIDATION_CODE: 'O campo deve ser maior que 0',
          NAME_INPUT: 'Nome',
          BRAND_INPUT: 'Bandeira',
          MARKER_CHECK: 'Marcador',
          MARKER_CHECK_TTP: 'Definir concorrente como marcador',
          ADRESS_INPUT: 'Endereço',
          MAP_DATA: 'Dados Mapa',
          LATITUDE_INPUT: 'Latitude',
          VALIDATION_LATITUDE1: 'Latitude deve ser menor ou igual a 90',
          VALIDATION_LATITUDE2: 'Latitude deve ser maior ou igual a -90',
          LONGITUDE_INPUT: 'Longitude',
          VALIDATION_LONGITUDE1: 'Longitude deve ser menor ou igual a 180',
          VALIDATION_LONGITUDE2: 'Longitude deve ser maior ou igual a -180',
          CLASIFICATION_TAB: 'Classificasão',
          Nuevo: 'Novo',
          Incompleto: 'Incompleto',
        },
        SENSITIVITIES: {
          NEW_SENSITIVITIE: 'Nova sensibilidade',
          SENSITIVITIE: 'Sensibilidade',
          NAME: 'Nome',
          COD: 'Código',
          SURVEYFREQUENCY: 'Frequência da pesquisa',
          EXPIRATIONDAYS: 'Data de Validade',
          VALIDITYINDAYS: 'Días de Validade',
          VALIDATION_1: 'O campo deve ser maior que 0',
          SAVE_BTN: 'Salvar',
          BASIC_INFORMATION_TAB: 'Dados Principais',
          DAYSTORELIEVE: 'Dia de Levantamento',
          _weekday: {
            Monday: 'Seg',
            Tuesday: 'Ter',
            Wednesday: 'Qua',
            Thursday: 'Qui',
            Friday: 'Sex',
            Saturday: 'Sab',
            Sunday: 'Dom',
          },
          _frecuency: {
            Daily: 'Diário',
            Weekly: 'Semanal',
            Biweekly: 'Quinzenal',
            Monthly: 'Mensal',
            Biannual: 'Semestral',
            Annual: 'Anual',
            BiMonthly: 'Bimestral',
            ThreeMonth: 'Trimestral',
            Never: 'Nunca',
          },
        },
        ROUND: {
          ROUTE: 'Preços / Configuração',
          TITLE: 'Regras de Arredondamento',
          NEW_ROUNDING_BTN: 'Nova Regra de Arredondamento',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Apagar arredondamentos selecionados',
          DELETE: 'Apagar',
          PRICE_TYPE: 'Tipo de Preço',
          SINCE: 'Desde',
          TO: 'Para',
          DIRECTION: 'Endereço',
          TO_TOP_OP1: 'Para Cima',
          NEAREST_OP2: 'Mais Perto',
          DOWN: 'Para Baixo',
          ROUNDING_TYPE: 'Tipo de Arredondamento',
          WITHOUT_TERMINATIONS: 'Sem Terminações',
          TOLERANCE: 'Tolerância %',
          SKUS: '# Produtos',
          SKUS_TO_EXCLUDE: '# Produtos a Excluir',
          USERS: '# Usuários',
          DEFINE_ROUNDING_STP1: 'Definir Arredondamento',
          PRODUCT_SCOPE_STP2: 'Escopo Produtos',
          STORE_SCOPE_STP3: 'Escopo Ponto de Venda',
          STP1: 'Passo 1 - Definir Arredondamento',
          TITLE_FORM_NEW: 'Novo Arredondamento',
          TITLE_FORM_EDIT: 'Editar Arredondamento',
          PRICE_TYPE_INPUT: 'Tipo de Preço Inicial',
          SINCE_INPUT: 'Desde',
          TO_INPUT: 'Para',
          TOLERANCE_INPUT: 'Tolerância %',
          DIRECTION_INPUT: 'Endereço',
          ROUNDING_TYPE_INPUT: 'Tipo de Arredondamento',
          ROUNDING_UNITS_OP1: 'Unidades de Arredondamento',
          TERMINATIONS_OP2: 'Terminações',
          ROUNDING_UNITS_INPUT: 'Unidades de Arredondamento',
          TERMINATIONS_INPUT: 'Terminações',
          NEXT_BTN: 'Seguinte',
          NEW_TERMINATION: 'Nova Terminaçāo',
          TERMINATION_MODAL_INPUT: 'Terminaçāo',
          NO_TERMINATION: 'Nenhuma Terminaçāo Adicionada',
          ADDED_TERMINATION: 'Terminaçāo Adicionada',
          ADDED_TERMINATIONS: 'Terminações Adicionadas',
          ADD_BTN: 'Adicionar',
          STP2: 'Passo 2 - Escopo Produtos',
          ADD_ATTRIBUTE_BTN: '+',
          PREVIUS_BTN: 'Prévio',
          STP3: 'Passo 3 - Escopo Pontos de Venda',
          SAVE_BTN: 'Salvar',
          NONE: 'Nenhum',
          ALL: 'Todos',
          EXCLUDED: 'Excluido',
          SEARCH: 'Procurar',
          milesimas: 'Milésimos ',
          centesimas: 'Centésimos ',
          decimas: 'Décimos ',
          unidades: 'Unidades ',
          decenas: 'Dezenas ',
          centenas: 'Centenas ',
          miles: 'Milhares ',
          decenas_de_miles: 'Dezenas de Milhares',
          Nearest: 'Ao mais perto',
          Down: 'Para baixo',
          Up: 'Para cima',
        },
        RULES: {
          DELETE_MODAL: {
            TITLE_ONE: null,
            CONTENT: null,
            TITLE_MULTIPLE: null,
          },
          PARENT_STORE_TITLE: 'Parentesco de {{val}}',
          PARENT_ITEM_TITLE: 'Parentesco de Produtos',
          COMPETENCE_TITLE: 'concorrência',
          MARGIN_TITLE: 'Margem',
          SELECT_ALL_SUBCATEGORY: 'Selecionar todas as subcategorias',
          SELECT_ALL_CATEGORY: 'Selecionar todas as categorias',
          ROUTE: 'Preços / Configuração',
          TITLE: 'Regras de Preço',
          SUGGESTION_BTN: 'Gerar Sugestões',
          GENERATE_SUGGESTIONS_TOOLTIP: 'Para gerar sugestões, você deve selecionar uma categoria (apenas uma) ou itens da lista.',
          GENERATE_SUGGESTIONS_TOOLTIP_WITH_CATEGORY_REQUIRED: 'Para poder gerar sugestões deve selecionar uma categoria por ves',
          GENERATE_SUGGESTIONS_TOOLTIP_WITH_CATEGORY_REQUIRED_MODAL_DESCRIPTION: null,
          GENERATE_SUGGESTIONS_SELECTION: null,
          SUGGESTION_OP1_MARGIN: 'Margem',
          SUGGESTION_OP2_COMPETITION: 'concorrência',
          NEW_RULE_BTN: 'Nova Regra',
          NEW_RULE_TITLE_MODAL: 'Nova Regra',
          RULE_TYPE_INPUT: 'Tipo de Regra',
          ADDITIONAL_FILTERS: 'Filtros adicionais',
          LEGEND_COMP: 'As regras de concorrência são acionadas quando uma mudança nos preços dos concorrentes é detectada.',
          LEGEND_MARG: 'As regras de margem são acionadas quando uma mudança no fornecedor ou nos custos de fabricação é detectada.',
          LEGEND_P_STORES: 'Regras pai de {{val}} são acionadas toda vez que o preço de um pai {{val2}} é alterado.',
          STORES_VAL: 'Pontos de Venda',
          LEGEND_P_ITEMS: 'As regras de parentesco de produtos são avaliados sempre que o preço de um produto pai é alterado.',
          CANCEL_BTN: 'Cancelar',
          CREATE_BTN: 'Criar',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Apagar regras selecionadas',
          DELETE: 'Apagar',
          CLEAN_FILTERS: 'Limpar Filtros',
          SHOW_RULES: 'Selecione pelo menos um filtro para mostrar as regras',
          NAME: 'Nome',
          TYPE: 'Tipo',
          STATE: 'Estado',
          COUNTRY: 'País',
          ZONE: 'Zona',
          STORES: '# Pontos de Venda',
          ITEMS: '# Produtos',
          MARGIN: 'Margem %',
          IPC: 'IPC %',
          LAST_MODIFICATION: 'Última Mudança',
          USER: 'Usuário',
          TYPE_RULES: 'Regras de Preço',
          SUGGESTIONS_GENERATED_SNACKBAR:
            'O processo de geração de sugestões foi iniciado. Você receberá uma notificação quando este processo for concluído.',
          INCLUDED_ITEMS: 'Produtos Incluídos',
          ACCEPT_BTN: 'Aceitar',
          INCLUDED_STORES: 'Pontos de Venda Incluídos',
          TITLE_FILTERS: 'Filtros',
          CATEGORY_FILTER: 'Categoria',
          SEARCH_CATEGORIES_FILTER: 'Procurar Categorias',
          SUBCATEGORY_FILTER: 'Subcategorias',
          SEARCH_SUBCATEGORIES_FILTER: 'Procurar Categorias',
          RULE_TYPE_FILTER: 'Tipo de Regra',
          USER_FILTER: 'Usuário',
          SEARCH_USER_FILTER: 'Procurar Usuário',
          CLEAN_FILTER_BTN: 'Limpar',
          APPLY_FILTER_BTN: 'Aplicar',
          DEFINE_RULE_STP1: 'Definir Regra',
          PRODUCT_SCOPE_STP2: 'Escopo Produtos',
          STORE_SCOPE_STP3: 'Escopo Pontos de Venda',
          STP3_STORE: 'Passo 3 - Escopo Produtos',
          STP2_STORE: 'Passo 2 - Escopo Pontos de Venda',
          STP4_COMPETENCE: 'Passo 5 - Limites por Concorrente',
          STP5_COMPETENCE: 'Passo 6 - Limites de Margem e Variaçāo',
          STP5_COMPETENCE_ALT: 'Passo 5 - Limites de Margem e Variaçāo',
          STP3_MARGIN: 'Passo 4 - Limites por Concorrente',
          STP4_MARGIN: 'Passo 5 - Limites por Margem',
          STP4_MARGIN_ALT: null,
          STORE_SCOPE_STP5: 'Limites por Concorrente',
          STORE_SCOPE_STP6: 'Limites de Margem e Variaçāo',
          TITLE_FORM_NEW: 'Nova Regra de Margem ou Markup',
          TITLE_FORM_EDIT: 'Regra Margem ou Markup',
          STP1: 'Passo 1 - Definir Regra',
          NAME_INPUT: 'Nome',
          QUERY: 'Descriçāo / Código',
          POSITION_TYPE_INPUT: 'Tipo de Regra',
          INDICATE_MARGIN_INPUT: 'Indicar Margem',
          VALIDATION_MARGIN: 'O campo deve ser menor que 100',
          NEXT_BTN: 'Seguinte',
          STP2: 'Passo 2 - Escopo Produtos',
          ADD_ATTRIBUTE_BTN: '+',
          SEARCH: 'Procurar',
          SEE_PROD: 'Ver Produtos',
          CODE: 'Código',
          BRAND: 'Marca',
          MANUFACTURER: 'Fabricante',
          PRICE: 'Preço R$',
          COST: 'Custo R$',
          PRICE_CHIP: 'Preço: R$',
          COST_CHIP: 'Custo: R$',
          REFRESH_PRODUCTS: 'Atualizar Produtos',
          REFRESH_STORES: 'Atualizar Pontos de Venda',
          SEE_STORE: 'Ver Pontos de Venda',
          SELECT_PROD: 'Produtos Selecionados ',
          SELECT_STORE: 'Pontos de Venda Selecionados ',
          FILTER_PROD: 'Produtos Filtrados ',
          FILTER_STORE: 'Pontos de Venda Filtrados ',
          PRICE_TABLE: 'Preço: R$',
          COST_TABLE: 'Custo: R$',
          ADD_PROD: 'Adicionar Produtos ao Listado',
          CHANGE_FORM: 'Mudar Filtros',
          PREVIUS_BTN: 'Anterior',
          STP3: 'Passo 3 - Escopo Pontos de Venda',
          SAVE_BTN: 'Salvar',
          EXIT_BTN: 'Sair',
          SKIP_BTN: 'Omitir',
          NONE: 'Nenhum',
          ALL: 'Todos',
          EXCLUDED: 'Excluido',
          COMPETITORS_SCOPE_STP2: 'Escopo Concorrentes',
          PARENT_PRODUCT_SCOPE_STP1: 'Definir Regra',
          PARENT_PRODUCT_SCOPE_STP2: 'Escopo Produtos',
          PARENT_PRODUCT_SCOPE_STP3: 'Escopo Pontos de Venda',
          PARENT_PRODUCT_SCOPE_STP4: 'Configuração de Regra de Parentesco de Itens',
          PARENT_PRODUCT_SCOPE_STP1_TITLE: 'Passo 1 - Definir Regra',
          PARENT_PRODUCT_SCOPE_STP2_TITLE: 'Passo 2 - Escopo Produtos',
          PARENT_PRODUCT_SCOPE_STP3_TITLE: 'Passo 3 - Escopo Pontos de Venda',
          PARENT_PRODUCT_SCOPE_STP4_TITLE: 'Passo 4 - Resumo',
          PARENT_STORE_SCOPE_STP1: 'Definir Regra',
          PARENT_STORE_SCOPE_STP2: 'Escopo Pontos de Venda',
          PARENT_STORE_SCOPE_STP3: 'Escopo Produtos',
          PARENT_STORE_SCOPE_STP1_TITLE: 'Passo 1 - Definir Regra',
          PARENT_STORE_SCOPE_STP2_TITLE: 'Passo 2 - Escopo Pontos de Venda',
          PARENT_STORE_SCOPE_STP3_TITLE: 'Passo 3 - Escopo Produtos',
          STORE_SCOPE_STP4: 'Escopo Pontos de Venda',
          COMPETITION_TITLE_FORM_NEW: 'Nova Regra de concorrência',
          COMPETITION_TITLE_FORM_EDIT: 'Regra de concorrência',
          POSITIONING_INPUT: 'Posicionamento',
          PERCENT_MARGIN_OBJETIVE: '%',
          ONLY_ALERT: 'Apenas Alertar: Gerará um alerta na tela de decisão de preço, mas não gerará nenhuma sugestão de mudança.',
          DO_NOTHING: null,
          IN_CASE: 'Em caso de não encontrar os preços de concorrentes:',
          PRICE_SUGGESTION_STEP5: 'O preço sugerido deve estar:',
          SELECTION_COMPETITORS_STEP5: 'Selecção dos concorrentes para calcular o limite:',
          RADIO_MIN_STEP5: 'Como mínimo um',
          RADIO_MAX_STEP5: 'Como máximo um',
          RADIO_AVG_STEP5: 'Introduzir',
          MORE: 'mais',
          AND_THE: 'e a/o',
          COMPETITORS_STEP5: 'quais concorrentes selecionar',
          COMPETITORS_STEP5_SECONDVALUE_CHEAP: 'Mais barato',
          COMPETITORS_STEP5_SECONDVALUE_EXPENSIVE: 'Mais caro',
          COMPETITORS_AVG_STEP5: 'com relação aos concorrentes a serem selecionados nesta etapa',
          SUGESSTION_LOW_STEP5: 'Sugerir abaixo do mínimo',
          SUGESSTION_HIGH_STEP5: 'Sugerir acima do máximo',
          TITLE_COMPETITORS_STEP6:
            'Diretrizes específicas de margem e variação podem ser definidas para esta regra. Se você quiser usar as diretrizes da categoria, salve as alterações diretamente, pulando esta etapa.',
          TITLE_COMPETITORS_STEP6_NOTICE: 'Se este limite for configurado, a configuração dos limites da categoria será substituída.',
          LINEAL_MARGIN_COMPETITORS_STEP6: 'Diretrizes de Margem',
          MIN_MARGIN_INPUT_STEP6: 'Margerm Min %',
          MAX_MARGIN_INPUT_STEP6: 'Margem Max %',
          MIN_MARGIN_INPUT_STEP6_ALT: null,
          MAX_MARGIN_INPUT_STEP6_ALT: null,
          VARIATION_MARGIN_INPUT_STEP6: 'Variaçāo Significativa Margem R$',
          MIN_SUGGESTION_MARGIN_INPUT_STEP6: 'Sugerir abaixo do mínimo',
          MAX_SUGGESTION_MARGIN_INPUT_STEP6: 'Sugerir acima do máximo',
          VARIATION_PRICE_COMPETITORS_STEP6: 'Variaçāo de Preço',
          MIN_VARIATION_PRICE_INPUT_STEP6: 'Queda Máxima %',
          MAX_VARIATION_PRICE_INPUT_STEP6: 'Aumento Máximo %',
          MIN_VARIATION_PRICE_INPUT_STEP6_ALT: null,
          MAX_VARIATION_PRICE_INPUT_STEP6_ALT: null,
          MIN_SUGGESTION_VARIATION_PRICE_INPUT_STEP6: 'Sugerir abaixo do mínimo',
          MAX_SUGGESTION_VARIATION_PRICE_INPUT_STEP6: 'Sugerir acima do máximo',
          MARGIN_OBJETIVE: 'Definir Margem Objetivo %',
          MARGIN_OBJETIVE_CATEGORY: 'Objetivo de margem por Subcategorias',
          MARGIN_OBJETIVE_CATEGORY_HELP: null,
          POSITION_AGAINST_INPUT: 'Se Posicionar Contra',
          POSITION_TREND_INPUT: 'Se houver mais de uma tendência a ser usada\n',
          SURVEY_SOURCES_INPUT: 'Fontes de Estudo',
          STP2_COMP: 'Passo 2 - Escopo Concorrentes',
          STP2_COMP_BUTTON_TITLE: 'Selecione como você deseja comparar com seus concorrentes.',
          MAINMARKET_TITLE: 'Principal Concorrente',
          MAINMARKET_MSG: 'Irá pesquisar o preço do Prinicpal Concorrente de cada Ponto de Venda.',
          USE_TRADE_AREA_TITLE: 'Área Comercial',
          USE_TRADE_AREA_MSG: 'Irá pesquisar o preço dos Concorrentes associados à Área Comercial de cada Ponto de Venda',
          SELECT_COMPETITORS_TITLE: 'Seleção de Concorrrentes',
          SELECT_COMPETITORS_MSG: 'Ele irá procurar por concorrentes que atendam aos critérios a serem definidos abaixo',
          TRADE_AREA_CHECK: 'Apenas Área Comercial',
          MARKERS_CHECK: 'Apenas Selecionar Principais Concorrentres',
          STP3_COMP: 'Passo 3 - Escopo Produtos',
          STP4_COMP: 'Passo 4 - Escopo Pontos de Venda',
          STORE_PARENT_TITLE_FORM_NEW: 'Nova Regra Parentesco de Ponto de Venda',
          STORE_PARENT_TITLE_FORM_EDIT: 'Regra Parentesco de Ponto de venda',
          STORE_PARENT_INPUT: 'Ponto de Venda Pai',
          STORE_PARENT_TTIP:
            'Apenas as lojas que não foram designadas como lojas relacionadas em outras regras aparecerão na lista de lojas-pai.',
          SEARCH_PARENT: 'Procurar Pai',
          RELATIONSHIP_TYPE_INPUT: 'Posicionamento de itens filhos',
          DIFFERENCE_IN_OPTION: 'Diferença en',
          DIFFERENCE_IN_INPUT: 'Condição',
          relationshipType_Price: 'Avaliar preço',
          relationshipType_Margin: 'Avaliar margem',
          relationshipType_DeltaPrice: 'Clonar valores do item pai',
          EVALUATE_PARENT_ITEM_PRICE: 'Avaliar preço do item pai',
          EVALUATE_PARENT_ITEM_MARGIN: 'Avaliar margem do item pai',
          DIFFERENCE_INPUT: 'Valor',
          DIFFERENCE_INPUT_NOT_ZERO:
            'O valor deve ser diferente de 0 (Zero). Se desejar replicar o valor do item pai, deve selecionar “Igualar margem/preço do item pai” no campo Condição.',
          TEXT_P_STORES: 'Os {{val}} filhos terão {{val2}} {{val3}} pai',
          CATEGORIES_INPUT: 'Categorias*',
          CATEGORIES_INPUT_EXC: 'Categoprias Excluídas*',
          CATEGORIES_TTIP: 'Você deve selecionar pelo menos uma categoria, para selecionar as subcategorias',
          SEARCH_CATEGORIES: 'Procurar Categorias',
          SUBCATEGORIES_INPUT: 'Subcategorias*',
          SUBCATEGORIES_INPUT_EXC: 'Subcategorias Excluídas*',
          SUBCATEGORIES_TTIP: 'Você deve selecionar pelo menos uma subcategoria por categoria selecionada',
          SEARCH_SUBCATEGORIES: 'Procurar Subcategorias',
          SELECT_ALL: 'Selecionar todos',
          VALIDATION_CATEGORY: 'Você deve selecionar pelo menos uma categoria',
          VALIDATION_SUBCATEGORY: 'Você deve selecionar pelo menos uma opção para cada categoria selecionada',
          GET_OUT_BTN: 'Sair',
          SAME_PRICE: 'O mesmo preço que ',
          HIGHER: 'Superior',
          LOWER: 'Inferior',
          UNDER: 'em baixo',
          ABOVE: 'acima de',
          MAYOR: 'Maior',
          MINOR: 'Menor',
          TEXT_P_STORES_TS: 'um preço {{val}} em {{val2}} no',
          ITEM_PARENT_TITLE_FORM_NEW: 'Nova Regra Parentesco de Produto',
          ITEM_PARENT_TITLE_FORM_EDIT: 'Regra Parentesco de Produto',
          PARENT_RULE_GENERATE_SUGGESTION_TITLE: null,
          PARENT_RULE_ONSAVE_GENERATE_SUGGESTION_CONTENT: null,
          ITEM_PARENT_INPUT: 'Produto Pai',
          ITEM_PARENT_TTIP:
            ' Apenas os produtos que não foram atribuídos como produtos filhos em outras regras aparecerão na lista de produtos pai.',
          TEXT_P_COMPETENCE: 'Posicione um {{val}} ',
          TEXT_P_COMPETENCE_TS: '{{val}} {{val2}} o {{val3}} ({{val4}}) de meus concorrentes',
          TEXT_P_COMPETENCE_TRADEAREA: 'dentro de {{val}} de cada Ponto de Venda',
          TEXT_P_COMPETENCE_MARKERS: 'Principais',
          TEXT_P_COMPETENCE_COMPETITORS_SELECTED: 'Selecionados',
          TEXT_P_COMPETENCE_STP3: 'dentro de {{val}} de cada Ponto de Venda',
          TEXT_P_COMPETENCE_STP4: ', para artigos selecionados',
          TEXT_P_COMPETENCE_STP4_ALL: ', para todos os artigos ',
          TEXT_P_ACTUAL_MARGIN: '{{val}} {{val2}}',
          TEXT_P_MARGIN: 'Posição com um {{val}} {{val2}}',
          TEXT_P_MARGIN_TS_1: 'para artigos selecionados',
          TEXT_P_MARGIN_TS_2: 'nas regiões requeridas ',
          TEXT_P_MARGIN_POSITION: '{{val}} de {{val2}}',
          TEXT_P_MARGIN_POSITION_0: 'Margem',
          TEXT_P_MARGIN_POSITION_1: 'Margem',
          TEXT_P_MARGIN_POSITION_2: 'Manter margem atual em %',
          TEXT_P_MARGIN_POSITION_4: 'Manter margem atual em R$',
          TEXT_P_MARGIN_POSITION_5: 'Margem objetivo por zona',
          TEXT_P_MARGIN_POSITION_6: 'Markup',
          TEXT_P_MARGIN_POSITION_7: null,
          MARGIN_POSITION_TYPE_0: 'Margem em %',
          MARGIN_POSITION_TYPE_1: 'Margem em R$',
          MARGIN_POSITION_TYPE_2: 'Manter a Margem Atual em %',
          MARGIN_POSITION_TYPE_4: 'Manter Margem Atual em R$',
          MARGIN_POSITION_TYPE_5: 'Margem objetivo por zona',
          MARGIN_POSITION_TYPE_6: 'Markup em %',
          MARGIN_POSITION_TYPE_7: null,
          LEGEND_RULE_TITLE: 'REGRA: ',
          TEXT_P_ITEMS: 'Os produtos filhos terāo {{val}} {{val2}}',
          TEXT_P_ITEMS_VARIANT: 'Os produtos filhos terāo a mesma variaçāo de preço que {{val}} {{val2}}',
          TEXT_P_ITEMS_TS: 'um preço {{val}} em {{val2}} que ',
          TEXT_P_ITEMS_TS_MARGEN: 'uma margem de {{val}} que ',
          TEXT_P_STORE: 'Os Pontos de Venda filhos terāo {{val}} {{val}}',
          TEXT_P_STORE_VARIANT: 'Os Pontos de Venda filhos terāo a mesma variaçāo de preço que {{val}}',
          SEARCH_RESULT_CHILD_STORE: '{val}} Pontos de venda serão afetados por esta regra',
          TEXT_P_STORE_TS: 'um Preço {{val}} em {{val2}} para ',
          MARK_AS_ZONE: 'Marcar como Zona',
          MARK_AS_ZONE_TTIP: 'Mesmo preço para todas as áreas',
          STRATEGY: 'Escolha a estratégia de seleção dos produtos filhos',
          STRATEGY_ATTR_TITLE: 'Seleção por atributo',
          STRATEGY_ATTR_MSG: 'Os atributos são selecionados para definir o escopo dos produtos',
          STRATEGY_STORE_ATTR_MSG: 'Os atributos são selecionados para definir o escopo dos Pontos de Venda',
          STRATEGY_PROD_TITLE: 'Seleção por produto',
          STRATEGY_STORE_TITLE: 'Seleção por Ponto de Venda',
          STRATEGY_PROD_MSG: 'Os produtos devem ser selecionados um por um',
          STRATEGY_STORE_MSG: 'Os Pontos de Venda devem ser selecionados um a um',
          STP3_PARENT_ITEM: 'Passo 3 - Escopo Pontos de Venda',
          GENERIC_MSG_ERRROR_EXECSUGGESTION: 'Não foi possível gerar sugestões.',
          GENERIC_MSG_NOSUGGESTIONS_EXECSUGGESTION: 'Não há novas sugestões.',
          NO_DIFF: null,
          LEVEL_SELECT: null,
          RULE_NAME: 'Nome da regra',
          RULE_CONDITION: 'Condição',
          RULE_CLONE_CHILD_ITEMS: 'Os itens filhos clonarão qualquer modificação de preço ou margem do item pai.',
          RULE_SAME_PRICE_PARENT_ITEM: 'Os itens filhos manterão o mesmo {{val}} que o item pai.',
          RULE_PRICE_POSITION: 'Os itens filhos se posicionarão com um {{val}} de {{val2}} em relação ao item pai.',
          RULE_PRICE: 'preço',
          RULE_MARGIN: 'margen',
          RULE_ITEM_PARENT_SELECTED: 'Item pai selecionado',
          RULE_ITEM_CHILD_SELECTED: 'Item filhos selecionado',
          RULE_PRICE_UPDATE_ON_CHILD:
            'Qualquer modificação de preço no item pai será replicada nos itens filhos de acordo com a configuração.',
          RULE_POINT_OF_SALE_SELECTED: 'Pontos de venda selecionados',
          RULE_REMEMBER_PARENT_ITEM_INDEPENDENT:
            'Lembre-se de que, por ser uma regra de parentesco de itens, o item pai é independente do ponto de venda.',
          RULE_REMEMBER_CHECK_ASSORTMENT:
            'Lembre-se de revisar o sortimento dos pontos de venda para verificar se os itens filhos estão incluídos.',
        },
        BRAND: {
          ROUTE: 'Preços / Configuração',
          TITLE: 'Bandeiras',
          NEW_BRAND_BTN: 'Nova Bandeira',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          DELETE_TTIP: 'Apagar bandeiras selecionadas',
          DELETE: 'Apagar',
          NAME: 'Nome',
          LOGO: 'Logo',
          COLOR: 'Cor',
          TITLE_FORM_NEW: 'Nova Bandeira',
          SAVE_BTN: 'Salvar',
          BASIC_INFORMATION_TAB: 'Informação Básica',
          NAME_INPUT: 'Nome',
          PICK_COLOR: 'Escolha uma cor',
          CLEAN_COLOR_TTIP: 'Limpar cor',
          IS_ASIGNED: 'Esta bandeira é atribuída a pontos de venda ou concorrentes, por isso não pode ser eliminada',
          OWN_BRAND: 'Própria marca',
        },
        GUI_AND_STRA: {
          ROUTE: 'Preços / Configuração',
          TITLE: 'Guidelines and Strategies',
          EXPORT_TTIP: 'Exportar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          CATEGORIES: 'Categorias',
          RULES: 'Regras',
          RULES_TTIP: 'Número de regras de preço',
          TARGET_MARGIN: 'Margem Objetivo %',
          TARGET_MARGIN_TTIP: 'Margem Objetivo Percentual',
          MIN_MARGIN: 'Mg Min %',
          MIN_MARGIN_TTIP: 'Margem Miníma Percentual',
          MAX_MARGIN: 'Mg Max %',
          MAX_MARGIN_TTIP: 'Margem Máxima Percentual',
          ALLOW_MIN_VIOLATION: 'Sug. Abaixo Mg Min',
          ALLOW_MIN_VIOLATION_TTIP:
            'Se esta caixa estiver marcada, o Prisma irá sugerir abaixo da margem mínima e exibirá um sinal de alerta próximo à sugestão. Caso contrário, a sugestão o levará ao valor mínimo inserido.',
          ALLOW_MAX_VIOLATION: 'Sug. Acima Mg Max',
          ALLOW_MAX_VIOLATION_TTIP:
            'Se esta caixa estiver marcada, o Prisma irá sugerir acima da margem máxima, e exibirá um sinal de alerta ao lado da sugestão, caso contrário a sugestão irá levá-lo ao valor máximo inserido.',
          SIGNIFICANT_VAR_MARGIN: 'Variação Significativa Mg R$',
          SIGNIFICANT_VAR_MARGIN_TTIP: 'Variação Significativa de Margem em Reais',
          MIN_VARIATION: 'Var Min %',
          MIN_VARIATION_TTIP: 'Redução máxima de preço permitida por alteração de preço',
          MAX_VARIATION: 'Var Max %',
          MAX_VARIATION_TTIP: 'Aumento de preço máximo permitido por alteração de preço',
          ALLOW_MIN_VARIATION: 'Sug. Abaixo Var Min',
          ALLOW_MIN_VARIATION_TTIP:
            'Se esta caixa estiver marcada, o Prisma irá sugerir acima da redução máxima, e exibirá um sinal de alerta ao lado da sugestão, caso contrário a sugestão irá levá-lo ao valor máximo inserido.',
          ALLOW_MAX_VARIATION: 'Sug. Acima Var Max',
          ALLOW_MAX_VARIATION_TTIP:
            'Se esta caixa estiver marcada, o Prisma irá sugerir acima do aumento máximo, e exibirá um sinal de alerta ao lado da sugestão, caso contrário a sugestão irá levá-lo ao valor máximo inserido.',
          SIGNIFICANT_VARIATION: 'Var Significativa R$',
          SIGNIFICANT_VARIATION_TTIP: 'Variação Significativa em Reais',
          SAVE_BTN: 'Salvar',
          BASIC_INFORMATION_TAB: 'Informação Básica',
          MARGIN_GUIDELINES: 'Diretrizes de margem',
          TARGET_MARGIN_INPUT: 'Margem Objetivo %',
          MIN_MARGIN_INPUT: 'Margem Min %',
          MAX_MARGIN_INPUT: 'Margem Max %',
          SIGNIFICANT_VAR_MARGIN_INPUT: 'Variação Significativa de Margem R$',
          ALLOW_MIN_VIOLATION_CHECK: 'Sugerir abaixo do mínimo',
          ALLOW_MIN_VIOLATION_CHECK_TTIP:
            'Se esta caixa estiver marcada, o Prisma irá sugerir abaixo da margem mínima, e irá mostrar um sinal de alerta ao lado da sugestão, caso contrário a sugestão irá levá-lo ao valor mínimo inserido.',
          ALLOW_MAX_VIOLATION_CHECK: 'Sugestão acima do máximo',
          ALLOW_MAX_VIOLATION_CHECK_TTIP:
            'Se esta caixa estiver marcada, o Prisma irá sugerir acima da margem máxima, e irá mostrar um sinal de alerta ao lado da sugestão, caso contrário irá levar a sugestão ao valor máximo',
          VARIATION_PRICE: 'Variação de preço',
          MIN_MARGIN_V_INPUT: 'Redução Máxima %',
          MAX_MARGIN_V_INPUT: 'Aumento Máximo %',
          SIGNIFICANT_VAR_MARGIN_V_INPUT: 'Variação Significativa de Margem R$',
          ALLOW_MIN_VIOLATION_V_CHECK: 'Sugerir abaixo do mínimo',
          ALLOW_MIN_VIOLATION_V_CHECK_TTIP:
            ' Se esta caixa estiver marcada, o Prisma irá sugerir acima da redução máxima, e exibirá um sinal de alerta ao lado da sugestão, caso contrário a sugestão irá levá-lo ao valor máximo inserido.',
          ALLOW_MAX_VIOLATION_V_CHECK: 'Sugestão acima do máximo',
          ALLOW_MAX_VIOLATION_V_CHECK_TTIP:
            ' Se esta caixa estiver marcada, o Prisma irá sugerir acima do aumento máximo, e exibirá um sinal de alerta ao lado da sugestão, caso contrário a sugestão irá levá-lo ao valor máximo inserido.',
          TITLE_MODAL: 'Mudanças de impacto nos próximos níveis da árvore de categorias',
          SUBTITLE_MODAL:
            'Deseja estabelecer essas mesmas diretrizes para os níveis seguintes da árvore de categorias, até e incluindo o nível de subcategoria?',
          REFUSE_BTN: 'Cancelar',
          ACCEPT_BTN: 'Ac',
        },
      },
      REPORTS: {
        ROUTE: 'Preços / Relatórios',
        SUGGESTIONREPORTS: {
          ROUTE: null,
          GENERATE: null,
          TITLE: null,
          GENERATE_TITLE: null,
          INPROCESS: null,
          DAY: 'Dia',
          INTRODUCTION: null,
        },
        COSTREPORTS: {
          ROUTE: null,
          GENERATE: null,
          TITLE: null,
          GENERATE_TITLE: null,
          INPROCESS: null,
          DAY: 'Dia',
          INTRODUCTION: null,
        },
        YPF: {
          ROUTE: 'Preços / Relatórios',
          TITLE: 'Relatórios YPF',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          COMPETITIVE_POSITION: 'Posicionamento Competitivo',
          EXPORT_TTIP: 'Exportar',
          APP_TTIP: 'Compartilhe com o aplicativo',
          DASHBOARD_TTIP: 'Ir para o dashboard',
          COMPETITOR: 'Concorrente',
          N2: 'N2',
          N3: 'N3',
          G2: 'G2',
          G3: 'G3',
          CHEAPER: 'Mais barato',
          EQUEAL_PRICE: 'Igual Preço',
          MORE_EXPENSIVE: 'Mais caro',
          PROVINCE_FILTER: 'Província',
          FILTER: 'Filtro',
          LOCATION_FILTER: 'Localidade',
          BRANDS_FILTER: 'Bandeiras',
          APPLY_BTN: 'APLICAR FILTROS',
          COMPETITION_PRICES: 'Preços dos Concorrentes',
          NS: 'NS',
          INF: 'INF',
          UD: 'UD',
          D500: 'D500',
          INF_D: 'INF D',
          INF_NS: 'INF vs NS',
          INFD_D500: 'INF vs D500',
          RETAIL_MARGIN: 'Margens Varejista',
          PRODUCTS_FILTER: 'Produtos',
        },
        DAILYMARGIN: {
          LIST: {
            TITLE: 'Relatórios diários de margens',
            ROUTE: '/prices/reports/daily-margin',
            DELETE: 'Retirar',
            DELETE_TTIP: 'Retirar',
            FILTER_TTIP: 'Filtro',
            EXPORT: 'Download',
            EXPORT_TTIP: 'Exportar relatório em formato excel',
            STORECODE: 'Código do ponto de venda',
            STORENAME: 'Ponto de venda',
            ITEMCODE: 'Código do produto',
            ITEMNAME: 'produtos',
            PRICE: 'Preço',
            PRICEWOTAXES: 'Preço sem impostos',
            COST: 'custo',
            MARGIN: 'Margem',
            MAXMARGINAMOUNT: 'Margem máxima',
            COMPETITOR: 'Concorrente',
            COMPETITORSPRICE: 'Preço do concorrente',
            MARKETAVERAGEPRICE: 'Preço médio',
            CURRENTDATE: 'Data atual',
            MARGINVSMAXMARGIN: 'Margem vs Margem Máxima',
            COMPETITIVEINDEX: 'Competitividade',
            MARKETCOMPETITIVEINDEX: 'Competitividade de mercado',
          },
        },
        PRICE_INCONSISTENCY: {
          ROUTE: 'Preços / Relatórios',
          TITLE: 'Inconsistências de preço',
          CODE: 'Código',
          NAME: 'Nome',
          CURRENT_PRICE: 'Preço Atual',
          MAXIMUM_PRICE: 'Preço Máximo',
          STORE: 'Ponto de Venda',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          TITLE_FILTERS: 'Filtros',
          CATEGORY_FILTER: 'Categoria',
          SEARCH_CATEGORY_FILTER: 'Procurarr Categorias',
          CLEAN_FILTER_BTN: 'Limpar',
          APPLY_FILTER_BTN: 'Aplicar',
        },
        PRICES_MAP: {
          ROUTE: 'Preços / Relatórios',
          TITLE: 'Mapa de Preços',
          CHOOSE_PRODUCT: 'Escolha um produto para mostrar o mapa',
          CHOOSE_PRODUCT_AND_ZONE: 'Escolha um produto e zona para mostrar o mapa',
          FILTER_TTIP: 'Filtrar',
          PRODUCT_FILTER: 'Produtos',
          MARKER_FILTER: 'Marker',
          OP1_ALL: 'Todos',
          OP2_MARKER: 'Marker',
          OP3_NO_MARKER: 'Sem Marcador',
          NAME: 'Nome',
          CODE: 'Código',
          PRICE: 'Preço',
          BRAND: 'Bandeira',
          MARKER: 'Marker',
          Nuevo: 'Novo',
          SOURCE: null,
          SOURCE_TTIP: null,
          VALIDFROM: null,
          Incompleto: 'Incompleto',
          OWN_BRAND: 'Própria marca',
          CHART_TITLE: 'Preços históricos',
          CHART_TITLE_PRICE: 'Preço',
          CHART_TITLE_VENTAS: 'Vendas',
          REFERENCE_STORE: null,
        },
        COMPETITOR_PRICES: {
          ROUTE: 'Preços / Relatórios',
          TITLE: 'Preços dos Concorrentes',
          DOWLOAD_REPORT: 'Baixar Relatório',
          SINCE_INPUT: 'Desde',
          TO_INPUT: 'Até',
        },
        PRICES_AUDIT: {
          PRICE_CHANGES: 'Auditoria de Preços',
          PROD_FILTERS: 'Filtros de Produto',
          FUELS: 'Combustíveis',
          BUSINESS_UNIT: 'Unidades de Negócio',
          DEP: 'Departamento',
          CAT: 'Categoria',
          SUBCAT: 'Subcategoria',
          PROD: 'Produto',
          POS_FILTERS: 'Filtros PDV',
          REGION: 'Região',
          OPERATION_TYPE_FILTER: 'Tipo de Operação',
          STORE_TYPE_INPUT: 'Ponto de Venda',
          DATE_FILTER: 'Filtros Data',
          INITIAL_DATE: 'Data Inicial',
          END_DATE: 'Data Final',
          USER: 'Usuário',
          REPORT: 'Gerar Reporte',
          REPORT_UPDATE: 'Atualizar Reporte',
          CODE: 'Código',
          STORES: 'Pontos de Venda',
          NAME: 'Nome',
          PREVIUS_PRICE_INPUT: 'R$ Preço Anterior',
          MARGIN_PRICE_INPUT: '% Margem Anterior',
          NEW_PRICE_INPUT: 'R$ Preço Novo',
          AUDIT_PRICE_CHANGE: 'Mudança de Preço',
          MARGIN_NEW: '% Margem Novo',
          INFORM: 'data informada',
          INFORM_BY: 'Informado por',
          SUBTITLE: 'Preços / Relatórios',
        },
        PRICE_REPORT: {
          TITLE: 'Preços / Relatórios',
          WeightedIndex: 'Índice P',
          SalesUnits: 'Vendas U',
          Sales: 'Vendas R$',
          Margin: 'Margem R$',
          PercentageMargin: 'Margem %',
          Gmroi: 'GMROI',
          COMPETITIVENESS_INDEX: 'Indice de competitividade',
          PREVIOUS_MONTH_DIF: 'Dif % Vs Mês anterior',
          SUGGESTIONS_ACCEPTED: '% Sugestões Aceitas',
          MarginDif: 'Dif. Margem vs Mês Anterior',
          PercentageMarginDif: 'Pontos Percentuais vs Mês Anterior',
          DEC_TYPE: 'Tipos de Decisões',
          INDEX_COMP: 'Indice de competitividade (IC%)',
          CATEGORY: 'Categoria',
          CHEAPER: 'Mais barato',
          SAME: 'Iguais',
          MORE_EXPENSIVE: 'Mais Caros',
          PRICES_TYPES: 'Tipos de Preços',
          PERIOD: 'Período',
          CATEGORYS: 'Categorias',
          EVOL_GRAPH: 'Gráfico Evolutivo',
          VARIABLE: 'Variável',
          VARIABLES: 'Variáveis',
          MAP: 'Mapa',
          PRODUCT_PERFORMANCE: 'Desempenho de Produtos',
          PRODUCT: 'Produto',
          VOLUME: 'Volume',
        },
      },
      DECISIONS: {
        SUMMARY: {
          ROUTE: 'Preços / Desições',
          TITLE: 'Resume de Decisōes',
          REVISE_BTN: 'Revisar tudo',
          UPDATE_STATISTICS: null,
          LASTUPDATEDON: null,
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          REVIEW_PENDING: 'Sem Revisar',
          CATEGORY: 'Categoria',
          REVIEW_PENDING_TTIP: 'Sugestões Pendentes de Revisão',
          DECISIONS: 'Revisadas',
          DECISIONS_TTIP: 'Decisões Tomadas',
          ADVANCED: 'Progresso %',
          ADVANCED_TTIP: 'Leva em consideração apenas sugestões',
          SEND: 'Enviar',
          SUGGESTED_IMPACT: 'Imp. Sug. R$',
          SUGGESTED_IMPACT_TTIP: 'Impacto Sugerido',
          DECISIONS_IMPACT: 'Decis. Imp. R$',
          DECISIONS_IMPACT_TTIP: 'Impacto Desições',
          REVIEW_IMPACT: 'Avaliação do impacto R$',
          REVIEW_IMPACT_TTIP: 'Avaliação do impacto',
          PENDING_APPROVAL: 'Sem Aprovar',
          PENDING_APPROVAL_TTIP: 'Número de decisões pendentes para aprovação',
          APPROVED: '# Aprovados',
          APPROVED_TTIP: 'Decisões Aprovadas',
          TITLE_FILTERS: 'Filtros',
          PROVINCES_FILTER: 'Estado',
          CLEAN_FILTER_BTN: 'Limpar',
          APPLY_FILTER_BTN: 'Aplicar',
          REJECTED_TTIP: 'Rejeitados',
          REJECTED: 'Rejeitadas',
          APPROVE: 'Aprovar',
          APPROVE_ALL: 'Aprovar Tudo',
          REVIEW: 'Avaliar',
          CONFIRM_SEND: 'Confirma o Envío?',
          CONFIRM_SEND_ALL: 'Confirma o envío de todos os ítens?',
          CONFIRM_APPROVE: 'Confirma a aprovaçāo?',
          CONFIRM_APPROVE_ALL: 'Confirma a aprovação de todos os itens?',
          CONFIRM_REVIEW: 'Confirma a revisão de todos os itens?',
        },
        ERP: {
          ROUTE: 'Preços / Desições',
          TITLE: 'Publicar Decisões',
          DOWLOAD_BTN: 'Baixar Info',
          PREVIEW_BTN: 'Vista Prévia',
          INFORM_BTN: 'Publicar',
          LAST_PRICES: 'Últimos preços Publicados',
          GENERATION_DATE: 'Data de geração',
          GENERATION_TIME: 'Tempo de Geração',
          USER: 'Usuário',
          FILE: 'Arquivo',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          PREVIEW_PROCCESING: 'O processamento foi iniciado. Você receberá uma notificação quando terminar.',
          PREVIEW_WITHOUT_PROCCESING: 'Não há novos dados para processar.',
        },
        PRICE_DECISIONS: {
          NO_GROUPID: null,
          APPLY_FILTERS: null,
          STRATEGY_DIALOG: {
            UNIT: 'Unidade',
            Price: 'R$',
            Margin: '%',
            UsdxM3: 'R$ * M3',
          },
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          SUGGESTED_IMPACT_TITLE: 'Impacto Sugerido R$',
          CHANGES_TITLE: '# Mudanças',
          VS_SUGGESTED_IMPACT_TITLE: 'Vs Impacto Sugerido R$',
          CHANGES_PRICE_TITLE: 'Preço Variação%',
          NEW_IMPACT_TITLE: 'Impacto Novo R$',
          SUGGESTIONS_ACCEPTED_DESCRIPTION: 'Sugestões Aceitas',
          WEIGHTED_SALE_DESCRIPTION: 'Ponderado por Vendas',
          PERCENT_CHANGE: 'Variação %',
          FILTER_CHIP: 'Filtrar preço de',
          CODE_ITEM: 'Código do produto',
          CODE_STORE: 'Código do ponto de venda',
          PARENT_RULE: 'Regra de parentesco',
          FILTER_CHIP_PARENT: 'Filtrar preços pela regra de relacionamento',
          FILTER_CHIP_PARENT_ITEM: 'Produto pai:',
          FILTER_CHIP_PARENT_STORE: 'Ponto de Venda, Pai:',
          TABLE_TITLE: 'Análise da Sugestão',
          INIT_PRICE_IMPORT_TITLE: null,
          OBSERVATIONS: null,
          REASON: null,
          STEP_BY_STEP: 'Passo a passo',
          SEE_MORE_TABLE: 'Ver mais...',
          PRICE_TABLE: 'Preço',
          MARGIN_TABLE: 'Margem',
          CI_TABLE: 'Custos mais Impostos',
          ORIGINAL_TABLE: 'Original R$',
          ACTUAL_TABLE: 'Atual R$',
          SUGGESTED_TABLE: 'Sugerido R$',
          DECISION_TABLE: 'Decisão R$',
          ROUTE: 'Preços / Desições',
          TITLE: 'Decisões de Preços',
          FILTER_TTIP: 'Filtrar',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          KEEP_PRICES: 'Ignorar Sugestões',
          KEEP_PRICES_TTIP: 'Ignorar sugestões selecionadas',
          ACCEPT_SUGGESTIONS: 'Aceitar sugestões',
          ACCEPT_SUGGESTIONS_TTIP: 'Aceitar sugestões selecionadas',
          ENDORSE: 'Aprovar Mudanças',
          ENDORSE_TTIP: 'Aprovar mudanças selecionadas',
          REJECT_ENDORSE: 'Rejeitar alterações',
          REJECT_ENDORSE_TTIP: 'Rejeitar alterações selecionadas',
          REVERT_PRICE: 'Reverter Preços',
          REVERT_PRICE_TTIP: 'Reverter preços selecionados',
          MARK_REVIEWED: 'Solicitar Aprovação',
          MARK_REVIEWED_TTIP: 'Solicitar Aprovação das decisões selecionadas',
          EDIT_PRICES: 'Modificar Preços',
          EDIT_PRICES_TTIP: 'Modificar Preços Manualmente',
          EFFECTIVE_DATE: 'Datas válidas',
          EFFECTIVE_DATE_TTIP: 'Definir datas válidas para produtos selecionados',
          CLEAN_FILTERS: 'Limpar Filtros',
          PRODUCT: 'Produto',
          PRICE_TYPE: 'Tipo de Preço',
          CURRENT_PRICE_TTIP: 'Preço Atual',
          VALID_PRICE_TTIP: 'Preço Válido',
          NOT_VALID_PRICE_TTIP: 'Preço Inválido',
          SUGGESTION_PRICE_TTIP: 'Preço sugerido R$',
          CHECK_TTIP: 'Revisada',
          PRICE: 'Preço Atual R$',
          COMPETITION_PRICE: null,
          SUGGESTION: 'Preço sugerido R$',
          DECISIONS: 'Decisão R$',
          IMPACT: 'Impacto R$',
          NEWVALIDATEDATE: 'Data de vigência',
          ACCEPT_SUGGESTION: 'Aceitar sugestões',
          KEEP_PRICE: 'Manter o preço atual',
          EDIT_MANUALLY: 'Mudar Manualmente',
          TEXT_SNACKBAR: 'Foram modificados {{val}} registros',
          TEXT_SNACKBAR_MAX_BULK_LIMIT: 'Não é possível realizar ações em massa para mais de {{val}} itens.',
          EFFECTIVE_DATE_MODAL: 'Data válida',
          EFFECTIVE_DATE_INPUT: 'Data válida',
          TO_DATE_INPUT: null,
          CANCEL_BTN: 'Cancelar',
          ACCEPT_BTN: 'Aceitar',
          TEXT_SNACKBAR2: 'Foram modificados {{val}} registros',
          TITLE_FILTERS: 'Filtros',
          PROVINCE_FILTERS: 'Província',
          CITY_FILTERS: 'Cidade',
          PRICE_ZONE1_FILTERS: 'Zona de preço 1',
          PRICE_ZONE2_FILTERS: 'Zona de preço 2',
          PRICE_ZONE3_FILTERS: 'Zona de preço 3',
          CLEAN_FILTER_BTN: 'Limpar',
          APPLY_FILTER_BTN: 'Aplicar',
          EDIT_PRICE_MODAL: 'Modificar Preços',
          STRATEGY_INPUT: 'Estratégia',
          ROUNDING_CHECK: 'Use regras de arredondamento',
          VALUE_INPUT: 'Valor',
          TAB1: 'Decisão',
          TAB2: 'Concorrentes',
          TAB3: 'Custos e Margens',
          TAB4: 'Auditoria',
          TAB5: 'Pontos de Venda Filhos',
          TAB6: 'Receita',
          TAB7: 'Produtos em Combo',
          TAB8: 'Combo',
          TAB_ZONES: 'Zonas',
          TAB_ZONES_TITLE: 'Zonas de preço (escopo de Pontos de Venda)',
          CODE_INPUT: 'Código',
          UM_BASE_INPUT: 'Base UM',
          BRAND_INPUT: 'Bandeira',
          NAME_INPUT: 'Nome',
          WITHOUT_SUGGESTION: 'Sem Sugestão',
          REVIEW_RULE_BTN: 'Revisar Regra',
          DISABLED_BY_CHILD: 'Nenhuma ação pode ser realizada. Este produto depende das decisões tomadas no pai da regra pai',
          SAME_PRICE_WARNING: 'The selected item already has a price of: $',
          SUGGESTION_ACCEPTED_BTN: 'Sugestão Aceita',
          SUGGESTION_ACCEPTED_OP1: 'Manter o preço atual',
          SUGGESTION_ACCEPTED_OP2: 'Mudar Manualmente',
          SUGGESTION_WITHOUT_REVIEW: 'Sugestão sem Revisão',
          SUGGESTION_ACCEPTED: 'Sugestão Aceita',
          SUGGESTION_IGNORED: 'Sugestão Ignorada',
          SUGGESTION_ACCEPT: 'Aceitar sugestões',
          SUGGESTION_IGNORE: 'Ignorar Sugestāo',
          SUGGESTION_REVIEWED: 'Revisado',
          SUGGESTION_ENDOECE: 'Aprovar Mudanças',
          SUGGESTION_ENDOECE_REJECTED: 'Rejeitar Alterações',
          SUGGESTION_REJECTED: 'Mudanças rejeitadas',
          SUGGESTION_ALREADY_ENDOECE: 'Aprovar',
          SUGGESTION_CHANGE_MANUALY: 'Mudar Manualmente',
          SUGGESTION_CHANGED_MANUALY: 'Mudar Manualmente',
          SUGGESTION_MARK_AS_REVIEW: 'Solicitar Aprovação',
          REVERT_NEW_PRICE: 'Reverter Preço',
          NO_DECISIONS_BTN: 'Sem decisões',
          NO_DECISIONS_OP1: 'Confirmar o Preço Atual',
          NO_DECISIONS_OP2: 'Mudar Manualmente',
          EDIT_MANUALLY_BTN: 'Editado Manualmente',
          EDIT_MANUALLY_OP1: 'Mudar Manualmente',
          EDIT_MANUALLY_OP2: 'Manter o preço atual',
          ORIGINAL_PRICE_INPUT: 'Preço Original',
          PREVIUS_PRICE_INPUT: 'Preço Anterior',
          COST_INPUT: 'Custo',
          MARGIN_INPUT: 'Margem R$',
          PERCENTAGE_MARGIN_INPUT: 'Mergem %',
          LEGEND_COMP_MAP:
            'Filtre o mapa e os KPIs usando os preços na Área Comercial do Ponto de Venda ou usando os preços da regra competitiva',
          COMPETITOR_INPUT: 'Concorrente',
          SOURCE_INPUT: 'Fonte',
          MARKERS_CHECK: 'Apenas Marcadores',
          MARKERS_TTIP: 'Apenas Concorrentes Relevantes',
          BRAND: 'Bandeira',
          CODE_NAME: 'Cód | Nome',
          TYPE: 'Tipo',
          SURVEY_DATE: 'Data',
          SURVEY: 'Fonte',
          COMPETENCE_TAB_KPIS_TITLE_1: 'Preço do Conc. Atual',
          COMPETENCE_TAB_KPIS_TITLE_2: 'IC% Atual',
          COMPETENCE_TAB_KPIS_TITLE_3: 'IC% Sugerido',
          COMPETENCE_TAB_KPIS_TITLE_4: 'Preço Sugerido',
          COMPETENCE_TAB_KPIS_DESC_3: 'Vs. IC atual',
          COMPETENCE_TAB_KPIS_DESC_4: 'Vs. Preço atual',
          COMPETENCE_KPIS_TITLE_TRADE: 'Área Comercial',
          COMPETENCE_KPIS_TITLE_COMPETITOR: 'Prinicipais Concorrentes',
          COMPETENCE_KPIS_TITLE_RULE: 'Regra',
          TITLE_MAP_SECTION: 'Mapa dos Concorrentes',
          TITLE_TABLE: 'Área Comercial',
          TITLE_KPIS: 'Analise da concorrência',
          NEW: 'Status',
          NEW_CHIP: 'NOVO',
          INVALID_CHIP: 'Inválido',
          OLD_CHIP: 'Velho',
          SUGGESTION_COLUMN: 'Sugerido',
          MARK: 'Marcador',
          OWNER: 'Prinicipal Concorrente',
          PVC: 'PVP',
          MARGIN: 'Margem',
          MARGIN_USD: 'Margem R$xM3',
          PERCENTAGE_MARGIN: 'Margem %',
          COST_TAXES: 'Custos e Impostos',
          CURRENT: 'Atual',
          PREVIUS: 'Anterior',
          VAR: 'Variação %',
          LAST_VAR: 'Últ. Var.',
          EVENT: 'Evento',
          DATE: 'Data',
          PREVIUS_PRICE: 'Preço Anterior R$',
          DECISIONS_PRICE: 'R$',
          COST: 'Custo',
          USER: 'Usuário',
          CHILDREN_STORE: 'Ponto de Venda Filho',
          CODE: 'Código',
          RECIPE: 'Receita',
          NAME: 'Nome',
          CURRENT_PRICE: 'Atual R$',
          SUGGESTION_RECIPE: 'Sugestão R$',
          DECISION: 'Decisão R$',
          SEE_RECIPE_BTN: 'Ver Receitas',
          INGREDIENTS: 'Ingredientes',
          ORIGIN_COST: 'Custo Original R$',
          QUANTITY: 'Quantidade',
          CURRENT_COST: 'Custo Atual R$',
          COMBO_PRODUCT: 'Produtos em Combo',
          COMBOS: 'Combos',
          PARTICIPATION: 'Participação %',
          SEE_COMBO_BTN: 'Ver Combo',
          PARTICIPATION_TTIP: 'Participação nas vendas deste combo.',
          LEGEND_COMBO: 'Os seguintes Combos podem ser direta ou indiretamente afetados pela decisão de preço tomada.',
          COMBO: 'Combo',
          SUGGESTION_COMBO: 'Sugestão R$',
          COMBOS_COMPONENTS: 'Componentes do Combo',
          BLOCK: 'Grupo',
          TOOLTIP_PARENT: 'Produto Pai',
          TOOLTIP_PARENT_OPEN: 'abrir produtos filhos',
          TOOLTIP_PARENT_OPENED: 'Fechar produtos filhos',
          TOOLTIP_CHILD: 'Produto Filho',
          TOOLTIP_PRICE_VARIATION: 'Os produtos filhos terão a mesma variação de preço que o produto pai',
          TOOLTIP_SUPERIOR_PRICE: 'Os produtos filhos terão um preço mais alto em {{val}} do que o produto pai',
          TOOLTIP_INFERIOR_PRICE: 'Os produtos filhos terão um preço inferior em {{val}} do que o produto pai',
          TOOLTIP_SAME_PRICE: 'Os produtos filhos terão o mesmo preço do produto pai',
          TOOLTIP_SUPERIOR_MARGIN: 'Produtos-filho terão uma margem mais alta em {{val}} do que o produto-pai',
          TOOLTIP_INFERIOR_MARGIN: 'Produtos-filho terão uma margem inferior em {{val}} do que o produto-pai',
          TOOLTIP_SAME_MARGIN: 'Os produtos filhos terão a mesma margem do produto pai',
          TOOLTIP_COMBO: 'Combo',
          TOOLTIP_RECIPE: 'Receita',
          TOOLTIP_STORE: 'Parentesco de Ponto de Venda',
          TOOLTIP_COMBO_ITEM: 'Componentes do Combo',
          TOOLTIP_MAX_PRICE: null,
          TOOLTIP_IS_PARENT: 'Item pai na regra de parentesco. A variação de seu preço afeta seus itens filhos.',
          RULE_TYPE_TITLE: 'Tipo de Regra',
          COMBO_ANALYST: 'Analista',
          COMBO_DECISIONTYPE: 'Tipo de Decisão',
          COMBO_APPROVER: 'Aprovador',
          COMBO_SITUATION: 'Situação',
          COMBO_RULETYPE: 'Tipo de Regra',
          AUDIT_PRICE_CHANGE: 'Mudança de Preço',
          SHOW_KPIS: 'Mostrar KPIs',
          HIDE_KPIS: 'Ocultar KPIs',
          RULE_TYPE: {
            Guideline: 'Diretriz',
            Margin: 'Margem',
            Competitive: 'Competitivo',
            ParentItem: 'Produtos Relacionados',
            ParentZone: 'Pontos de Venda Relacionados',
            BasePriceList: 'Preço de Lista Base',
          },
          ADVANCED_FILTER: {
            NONE: null,
            SEE_CHILDS_MSG:
              'Ao habilitar esta opção você poderá pesquisar e visualizar os produtos filhos de outros produtos no que diz respeito à gestão de preços realizada nas regras de preços de lojas e produtos',
            SEE_CHILDS: 'Ver produtos filhos',
            SEE_FIXED_PRICES_MSG: null,
            SEE_FIXED_PRICES: 'Ver preços fixos',
            TITLE_FILTERS: 'Filtros',
            STATE_FILTERS: 'Estado',
            ACTION_TYPE_FILTERS: 'Situação',
            PRICE_TYPE_FILTERS: 'Tipo de Preço',
            TYPE_FILTERS: 'Tipo de Produto',
            CATEGORY_INPUT: 'Categoria',
            SEARCH_CATEGORY: 'Procurar Categorias…',
            SUBCATEGORIES_INPUT: 'Subcategorías',
            SEARCH_SUBCATEGORIES: 'Procurar Subcategorias',
            TitleStoreTags: 'Filtros para Pontos de Venda',
            TitleItemTags: 'Filtros de Produto',
            UnCheckedSuggestion: 'Sugestão sem revisar',
            PriceRaised: 'Preço aumentado',
            NoSuggestions: 'Sem sugestões',
            NotCheckedSimulation: 'Sugestões sem revisar',
            SuggestionAccepted: 'Sugestões Aceitas',
            ManualPriceEdited: 'Preços alterados manualmente',
            SimulationAcepted: 'Simulação Aceita',
            AboutToExpire: 'Prestes a expirar',
            OldPrices: 'Preços Antigos',
            Regular: 'Regular',
            Competition: 'Concorrente',
            Current: 'Vigente',
            Todos: 'Todos',
            Checked: 'Revisados',
            Unchecked: 'Sem Revisar',
            ApprovalPending: 'Pendente por Aprovaçāo',
            'ApprovalPending-Aproval': 'Pendente por Aprovaçāo',
            Rejected: 'Rejeitados',
            Approved: 'Aprovado',
            Error: null,
            SuggestionIgnored: 'Sugestões Ignoradas',
            PriceDrop: 'Queda de Preço',
            SharpPriceDrop: 'Queda acentuada de preços',
            SharpPriceRaised: 'Subida acentuada de preços',
            DepressedMargins: 'Margens',
            HighMargins: 'Margens Altas',
            Margin: 'Regra de Margem',
            Competitive: 'Margens Deprimidas',
            AUDIT_PRICE_CHANGE: 'Mudança de Preço',
          },
          TAB: {
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_APPLIED_FORCED:
              'O preço de {{val}} foi alterado para {{val3}} porque os limites de Preços Máximos Regulados foram aplicados.',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED_FORCED:
              'O preço de {{val}} está dentro dos limites de preços máximos regulados {{val3}}.',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED_HIGHER_NOTFORCED:
              'O preço de {{val}} excede os limites de Preços Máximos Regulados {{val3}}, mas nenhuma correção foi feita.',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED_MINOR_NOTFORCED:
              'O preço de {{val}} está dentro dos limites de Preços Máximos Regulados {{val3}}, e nenhuma correção foi feita.',
            STEP_LINK_COMPETITIVE_RULE: 'Regra de concorrência ',
            STEP_TEXT_COMPETENCE_RULE_below: 'abaixo da média ',
            STEP_TEXT_COMPETENCE_RULE_above: 'Acima da Média ',
            STEP_TEXT_COMPETENCE_RULE_equal: 'igual à média ',
            STEP_TEXT_COMPETENCE_RULE_PRE: 'Foi aplicada uma ',
            STEP_TEXT_COMPETENCE_RULE_POST: 'para definir o preço de um {{val2}} {{val}} {{val4}}, permanecendo em {{val3}}',
            STEP_TEXT_COMPETENCE_RULE: 'foi aplicada uma {{val}}: "{{val2}}" e gerou um preço sugerido de {{val3}}',
            STEP_TEXT_MARGIN_RULE: 'foi aplicado um {{val}}: "{{val2}}" e gerou um preço sugerido de {{val3}}',
            STEP_TEXT_COMPETENCE_RULE_NOTAPPLIED: 'Nenhuma regra de preço aplicada',
            STEP_TEXT_MARGIN_RULE_NOTAPPLIED: 'Nenhuma regra de preço aplicada',
            STEP_TEXT_MARGIN_SUGGESTION_TRIGGER:
              'PRISMA detectou que seu preço atual de {{val}} não está de acordo com a margem alvo de {{val2}}',
            STEP_TEXT_COMPETITIVE_SUGGESTION_TRIGGER:
              'PRISMA detectou que seu preço atual de {{val}} não está de acordo com seu posicionamento competitivo definido na regra {{val2}} considerando o preço de referência de mercado de {{val3}}',
            STEP_TEXT_SUGGESTION_ERROR: null,
            STEP_TEXT_COMPETITIVE_SUGGESTION_TRIGGER_SHORT:
              'PRISMA detectou que seu preço atual de {{val}} não está de acordo com seu posicionamento competitivo.',
            STEP_TEXT_RELATED_PRODUCTS_RULE_SHORT:
              'A regra de relacionamento do produto foi aplicada: "{{val}}" e gerou um preço de {{val2}}',
            STEP_TEXT_RELATED_STORES_RULE_SHORT:
              'A regra de relacionamento com a loja foi aplicada: "{{val}}" e gerou um preço de {{val2}}',
            STEP_TEXT_COMPETENCE_LIMITS_APPLIED:
              'A sugestão de {{val}} foi alterada para {{val2}} porque os limites de competência foram aplicados',
            STEP_TEXT_COMPETENCE_LIMITS_NOTAPPLIED: 'Não há limites de competição carregados.',
            STEP_TEXT_COMPETENCE_RULE_MARGIN_FALLBACK:
              'Prisma não conseguiu encontrar o preço de referência do mercado. A sugestão foi criada usando fallback de margem de regra. {{val}}',
            STEP_TEXT_COMPETENCE_CATEGORY_MARGIN_FALLBACK:
              'Prisma não conseguiu encontrar preço de referência de mercado. A sugestão foi criada usando o fallback de margem de categoria. {{val}}',
            STEP_TEXT_VARIATION_GUIDELINES_APPLIED:
              'A sugestão de {{val}} (variação: {{val2}}) foi alterada para {{val3}}, pois o {{val5}} compatível com esta regra é {{val4}}.',
            STEP_TEXT_VARIATION_GUIDELINES_NOTAPPLIED: 'As diretrizes de variação de preços foram verificadas. Nenhuma correção foi feita.',
            STEP_TEXT_MARGIN_GUIDELINES_APPLIED:
              'A sugestão de {{val}} (margem: {{val2}}) foi alterada para {{val3}} pois {{val5}} era a margem de {{val4}} para a categoria.',
            STEP_TEXT_VARIATION_GUIDELINES_APPLIED_RULE: null,
            STEP_TEXT_MARGIN_GUIDELINES_APPLIED_RULE: null,
            STEP_TEXT_MARGIN_GUIDELINES_NOTAPPLIED: 'As diretrizes de margem foram verificadas. Nenhuma correção foi feita.',
            STEP_TEXT_ROUNDING_APPLIED: 'A sugestão foi arredondada para {{val}}',
            STEP_TEXT_ROUNDING_NOTAPPLIED: 'Nenhum arredondamento foi aplicado',
            STEP_TEXT_SUMMARY_ACCEPTED: 'Você tomou uma decisão e aceitou a sugestão de {{val}}',
            STEP_TEXT_SUMMARY_CHANGED: 'Você tomou uma decisão e alterou o preço para {{val}}',
            STEP_TEXT_SUMMARY_IGNORED: 'Você tomou uma decisão e ignore o preço {{val}}',
            STEP_TEXT_SUMMARY_WITHOUT_SUGGESTION: 'Você tomou uma decisão e alterou o preço de {{val}} para {{val2}}',
            STEP_LINK_APPLIED_RULE: 'revisar regra…',
            STEP_LINK_COMPETITIVE_SUGGESTION_TRIGGER: 'ver dados…',
            STEP_LINK_VARIATION_GUIDELINES_APPLIED: 'revisar diretrizes ...',
            STEP_LINK_MARGIN_GUIDELINES_APPLIED: 'revisar diretrizes ...',
            STEP_LINK_VARIATION_GUIDELINES_APPLIED_RULE: null,
            STEP_LINK_MARGIN_GUIDELINES_APPLIED_RULE: null,
            STEP_LINK_ROUNDING_APPLIED: 'revisar regra…',
            STEP_LINK_REGULATEDPRICE_GUIDELINES_APPLIED: null,
            STEP_LINK_REGULATEDPRICE_GUIDELINES_NOTAPPLIED: null,
            STEP_LINK_RELATED_PRODUCTS_RULE: 'ver regra de parentesco...',
            STEP_LINK_RELATED_STORES_RULE: 'ver regra de parentesco...',
            STEP_COMPETENCE_RULE: 'Regra de concorrência',
            STEP_MARGIN_RULE: 'Regra de Margem',
            SUGGESTION_TITLE: 'Decisão de Preço',
            NOT_STEPS: 'Nenhum passo encontrado',
            AUX_MAX_MARGIN: 'máximo',
            AUX_MIN_MARGIN: 'mínimo',
            AUX_MAX_VARIATION: 'aumento máximo',
            AUX_MIN_VARIATION: 'mínimo baixo',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_APPLIED: null,
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED: null,
            STEP_TEXT_APPLY_PRODUCTS_RULE:
              'Este item foi identificado como principal em certas regras de hierarquia de itens. Em relação a este item, as seguintes regras foram aplicadas e geraram uma decisão sobre os itens secundários: {{val}}',
            STEP_TEXT_APPLY_STORES_RULE:
              'Esta loja foi identificada como principal em certas regras de hierarquia de lojas. Em relação a este item, as seguintes regras foram aplicadas e geraram uma decisão sobre as lojas secundárias: {{val}}',
          },
          TAB_ZONES_COL_ZONE: 'Zona de Preço',
          TAB_ZONES_COL_RULE: 'Regra',
          TAB_ZONES_COL_ACTUAL: 'Atual',
          TAB_ZONES_COL_SUGGEST: 'Sugerir',
          TAB_ZONES_COL_DIFF: 'dif%',
          TAB_ZONES_COL_DECISION: 'Decisão',
          TAB_ZONES_COL_IMPACT: 'Impacto',
          WARNING_CHILD_WHITH_WARNING: 'Preços relacionados com avisos',
          WARNINGS_PRICES_LIST: {
            TEST: 'teste de aviso / valor: {{val}}, digite: {{val2}}',
            MIN_MARGIN: 'A margem {{val}} está abaixo da margem mínima de {{val2}}.',
            MAX_MARGIN: 'A margem {{val} está acima da margem máxima de {{val2}}.',
            EQUAL_MARGIN: null,
            VAR_BAJA: 'O preço diminuiu {{val}}. A diminuição máxima é {{val2}} de acordo com as diretrizes.',
            VAR_SUBA: 'O preço aumentou em {{val}}. O aumento máximo é {{val2}} de acordo com as diretrizes.',
            SUGGESTION_ERROR: null,
          },
          STRATEGY_OP3: 'Carregue um preço fixo',
          STRATEGY_OP2: 'Levar a uma margem alvo',
          STRATEGY_OP1: 'Aplicar variaçāo de preço',
          SUGGESTED_IMPACT_TTIP: 'Não inclui produtos filhos',
          NEW_IMPACT_TTIP: 'Inclui produtos filhos',
          VAR_TABLE: ' ',
        },
      },
      TAXES: {
        IIBB: {
          ROUTE: 'Resultados de traducción',
          TITLE: 'Renda Bruta',
          JURISDICTION: 'Jurisdição',
          PERCENTAGE_RATE: 'Taxa Percentual',
          SAVE_BTN: 'Salvar',
          PERCENTAGE_RATE_INPUT: 'Taxa Percentual',
          VALIDATION_1: 'O campo não pode ser maior que 100',
          VALIDATION_2: 'O campo deve ser maior que 0',
          VALIDATION_3: 'O campo é obrigatório',
        },
        CO2: {
          ROUTE: 'Preços / Administração / Impostos',
          TITLE: 'Impostos CO2',
          YES: 'Sim',
          NOT: 'Não',
          EXCEMPT_ZONE: 'Zona Isenta',
          NS: 'NS',
          IN: 'IN',
          UD: 'UD',
          D500: 'D500',
          EURO: 'EURO',
          SAVE_BTN: 'Salvar',
          NS_INPUT: 'NS',
          VALIDATION1: 'O campo não deve exceder 100',
          VALIDATION2: 'O campo deve ser maior que 0',
          IN_INPUT: 'IN',
          UD_INPUT: 'UD',
          D500_INPUT: 'D500',
          EURO_INPUT: 'EURO',
          TITLE_FORM: 'Impostos CO2 - Zona {{val}} isento',
        },
        ICL: {
          ROUTE: 'Preços / Administração / Impostos',
          TITLE: 'Impostos ICL',
          YES: 'Sim',
          NOT: 'Não',
          EXCEMPT_ZONE: 'Zona Isenta',
          NS: 'NS',
          IN: 'IN',
          UD: 'UD',
          D500: 'D500',
          EURO: 'REAIS',
          SAVE_BTN: 'Salvar',
          NS_INPUT: 'NS',
          VALIDATION1: 'O campo não deve exceder 100',
          VALIDATION2: 'O campo deve ser maior que 0',
          IN_INPUT: 'IN',
          UD_INPUT: 'UD',
          D500_INPUT: 'D500',
          EURO_INPUT: 'REAIS',
          TITLE_FORM: 'Impostos ICL - Zona {{val}} isenta',
        },
      },
      IMPORTERS: {
        PARITY: {
          ROUTE: 'Preços / Administração / Importadores',
          TITLE: 'Import Parity',
          IP_BTN: 'Carregar IP Diário',
          DAY: 'Dia',
          TRANSFER_PRICING: 'Preços de transferência',
          IMPORT_TTIP: 'Importar dados',
          IP_DAILY: 'Ponderação IP diaria',
          IP_NS: 'IP NS',
          IP_IN: 'IP IN',
          IP_GO2: 'IP GO2',
          IP_GO3: 'IP GO3',
          TC: 'TC',
          TERMINAL: 'Terminal',
          DESCRIPTION: 'Descrição',
          NS: 'NS',
          IN: 'IN',
          GO2: 'GO2',
          GO3: 'GO3',
          TITLE_FORM: 'Importar Paridade - Coeficientes',
          SAVE_BTN: 'Salvar',
          DATE_COEFFICIENTS_INPUT: 'Mudar data de coeficientes IP',
          VALIDATION1: 'O campo deve ser maior que 0',
          TC_INPUT: 'TC',
          NS_INPUT: 'NS',
          IN_INPUT: 'IN',
          GO2_INPUT: 'GO grau 2',
          GO3_INPUT: 'GO grau 3',
        },
        FERRYES_AND_COMISIONS: {
          TITLE: 'Importador Frete e comissões',
        },
        COST_AND_TAXES: {
          TITLE: 'Importador Custos e Taxas',
        },
        VENDOR_COST: {
          TITLE: 'Importador de custos por Fornecedor',
        },
        STORE_TAXES: {
          TITLE: 'Importador de impostos por Ponto de Venda',
        },
        NEW_PRICES: {
          TITLE: 'Novo importador de preço',
        },
        COMPETITOR_PRICES: {
          TITLE: 'Importador de preço dos Concorrentes',
        },
      },
    },
    ASSORT: {
      STP: {
        CLUSTERING: {
          ROUTE: 'Sortimento / Configuração',
          TITLE: 'Clusterização',
          SUBTITLE:
            'A Clusterização permite classificar estrategicamente os pontos de venda de acordo com um conjunto de variáveis. Pode ser um único cluster para todas as categorias ou várias classificações, dependendo da categoria. Desta forma, a tomada de decisões de negócios é simplificada e as ofertas de produtos são padronizadas. Nessa tela, você pode criar e modificar clusterizações.',
          NEW_CLUSTERIZATION_BTN: 'Nova Clusterização',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Apagar clusters selecionados',
          DELETE: 'Apagar',
          NAME: 'Nome',
          OBSERVATION: 'Observação',
          EDIT_TITLE_FORM: 'Editar Clusterização',
          NEW_TITLE_FORM: 'Nova Clusterização',
          SAVE_BTN: 'Salvar',
          BASIC_INFORMATION_TAB: 'Informação Básica',
          NAME_INPUT: 'Nome',
          OBSERVATION_INPUT: 'Observação',
          CLUSTER_NAME_TTIP: 'Clique aqui para ver os Pontos de Venda e clusters associados',
        },
        CLUSTER_ASSIGN: {
          REDO_CHANGE_CLUSTERS: 'Retornar mudanças de clusters selecionados',
          REDO_CHANGE_CLUSTERS_DESC: 'Você quer filtrar pelo cluster selecionado? isso irá apagar as alterações não salvas',
          ROUTE: 'Clusterização / Atribuição de Clusters',
          SUBTITLE:
            'Nesta tela você pode criar e atribuir um cluster a cada ponto de venda. Ou trabalhe os pontos de venda como agentes livres sem atribuir um cluster. Neste caso, funciona de forma independente como um cluster com um único ponto de venda.',
          SAVE_BTN: 'Salvar',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          STORES: 'Pontos de Vendas',
          CODE: 'Código',
          TYPE: 'Tipo',
          PROVINCE: 'Província',
          STATE: 'Estado',
          CLUSTER: 'Cluster',
          NEW_CLUSTER: 'Novo Cluster',
          NO_CLUSTER_ASSIGNED: 'Nenhum cluster atribuído',
          NEW_CLUSTER_BTN: 'NOVO CLUSTER',
          NEW_CLUSTER_TITLE: 'Novo Cluster',
          NEW_CLUSTER_SUBTITLE:
            'O cluster é um grupo de pontos de venda, com características específicas ou comportamento de compra semelhante, aos quais são atribuídos um papel e uma estratégia juntamente com a categoria. Por sua vez, simplifica a alocação e o gerenciamento do sortimento.',
          NAME_INPUT: 'Nome',
          OBSERVATION_INPUT: 'Observação',
          CANCEL_BTN: 'Cancelar',
          ADD_BTN: 'Adicionar',
          TITLE_FILTERS: 'Filtros',
          STATE_FILTERS: 'Estado',
          TYPE_FILTERS: 'Tipo',
          SEARCH_TYPE: 'Procurar Tipo...',
          PROVINCE_FILTERS: 'Província',
          CLUSTER_FILTERS: 'Cluster',
          SEARCH_CLUSTER: 'Procurar Cluster…',
          CLEAN_BTN: 'Limpar',
          APPLY_BTN: 'Aplicar',
          Todos: 'Todos',
          Checked: 'Ativo',
          Unchecked: 'Inativo',
          FREE_AGENTS: 'Agente Livre',
        },
        CATEGORY_SETUP: {
          ROUTE: 'Sortimento / Configuração',
          TITLE: 'Configurações de categoria',
          SUBTITLE:
            'Nesta tela você pode atribuir um agrupamento e a segmentação principal de produtos para cada nível de categoria, previamente definida na árvore de categorias; você também pode atribuir uma função e uma estratégia diferenciada para cada agrupamento, e ver as informações relativas à distribuição e atribuição dos segmentos.',
          LIST: {
            CATEGORIES: 'Categorias',
            CLUSTERIZATION: 'Clustering',
            ROL: 'Papel',
            STRATEGY: 'Estratégia',
            SEGMENTATION: 'Segmentação',
            SEGMENT_QUANTITY: '# Segmentos',
            SEGMENT_QUANTITY_TTIP: 'Quantidade de Segmentos',
            CLASSIFIELDS: 'Clssificados %',
            CLASSIFIELDS_TTIP: 'Porcentagem de produtos dentro da categoria classificados com um segmento.',
            SWITCH_ACTIVE: 'Mostrar Categorias sem Atribuição',
            SWITCH_INACTIVE: 'Mostrar Categorias com Atribuição',
            CLUSTERIZATION_OR_SEGMENT_DISABLE: 'Categoria não editável porque tem itens associados.',
            FILTER_TTIP: 'Filtrar',
            NOTFOUNDCATEGORYTREE:
              'Não foram encontradas categorias não atribuídas com os filtros selecionados, entre em contato com o suporte para habilitá-los.',
            WITHOUTCLUSTERIZATION: 'Sem Agrupamento',
            WITHOUTSEGMENT: 'Sem Segmentos',
            WITHOUTSTRATEGY: 'Sem Estratégias',
            WITHOUTROL: 'Sem Aglomerados',
            WITHOUTCLUSTER: 'Sem Aglomerados',
            CLUSTERING: 'Clusterização',
          },
        },
        ROLES_STRATEGIES_ASSIGN: {
          SUBTITLE:
            'Nesta tela você pode atribuir um cluster para cada nível de categoria, previamente definido na árvore de categorias. Por sua vez, para cada cluster ou ponto de venda do cluster selecionado, pode-se atribuir uma função e uma estratégia diferenciada.',
          EXPORT_TTIP: 'Exportar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          CATEGORIES: 'Categorias',
          CLUSTERING: 'Clusterização',
          ROL: 'Papel',
          STRATEGY: 'Estratégia',
          UNALLOCATED_TTIP: 'Não Atribuído',
          SEARCH_CLUSTER: 'Procurar Cluster…',
          SEARCH_ROL: 'Procurar Papel…',
          WITHOUT_CLUSTERS: 'Função habilitada se os clusters são atribuídos',
          SEARCH_STRATEGY: 'Procurar Estratégia…',
          CHANGE_CLUSTERING_TITLE: 'Tem certeza de que deseja alterar este clust',
          CHANGE_CLUSTERING_SUBTITLE: 'Isso limpará os papéis e estratégias atribuídas a todos os clusters nesta categoria.',
          CANCEL_BTN: 'Cancelar',
          CHANGE_BTN: 'Mudar',
          ACCEPT_BTN: 'Aceitar',
          CHANGE_RS_TITLE: 'Modificar Papel / Estratégia',
          CHANGE_RS_SUBTITLE: 'Você deseja atribuir: {{val}} a todos os clusters nesta categoria?',
          ROUTE: 'Sortimento / Configuração',
          TITLE: 'Configurações de categoria',
        },
        ROLES: {
          ROUTE: 'Sortimento / Configuração',
          TITLE: 'Papéisl',
          SUBTITLE: 'Nesta tela você pode criar e modificar as diferentes funções que serão atribuídas às categorias.',
          NEW_ROLE_BTN: 'Novo Papel',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Apagar papéis selecionados',
          DELETE: 'Apagar',
          NAME: 'Nome',
          OBSERVATION: 'Observação',
          DELISTING_RULE: 'Desativar regra',
          LISTING_RULE: 'Ativar Regra',
          OTHERS_RULES: 'Outras Regras',
          FRECUENCY: 'Frequência',
          DEFINE_ROL_STP1: 'Definir Papel',
          DELISTING_RULES_STEP2: 'Regras para Desativar\n',
          LISTING_RULES_STEP3: 'Regras para Ativar',
          OTHERS_RULES_STEP4: 'Regras Adicionais',
          FRECUENCY_STEP5: 'Frequência de Análise',
          TITLE_FORM_NEW: 'Novo Papel',
          TITLE2_FORM_NEW: 'Novo Papel /',
          STP1: 'Etapa 1 - Definir Papel',
          LEGEND_ROL:
            'O papel da categoria define os critérios para ativar ou desativar um produto para um determinado cluster. O papel e a estratégia definem os critérios e métricas a serem avaliados para gerar sugestões.',
          NAME_INPUT: 'Nome',
          OBSERVATION_INPUT: 'Observação',
          NEXT_BTN: 'Próximo',
          STP2: 'Etapa 2 - Regras para Desativar',
          LEGEND_DELISTING_RULE: 'Nesta etapa você pode definir os critérios para desativar produtos.',
          RULE_DELISTING_RULE: 'Sugerir desativar produtos cujo ',
          RULE_LISTING_RULE: 'Sugerir ativar produtos cujo ',
          RULE_DELISTING_RULE_ALT: 'Sugerir produtos que desativam ',
          RULE_LISTING_RULE_ALT: 'Sugerir produtos ativadores ',
          RULE_HIGH_AI: 'Sugerir ativar produtos usando IA',
          MINIMUM_AVAILABILITY: 'Disponibilidade mínima de ',
          PRESENCE_IN_STORES: 'Presença mínima na loja de ',
          SUPPLIERS_W_SKU: 'Fornecedores com uma participação mínima de produtos de ',
          NEW_DATES: 'Quando novos dados são inseridos',
          OPPORTUNITIES_IA: 'Quando a IA encontra oportunidades',
          AND: 'E',
          THE: 'o',
          THEFREQ: 'o',
          AUXS: {
            _variableSource: {
              _DEMOMercado: 'Mercado',
              ParetoCluster: 'Pareto do Cluster',
              ParetoGeneral: 'Pareto Total',
              ParetoMarket: 'Pareto do Mercado',
              TotalMarket: null,
              ParetoReference: 'Referência Pareto',
            },
            _variableSource_phrase: {
              ParetoCluster: 'do Pareto do Cluster',
              ParetoGeneral: 'do Pareto Total',
              ParetoMarket: 'do Pareto do Mercado',
              TotalMarket: null,
              ParetoReference: 'da Marca de Referência de Pareto',
            },
            _variable: {
              WeightedIndex: 'Índice P',
              SalesUnits: 'Vendas U',
              Sales: 'Vendas R$',
              Margin: 'Margem R$',
              MarginWithVendor: 'Margem por acordo',
              PercentageMargin: 'Margem %',
              Gmroi: 'GMROI',
              Presence: 'Presença',
              MarketSales: 'Mercado $',
              MarketUnits: 'Mercado Un.',
            },
            _variable_phrase: {
              WeightedIndex: 'de Vendas em Índice Ponderado',
              SalesUnits: 'de Vendas em Unidades',
              Sales: 'de Vendas em R$',
              Margin: 'Margem em R$',
              MarginWithVendor: 'de Margem por acordo',
              PercentageMargin: 'Margem em %',
              Gmroi: 'de GMROI',
              Presence: 'Presença',
              MarketSales: 'Vendas de Mercado em $',
              MarketUnits: 'Vendas de Mercado em Unidades',
            },
            _method: {
              AbsoluteValue: 'Valor Absoluto',
              TotalShare: 'Participação %',
              CumulativeShare: 'Participaçāo Acum. %',
            },
            _methodTop: {
              AbsoluteValue: 'Valor Absoluto',
              TotalShare: 'Share %',
              CumulativeShare: 'Top %',
            },
            _method_phrase: {
              AbsoluteValue: 'Valor Absoluto',
              TotalShare: 'Participação %',
              CumulativeShare: 'Participaçāo Acum. %',
            },
            _condition: {
              GreaterThan: 'é maior',
              LowerThan: 'é menor',
              Equals: 'é igual',
            },
            _condition_phrase: {
              GreaterThan: 'ser maior que',
              LowerThan: 'ser menor que',
              Equals: 'ser igual a',
            },
            _conditionTopBottom: {
              LowerThan: 'Topo',
              GreaterThan: 'Inferior',
            },
            _conditionTopBottom_phrase: {
              LowerThan: 'é menor ou igual a',
              GreaterThan: 'é maior que ou igual a',
            },
            _combinationMethod: {
              And: 'e',
              Or: 'ou',
            },
            _combinationMethod_phrase: {
              And: 'e a',
              Or: 'ou o',
            },
            _weekday: {
              Monday: 'Segunda',
              Tuesday: 'Terça',
              Wednesday: 'Quarta',
              Thursday: 'Quinta',
              Friday: 'Sexta',
              Saturday: 'Sábado',
              Sunday: 'Domingo',
            },
            _frecuency: {
              Biweekly: 'Quinzenal',
              Monthly: 'Mensal',
              BiMonthly: 'Bimestral',
              ThreeMonth: 'Trimestral',
              FourMonth: 'A cada 4 meses',
              SixMonth: 'Semestral',
              Annually: 'Anual',
            },
            _frecuency_phrase: {
              Biweekly: 'Quinzenal',
              Monthly: 'Mensal',
              BiMonthly: 'Bimensal',
              ThreeMonth: 'Trimestral',
              FourMonth: 'A cada 4 meses',
              SixMonth: 'Semestral',
              Annually: 'Anual',
            },
          },
          COMBINATION: 'REGRA',
          DELETE_COMBINATION_TTIP: 'Apagar Regra',
          CANCEL_BTN: 'Cancelar',
          CONFIRM_BTN: 'Confirmar eliminação',
          VARIABLE_SOURCE_INPUT: 'Fonte',
          VARIABLE_INPUT: 'Variável',
          METHOD_INPUT: 'Método',
          CONDITION_INPUT: 'Condição',
          VALUE_INPUT: 'Valor',
          LOGIC_INPUT: 'Lógica',
          REFERENCE_CLUSTER: 'Cluster de referência',
          ADD_COMBINATION_BTN: 'Adicionar Regra',
          PREVIUS_BTN: 'Anterior',
          STP3: 'Etapa 3 - Regras para Ativar',
          LEGEND_LISTING_RULE: 'Nesta etapa você pode definir os critérios para ativar novos produtos.',
          STP4: 'Etapa 4 - Outras Regras',
          LEGEND_OTHERS_RULES: 'Nesta etapa, você pode configurar outras regras e restrições que deseja adicionar a esta função.',
          CHECK_AVAILABILITY: 'Verifique a disponibilidade antes de desativar',
          CHECK_AVAILABILITY_TEXT:
            'Sugere desativar um produto somente se a disponibilidade for igual ou superior ao valor aqui especificado. Você tem que ter dados de estoque.',
          MINIMUM_AVAILABILITY_INPUT: 'Disp. Mín.',
          MINIMUM_AVAILABILITY_TTIP: 'Disponibilidade Mínima',
          CHECK_PRESENCE: 'Verifique a presença antes de desativar',
          CHECK_PRESENCE_TEXT:
            'Sugere desativar apenas quando um produto tiver vendas no mínimo {{val}}% das lojas no cluster e dentro do período de análise selecionado.',
          MINIMUM_PRESENCE_INPUT: 'Pres. Mínima',
          MINIMUM_PRESENCE_TTIP: 'Presença Mínima',
          CHECK_SUPPLIER: 'Verifique fornecedores com poucos produtos',
          CHECK_SUPPLIER_TEXT:
            'Se esta opção estiver ativada, o Prisma irá sugerir a desativação de todos os produtos pertencentes a fornecedores com uma participação nas vendas inferior ao aqui especificado, otimizando assim a sua cadeia de abastecimento.',
          MINIMUM_PART_INPUT: 'Participação Mínima',
          MINIMUM_PART_TTIP: 'Participação Mínima',
          SUGGEST_HIGH: 'Sugere ativar com inteligência artificial',
          SUGGEST_HIGH_TEXT:
            'A inteligência artificial do prisma irá sugerir a ativação de produtos dependendo de seus atributos e desempenho no {{val}} / cluster.',
          COMING_SOON: 'Em breve',
          STP5: 'Etapa 5 - Frequência de análise',
          LEGEND_CATEGORY_ANALYSIS:
            'Nesta etapa, você definirá com que frequência executará uma análise de revisão de intervalo para as categorias com este papel.',
          FRECUENCY_INPUT: 'Frequência',
          WEEKDAY_INPUT: 'Dia da semana',
          GENERATE_SUGGESTIONS_IA: 'Gerar sugestões ativas quando a IA encontra oportunidades',
          GENERATE_SUGGESTIONS_IA_TEXT:
            'Cada vez que a inteligência artificial encontra uma oportunidade de melhoria, sugestões serão geradas para ativar produtos além da frequência e regras definidas.',
          GENERATE_SUGGESTIONS: 'Gerar sugestões de ativação sempre que novas informações de mercado estiverem disponíveis',
          GENERATE_SUGGESTIONS_TEXT:
            'Gerar sugestões ativas para novos lançamentos de produtos com base nas informações de mercado disponíveis.',
          SAVE_BTN: 'Salvar',
          NEW_COMBINATION: 'Nova Regra',
          ADD_BTN: 'Adicionar',
          REPEATED_VARIABLE_AND_SOURCE: 'Variável e Fonte não podem ser duplicadas para mais de uma regra.',
          SUGGESTION_ROLE_NAME: null,
          SUGGESTION_ROLE_DESCRIPTION: null,
        },
        STRATEGIES: {
          DELETE_TITLE: 'Excluir estratégia',
          DELETE_TITLE_MULTIPLE: 'Excluir estratégias',
          DELETE_CONTENT: '{{val}} estratégia será excluída.',
          DELETE_CONTENT_MULTIPLE: '{{val}} registros de estratégia serão excluídos.',
          ROUTE: 'Sortimento / Configuração',
          TITLE: 'Estratégias',
          SUBTITLE: 'Nesta tela você pode criar e modificar as diferentes estratégias que serão atribuídas às categorias.',
          NEW_STRATEGY_BTN: 'Nova Estratégia',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Apagar estrategias selecionadas',
          DELETE: 'Apagar',
          STRATEGY: 'Estratégia',
          OBSERVATION: 'Observação',
          VARIABLE: 'Variável',
          NEWS: 'Novo',
          TYPOLOGIES: 'Classificação',
          PERIOD: 'Periodo',
          DEFINE_STRATEGY_STP1: 'Definir Estratégia',
          VARIABLES_SELECTION_STP2: 'Seleção de Variáveis',
          OTHER_CONFIGURATIONS_STP3: 'Outras Configurações',
          ANALYSIS_PERIOD_STP4: 'Periodo de Análise',
          TITLE_FORM_NEW: 'Nova Estratégia',
          TITLE_FORM_EDIT: 'Editar Estratégia',
          STP1: 'Etapa 1 - Definir Estratégia',
          LEGEND_STRATEGY: 'A estratégia o ajudará a definir a métrica pela qual a análise de Pareto será realizada.',
          NAME_INPUT: 'Nome',
          OBSERVATION_INPUT: 'Observação',
          NEXT_BTN: 'Próximo',
          STP2: 'Etapa 2 - Seleção de Métricas',
          LEGEND_SELECTION_VARIABLES: 'Nesta etapa você pode definir os critérios para que o sortimento seja analisado e otimizado.',
          VARIABLE_INPUT: 'Variável',
          COMBINATION_PERCENTAGE_INPUT: 'Combinação',
          VALIADATION: 'O valor inserido deve ser maior que 0 ou igual a 100%',
          DELETE_VARIABLE_TTIP: 'Apagar',
          VALIADATION2: 'A soma de todas as métricas deve ser 100%',
          ADD_COMBINATION_BTN: 'Adicionar Combinaçāo',
          PREVIUS_BTN: 'Anterior',
          STP3: 'Etapa 3 - Outras Configurações',
          LEGEND_OTHER_CONFIGURATIONS: 'Nesta etapa, você pode inserir configurações adicionais que melhorarão a precisão da análise.',
          OTHER_CONFIGURATIONS_TEXT: 'Um produto é considerado novo por',
          DAYS_INPUT: 'Dias',
          DAY_INPUT: 'Dia',
          SINCE_TEXT: 'considerado novo a partir da',
          SINCE: 'desde sua',
          EVENT_INPUT: 'Evento',
          TYPOLOGIES_TEXT: 'CLASSIFICAÇÃO (seções de Pareto):',
          DEFINE_PARETO_TEXT:
            ' Defina os intervalos das seções de Pareto para classificar os produtos automaticamente com base na métrica selecionada.',
          HIGH_ROTATION: 'ALTO GIRO',
          HIGH_ROTATION_TEXT: ': Produtos cujo índice acumulado (Pareto) vai de 0 a',
          VALIDATION_HIGH_ROTATION: 'Deve ser inferior a 100% e inferior a produtos de baixo giro',
          LOW_ROTATION: 'BAIXO GIRO',
          LOW_ROTATION_TEXT: ': Produtos cujo índice acumulado (Pareto) vai de {{val}}% a',
          VALIDATION_LOW_ROTATION: 'Deve ser menor que 100% e maior que Alto Giro',
          LONG_TAIL: 'CAUDA LONGA',
          LONG_TAIL_TEXT: ': Produtos cujo índice acumulado (Pareto) vai de {{val}}% a 100%. Eles são os últimos {{val2}}% do Pareto.',
          WITHOUT_ROTATION: 'SEM-GIRO',
          WITHOUT_ROTATION_TEXT: ': Produtos sem vendas durante o período de análise selecionado.',
          NEWS1: 'NOVOS PRODUTOS',
          NEWS_TEXT: 'Produtos que têm menos de {{val}} dias desde seu {{val2}}',
          STP4: 'Etapa 4 - Período de Análise',
          DEFINE_PERIOD_TEXT:
            'Nesta etapa você pode definir o período em meses que levará para realizar a análise. Para normalizar e poder comparar você sempre verá valores médios expressos em 30 dias em relatórios e pareto.',
          LATEST_INPUT: 'Últimos',
          MONTHS: 'Meses',
          JANUARY: 'JAN',
          FEBRUARY: 'FEV',
          MARCH: 'MAR',
          APRIL: 'APRL',
          MAY: 'MAI',
          JUNE: 'JUN',
          JULY: 'JUL',
          AUGUST: 'AGO',
          SEPTEMBER: 'SET',
          OCTOBER: 'OUT',
          NOVEMBER: 'NOV',
          DECEMBER: 'DEZ',
          LEGEND_MOTHS:
            'Se você excluir um mês da lista, ele não será considerado como parte da média. Se o número de meses selecionados for menor que o período selecionado, a média será calculada usando o número de meses disponíveis.',
          SAVE_BTN: 'Salvar',
          FROM_DATE_OF: 'a partir da data de',
          LAST_MONTH_: 'Último mês',
          LASTS_: 'Último',
          MONTHS_: 'Meses',
          EXCEPT_FOR: 'Exceto por',
          MONTHS_VALIDATION: 'Os meses de análise não podem ser maiores que os meses habilitados',
        },
        SEG_ASSIGNMENT: {
          ROUTE: 'Sortimento / Configuração',
          TITLE: 'Designar Segmentos',
          SUBTITLE:
            'Nesta tela pode-se atribuir a cada nível de categoria, previamente definido na árvore de categorias, a segmentação do produto principal que se utilizará para realizar a análise e a otimização do sortimento.',
          EXPORT_TTIP: 'Exportar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          CATEGORY: 'Categoria',
          SEGMENTATION: 'Segmentação',
          NOT_ASSIGNED_TTIP: 'Não Atribuído',
          SEARCH_SEGMENTATION: 'Procurar Segmentação…',
          SEGMENT_QUANTITY: '# Quant. de Segmentos',
          SEGMENT_QUANTITY_TTIP: 'Quantidade de Segmentos',
          CLASSIFIELDS: 'Clssificados %',
          CLASSIFIELDS_TTIP: 'Porcentagem de produtos dentro da categoria classificados com um segmento.',
        },
        CATEGORIES: {
          ROUTE: 'Sortimento / Configuração',
          TITLE: 'Árvore de Categorias',
          SUBTITLE: 'Aqui você pode visualizar a hierarquia de categorias carregadas no Prisma. ',
          SAVE_BTN: 'Salvar',
          PRINT_TTIP: 'Imprimir',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          SECTOR: 'Setor',
          CATEGORY: 'Categoria',
          SUBCATEGORY: 'Subcategoria',
          ELEMENTS: 'Elementos',
          SELECTED_CATEGORIES_TTIP: 'Categorias Selecionadas: ',
          MODAL_TTILE: 'Você deseja salvar as alterações?',
          MODAL_SUBTTILE: 'Ao salvar, as alterações anteriores serão acionadas',
          CLOSE_BTN: 'Fechar',
          UNDO_BTN: 'Desfazer alterações',
          ACCEPT_BTN: 'Aceitar',
        },
        HELP: {
          VARIABLE_SOURCE_INPUT:
            'Indica se a sugestão será gerada com base no pareto do cluster em análise, no pareto de todos os clusters ou com informações de mercado.',
          VARIABLE_INPUT: 'Indique com qual variável o pareto deve ser construído para avaliar a regra.',
          CONDITION_INPUT: 'Indica a condição numérica para gerar a regra. Pode ser maior, menor ou igual.',
          METHOD_INPUT:
            'Permite definir que tipo de corte será utilizado para a regra. Pode ser um valor absoluto, uma porcentagem de participação ou uma porcentagem cumulativa de participação.',
          VALUE_INPUT: 'Valor numérico necessário para completar a condição da regra.',
          FRECUENCY_INPUT: 'Indica a repetição da análise da categoria ao longo do tempo.',
          WEEKDAY_INPUT: 'Dias da semana em que a análise da categoria deve ser realizada.',
          VARIABLE_S_INPUT: 'Indica com qual variável o sortimento deve ser analisado.',
          COMBINATION_PERCENTAGE_INPUT:
            'Indica o percentual da variável que será levado em consideração para a análise. Se for uma única variável, o valor deve ser 100%. Se houver mais variáveis de análise, a soma das porcentagens de análise das mesmas deve ser 100%.',
        },
        MARKET_INFO: {
          TITLE: null,
          CATEGORY_ASSIGN: null,
          CATEGORY_ASSIGN_TOOLTIP: 'Atribuir Categoria',
          CATEGORY_ASSIGN_DISABLED_TOOLTIP: 'Você não tem permissões',
          CATEGORY_ASSIGN_MSG: null,
          marketItemName: null,
          marketItemCode: null,
          marketCategory: null,
          prismaCategory: null,
          marketItemBrand: null,
          marketItemManufacturer: null,
          matching: null,
          CREATION_DATE: null,
          ITEMS_NOT_MATCH: null,
          MARKET_CATEGORIES: null,
          MARKET_CATEGORY: null,
          PRODUCT: null,
          PRODUCTS: null,
          itemMarketDataType: {
            ownItems: null,
            marketItems: null,
            all: null,
          },
        },
      },
      DECISIONS: {
        ASSORT_ASSIGNATION: {
          ROUTE: 'Sortimento / Decisões',
          TITLE: 'Decisões de Sortimento',
          SUBTITLE:
            'Nesta tela você verá como é composto o mix de sortimento por categoria em cada cluster. Por sua vez, você terá um resumo das sugestões e impactos delas. Usando as alavancas, você pode ativar ou desativar categorias, segmentos ou mix de sortimento.',
          SELECT_CATEGORY: 'Selecione a categoria que deseja trabalhar.',
          LAST_SALE: 'Último mês de vendas:',
          NO_DATA: 'Sem dados',
          LAST_STOCK: 'Último mês de estoque:',
          LAST_MARGIN2: 'Último mês de Margem por Acordos:',
          LAST_MARKET_INFO: 'Último mês de Informação de Mercado:',
          SHOW_CLUSTER_ASSORTMENT: 'Escolha uma categoria para mostrar o sortimento de cada cluster',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          PRINT_TTIP: 'Imprimir',
          CLUSTER: 'Cluster',
          CATEGORY: 'Categoria',
          SEGMENT: 'Segmento',
          NO_SEGMENT: 'Você deve ter um segmento para poder ativá-lo',
          IE: 'IE%',
          LISTINGS: '# Ativos',
          SKUS: '# Produtos',
          TOTAL: '# Total',
          INACTIVE: '# Para Desativar',
          ACTIVE: '# Para Ativar',
          IMPACT: 'Impacto R$',
          VIEW_PARETOC_TTIP: 'Clique aqui para ver o pareto de "categoria" em "cluster"',
          VIEW_PARETOT_TTIP: 'Clique aqui para ver o pareto da "categoria" total da empresa',
          IMPACT_TTIP: 'Impacto nas vendas de R$ se as sugestões forem ativadas ou desativadas',
          DELIST_TTIP: 'Número de sugestões para desativar',
          LIST_TTIP: 'Número de sugestões para ativar',
          TOTAL_TTIP: 'Total de produtos dentro do segmento',
          LISTINGS_TTIP: 'Total de produtos ativos no cluster',
          PERCENTAGE_TTIP: 'Porcentagem de produtos ativos',
          ERROR: 'Alocação falhou',
          CORRECT: 'Alocação salva com sucesso',
          ACCEPT_BTN: 'Alocação salva com sucesso',
          ENABLE_ASSIGNMENT_TITLE: 'Confirma ativar esses produtos neste cluster?',
          DISABLE_ASSIGNMENT_TITLE: 'Confirma desativar esses produtos neste cluster?',
          ACTION_ASSIGNMENT_SUBTITLE: 'Esta ação modificará o sortimento de todos os produtos do Cluster.',
          CANCEL_BTN: 'Cancelar',
          EDIT_BTN: 'Modificado',
          CHECK: 'Não perguntar de novo.',
          TITLE_FILTERS: 'Filtros',
          CATEGORY_FILTERS: 'Categoria',
          CATEGORY_FILTERS_TTIP: 'Você deve selecionar pelo menos uma categoria para poder filtrar por cluster',
          SEARCH_CATEGORY: 'Procurar Categorias…',
          COUNT_FILTRED: 'Quantidade filtrada',
          TOTAL_COUNT: 'Quantidade total',
          CLUSTER_FILTERS: 'Cluster',
          WITHOUT_NEWS: 'Sem novos produtos',
          TOTALITEMS: 'Total de produtos',
          TOTALSALES: 'Total de vendas',
          MSG_WITHOUT_NEWS: 'Os produtos que não estiverem em novas condições serão exibidos, mantendo sua posição na tabela.',
          WITHOUT_INACTIVES: 'Sem produtos inativos',
          MSG_WITHOUT_INACTIVES: 'Produtos inativos não serão exibidos para os clusters selecionados.',
          RECALC_PARETO: 'Recálculo de Pareto',
          SHOW_ONLY_SUGGESTIONS: null,
          MSG_RECALC_PARETO: 'O pareto será atualizado com base nos produtos filtrados.',
          ANALYST_FILTERS: 'Analista',
          APPROVER_FILTERS: 'Aprovador',
          CLEAN_FILTER_BTN: 'Limpar',
          APPLY_FILTER_BTN: 'Aplicar',
          UNASSIGNEDSEGMENTS: 'Mostrar segmentos não atribuídos',
          UNASSIGNEDCLUSTERS: null,
          EXPORT_TTIP: null,
        },
        ASSORT_ASSIGNATION_ITEM: {
          OBSERVATION: 'Observações',
          ACTIVE_IN: 'Ativo em',
          ITEM_TAGS: 'Filtros de produtos',
          STORE_TAGS: null,
          BEHAVIOR_FOR_CATEGORY: 'Este item só pode ser operado a partir do cluster geral Pareto.',
          SUGGESTION_MODAL_MSG:
            'A solicitação para gerar sugestões para este cluster será atendida, você será notificado assim que o processo for concluído, pode demorar alguns minutos',
          NOT_ASSORTMENT_BASED: 'Este item não é baseado em sortimento. Você deve modificá-lo a partir do mestre do item primeiro.',
          BLOCKED_FOR_SALES: 'Este item está bloqueado para venda. Você deve modificá-lo a partir do mestre do item primeiro.',
          BLOCKED_FOR_MARKET: 'Este item está bloqueado. Produto de Mercado, deve ser gerido a partir das sugestões por cluster.',
          ROUTE: 'Alocação de sortimento /',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          ORDER_TTIP: 'Ordenar',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          PRINT_TTIP: 'Imprimir',
          ACTIVATE: 'Ativar',
          ACTIVATE_TTIP: 'Ativar os produtos selecionados',
          INACTIVATE: 'Desativar',
          INACTIVATE_TTIP: 'Desativar os produtos selecionados',
          PRODUCT: 'Produto',
          BRAND: 'Marca',
          STATE: 'Estado',
          ACTIVE: 'Ativo',
          INACTIVE: 'Inativo',
          INACTIVE_MARKET_PROD: 'Produto de Mercado, devem ser geradas sugestões para ação',
          SEGMENT: 'Segmento',
          CIA: '# UN Companhia',
          MARKET: '# UN Mercado.',
          PRICE: 'Preço R$',
          MARGIN: 'Margem %',
          CORRECT: 'Alocação salva com sucesso',
          REPORT_OK: null,
          ACCEPT_BTN: 'Aceitar',
          TITLE_FILTERS: 'Filtros',
          STATE_FILTERS: 'Estado',
          ALL_OP1: 'Todos',
          ACTIVE_OP2: 'Ativo',
          INACTIVE_OP3: 'Inativo',
          CLEAN_FILTER_BTN: 'Limpar',
          APPLY_FILTER_BTN: 'Aplicar',
          LISTING: 'Ativar',
          DELISTING: 'Desativar',
          ACCEPT_SUGGESTION: 'Aceitar sugestões',
          REJECT_SUGGESTION: 'Rejeitar Sugestão',
          ACCEPT_SUGGESTIONS: null,
          REJECT_SUGGESTIONS: null,
          REVERT_SUGGESTION: 'Reverter alterações',
          REVERT_SUGGESTION_TO_INACTIVE: 'Reverter alterações (desativar)',
          REVERT_SUGGESTION_TO_ACTIVE: 'Reverter alterações (ativar)',
          COLUMN_DISPLAY_TTIP: 'Visualização de Colunas',
          REPORTS: 'Reportes',
          SUGGESTION: 'Gerar sugestão',
          PRODUCTS: 'Produtos',
          SALES: 'Vendas',
          IMPACT: 'Impacto nas Vendas',
          DAYS: 'Dias',
          COST: 'Custo R$',
          ORDERS: '# Ordens',
          DAYS_STOCK: 'Dias de Estoque',
          IN_STOCK: 'Em-Estoque',
          CLUSTER_SUGGESTION_ADD_TTIP: null,
          CLUSTER_SUGGESTION_REMOVE_TTIP: null,
          CLUSTER_SUGGESTION_ADD_DEMO_TTIP: null,
          CLUSTER_SUGGESTION_REMOVE_DEMO_TTIP: null,
          ACTIVE_NEW: 'Para ativar',
          INACTIVE_NEW: 'Para desativar',
          ASSORTMENT_FOR_CATEGORY__BY_CLUSTER: 'Manipular por cluster',
          ASSORTMENT_FOR_CATEGORY__BY_CATEGORY: 'Manipular por Total Geral',
          LISTING_ALL_CLUSTERS: 'Ativar em todos os clusters',
          DELISTING_ALL_CLUSTERS: 'Desativar em todos os clusters',
          STATUS_PDV: 'Apenas sortimento de ponto de venda',
          STATUS_COMBO: 'Parte da Receita ou do Combo',
          CHANGE_GENERAL_CONFIRM_DIALOG_TITLE: 'Confirmação da atribuição do sortimento',
          CHANGE_GENERAL_CONFIRM_DIALOG_BODY: 'Você quer lidar com este item de uma maneira geral?',
          CHANGE_GENERAL_CONFIRM_DIALOG_BODY_ALT:
            'A atribuição do sortimento será alterada para "Geral". Perder-se-ão as tarefas individuais de agrupamento.',
          WHITH_FILTERS: 'Com filtros',
          WHITH_NO_FILTERS: 'Não filtrado',
          setOrderBydefault: null,
          setOrderBysalesShare: null,
          setOrderBysalesShareGap: null,
          EXIST_EXCEPTION_STORE_ASSORTMENT_TOOLTIP: null,
          STORE_CLUSTER_COUNT_TOOLTIP_ACTIVE: 'O produto está Ativo para venda em {{val}} de {{val2}} PDV.',
          STORE_CLUSTER_COUNT_TOOLTIP_INACTIVE: 'O produto está Inativo para venda em {{val}} de {{val2}} PDV.',
        },
        ASSORT_CLUSTER_REPORTS: {
          GENERAL_REPORT: 'Relatório Geral',
          CLUSTER_REPORT: 'Relatório por Cluster',
          PREFIX_REPORT: 'Reportado por',
        },
        ERP: {
          ROUTE: 'Sortimento / Desições',
          TITLE: 'Publicar Decisões',
          DOWLOAD_BTN: 'Baixar Info',
          PREVIEW_BTN: 'Vista Prévia',
          INFORM_BTN: 'Publicar',
          LAST_PRICES: 'Últimos preços publicados',
          GENERATION_DATE: 'Data de geração',
          GENERATION_TIME: 'Tempo de Geração',
          USER: 'Usuário',
          FILE: 'Arquivo',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          PREVIEW_PROCCESING: 'O processo começou. Você receberá uma notificação quando terminar.',
          PREVIEW_WITHOUT_PROCCESING: 'Não há novos dados para processar.',
          REPORT_ASSORTMENT_DECISIONS: 'Decisões de Sortimento',
          REPORT_MARKET_ITEM: 'Produtos de Mercado',
        },
      },
      REPORTS: {
        TITLE_DECISIONS: 'Relatórios de Decisões',
        CLUSTER_ANALYSIS: {
          ROUTE: 'Sortimento / Reportes',
          TITLE: 'Analise do Cluster',
          SUBTITLE:
            'Prisma utiliza algoritmos de inteligência artificial para detectar padrões de compra do consumidor e, assim, validar o clusterização realizada. Nesta tela, você pode analisar a consistência da clusterização definida anteriormente e alterar um ponto de venda de um cluster, se necessário. Ao aceitar uma sugestão de mudança, Prisma produzirá automaticamente a reatribuição do sortimento e o relatório que deve ser publicado ou pontos de venda para que a mudança seja corretamente implementada.',
          DISPERSION_TITLE: 'Dispersão de {{val}} por Cluster',
          EXPORT_TTIP: 'Exportar',
          APP_TTIP: 'Compartilhe com o aplicativo',
          DASHBOARD_TTIP: 'Ir para o dashboard',
          NUMBER_OF_STORES: 'Número de lojas',
          PREVIOUS_MONTH_DIF: 'Dif % Vs Mês Anterior',
          CLUSTER_QUANTITY: 'Quantidade de Cluster',
        },
      },
      ADMIN: {
        IMPORTERS: {
          MARKET_INFO: {
            TITLE: 'Informação de Mercado',
          },
        },
      },
      ABM_MODAL: {
        ROLE_SELECTION: 'Seleção do papel',
        NEW_ROL_NAME: 'Dados de papel',
        RULES_TO_DELIST: 'Regras de desabilitação',
        RULES_TO_LIST: 'Regras para Ativar',
        MARKET_LIST: null,
        VIEW: 'Ver',
        RULES_SELECT_LEGEND1:
          'A ordem será tomada para gerar as sugestões para esta categoria, você será notificado assim que o processo for concluído, podendo levar alguns minutos.',
        RULES_SELECT_LEGEND2:
          'O papel é atribuído à categoria e combinado com a estratégia define os critérios e variáveis a serem avaliados nas sugestões.',
        RULES_SELECT_LEGEND3: ' As sugestões só serão geradas para grupos visíveis.',
        ASSIGN_ROLE: 'Atribuir Papel',
        ROLES_TYPES_TITLES: {
          NORMAL: 'Papel predefinido da categoria:',
          OTHER: 'Atribuir Papel Previamente criado',
          NEW: 'Criar e atribuir um novo papel',
          TEST: 'Atribuir regras de teste',
          MARKET: null,
        },
        ROLES_TYPES_DESCRIPTION: {
          NORMAL:
            'As sugestões para registro e cancelamento de registro serão geradas de acordo com o Papel já selecionado para esta categoria.',
          OTHER:
            'As sugestões para registro e cancelamento do registro serão geradas de acordo com a seleção de uma Função previamente registrada.',
          NEW: 'Permite criar e atribuir um novo papel para esta categoria.',
          TEST: ' As regras de descarga e descarga serão atribuídas e geradas de acordo com as configurações pré-definidas, de forma personalizada para esta categoria.',
          MARKET: null,
        },
        GENERATE_SUGGESTIONS_OK: 'Sugestão atribuída com sucesso',
      },
    },
    IMV2: {
      LIST: {
        TITLE: 'Item Master',
        NEW_ITEM: 'Novo item',
        ROUTE: 'Item-mestre / lista',
        DELETE: 'Desativar',
        DELETE_TTIP: 'Desativar registro',
        UNDELETE: 'Ativar',
        UNDELETE_TTIP: 'Ativar the registry',
        DELETE_ROWS: 'Desativar registros',
        DELETE_ROW_CONTENT: 'Seguro que desea Desativar {{val}}? solo podra ser desativados si não se encuentra en uso',
        DELETE_ROWS_CONTENT: 'Seguro que desea Desativar {{val}} produtos? solo podran ser Desativados si no se encuentran en uso',
        UNDELETE_ROW: 'Ativar Registro',
        UNDELETE_ROWS: 'Ativar Registros',
        UNDELETE_ROW_CONTENT: 'Você tem certeza que quer ativar {{val}? quando ativado, ele será iniciado com o novo estado',
        UNDELETE_ROWS_CONTENT:
          'Você tem certeza de que quer ativar os produtos {{val}}? Quando ativados, eles começarão com o novo status.',
        ACTION_ROW_CONTENT_SUCCESS: 'Ação concluída com sucesso',
        ENTITIES: {
          REPORT: 'Relatório completo aqui',
          APPLY: null,
          ITEM_NOT_FOUND: null,
          USEDINCOMBOS: null,
          USEDINMISSIONS: null,
          USEDINPARENTRULES: null,
          USEDINPLANOGRAMS: null,
          USEDINPROMOTIONS: null,
          USEDINRECIPES: null,
          IMPEDIMENT: {
            USEDINPARENTRULES: null,
            USEDINMISSIONS: null,
            USEDINPLANOGRAMS: null,
            USEDINRECIPES: null,
            USEDINCOMBOS: null,
          },
        },
        ACTION_FAIL: {
          DESCRIPTION_ERROR: null,
          TYPE: null,
          KEYWORD: null,
          VALUES: null,
          WARNING: null,
          ERROR: null,
          ENTITYNOTFOUND: null,
        },
        FILTER_TTIP: 'Filtro',
        FULLSCREEN_TTIP: 'Maximizar',
        EXPORT_TTIP: 'Exportar',
        NAME: 'Nome',
        CODE: 'Código',
        INTERNAL_CODE: 'Código',
        CODE_EXIST: 'O código {{val}} já existe',
        TYPE: 'Gentil',
        CATEGORY: 'Categoria',
        SUBCATEGORY: 'Subcategoria',
        BRAND: 'Marca',
        EDIT_ROW: 'Editar registro',
        VIEW_ROW: null,
        EDIT_ROW_DISABLED_TOOLTIP: 'Não é possível editar um registro no processo de publicação',
        COPY_ROW: 'Copiar registro',
        DISABLED_CLONE_TOOLTIP: 'Somente registros no estado publicado podem ser clonados',
        DISABLED_CLONE_TOOLTIP_OR_NEW: null,
        DELETE_ROW: 'Desativar registro',
        TYPE_FILTERS: 'Tipos de itens',
        SEE_DELETED_FILTERS: 'Ver Inativados',
        SEE_ITEMS_CREATED_WHIT_MARKET_RULE: null,
        SHORT_NAME: 'Pequena descrição',
        STATUS: 'Status',
        STATUS_NEW: 'Novo',
        STATUS_MODIFIED: 'Modified',
        STATUS_CURRENT: 'Postado',
        EAN: 'Código Interno',
        ITEM_CREATION_ON: 'Data de criação',
        ITEM_MODIFY_ON: 'Data de modificação',
        ASSORTMENTBEHAVIORS: 'Surtido',
        STORES_FILTER: 'Pontos de Venda',
        CLONE_ITEM_TITLE: 'Clone Item',
        CLONE_ITEM_CONTENT:
          'Definir nome e código interno para o novo item. Todos os pontos existentes serão clonados de {{val}} ({{val2}}), havendo a possibilidade de edição.',
        ITEM_TAGS: 'Filtros de Produto',
        ITEM_FILTER: 'Produto',
        ACTION_ROW_CONTENT_FAILED: null,
      },
      EDIT: {
        ITEM: {
          SAVE: 'Salvar',
          PUBLISH: 'Publicar',
          WARNING_SAVE: 'As alterações atuais devem ser salvas.',
          WARNING_PUBLISH: 'Você precisa republicar para que as alterações salvas tenham efeito.',
          NOVALID_PUBLISH_ADVICE: 'A seguinte tab não é válido:',
          BASIC_INFORMATION_TAB: 'Dados Principais',
          CLASSIFICATION_TAB: 'Classificasão',
          UNITS_OF_MEASURE_TAB: 'Unidades de medida',
          ASSORTMENT_TAB: 'Sortimentos',
          WARNING_SEGMENT: null,
          VENDORS_TAB: 'Fornecedores, Custos e Impostos',
          PRICES_TAB: 'Preços',
          INGREDIENTS_TAB: 'Ingredientes',
          COMPONENTS_TAB: 'Componentes',
          CODES_TAB: 'Códigos',
          CODES_MATCHING: 'Equivalência',
          DELIVERY_TAB: 'Canais de venda',
          LOADING: 'Carregando...',
          NEW_ITEM_BTN: 'Novo item',
          PUBLISH_CONFIRM:
            'O processo de publicação inclui a criação do sortimento, preços, custos e outros registros relacionados ao produto. <br> <br> <b> Tem certeza que deseja publicar? </b>',
          PUBLISH_CONFIRM_TITLE: 'Confirmação de Publicação',
          PUBLISHING_WARNING: 'Item em processo de publicação, tente novamente em alguns minutos.',
          PUBLISH_LONG_TIME:
            'A publicação deste Produto pode demorar alguns minutos, quando for encerrada você será notificado com uma notificação.',
          PUBLISH_InvalidCombo: null,
          PUBLISH_InvalidRecipe: null,
          PUBLISH_Vendors: null,
          REPORT: 'Reporte',
          REPORT_WARNING: 'A publicação é necessária para baixar o relatório.',
        },
        MAIN_DATA: {
          NAME: 'Nome',
          SHORT_NAME: 'Pequena descrição',
          CREATION_DATE: 'Data de criação',
          INTERNAL_CODE: 'Código Interno',
          VALIDATION_CODE2: 'O código {{val}} já existe.',
          BAR_CODE: 'Código de barras',
          REGIONAL_ITEM: null,
          REGIONAL_ITEM_CHANGE: null,
          REGIONAL_ITEM_DISABLED: null,
          REGIONAL_ITEM_MODAL_TITLE: null,
          REGIONAL_ITEM_MODAL_CONTENT: null,
        },
        UNITS_OF_MEASURE: {
          NAME: 'Nome',
          DUP_CODE_AND_CONVERSION: 'Não pode haver 2 unidades de medida com um nome exato e fator de conversão.',
          UNIT_NAME: 'Nome da Unidade',
          CONVERSION: 'Fator de Conversão',
          UNIT_BASE: 'Unidade Base',
          SALES_UNITS: 'Unidade de Venda',
          SOME_SELLING_UNIT: 'Deve haver pelo menos uma unidade de vendas.',
          INITIAL_PRICE: 'Preço Inicial',
          INITIAL_PRICE_TIP: 'Este precio se va a aplicar a todas las tiendas donde el producto este activo',
          FRONT_PHOTO: 'Imagem do item frontal',
          SIDE_PHOTO: 'Imagem do item lateral',
          PHOTO_ABOVE: 'Imagem do item acima',
          EXHIBITION_UNITS: 'Unidade de Exhibição',
          FACINGS_LIMIT: 'Limite de Faces',
          MIN_FACES: 'Mínimo de Faces',
          MAX_FACES: 'Máximo de Faces',
          MAG_HEIGHT_WITH_MAG: 'Altura (cm.)',
          MAG_WIDTH_WITH_MAG: 'Largura (cm)',
          MAG_DEPTH_WITH_MAG: 'Profundidade (cm.)',
          STACKABLE_SWITCH: 'Empilhável',
          OVERTHROW_SWITCH: 'Pode Tombar',
          ROTATE_SWITCH: 'Pode Girar',
          MAX_STACKS: 'Máximo de pilhas',
          NEW_UNIT_BTN: 'Nova Unidade',
          REGULATED_PRICE_MAX: null,
          REGULATED_COST_MAX: null,
          REGULATED_PRICE_TIP: null,
          REGULATED_COST_TIP: null,
          FORCE_REGULATED_PRICE_MAX: null,
          FORCE_REGULATED_COST_MAX: null,
          REGULATED_PRICE_MARGIN: null,
          REGULATED_PRICE_MARGIN_TIP: null,
        },
        RECIPE: {
          COMPONENT: 'componente',
          TYPE: 'Tipo',
          NO_UOM: 'Item sem unidade de medida',
          UOM: 'Unidade de medida',
          QUANTITY: 'Quantidade',
          WASTE_INPUT: 'Desperdício %',
          COST: 'Custo',
          TAX: 'Imposto',
          TOTAL_COST: 'Custo Total Aproximado:',
          ADD_COMPONENT: 'Adicionar Componente',
        },
        COMBO: {
          NAME: 'Nome',
          QUANTITY: 'Quantidade',
          NO_UOM: 'Item sem unidade de medida',
          UOM: 'Unidade de medida',
          MAX_COST_TIP: 'O componente com o maior custo é levado em consideração',
          TOTAL_COST: 'Custo Total Aproximado:',
          ADD_BLOCK: 'Adicionar Grupo',
          ADD_COMPONENT: 'Adicionar Componente',
          COST: 'Custo',
          TAX: 'Imposto',
        },
        CODES: {
          HELP_BOX: 'Nesta seção, você pode adicionar códigos de barras adicionais ou outros códigos externos.',
          CODE_TYPE: 'Tipo de código',
          CODE: 'Código',
          VALIDATION_CODE2: 'O código {{val}} já existe.',
          VALIDATION_CODE_DUPLICATE: 'Existem códigos duplicados',
          INVALID_CODE: 'Formato de código inválido.',
          UNITS_OF_MEASURE_BUY: 'Unidade de Medida',
          ADD_CODE: 'Adicionar Código',
          NEW_CODE: 'Novo Código',
          PRIMARY_CODE_DELETE_WARNING: null,
          PRIMARY_VENDOR_DELETE_WARNING: 'Não é possível excluir um Código Principal.',
        },
        MATCHINGS: {
          ADD_MATCHING: 'Adicionar equivalência',
          ITEM_CODE_TYPE: 'Tipo de código do item',
          ITEM_CODE: 'Código do item',
          ITEM_NAME: 'Nome do item',
          DUPLICATED_FLAGS: 'Combinação Código do Item-Bandeira Duplicada',
          INVALID_CODE: null,
          NAME_REQUIRED: null,
          FLAG_REQUIRED: null,
          MATCHING_OF: 'Equivalência de',
        },
        ASSORTMENT: {
          NOT_PARAMETERIZED: null,
          NOT_ACCESS_PERMISSION: 'Não tenho permissão para completar esta seção',
          NOTIFY_USER: 'NOTIFICAR USUÁRIO',
          NOTIFIED_USER: 'Usuário notificado',
          IMPORT_FILE_NAME_LINK: 'determinar-PDV.xlsx',
          IMPORT_FILE_NAME_BTN: 'Carregamento de sortimento excepcional',
          IMPORT_ASSORTMENT_STORE_CONTEXT:
            'Pode importar exceções de sortimento pelo botão do importador ou ver o estado do sortimento para este item',
          BTN1_TITLE: 'Baseado no Sortimento',
          BTN1_MSG:
            'Leva em consideração a segmentação principal definida para a categoria, podendo fazer exceções por meio de um importador.',
          BTN2_TITLE: 'Ativo em todos os Pontos de Venda',
          BTN2_MSG: 'O produto estará ativo em todos os pontos de venda',
          BTN3_TITLE: 'Só se faz parte da Receita ou Combo',
          BTN3_MSG: 'O produto pode participar de uma receita ou de um combo',
          BTN4_TITLE: 'Só sortimento de Pontos de Venda',
          BTN4_MSG: 'O produto estará ativo em determinados Pontos de Venda',
          ASSORTMENT_SEGMENT_SETUP_TITLE: 'Definir segmento',
          ASSORTMENT_SEGMENT_SETUP_CONTENT_1: 'Definir segmento para a categoria {{val}}',
          ASSORTMENT_SEGMENT_SETUP_CONTENT_2: 'Esta mudança terá impacto na tela de atribuição de segmentos no módulo de sortimentos.',
          SEGMENT: 'Segmento',
          DEFINE: 'Definir',
          CANCEL: 'Cancelar',
          IMPORT_TITLE: 'Importar Pontos de Venda',
          ASSORTMENTBEHAVIORS_BASEDONASSORTMENT: 'Com base na variedade',
          ASSORTMENTBEHAVIORS_ACTIVEINALLSTORES: 'Ativo em todos os pontos de venda',
          ASSORTMENTBEHAVIORS_ONLYIFPARTOFRECIPEORCOMBO: 'Apenas se fizer parte da Receita ou Combinação',
          ASSORTMENTBEHAVIORS_ONLYSTOREASSORTMENT: 'Apenas variedade de pontos de venda',
          INIT_ASSORMENT: 'Inicialização de sortimentos',
          CHOOSE_OPT_ASSORMENT: 'Escolha uma opção de inicialização de sortimento',
          BLOCKED_FOR_PURCHASE: 'Bloqueio de compra',
          BLOCKED_FOR_SALES: 'Bloqueio de venda',
          CLONE_EXCEPTION_STORE_ASSORTMENT: null,
          CLONE_EXCEPTION_STORE_ASSORTMENT_MSG: null,
          DOWLOAD_EXCEL: 'Descarregar excel',
          VIEW_ASSORTMENT_STATUS: 'Relatório de sortimento',
          REQUIRE_SAVE_ASSORTMENT_STATUS: 'É necessário salvar as alterações feitas',
          REQUIRE_SAVE_ASSORTMENT_STATUS_MSG:
            'Foram detectadas alterações que afetam o status do Sortimento, para poder acessar você deve salvar as alterações feitas',
          IMPORT_ITEMS: 'PRODUTOS IMPORTADOS',
          IMPORT_EXCEL_ITEMS_FIXED_PRICE: 'Importar Preços Fixos',
          IMPORT_EXCEL_ITEMS_DISCOUNT: 'Importar Descontos',
          IMPORT_EXCEL_ITEMS: 'Importar exceções',
          DELETE_EXCEL_ITEMS: 'Excluir Exceções',
          DELETE_EXCEL_ITEMS_MESSAGE: 'Todas as exceções carregadas serão excluídas.',
          IMPORT_EXCEL_ITEMS_WARNING: 'Para importar é necessário ter pelo menos os dados principais salvos. ',
          CATEGORY_CONFIG_WARNING: null,
          CONFIG_CATEGORY: null,
          CONFIG_TAG: null,
          CONFIG_TAG_CONTENT: null,
          DEFINE_SEGMENT: 'DEFINIR SEGMENTO',
          CATEGORY_WARNING:
            'A categoria {{val}} não tem uma segmentação principal definida, escolha uma opção para avançar com a inicialização do sortimento.',
          CATEGORY_SELECT_WARNING:
            'Você precisa selecionar uma categoria para este produto para prosseguir com a inicialização do sortimento.',
          NAME: 'Nome',
          CODE: 'Código',
          ACTIVE: 'Ativo',
          BLOCQUED_SELL: 'Bloqueado para a venda',
          BLOCQUED_BUY: 'Bloqueado para a compra',
          OBSERVATIONS: 'Observações',
        },
        PRICES: {
          SUGGESTED_PRICE_INPUT: 'Preço Fixo a Nível Nacional',
          DINAMIC_PRICE_INPUT: 'Desconto % sobre componentes',
          PRICE_TYPE_INPUT: 'Tipo de preço',
          DISCOUNT_INPUT: 'Desconto',
          BTN3_TITLE: 'Em base ao preço de seus componentes',
          BTN3_MSG: 'Cada vez que você altera o preço de um componente, o preço do kit pode mudar.',
          PERMANENT_PRICE_INPUT: 'Preço fixo',
          BTN1_TITLE: 'Preço Nacional',
          BTN1_MSG: 'Leva em consideração o preço único inicial definido para cada uma das unidades de medida de venda',
          BTN2_TITLE: 'Preço do clone',
          BTN2_MSG: 'Clone o preço de um produto existente',
          INIT_PRICE: 'Inicialização de Preços',
          CHOOSE_INIT_PRICE: 'Escolha uma opção de inicialização de preço',
          REVIEW_CHANGES: 'Para consultar as alterações de preço, vá para ',
          PRICE_DECISIONS: 'Decisões de Precos',
          NO_PRICE_TIP:
            'No caso de não se encontrar um preço em nenhum ponto de venda para o produto clonado, será utilizado o preço inicial da unidade de medida de venda.',
          INITIAL_PRICE: 'Preço Inicial',
          SELL_UOM: 'Unidade de medida de venda',
          IVA: 'IVA',
          VIEW_ASOCIATED_RULES: 'VER REGRAS ASSOCIADAS',
          CONFIRM_CLONE_VALIDATION_MSG:
            'O produto cujos preços você deseja clonar não possui as mesmas unidades de medida. Recomenda-se escolher um produto com as mesmas unidades de medida de venda.',
          CONFIRM_CLONE_VALIDATION_TITLE: 'Confirmar seleção',
          CONFIRM_CLONE_VALIDATION_OK: 'Continuar com a seleção atual',
          CONFIRM_CLONE_VALIDATION_CANCEL: 'Escolha um novo produto',
          VIEW_ASOCIATED_RULES_TITLE: 'Sem regras associadas',
          VIEW_ASOCIATED_RULES_TITLE_MGS: 'Não é possível consultar um item que não foi publicado',
        },
        VENDORS: {
          TITLE: 'Fornecedores, Custos e Impostos',
          SUBTITLE:
            'Defina um (s) fornecedor (es) para este produto. Se optar por uma segmentação, todos os pontos de venda devem ter um valor atribuído e desta forma podem carregar custos e impostos específicos para cada valor do segmento.',
          VENDOR: 'Fornecedor',
          PRIMARY_VENDOR_WARNING: 'Deve existir pelo menos um Fornecedor Principal.',
          PRIMARY_VENDOR_WARNING_ONLY_ONE: 'Não pode haver mais de um Provedor Principa',
          PRIMARY_VENDOR_WARNING_UOM: 'Não  pode existir o mesmo fornecedor com a mesma unidade de medida de compra.',
          PRIMARY_VENDOR_WARNING_DUP: 'Não é possível registrar um provedor mais de uma vez.',
          REGIONAL_PRODUCT_WITH_ONE_VENDOR: null,
          PRIMARY_VENDOR_DELETE_WARNING: 'Não é possível excluir um provedor principal.',
          INTERNAL_CODE: 'Código Interno do Provedor',
          SEGMENT: 'Segmento',
          SEGMENTATION: 'Segmentação',
          UOM_BUY: 'Unidade de medida de compra',
          MIN_ORDER: 'Ordem Mínima',
          MAX_ORDER: 'Ordem Máxima',
          NEW_VENDOR: 'Novo Fornecedor',
          UNIQUE_COST: 'Custo Único',
          UNIQUE_COST_NATIONAL: 'Custo nacional',
          SEGMENT_COSTS: 'Segmentar Custos',
          PRIMARY_VENDOR: 'Fornecedor primário',
          SECONDARYY_VENDOR: 'Fornecodor secundário',
          INDICATE_TEXT: 'indique os valores de custo e impostos correspondentes a:',
        },
      },
    },
    ITEM_MASTER: {
      TITLE: 'Maestro de Itens',
      NEW_ITEM_BTN: 'Iten Novo',
      DUPLICATED_ITEM: 'Iten duplicado',
      FILTER_TTIP: 'Filtrar',
      FULLSCREEN_TTIP: 'Expandir',
      EXITFULLSCREEN_TTIP: 'Reduzir',
      EXPORT_TTIP: 'Exportar',
      IMPORT_TTIP: 'Importar',
      PRINT_TTIP: 'Imprimir',
      DELETE_TTIP: 'Apagar itens selecionados',
      DELETE: 'Apagar',
      RECOVER: 'Recuperar',
      RECOVER_TTIP: 'Recuperar itens selecionados',
      NAME: 'Nome',
      CODE: 'Código',
      TYPE: 'Tipo',
      CATEGORY: 'Categoria',
      SUBCATEGORY: 'Subcategoria',
      BRAND: 'Marca',
      CREATION_DATE: 'Data de Criação',
      RECOVER_ITEMS_TITLE: 'Você quer recuperar os itens selecionados?',
      RECOVER_ITEMS_SUBTITLE: 'Esses itens desaparecerão da lista de itens excluídos.',
      CANCEL_BTN: 'Cancelar',
      CONFIRM_BTN: 'Confirmar',
      NEW_ITEM_MODAL: 'Iten Novo',
      ITEM_TYPE_INPUT: 'Tipo de Iten',
      LEGEND_RETAIL:
        'Os itens de Varejo são itens regulares que podem ser vendidos em qualquer canal de vendas sem sofrer transformações ou combinações com outros itens.',
      LEGEND_INGREDIENT: 'Esses itens não podem ser vendidos individualmente, mas fazem parte de uma receita.',
      LEGEND_RECIPES: 'É um produto constituído por um ou mais ingredientes.',
      LEGEND_SUPPLY: 'Esses itens não podem ser vendidos individualmente, mas fazem parte de uma receita.',
      CREATE_BTN: 'Criar',
      TITLE_FILTERS: 'Filtros',
      SEE_DELETED_FILTERS: 'Ver Eliminados',
      TYPE_FILTERS: 'Tipo',
      CLEAN_FILTER_BTN: 'Limpar',
      APPLY_FILTER_BTN: 'Aplicar',
      DEFINE_ITEM_STP1: 'Definir Iten',
      CLASSIFICATION_STP2: 'Classificação',
      LOGISTICS_DATA_STP3: 'Informação de Logística',
      INTEGRATION_STP3: 'Integração',
      PURCHASE_DATA_STP4: 'Dados de Compra',
      SALES_DATA_STP5: 'Dados de Venda',
      EDIT_TITLE_FORM: 'Editar',
      NEW_TITLE_FORM: 'Novo',
      INGREDIENT_TITLE: 'Ingrediente',
      SUPPLY_TITLE: 'Abastecimento',
      RETAIL_TITLE: 'Varejo',
      RECIPE_TITLE: 'Iten - Receita',
      COMBO_TITLE: 'Iten - Kit',
      ITEM: 'Iten',
      STP1: 'Etapa 1 - Definir Iten',
      INTERNAL_CODE_INPUT: 'Código Interno',
      ERROR: 'Incorreto',
      EAN_INPUT: 'Código EAN',
      TYPE_INPUT: 'Tipo',
      NAME_INPUT: 'Nome',
      SHORT_DESCRIPTION_INPUT: 'Descrição Curta',
      SHORT_DESCRIPTION_VALIDATION: 'Caracteres inseridos:',
      CREATION_DATE_INPUT: 'Data de Criação',
      NEXT_BTN: 'Próximo',
      STP2: 'Etapa 2 - Classificação do Iten',
      CATEGORY_INPUT: 'Categoria',
      SEARCH_CATEGORY: 'Procurar Categoria…',
      SUBCATEGORY_INPUT: 'Subcategoria',
      SEARCH_SUBCATEGORY: 'Procurar Subcategoria…',
      BRAND_INPUT: 'Marca',
      SEARCH_BRAND: 'Procurar Marca…',
      ADD_BRAND_BTN: 'Adicionar Marca',
      PREVIUS_BTN: 'Anterior',
      STP3: 'Etapa 3 - Dados Logísticos',
      UNIT_NAME: 'Nome da Unidade',
      UNIT_NAME_INPUT: 'Nome da Unidade',
      DELETE_UM: 'Apagar Unidade de Medida',
      CODE_INPUT: 'Código',
      REQUIRED: 'Campo obrigatório',
      CODE_VALIDATION: 'O Código deve ser único',
      UNIT_REPEATED: 'Unidade Repetida',
      CODE_REPEATED: 'Código repetifdo',
      CREATE_UNIT: 'Criar nova Unidade',
      CONVERSION_FACTOR_INPUT: 'Fator de Conversão',
      UNIT_BASE_CHECK: 'É Unidade Base',
      NEW_UNIT_BTN: 'Nova Unidade',
      UNIT_ALREADY_EXIST: 'Unidade já existente',
      STP4: 'Etapa 4 - Dados de Compra',
      STP5: 'Etapa 5 - Dados de Compra',
      PURCHASE_UNIT_INPUT: 'Unidade de Compra',
      QUANTITY_UMB_INPUT: 'Quantidade x UMN',
      SUPPLIER_INPUT: 'Fornecedores',
      SEARCH_SUPPLIER: 'Procurar Fornecedores…',
      COST_INPUT: 'Custo',
      PURCHASE_IVA_INPUT: 'Imposto de Compra',
      SALE_IVA_INPUT: 'Taxa Venda',
      SAVE_BTN: 'Salvar',
      STP_4_2: 'Etapa 4 - Integração',
      UNITS_OF_MEASURE_INPUT: 'Unidade de medida',
      WASTE_INPUT: 'Desperdício %',
      STP5_R: 'Etapa 5 - Dados de Venda',
      SALES_UNITS: 'UNIDADE DE VENDA',
      SALES_UNITS_DELETE_TTIP: 'Apagar Unidade de Venda',
      SALES_UNITS_INPUT: 'Unidade de Venda',
      SALES_UNITS_VALIDATION: 'Unidade já selecionada',
      HIGH_INPUT: 'Alto',
      WIDTH_INPUT: 'Largura',
      DEPTH_INPUT: 'Profundidade',
      MAX_STACKABLE: 'Máxima quantidade a empilhar',
      OVERTHROW_SWITCH: 'Pode Tombar',
      ROTATE_SWITCH: 'Pode Girar',
      ROTATEY_SWITCH: 'Gira em Y',
      EXHIBITION_UNITS_INPUT: 'Unidade de Exhibição',
      STACKABLE_SWITCH: 'Empilhável',
      FRONT_PHOTO: 'Carregar imagem do item frontal',
      SIDE_PHOTO: 'Faça upload da imagem do item lateral',
      PHOTO_ABOVE: 'Carregar imagem do item acima',
      COMPOSITION_STP3: 'Ingredientes',
      STP3_RECIPES: 'Etapa 3 - Copmposição da receita',
      INGREDIENT: 'Ingrediente',
      ITEM_TITLE: 'Iten',
      INGREDIENT_DELETED_TTIP: 'Apagar o {{val}} ingrediente',
      ITEM_DELETED_TTIP: 'Apagar o {{val}} iten',
      INGREDIENT_INPUT: 'Ingrediente',
      ITEM_INPUT: 'Iten',
      SEARCH_INGREDIENT: 'Procurar Ingredientes…',
      QUANTITY_INPUT: 'Quantidade',
      NEW_INGREDIENT_BTN: 'Novo Ingrediente',
      DEFINE_COMBO_STP1: 'Definir Combo',
      COMBO_ARMING_STP3: 'Grupos de itens do Combo',
      PRICE_STP4: 'Preço',
      STP1_COMBO: 'Etapa 1 - Definir Combo',
      STP3_COMBO: 'Etapa 3 - Grupos de itens do Combo',
      BLOCK_NAME_INPUT: 'Nome do Grupo',
      BLOCK_DELETED_TTIP: 'Apagar Bloco',
      ITEMS_INPUT: 'Itens',
      SEARCH_ITEMS: 'Procurar Itens…',
      ADD_BLOCK_BTN: 'Adicionar Grupo',
      STP4_COMBO: 'Etapa 4 - Definição do tipo de preço',
      PRICE_TYPE_INPUT: 'Tipo de Preço',
      DISCOUNT_INPUT: 'Desconto',
      FIXED_PRICE_MESSAGE: 'O preço fixo em todo o país definirá o mesmo preço para todas as lojas e canais',
      DISCOUNT_MESSAGE:
        'Leva em consideração o preço do componente mais barato de cada grupo e apenas os itens que estão ativos no sortimento de cada loja.',
      SUGGESTED_PRICE_INPUT: 'Preço fixo a nível nacional',
      PERMANENT_PRICE_INPUT: 'Preço Fixo',
      EXCLUDED_RULES_CHECK: 'Este combo será excluído das regras de margem e competição',
      EXCLUDED_RULES_CHECK_ALT: 'Este combo será incluido nas regras de margem e competição',
      DINAMIC_PRICE_INPUT: 'Desconto % sobre componentes',
      PRICE_STRATEGY: 'Como você vai definir o preço deste kit?',
      INIT_PRICE: 'Como você vai definir o preço inicial deste kit?',
      INIT_PRICE_EDIT: 'Quer alterar o preço atual?',
      PRICE_STRATEGY_COMPONENT_TITLE: 'Com base no preço de seus componentes',
      PRICE_STRATEGY_COMPONENT_MSG: 'Cada vez que você altera o preço de um componente, o preço do kit pode mudar.',
      PRICE_STRATEGY_RULE_TITLE: 'Más información sobre este texto de origen',
      PRICE_STRATEGY_RULE_MSG:
        'Quando o preço de um concorrente muda ou quando o preço de um item relacionado muda, o Prisma irá sugerir uma mudança de preço.',
      SUGGESTED_PRICE_TEXT:
        ': Preço mínimo dos produtos em todos os blocos - Mesmo preço em todos os pontos de venda, partindo ou não do sugerido.',
      DINAMIC_PRICE_TEXT:
        ': Preço atual de cada ponto de venda (de acordo com seus produtos) + desconto. Não será afetado pelas regras de parentesco.',
      DINAMIC_PRICE_TTIP: 'Preço Dinâmico: Preço atual de cada loja (de acordo com seus itens) + desconto',
      NEW_UNIT: 'Nova Unidade',
      NAME_INNPUT: 'Nome',
      ADD_BTN: 'Adicionar',
      NEW_INGREDIENT: 'Novo Ingrediente',
      FREE_STORES: 'Pontos de Vendasem custo nem preço',
      FREE_STORES_SUBTITLE: 'Custos e preços foram gerados para todas as lojas',
      ACCEPT_BTN: 'Aceitar',
      ERROR1: 'Nenhum custo ou preço foi gerado para essas lojas, pois alguns dos itens dos combos não possuem preço.',
      ERROR2: 'Nenhum custo ou preço foi gerado para essas lojas, pois alguns dos ingredientes não têm custo.',
      NEW_BRAND: 'Nova Marca',
      NEW_SUPPLIER: 'Novo Fornecedor',
      INFO_PERMISSION: 'O usuário atual tem permissão apenas para editar atributos, nenhuma outra alteração será permitida.',
      Retail: 'Varejo',
      Ingrediente: 'Ingrediente',
      Receta: 'Receita',
      Combo: 'Combo',
      Supply: 'Abastecimento',
      Insumo: 'Abastecimento',
    },
    SUPPLY: {
      SETUP: {
        ORDER_SCHEDULE: {
          TITLE: 'Cronograma de Pedidos',
          ROUTE: 'Abastecimwento / Configuração',
          DEFINITION: 'Definição do Centro / Fornecedor',
          SCOPE_PDV: 'Escopo de Pontos de Venda',
          FREQUENCY: 'Frequência',
          NEW_ORDER_BTN: 'NOVA CRONOGRAMA',
          STORES_FILTER: 'Pontos de Venda',
          SUPPLY_FILTER: 'Fornecedor',
          TYPE_ALL: 'Todos',
          TYPE_SUPPLIER: 'Fornecedor',
          TYPE_DISTRIBUTION_CENTER: 'Centro de Distribuição',
          TITLE_SCHEDULE_TO_LEAD: 'Cronograma a carregar',
          TITLE_FRECUENCY: 'Frequência',
          TITLE_INCLUDES: 'Inclui',
          TITLE_LEAD_TIME: 'Lead Time (dias)',
          TITLE_OVERLAPP: 'Cronogramas Sobrepostos',
          TTIP_OVERLAPP: 'Ver cronogramas sobrepostos',
          BTN_OVERLAPP_MODAL_CLOSE: 'Fechar',
          PH_CATEGORYS: 'Categorias',
          PH_CATEGORYS_EXC: 'Categorias Excluídas',
          FORM: {
            EDIT: 'Editar Cronograma',
            NEW: 'Novo Cronograma',
            STEP: 'Passo',
            NEXT_BTN: 'Próximo',
            PREVIUS_BTN: 'Anterior',
            SAVE_BTN: 'Salvar',
            SCOPE_PDV: {
              CODE_SEARCH: 'Procurar por código',
              TITLE: 'Seleção Avançada',
              DESC: 'Aplique filtros à sua pesquisa para chegar ao resultado desejado. Esta opção irá anluar a seleção por filtros.',
              CLIPBOARD_TITLE: 'Colar pontos de venda desde a área de transferência',
            },
            FRECUENCY: {
              HOLIDAY_RECEPTION: 'Caso o dia de "Recepção do pedido" / "Entrega da mercadoria" seja feriado / dia não útil',
              NEXT_BUSINESS_DAY: 'O pedido é recebido no próximo dia útil',
              NEXT_DAY_STIPULATED: 'O pedido é recebido no dia seguinte estipulado no cronograma.',
            },
          },
          TYPEOFDELIVER: {
            DELIVERY: 'Entrega de produtos',
            RECEIPT: 'Recebimento de pedidos',
          },
          FREQUENCYTIME: {
            DAILY: 'Diária',
            WEEKLY: 'Semanal',
            MONTHLY: 'Mensal',
          },
          HOLIDAYSHANDLING: {
            WEEKLY_DAYS_NO_HOLIDAYS: 'Apenas dias da semana, exceto feriados',
            ALL_DAYS_NO_HOLIDAYS_NO_SUNDAYS: 'Todos os dias, exceto feriados e domingo',
            ALL_DAYS_NO_HOLIDAYS: 'Todos os dias, exceto feriados',
            ALL_DAYS: 'Todos os dias',
          },
        },
        ORDER_SCHEDULE_LIST: {
          TYPE_FILTER: 'Tipos',
          STORES_FILTER: 'Pontos de Venda',
          NAME: 'Fornecedor / CD',
          NAME_TTIP: 'Fornecedor ou Centro de Distribuição',
          CATEGORY: 'Categorias',
          STORES: 'Pontos de Venda',
          STORES_TTIP: 'Pontos de Venda',
          FREQUENCY: 'Frequência',
          MONDAY_CHAR: 'S',
          TUESDAY_CHAR: 'T',
          WEDNESDAY_CHAR: 'Q',
          THURSDAY_CHAR: 'Q',
          FRIDAY_CHAR: 'S',
          SATURDAY_CHAR: 'S',
          SUNDAY_CHAR: 'D',
        },
      },
      DECISIONS: {
        ORDER_CONTROL: {
          TITLE: 'Controle de Pedidos',
          ROUTE: 'Abastecimento / Decisões',
          INFORM_BTN: 'Informar',
          NEW_ORDER_BTN: 'Novo Pedido',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXPORT_TTIP: 'Exportar',
          STATUS_FILTER: 'Status',
          TYPE_FILTER: 'Tipo de movimento',
          TYPE_ALL: 'Todos',
          TYPE_AUTOMATIC: 'Automático',
          TYPE_MANUAL: 'Manual',
          ORIGIN_FILTER: 'Origem',
          DESTINATION_FILTER: 'Destino',
          CREATION_DATE_FILTER: 'Data do Pedido',
          PLANNING_DATE_FILTER: 'Data de Entrega Objetivo',
          DELIVERY_DATE_FILTER: 'Data de Entrega',
          NRO: 'Nro.',
          TYPE: 'Tipo',
          TYPE_TOOLTIP: 'Tipo de movimento',
          ORIGIN: 'Origem',
          DESTINATION: 'Destino',
          CREATION_DATE: 'Data do Pedido',
          CREATION_DATE_TTIP: 'Data do Pedido',
          PLANNING_DATE: 'Data de Entrega Objetivo',
          PLANNING_DATE_TTIP: 'Data de Entrega Objetivo',
          DELIVERY_DATE: 'Data de Entrega',
          DELIVERY_DATE_TTIP: 'Data de Entrega',
          STATUS: 'Status',
          VALUE: 'R$ Custo',
          DECISIONS: 'Decisões',
          SAVE_BTN: 'Salvar',
          HEADER: 'Cabeçalho',
          PRINT_TTIP: 'Imprimir',
          ADD_TTIP: 'Adicionar Produtos ao Pedido',
          COPY_TTIP: 'Adicionar produtos de uma lista copiada para sua área de transferência',
          CODE: 'Código',
          DESCRIPTION: 'Descrição',
          CATEGORY: 'Categoria',
          BRAND: 'Marca',
          APPROVE: 'Aprovar',
          REJECT: 'Rejeitar',
          INFORM: 'Informar',
          TYPE_INPUT: 'Tipo de movimento',
          CODE_INPUT: 'Código',
          ORIGIN_INPUT: 'Origem',
          DESTINATION_INPUT: 'Destino',
          STATE_INPUT: 'Status',
          PRIORITY_INPUT: 'Prioridade',
          OBSERVATION_INPUT: 'Observação',
          CREATION_DATE_INPUT: 'Data do Pedido',
          PLANNING_DATE_INPUT: 'Data de Entrega Objetivo',
          DELIVERY_DATE_INPUT: 'Data de Entrega',
          CANCEL_BTN: 'Cancelar',
          ORDER_VALUE: 'Custo do Pedido:',
          ADD_BTN: 'Adicionar',
          ADD_PROD_TITLE_MODAL: 'Adicionar produtos ao pedido',
          PRODUCT_INPUT: 'Produto',
          UDM_INPUT: 'Unidade de medida',
          QUANTITY_INPUT: 'Quantidade',
          DELETE: 'Apagar',
          DELETE_TTIP: 'Remover produto do predido',
          SUGGESTED: '# Sugerida(o)',
          SUGGESTED_TTIP: 'Quantidade Sugerida',
          TO_ASK: '# A pedir',
          TO_ASK_TTIP: 'Quantidade a pedir',
          TO_ASK_TTIP_ANEXO: '(Pallets)',
          UDM: 'UDM',
          EAN: 'EAN: {{val}}',
          SUPPLIER: 'Fornecedor: {{val}}',
          STEP_BY_STEP: 'Passo a Passo',
          UNITS_INPUT: 'Unidades',
          NAME: 'Nome',
          FORECASTED_SALE: 'Previsão de Vendas',
          CURRENT_STOCK: 'Estoque atual no Super San Martin',
          CURRENT_STOCK2: 'Estoque atual no Centro de Distribuição Campana',
          TRANSIT_STOCK: 'Estoque em trânsito para Super San Martin',
          MINIMUM_STOCK: 'Estoque Mínimo',
          MAXIMUM_STOCK: 'Estoque Máximo',
          AVAILABITY_DAYS_NOW: 'Dias de Disponibilidade Agora',
          AVAILABITY_DAYS_AFTER: 'Dias de Disponibilidade Depois',
          NEED: 'Necessidade',
          SUBCATEGORY: 'Subcategoria',
          EDIT_TTIP: 'Editar quantidade do produto a pedir',
          INVENTORY: 'Inventário',
        },
      },
    },
    ADMIN: {
      MASTER: {
        STORES: {
          LIST: {
            TYPE_FILTERS: 'Tipo',
            STATUS: 'Estado',
            STORE_TAGS: 'Etiqueta Pontos de Venda',
            NAME: 'Nome',
            CODE: 'Código',
            BRAND: 'Bandeira',
            STATE: 'Status',
            TYPE: 'Tipo',
            AVAIBLE: 'Habilitado',
            ROUTE: 'Administração / Mestres',
            TITLE: 'Pontos de Venda',
            NEW_STORE_BTN: 'Novo Ponto de Venda',
            FILTER_TTIP: 'Filtrar',
            FULLSCREEN_TTIP: 'Expandir',
            EXITFULLSCREEN_TTIP: 'Reduzir',
            VIEW_TTIP: 'Vista',
            VIEW_TABLE: 'Só Tabela',
            VIEW_MAP: 'Só Mapa',
            VIEW_TABLE_MAP: 'Mapa e Tabela',
            EDIT_ROW: 'Editar',
            COPY_ROW: 'Clonar',
            DELETE_ROW: 'Apagar',
            INACTIVE: 'Inativo',
            ACTIVE: 'Ativa',
            CLONE_STORE_TITLE: 'Clonar Ponto de Venda',
            CLONE_ITEM_CONTENT: 'Um novo ponto de venda será criado a partir dos dados da linha selecionada',
            CANCEL_BTN: 'Cancelar',
            CREATE_BTN: 'Criar',
          },
          SAVE_TOOLTIP: {
            ASSORTMENT_INVALID: 'Você deve atribuir a todos os clusters um cluster para salvar.',
          },
          PRICES: {
            BTN2_TITLE: 'Clonar preços de um Ponto de Venda',
            BTN2_MSG: 'Escolha um ponto de venda de referência, de onde serão copiados todos os preços.',
          },
          STORES_FILTER: 'Ponto de Venda',
          ROUTE: 'Administração / Mestre',
          TITLE: 'Pontos de Venda',
          NEW_STORE_BTN: 'Novo Ponto de Venda',
          FILTER_TTIP: 'Filtros',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          VIEW_TTIP: 'Visualizar',
          VIEW_TABLE: 'Apenas Tabela',
          VIEW_MAP: 'Apenas Mapa',
          VIEW_TABLE_MAP: 'Mapa e Tabela',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Apagar pontos de venda selecionados',
          DELETE: 'Apagar',
          NAME: 'Nome',
          CODE: 'Código',
          BRAND: 'Bandeira',
          TYPE: 'Tipo',
          STORE: 'Ponto de Venda',
          STORES_V2: 'Ponto de Venda',
          STATE: 'Estado',
          INACTIVE: 'Inativo',
          ACTIVE: 'Ativo',
          TITLE_FILTERS: 'Filtros',
          STATE_FILTER: 'Estado',
          TYPE_FILTER: 'Tipo',
          CLEAN_FILTER_BTN: 'Limpar',
          APPLY_FILTER_BTN: 'Aplicar',
          TITLE_FORM: 'Novo Ponto de Venda',
          SAVE_BTN: 'Salvar',
          BASIC_INFORMATION_TAB: 'Informação Básica',
          LATITUDE_INPUT: 'Latitude',
          VALIDATION_LATITUDE1: 'A latitude deve ser menor ou igual a 90',
          VALIDATION_LATITUDE2: 'A latitude deve ser maior ou igual a -90',
          LONGITUDE_INPUT: 'Longitude',
          VALIDATION_LONGITUDE1: 'O comprimento deve ser menor ou igual a 180',
          VALIDATION_LONGITUDE2: 'O comprimento deve ser maior ou igual a -180',
          CODE_INPUT: 'Código',
          EXTERNALCODE_INPUT: 'Código Externo',
          VALIDATION_CODE: 'O campo deve ser maior que 0',
          VALIDATION_CODE2: 'O código {{val}} já existe.',
          NAME_INPUT: 'Nome',
          BRAND_INPUT: 'Bandeira',
          EMAIL_INPUT: 'Email',
          PHONE_INPUT: 'Telefone',
          OPENING_DATE_INPUT: 'Data de abertura',
          ADRESS_INPUT: 'Endereço',
          ACTIVE_CHECK: 'Ativo',
          ACTIVE_TTIP: 'Marcar como Ativar o {{val}}',
          EXCEMPT_CHECK: 'Isento',
          EXCEMPT_TTIP: 'Marcar como isento',
          CLASSIFICATION_TAB: 'Classificação',
          WITHOUT_SELECTION: 'Sem Seleção ...',
          ADD_ATRIBUTE_BTN: 'Adicionar Atributos',
          QUERY_FILTER: 'Nome',
          FEATURES_TAB: 'Características',
          STORE_TYPE_INPUT: 'Tipo de Ponto de Venda',
          TERMINAL_INPUT: 'Centro de Distribuição',
          TRADE_AREA_TAB: 'Área Comercial',
          LEGEND_FILTERS: 'Filtre os concorrentes definindo a Área Comercial',
          TRADE_AREA_TITLE: 'Distância da área comercial dos concorrentes: ',
          SELECTED_COMPETITORS: 'Concorrentes Selecionados',
          NO_SELECTED_COMPETITORS: 'Nenhum concorrente selecionado.',
          NO_SELECTED_COMPETITORS_TTIP: 'Atualizar lista',
          DELETE_BTN: 'Remover',
          DELETE_COMPETITOR_TTIP: 'Apagar Concorrente',
          CODE_NAME: 'Cod | Nome',
          MARKER: 'Marcador',
          PROVINCE: 'Província',
          LOCATION: 'Localidade',
          ADD_COMPETITORS_BTN: 'Adicionar Concorrentes',
          EMPTY_STATE: 'Nenhum concorrente encontrado com os critérios de pesquisa selecionados.',
          ADD_BTN: 'Adicionar',
          ADD_COMPETITOR_TTIP: 'Adicionar Concorrente',
          CHANGE_TRADE_AREA_TITLE_MODAL: 'Modifique a área comercial para: {{val}} Km.',
          CHANGE_TRADE_AREA_SUBTITLE_MODAL:
            'Os competidores selecionados serão perdidos e serão modificados com os da nova Área Comercial.',
          EDIT_BTN: 'Modificar',
          SEARCH: 'Procurar ',
          SET_PRINCIPAL_COMPETITOR_TTIP: 'Marque como principal concorrente desta Loja',
          SET_PRINCIPAL_COMPETITOR__REMOVE_TTIP: 'Remover como principal concorrente desta loja',
          ASSORTMENT_TAB: 'Sortimento',
          ASSORTMENT_TAB_ENABLED: 'Guia habilitada apenas na criação',
          ASSORTMENT_TAB_NO_EDIT_TOOLTIP: 'esta informação pode ser completada ao criar o ponto de venda',
          ASSORTMENT_TITLE: 'Inicialização de Sortimento',
          ASSORTMENT_SUBTITLE: 'Selecionar o cluster ao qual a nova loja pertencerá em cada uma das Clusterizações definidas no Prisma.',
          CLUSTERING: 'Clusterização',
          CLUSTER: 'Cluster',
          CLUSTER_ASSIGNMENT: 'Atribuição de Cluster',
          CLONE_TTIP: 'Clone de um Ponto de Venda existente.',
          CLONE_MODAL_TITLE: 'Clonar Sortimento',
          CLONE_MODAL_SUBTITLE: 'Os clusters serão clonados para cada agrupamento de acordo com o ponto de venda selecionado.',
          STORES: 'Ponto de Venda',
          CANCEL_BTN: 'Cancelar',
          CLONE_BTN: 'Clonar',
          PRICING_TAB: 'Preços',
          PRICING_TITLE: 'Inicialização de preços',
          PRICING_SUBTITLE: 'Escolha uma opção de inicialização de preço',
          BTN1_TITLE: 'Completar preços no Excel',
          BTN1_MSG: 'A coluna de preço deve ser preenchida para todo o sortimento ativo.',
          BTN2_TITLE: 'Clonar preço de um Ponto de Venda',
          BTN2_MSG: 'Escolha um ponto de venda de referência, de onde serão copiados todos os preços.',
          BTN3_TITLE: 'Regras de Parentesco',
          BTN3_MSG: 'As regras de parentesco serão levadas em consideração para o preenchimento automático dos preços',
          DOWLOAD_EXCEL: 'Baixar Excel',
          IMPORT_EXCEL_BTN: 'Importar preços do Excel',
          CLONE_PRICE_MODAL_TITLE: 'Clonar Preços',
          CLONE_PRICE_MODAL_SUBTITLE: 'Os preços serão clonados de acordo com a loja selecionada.',
          EXCEL1: 'Completar-Preços.xlsx',
          EXCEL2: 'Clonar-Preços.xlsx',
          EXCEL3: 'Regras-de-Parentesco.xlsx',
          IMPORT_PRICES_TITLE: 'Importar preços',
          VENDORS: 'Fornecedores',
          TABS: {
            VENDORS: {
              TITLE: 'Inicialização dos fornecedores',
              INTRO: 'Atribui quais fornecedores fornecem cada produto ao ponto de venda.',
              DISABLED_VENDORS_INPUT: 'Fornecedores já inicializados',
              BTN_IMPORT_SWITCH: 'Resolver Conflitos',
              COMP: {
                TITLE: 'Seleção Avançada',
                INTRO: 'Selecione os fornecedores que abastecerão o ponto de venda.',
              },
            },
          },
          STORE_V2: 'Ponto de Venda',
        },
        STORESV2: {
          LIST: {
            TYPE_FILTERS: 'Tipo',
            STATUS: 'Estado',
            STORE_TAGS: 'Etiqueta Pontos de Venda',
            NAME: 'Nome',
            CODE: 'Código',
            BRAND: 'Bandeira',
            STATE: 'Status',
            TYPE: 'Tipo',
            AVAIBLE: 'Habilitado',
            ROUTE: 'Administração / Mestres',
            TITLE: 'Pontos de Venda',
            NEW_STORE_BTN: 'Novo Ponto de Venda',
            FILTER_TTIP: 'Filtrar',
            FULLSCREEN_TTIP: 'Expandir',
            EXITFULLSCREEN_TTIP: 'Reduzir',
            VIEW_TTIP: 'Vista',
            VIEW_TABLE: 'Só Tabela',
            VIEW_MAP: 'Só Mapa',
            VIEW_TABLE_MAP: 'Mapa e Tabela',
            EDIT_ROW: 'Editar',
            EDIT_ROW_DISABLED_TOOLTIP: 'Não é possível editar um registro no processo de publicação',
            COPY_ROW: 'Clonar',
            DISABLED_CLONE_TOOLTIP: 'Apenas registros em estado publicado podem ser clonados',
            DISABLED_DELETE_EQUIPMENT: null,
            DELETE_ROW: 'Inativo',
            UNDELETE_ROW: 'Ativa',
            INACTIVE: 'Inativo',
            ACTIVE: 'Ativa',
            CLONE_STORE_TITLE: 'Clonar Ponto de Venda',
            CLONE_ITEM_CONTENT: 'Um novo ponto de venda será criado a partir dos dados da linha selecionada',
            CANCEL_BTN: 'Cancelar',
            CREATE_BTN: 'Criar',
            SEE_DELETED_FILTERS: 'Ver Eliminados',
          },
          SAVE_TOOLTIP: {
            ASSORTMENT_INVALID: 'Você deve atribuir a todos os clusters um cluster para salvar.',
          },
          PRICES: {
            BTN2_TITLE: 'Clonar preços de um Ponto de Venda',
            BTN2_MSG: 'Escolha um ponto de venda de referência, de onde serão copiados todos os preços.',
            REVIEW_CHANGES: 'Para consultar as alterações de preço, vá para ',
            PRICE_DECISIONS: 'Decisões de Preços',
            VIEW_ASOCIATED_RULES: 'VER REGRAS ASSOCIADAS',
            MAIN_SAVE_WARNING: 'Para usar esta seção, é necessário pelo menos ter os dados principais salvos',
          },
          EQUIPMENT: {
            ownerShip: null,
            acquisitionDate: null,
            acquisitionValue: null,
            adquisitionDate: null,
            adquisitionValue: null,
            usefulLife: null,
            EQUIPMENT_TYPE: null,
            ADD_EQUIPMENT: null,
            REMAINING_LIVE: null,
            NAME: null,
            ASSIGMENT_DATE: null,
            EDIT_EQUIPMENT: null,
            ADD_EQUIPMENT_TITLE: null,
            AWARD_DATE: null,
            DELETE_TITLE: null,
            DELETE_CONTENT: null,
            NAME_ALREADY_EXIST: null,
            DISABLE_REUBICATION: null,
            REUBICATION_CONFIRM: null,
            REUBICATION: null,
            OWNER_OPTION: {
              Owner: null,
              Third: null,
            },
          },
          COMPETITORS_SCOPE: null,
          COMPETITORS_SCOPE_SUBTITLE: null,
          SELECTED_COMPETITOR_TTIP: null,
          COMPETITOR_SELECTED: null,
          COMPETITOR_SELECTED_COUNT: null,
          COMPETITOR_SELECTED_TITLE: null,
          COMPETITOR_SELECTED_CONTENT: null,
          VIEW_ASOCIATED_RULES_TITLE: 'Sem regras associadas',
          VIEW_ASOCIATED_RULES_TITLE_MGS: 'Não é possível consultar uma loja que não foi publicada',
          INFO_ASSORTMENT_STATUS:
            'O status do sortimento exibido representa o status depois de publicado, da mesma forma que todos os dados afetados por esta modificação terão efeito quando estiverem nesse estado.',
          IMPORT_ITEMS: 'Sortimento por item',
          CODE_EXIST: 'El código {{val}} ya existe',
          ITEMS_ALLOWED: '# Itens permitidos',
          TIP_ITEMS_ALLOWED: 'Número máximo de produtos próprios que esta loja pode criar.',
          NOT_ACCESS_PERMISSION: 'Você não tem permissão para completar esta seção',
          NOTIFY_USER: 'NOTIFICAR USUÁRIO',
          NOTIFIED_USER: 'Usuário notificado',
          IMPORT_FILE_NAME_LINK: 'determinar-PDV.xlsx',
          IMPORT_FILE_NAME_LINK_ITEMS: 'determinar-surtido.xlsx',
          IMPORT_FILE_NAME_BTN: 'carga de exceção de sortimento',
          IMPORT_ASSORTMENT_STORE_CONTEXT:
            'Pode importar exceções de sortimento pelo botão do importador ou ver o estado do sortimento para este ponto de venda',
          STORES_FILTER: 'Ponto de Venda',
          ROUTE: 'Administração / Mestre',
          TITLE: 'Pontos de Venda',
          NEW_STORE_BTN: 'Novo Ponto de Venda',
          FILTER_TTIP: 'Filtros',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          VIEW_TTIP: 'Visualizar',
          VIEW_TABLE: 'Apenas Tabela',
          VIEW_MAP: 'Apenas Mapa',
          VIEW_TABLE_MAP: 'Mapa e Tabela',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Apagar pontos de venda selecionados',
          DELETE: 'Apagar',
          NAME: 'Nome',
          CODE: 'Código',
          BRAND: 'Bandeira',
          TYPE: 'Tipo',
          STORE: 'Ponto de Venda',
          STORES_V2: 'Ponto de Venda',
          STATE: 'Estado',
          INACTIVE: 'Inativo',
          ACTIVE: 'Ativo',
          TITLE_FILTERS: 'Filtros',
          STATE_FILTER: 'Estado',
          TYPE_FILTER: 'Tipo',
          CLEAN_FILTER_BTN: 'Limpar',
          APPLY_FILTER_BTN: 'Aplicar',
          TITLE_FORM: 'Novo Ponto de Venda',
          SAVE_BTN: 'Salvar',
          BASIC_INFORMATION_TAB: 'Informação Básica',
          LATITUDE_INPUT: 'Latitude',
          VALIDATION_LATITUDE1: 'A latitude deve ser menor ou igual a 90',
          VALIDATION_LATITUDE2: 'A latitude deve ser maior ou igual a -90',
          LONGITUDE_INPUT: 'Longitude',
          VALIDATION_LONGITUDE1: 'O comprimento deve ser menor ou igual a 180',
          VALIDATION_LONGITUDE2: 'O comprimento deve ser maior ou igual a -180',
          CODE_INPUT: 'Código',
          EXTERNALCODE_INPUT: 'Código Externo',
          VALIDATION_CODE: 'O campo deve ser maior que 0',
          VALIDATION_CODE2: 'O código {{val}} já existe.',
          NAME_INPUT: 'Nome',
          BRAND_INPUT: 'Bandeira',
          EMAIL_INPUT: 'Email',
          PHONE_INPUT: 'Telefone',
          OPENING_DATE_INPUT: 'Data de abertura',
          ADRESS_INPUT: 'Endereço',
          ACTIVE_CHECK: 'Ativo',
          ACTIVE_TTIP: 'Marcar como Ativar o {{val}}',
          EXCEMPT_CHECK: 'Isento',
          EXCEMPT_TTIP: 'Marcar como isento',
          CLASSIFICATION_TAB: 'Classificação',
          WITHOUT_SELECTION: 'Sem Seleção ...',
          ADD_ATRIBUTE_BTN: 'Adicionar Atributos',
          FEATURES_TAB: 'Características',
          STORE_TYPE_INPUT: 'Tipo de Ponto de Venda',
          TERMINAL_INPUT: 'Centro de Distribuição',
          TRADE_AREA_ALL_DISTANCE: null,
          GEOLOCATION_STORE: null,
          TRADE_AREA_TAB: 'Área Comercial',
          LEGEND_FILTERS: 'Filtre os concorrentes definindo a Área Comercial',
          TRADE_AREA_TITLE: 'Distância da área comercial dos concorrentes: ',
          SELECTED_COMPETITORS: 'Concorrentes Selecionados',
          NO_SELECTED_COMPETITORS: 'Nenhum concorrente selecionado.',
          NO_SELECTED_COMPETITORS_TTIP: 'Atualizar lista',
          DELETE_BTN: 'Remover',
          DELETE_COMPETITOR_TTIP: 'Apagar Concorrente',
          CODE_NAME: 'Cod | Nome',
          MARKER: 'Marcador',
          PROVINCE: 'Província',
          LOCATION: 'Localidade',
          ADD_COMPETITORS_BTN: 'Adicionar Concorrentes',
          EMPTY_STATE: 'Nenhum concorrente encontrado com os critérios de pesquisa selecionados.',
          ADD_BTN: 'Adicionar',
          ADD_COMPETITOR_TTIP: 'Adicionar Concorrente',
          CHANGE_TRADE_AREA_TITLE_MODAL: 'Modifique a área comercial para: {{val}} Km.',
          CHANGE_TRADE_AREA_TITLE_MODAL_ALT: 'Mudar Localização',
          CHANGE_TRADE_AREA_SUBTITLE_MODAL:
            'Os competidores selecionados serão perdidos e serão modificados com os da nova Área Comercial.',
          CHANGE_TRADE_AREA_SUBTITLE_MODAL_ALT:
            'Os competidores selecionados serão perdidos e serão modificados com os da nova Área Comercial.',
          EDIT_BTN: 'Modificar',
          SEARCH: 'Procurar ',
          SET_PRINCIPAL_COMPETITOR_TTIP: 'Marque como principal concorrente desta Loja',
          SET_PRINCIPAL_COMPETITOR__REMOVE_TTIP: 'Remover como principal concorrente desta loja',
          ASSORTMENT_TAB: 'Sortimento',
          ASSORTMENT_TAB_ENABLED: 'Guia habilitada apenas na criação',
          ASSORTMENT_TAB_NO_EDIT_TOOLTIP: 'esta informação pode ser completada ao criar o ponto de venda',
          ASSORTMENT_TITLE: 'Inicialização de Sortimento',
          ASSORTMENT_SUBTITLE: 'Selecionar o cluster ao qual a nova loja pertencerá em cada uma das Clusterizações definidas no Prisma.',
          CLUSTERING: 'Clusterização',
          CLUSTER: 'Cluster',
          CLUSTER_ASSIGNMENT: 'Atribuição de Cluster',
          CLONE_TTIP: 'Clone de um Ponto de Venda existente.',
          CLONE_MODAL_TITLE: 'Clonar Sortimento',
          CLONE_MODAL_SUBTITLE: 'Os clusters serão clonados para cada agrupamento de acordo com o ponto de venda selecionado.',
          STORES: 'Ponto de Venda',
          CANCEL_BTN: 'Cancelar',
          CLONE_BTN: 'Clonar',
          PRICING_TAB: 'Preços',
          PRICING_TITLE: 'Inicialização de preços',
          PRICING_SUBTITLE: 'Escolha uma opção de inicialização de preço',
          BTN1_TITLE: 'Completar preços no Excel',
          BTN1_MSG: 'A coluna de preço deve ser preenchida para todo o sortimento ativo.',
          BTN2_TITLE: 'Clonar preço de um Ponto de Venda',
          BTN2_MSG: 'Escolha um ponto de venda de referência, de onde serão copiados todos os preços.',
          BTN3_TITLE: 'Regras de Parentesco',
          BTN3_MSG: 'As regras de parentesco serão levadas em consideração para o preenchimento automático dos preços',
          DOWLOAD_EXCEL: 'Baixar Excel',
          VIEW_ASSORTMENT_STATUS: 'Relatório de sortimento',
          REQUIRE_SAVE_ASSORTMENT_STATUS: 'Requer salvar as alterações feitas',
          REQUIRE_SAVE_ASSORTMENT_STATUS_MSG:
            'Foram detectadas alterações que afetam o status do Sortimento, para poder acessar você deve salvar as alterações feitas',
          IMPORT_EXCEL_BTN: 'Importar preços do Excel',
          CLONE_PRICE_MODAL_TITLE: 'Clonar Preços',
          CLONE_PRICE_MODAL_SUBTITLE: 'Os preços serão clonados de acordo com a loja selecionada.',
          EXCEL1: 'Completar-Preços.xlsx',
          EXCEL2: 'Clonar-Preços.xlsx',
          EXCEL3: 'Regras-de-Parentesco.xlsx',
          IMPORT_PRICES_TITLE: 'Importar preços',
          VENDORS: 'Fornecedores',
          IMPORT_EXCEL_ITEMS: 'IMPORTAR PDV DESDE EXCEL',
          IMPORT_EXCEL_ITEMS_EXCEPT: 'AVALIAÇÃO DE IMPORTAÇÃO DO EXCEL',
          IMPORT_TITLE_ITEMS: 'Variedade importante',
          MAIN_SAVE_WARNING: 'Para usar esta seção, é necessário pelo menos ter os dados principais salvos',
          IMPORT_PDV_OK: 'PONTOS DE VENDA IMPORTADOS',
          NOVALID_PUBLISH_ADVICE: 'A seguinte tab não é válido:',
          TABS: {
            VENDORS: {
              TITLE: 'Inicialização dos fornecedores',
              INTRO: 'Atribui quais fornecedores fornecem cada produto ao ponto de venda.',
              DISABLED_VENDORS_INPUT: 'Fornecedores já inicializados',
              BTN_IMPORT_SWITCH: 'Resolver Conflitos',
              COMP: {
                TITLE: 'Seleção Avançada',
                INTRO: 'Selecione os fornecedores que abastecerão o ponto de venda.',
              },
            },
          },
          ITEM: {
            SAVE: 'Salvar',
            PUBLISH: 'Publicar',
            REPORT: 'Reporte',
            REPORT_TOOLTIP: null,
            REPORT_WARNING: 'Es necesario Publicar para descargar el reporte.',
            WARNING_SAVE: 'As alterações atuais devem ser salvas.',
            WARNING_PUBLISH: 'Você precisa republicar para que as alterações salvas tenham efeito.',
            BASIC_INFORMATION_TAB: 'Dados Principais',
            CLASSIFICATION_TAB: 'Classificasão',
            PRICES_TAB: 'Preços',
            LOADING: 'Carregando...',
            NEW_ITEM_BTN: 'Novo item',
            TRADE_AREA_TAB: 'Trade Area',
            COMPETITORS_SCOPE: null,
            PUBLISH_CONFIRM:
              'O processo de publicação inclui a criação de Sortimento, Preços, Custos e outros registros relacionados à Loja. <br> <br> <b> Tem certeza que deseja Publicar? </b>',
            PUBLISH_CONFIRM_TITLE: 'Confirmação de Publicação',
            PUBLISHING_WARNING: 'Item em processo de publicação, tente novamente em alguns minutos.',
            PUBLISH_LONG_TIME:
              'A publicação deste item pode demorar alguns minutos, quando for encerrada você será notificado com uma notificação.',
          },
        },
        SUPPLIER: {
          ROUTE: 'Administração / Mestre',
          TITLE: 'Fornecedor',
          NEW_SUPPLIER_BTN: 'Novo Fornecedor',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Apagar fornecedores selecionados',
          DELETE: 'Apagar',
          NAME: 'Nome',
          CODE: 'Código',
          PHONE: 'Telefone',
          DEFINE_SUPPLIER_STP1: 'Definir Fornecedor',
          ITEMS_ALLOCATION_STP2: 'Produtos Fornecidos',
          EDIT_TITLE_FORM: 'Editar Fornecedor',
          NEW_TITLE_FORM: 'Novo Fornecedor',
          STP1: 'Etapa 1 - Definir Fornecedor',
          NAME_INPUT: 'Nome',
          CODE_INPUT: 'Código',
          PHONE_INPUT: 'Telefone',
          NEXT_BTN: 'Próximo',
          STP2: 'Etapa 2 - Atribuição de Produtos',
          ITEM: 'Produto',
          MAIN_STORES: '# Pontos de Venda',
          SECONDARY_STORES: '# Pontos de Venda Sucundários',
          DELETE_ITEM_TTIP: 'Apagar Produto',
          ADD_ITEM_BTN: 'Adicionar Produto',
          PREVIUS_BTN: 'Anterior',
          SAVE_BTN: 'Salvar',
          DELETE_TITLE_MODAL: 'Apagar',
          DELETE_SUBTITLE_MODAL: 'Tem certeza de que deseja excluir?',
          DELETE_SUBTITLE2_MODAL: 'Você vai excluir: {{val}}}. Tem certeza?',
          DELETE_BTN: 'Apagar',
          ALLOCATION_STORES: 'Atribuição de Pontos de Venda',
          ITEM_INPUT: 'Produto',
          SEARCH_ITEM: 'Procurar Produto…',
          STORES_INPUT: 'Pontos de Venda',
          ADD_STORES: 'Adicionar mais Pontos de Venda',
          SEARCH_STORES: 'Procurar Pontos de Venda…',
          ALL_CHECK: 'Todos',
          SECONDARY_STORES_INPUT: 'Pontos de Venda Secundários',
          ADD_SECONDARY_STORE: 'Adicionar mais Pontos de Venda secundários',
          SEARCH_SECONDARY_STORE: 'Procurar Pontos de Venda Secundários',
          CANCEL_BTN: 'Cancelar',
          ACCEPT_BTN: 'Aceitar',
        },
        SUPPLIERV2: {
          ROUTE: 'Administração / Mestre',
          TITLE: 'Fornecedor',
          NEW_SUPPLIER_BTN: 'Novo Fornecedor',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reduzir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Apagar fornecedores selecionados',
          DELETE: 'Apagar',
          NAME: 'Nome',
          CODE: 'Código',
          PHONE: 'Telefone',
          STATUS: 'Status',
          DEFINE_SUPPLIER_STP1: 'Definir Fornecedor',
          CLASIFICATION: 'Classificação',
          ITEMS_ALLOCATION_STP2: 'Produtos Fornecidos',
          EDIT_TITLE_FORM: 'Editar Fornecedor',
          NEW_TITLE_FORM: 'Novo Fornecedor',
          STP1: 'Etapa 1 - Definir Fornecedor',
          NAME_INPUT: 'Nome',
          CODE_INPUT: 'Código',
          PHONE_INPUT: 'Telefone',
          NEXT_BTN: 'Próximo',
          STP2: 'Etapa 2 - Atribuição de Produtos',
          ITEM: 'Produto',
          MAIN_STORES: '# Pontos de Venda',
          SECONDARY_STORES: '# Pontos de Venda Sucundários',
          DELETE_ITEM_TTIP: 'Apagar Produto',
          ADD_ITEM_BTN: 'Adicionar Produto',
          PREVIUS_BTN: 'Anterior',
          SAVE_BTN: 'Salvar',
          DELETE_TITLE_MODAL: 'Apagar',
          DELETE_SUBTITLE_MODAL: 'Tem certeza de que deseja excluir?',
          DELETE_SUBTITLE2_MODAL: 'Você vai excluir: {{val}}}. Tem certeza?',
          DELETE_BTN: 'Apagar',
          ALLOCATION_STORES: 'Atribuição de Pontos de Venda',
          ITEM_INPUT: 'Produto',
          SEARCH_ITEM: 'Procurar Produto…',
          STORES_INPUT: 'Pontos de Venda',
          ADD_STORES: 'Adicionar mais Pontos de Venda',
          SEARCH_STORES: 'Procurar Pontos de Venda…',
          ALL_CHECK: 'Todos',
          SECONDARY_STORES_INPUT: 'Pontos de Venda Secundários',
          ADD_SECONDARY_STORE: 'Adicionar mais Pontos de Venda secundários',
          SEARCH_SECONDARY_STORE: 'Procurar Pontos de Venda Secundários',
          CANCEL_BTN: 'Cancelar',
          ACCEPT_BTN: 'Aceitar',
          SEGMENTATION: 'Segmentação',
          INTERNAL_CODE_INPUT: 'Código Interno',
          UNIQUE_CODE_INPUT: 'Código Único',
          ITEM_FORM: {
            SAVE: 'Salvar',
            PUBLISH: 'Publicar',
            WARNING_SAVE: 'As alterações atuais devem ser salvas.',
            WARNING_PUBLISH: 'Você precisa republicar para que as alterações salvas tenham efeito.',
            LOADING: 'Carregando...',
            NEW_ITEM_BTN: 'Novo item',
            PUBLISH_CONFIRM:
              'O processo de publicação inclui a criação de custos nas lojas relacionadas ao fornecedor. <br> <br> <b> Tem certeza que deseja Publicar? </b>',
            PUBLISH_CONFIRM_TITLE: 'Confirmação de Publicação',
            NO_SEGMENT: 'Não segmentado',
            PUBLISHING_WARNING: 'Item em processo de publicação, tente novamente em alguns minutos.',
            PUBLISH_LONG_TIME:
              'A publicação deste item pode demorar alguns minutos, quando for encerrada você será notificado com uma notificação.',
            TIP_ATTRIBUTES_NO_SELECT: 'Os custos do fornecedor serão aplicados a toda a rede.',
            TIP_ATTRIBUTES_SELECT: 'Os custos do fornecedor se aplicam apenas a pontos de venda marcados como:',
            TIP_USESOWNITEMNUMBERS:
              'Se o código interno for necessário, tanto a atualização de custo quanto os pedidos do módulo de abastecimento devem ser concluídos com os códigos internos do fornecedor.',
          },
        },
        INSIGHTS: {
          ROUTE: 'Administração / Mestre',
          TITLE: 'Insights',
          FILTER_TTIP: 'Filtrar',
          NAME: 'Nome',
          DESCRIPTION: 'Descrição',
          MARGIN: 'Margem R$',
          CREATE_DATE: 'Data',
          ACTIONS: 'Ações',
          CONSULT_REPORTS: 'Consultar Reportes',
          REVIEW_PRICES: 'Rever Preços',
          TOGGLE_FILTER: 'Só os meus Insights',
          ANTIQUITY_FILTER: 'Antiguedade',
          INSIGHTS_TYPE_FILTER: 'Tipo de Insight',
          STORES_FILTER: 'Pontos de Venda',
        },
        EQUIPMENTS: {
          ROUTE: null,
          TITLE: null,
          TITLE_FULL: null,
          FILTER_TTIP: null,
          NAME: null,
          DESCRIPTION: null,
          EDIT: null,
          NEW: null,
          DELETE: null,
          DELETE_WARN: null,
          ITEMS: null,
          ATTR: null,
          ATTR_ALT: null,
          REQUIRED: null,
          SAVE: null,
          PUBLISH: null,
          WARNING_SAVE: null,
          WARNING_PUBLISH: null,
          LOADING: null,
          ADD_ATTR: null,
          EDIT_ATTR: null,
          TYPE_ATTR: null,
          NEW_ITEM: null,
          VALUE_LIST: null,
          ADD_VALUE: '+',
          VALUE: null,
          VALIDATION_NAME_DUPLICATE: null,
          VALIDATION_ATTR_DUPLICATE: null,
          ASSIGNED: null,
          ATTR_REQUIRED: null,
          GENERAL: null,
          ASSIGN_REQUIRED_DISCLAIMER: null,
          REPORT: null,
          TYPES: {
            NUMBER: null,
            STRING: null,
            BOOLEAN: null,
            LIST: null,
            PRICE: null,
            DATETIME: null,
          },
        },
        TAGS: {
          ACCEPT: 'Aceitar',
          NAME_ALREADY_EXIST: null,
          TYPES: {
            Item: 'Produto',
            Store: 'Ponto de Venda',
            Competitor: 'Concorrente',
            Brands: 'Bandeiras',
            Vendor: 'Fornecedor',
          },
          DATA_TYPES: {
            Text: 'Texto',
            Number: 'Número',
            Boolean: 'Boleano',
            DynamicEntity: 'Entidade dinâmica',
          },
          ENTITIES: {
            COMPETITORS: 'Concorrentes: {{val}}',
            ITEMS: 'Produtos: {{val}}',
            ROUNDINGRULES: 'Regras de arredondamento: {{val}}',
            COMPETITIVELIMITRULES: 'Limite de concorrentes: {{val}}',
            PRICINGRULESITEMS: 'Regras de parentesco de produtos: {{val}}',
            PRICINGRULESSTORES: 'Regras de parentesco de pontos de venda: {{val}}',
            STORES: 'Pontos de venda: {{val}}',
            VENDORS: 'Fornecedores: {{val}}',
            VENDORCOSTS: 'Custos: {{val}}',
            ASSORTMENTASSIGNMENTS: 'Atribuição de sortimento: {{val}}',
            ASSORTMENTITEMEXCEPTIONS: 'Exceção de sortimento por produtos: {{val}}',
            ASSORTMENTSTOREEXCEPTIONS: 'Exceção de sortimento por ponto de venda: {{val}}',
            ASSORTMENTCATEGORIES: 'Categorias de sortimento: {{val}}',
          },
          DEFAULTVALUE: null,
          ISDEFAULTVALUE: null,
          TAG_IN_USE: 'Erro ao excluir a tag está em uso',
          ENTITIES_IN_USE: 'Você deve reatribuir o seguinte número de registros para cada entidade que usa a tag para excluir',
          CANCEL_BTN: 'Cancelar',
          NEW_TAG_VALUE_ADD_BTN: 'Criar novo valor de etiqueta',
          ROUTE: 'Administração / Mestre',
          ISDELETED: 'Eliminados',
          ISDELETED_POPOVER_TOOLTIP: 'Ao ativar apenas as etiquetas excluídas serão mostradas',
          USEFORREPORTS: 'Usados para relatórios',
          USEFORREPORT: 'Usado para relatórios',
          USEFORREPORTS_POPOVER_TOOLTIP: 'Ao habilitar, apenas as etiquetas atribuídas aos relatórios serão mostradas',
          YES: 'Sim',
          NO: 'Não',
          REQUIRED_CHECKBOX_POPOVER_TOOLTIP:
            'Torne este campo obrigatório para todos os "produtos" ...\nO sistema não permitirá elementos que este campo não esteja completo.',
          REQUIRED_FOR_COMPETITOR_POPOVER_TOOLTIP: null,
          REQUIRED_FOR_STORE_POPOVER_TOOLTIP: null,
          TERMINATION_SLIDE_POPOVER_TOOLTIP:
            'Você pode adicionar uma terminação padrão aos valores da etiqueta. Ex: para conteúdo líquido, "cc" ou "cm3". Tag: 250 cc',
          DEPENDENCIES_POPOVER_TOOLTIP:
            'Usá-lo em caso esta etiqueta dependa de outra. Relacionamento pai / filho. Ex: "Cidade" depende de "Estado"',
          SELECT_PARENT_TAG_POPOVER_TOOLTIP:
            'Em caso de mudança para outra etiqueta pai, os valores desta etiqueta serão eliminados. Em caso de desalocação, os valores desta etiqueta perderão relação com os valores pais',
          SELECT_PARENT_TAG_WARNING:
            'Certifique-se de selecionar a etiqueta pai correta antes de carregar os valores pai, se alterado você perderá os valores adicionados anteriormente',
          TITLE: 'Etiquetas',
          CANCEL: 'Cancelar',
          OK: 'Ok',
          NEW_STORE_BTN: 'Nova Etiqueta',
          DELETE: 'Apagar',
          DELETE_TTIP: 'Apagar Etiquetas Selecionadas',
          FILTER_TTIP: 'Filtrar',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          NAME: 'Nome',
          TYPE: 'Tipo',
          FIELD_TYPE: 'Tipo de Campo',
          REQUIRED: 'Obrigatório',
          QUANTITY_TAGS: '# Etiquetas',
          QUANTITY_TAGS_TTIP: 'Quantidade de etiquetas que pertencem a um grupo',
          DEFINE_TAG_STP1: 'Definir Etiqueta',
          ADMIN_TAG_STP2: 'Gerenciar Etiqueta',
          EDIT_TAG: 'Editar Etiqueta',
          DELETE_ROW: 'Apagar Tag',
          NEW_TAG: 'Nova Etiqueta',
          STP1: 'Definir Etiqueta',
          NAME_INPUT: 'Nome',
          TYPE_INPUT: 'Tipo de Etiqueta',
          DATATYPE_INPUT: 'Tipo de Dados',
          REQUIRED_CHECKBOX: 'Obrigatório para todos os produtos',
          REQUIRED_FOR_COMPETITOR: 'Obrigatório para todos os concorrentes',
          REQUIRED_FOR_STORE: 'Obrigatório para todos os Pontos de Venda das marcas próprias',
          ADDITIONAL_SLIDE: 'Características adicionais',
          COLOR_SLIDE: 'Cor',
          ICON_SLIDE: 'Ícone e Imagen',
          REQUIRED_SLIDE: 'Requer Permissão Especial',
          TERMINATION_SLIDE: 'Terminação',
          TERMINATION_INPUT: 'Ingressar Terminação',
          DEPENDENCIES: 'Dependências',
          DEPENDENCIES_INPUT: 'Selecionar Etiqueta Pai',
          REQUIRED_WARNING_OFF_CONTENT: null,
          REQUIRED_WARNING_OFF_TITLE: null,
          NEXT_BTN: 'Próximo',
          STP2: 'Gerenciar Valores da Etiqueta',
          NEW_TAG_INPUT: 'Adicionar nova etiqueta',
          DEPENCENCY_TAG_INPUT: 'Selecionar o Valor da Etiqueta Pai',
          PARENT: 'Pai',
          TYPE_CENTER: 'Tipo de Centro',
          COLOR: 'Cor',
          ICON: 'Ícone',
          TERMINATION: 'Terminação',
          PARENT_VALUE: 'Valor do Pai',
          ELEMENTS: '# Elemenos',
          ELEMENTS_TTIP: 'Quantidade de Elementos',
          PREVIOUS_BTN: 'Anterior',
          SAVE_BTN: 'Salvar',
          ADD_BTN: 'Adicionar',
          EDIT_BTN: 'Editar',
          TAG_VALUE_EXIST: 'O valor da etiqueta já existe',
          DEFAULT_TTP: null,
          INVALID_TAG_VALUES_FORM: 'Todos os valores carregados devem conter os campos obrigatórios',
          OWN_STORES: 'próprias lojas',
          PRODUCTS: 'produtos',
          COMPETITORS: 'concorrentes',
          REQUIRED_DEFAULT_TAG: 'Você deve configurar um valor de etiqueta padrão para poder Salvar',
          TAG_UPDATE_ALL_PRODUCTS: 'Esta etiqueta atualizará todos os Produtos. Deseja continuar?',
          TAG_UPDATE_ALL_OWN_STORES: 'Esta etiqueta atualizará todos os Pontos de Venda Própria. Deseja continuar?',
          TAG_UPDATE_ALL_COMPETITORS: 'Esta etiqueta atualizará todos os Competidores. Deseja continuar?',
        },
      },
      SECURITY: {
        USERS: {
          LIST: {
            TITLE: 'Usuários',
            EDIT_ROW: 'Editar',
            USERNAME_INPUT: 'Nome de usuário',
            EMAIL_INPUT: 'Email',
            ROLE_INPUT: 'Função',
          },
          ITEM: {
            TAB1: 'Dados Gerais',
            USERNAME_INPUT: 'Nome de usuário',
            FIRSTNAME_INPUT: 'Nome',
            LASTNAME_INPUT: 'Sobrenome',
            EMAIL_INPUT: 'Email',
            PASSWORD_INPUT: 'Contra-senha',
            PREV_PASSWORD_INPUT: 'Contra-senha anterior',
            RESET_PASS: 'Blanquear Contra-senha',
            REPEAT_PASSWORD_INPUT: 'Redifinir Contra-senha',
            PASSWORD_BTN: 'Esconder contra-senha',
            ROLES_INPUT: 'Função',
            CATEGORIES: 'Categorias',
            STORES: 'Pontos de Venda',
            EDIT_STORES: 'Editar Pontos de Venda',
            CATEGORIES_ADVICE: 'Caso deixe esta seleção vazia, nenhuma restrição por categoria será aplicada ao usuário.',
            STORES_ADVICE: 'Caso deixe esta seleção vazia, nenhuma restrição por ponto de venda será aplicada ao usuário.',
          },
          NEW_USER_BTN: 'Novo Usuário',
        },
        ROLES: {
          LIST: {
            TITLE: 'Funções',
            EDIT_ROW: 'Editar',
            NAME_INPUT: 'Nome',
            SELECTED: 'Seleccionado',
            SELECTED_PLURAL: 'Seleccionados',
          },
          ITEM: {
            TAB1: 'Dados Gerais',
            NAME_INPUT: 'Nome',
            NAME_ROLE: 'Nome',
            DESCRIPTION_ROLE: 'Descrição',
            SELECTED: null,
            SELECTED_PLURAL: null,
            ROLE: {
              admin_view: 'Visualizar menu de Administração',
              itemmaster_view: 'Visualizar menu de Item Master',
              prices_competitors_view: 'Visualizar menu de bandeiras e concorrentes',
              prices_costsandtaxes: 'Importador de custos por loja e custos por fornecedor',
              prices_decisions: 'Alterar preços, Aceitar e ignorar sugestões. Solicitar aprovação e importar novos preços',
              prices_endorse: 'Aprovar preços e rejeitar alterações.',
              prices_guidelineStrategy_view: 'Visualizar menu de diretrizes e estratégias',
              prices_competitors_delete: 'Excluir um concorrente',
              prices_roundings_delete: 'Excluir uma regra de arredondamento',
              prices_rules_delete: 'Excluir uma regra',
              prices_sensitivities_delete: 'Excluir uma sensibilidade',
              prices_decisions_prices_endorse: 'Aceitar uma sugestão (enviar para publicação)',
              assortment_clustering_save: 'Salvar uma nova clusterização',
              assortment_clustering_delete: 'Excluir uma clusterização',
              assortment_roles_save: 'Salvar alterações em um papel (ou criar um novo)',
              assortment_roles_delete: 'Excluir um papel',
              assortment_strategies_save: 'Salvar alterações em uma estratégia (ou criar uma nova)',
              assortment_strategies_delete: 'Excluir uma estratégia',
              assortment_decisions_save: 'TBD',
              assortment_output: 'TBD',
              spaces_planograms_save: 'Salvar alterações em um planograma (ou criar um novo)',
              spaces_planograms_upload_audit_photo: 'Carregar fotos de auditoria do planograma',
              spaces_planograms_approve: 'Ação no fluxo de aprovação do planograma (TBD)',
              spaces_planograms_request_approval: 'Ação no fluxo de aprovação do planograma (TBD)',
              spaces_planograms_inform: 'Ação no fluxo de aprovação do planograma (TBD)',
              spaces_planograms_messaging: 'TBD',
              spaces_planograms_pop_material_save: 'Salvar material POP padrão (TBD Promos)',
              spaces_layout_save: 'Salvar alterações em um layout (ou criar um novo)',
              spaces_layout_delete: 'Excluir um layout',
              spaces_templates_save: 'Salvar uma nova configuração do Gerador de Planograma',
              spaces_templates_delete: 'Excluir uma configuração do Gerador de Planograma',
              equipments_save: 'Salvar alterações em um equipamento (ou criar um novo)',
              equipments_delete: 'Excluir equipamento',
              storemaster_save: 'Salvar alterações em um Ponto de Venda (ou criar um novo)',
              storemaster_delete: 'Acessar menu de Tags',
              tags_delete: 'Excluir uma tag',
              vendor_save: 'Salvar alterações em um Fornecedor (ou criar um novo)',
              vendor_publish: 'Publicar um fornecedor (TBD)',
              vendor_delete: 'Excluir um fornecedor',
              general_importer_view: 'Acessar importador geral',
              itemmaster_save: 'Salvar alterações em um item (ou criar um novo)',
              itemmaster_publish: 'Publicar um item',
              itemmaster_delete: 'Excluir um item',
              update_custom_item_to_standard_item: 'Converter um item próprio em um item padrão',
              mysteryShopper_missions_save: 'Salvar alterações em uma missão (ou criar uma nova)',
              mysteryShopper_missions_delete: 'Excluir uma missão',
              prices_output: 'Publicar decisões de preços',
              prices_output_readonly: 'Visualizar menu de Publicar decisões e gerar visualização prévia',
              prices_roundings_view: 'Visualizar menu de regras de arredondamento',
              prices_rules_view: 'Visualizar menu de regras de preços',
              prices_sensitivities_view: 'Visualizar menu de sensibilidades',
              prices_view: 'Visualizar menu de decisões de preços',
              pricing_manual_suggestions: 'Permitir gerar sugestões de preços manualmente',
              pricing_survey: 'Permitir importar preços da concorrência',
              storemaster_view: 'Visualizar menu de Pontos de venda',
              tags_view: 'Visualizar menu de tags',
              prices_competitors_save: null,
              prices_guidelineStrategy_save: null,
              prices_roundings_save: null,
              prices_rules_save: null,
              prices_sensitivities_save: null,
              tags_save: 'Criar e atualizar tags',
              assortment_categoryTree_view: 'Visualizar menu de Árvore de categorias e Configuração de categorias.',
              assortment_clustering_view: 'Visualizar menu de agrupamento',
              assortment_decisions_view: 'Visualizar menu de decisões de sortimento',
              assortment_output_readonly: 'Visualizar menu de publicar decisões de sortimento e permitir gerar visualização prévia',
              assortment_roles_view: 'Visualizar menu de papéis',
              assortment_strategies_view: 'Visualizar menu de estratégias',
              assortment_view: 'Habilitar menu geral de sortimento',
              equipments_view: 'Visualizar menu de equipamentos',
              category_tree_button_view: 'Visualizar botão de relatório de árvore de categorias.',
              mysteryShopper_missions_view: 'Visualizar menu de missões',
              mysteryShopper_userAssigment_view: 'Visualizar menu de atribuição de usuários',
              mysteryShopper_view: 'Habilitar menu geral de missões',
              promotion_action_view: 'Visualizar menu de Ações',
              promotion_campaign_view: 'Visualizar menu de Campanhas',
              promotion_campaign_save: 'Criar e atualizar campanhas',
              promotion_view: 'Habilitar menu geral de promoções',
              promotion_calendar_view: 'Visualizar o menu de calendário',
              promotion_configuration_view: 'Visualizar o menu de configurações',
              promotion_reportes_view: 'Visualizar o menu de relatórios',
              promotion_periods_view: 'Visualizar o menu de períodos promocionais',
              promotion_reportes_production_view: 'Visualizar listas de produção',
              promotion_reportes_logistic_view: 'Visualizar listas de logística',
              promotion_reportes_pos_view: 'Visualizar dados para POS',
              promotion_action_save: 'Criar e atualizar ações promocionais',
              promotion_action_cancel: 'Eliminar ações promocionais',
              promotion_action_publish: 'Publicar ações promocionais',
              promotion_reports_view: 'Visualizar relatórios de promoções',
              promotion_can_edit_all_promotional_actions: 'Criar ou editar promoções de períodos que já começaram/terminaram',
              promotion_promotional_process_view: 'Visualizar o menu de processos promocionais',
              promotion_promotional_process_edit: 'Criar e atualizar processos promocionais',
              spaces_layout_view: 'Visualizar menu de Layout',
              spaces_planograms_pop_material_view: 'Visualizar menu de Material Pop',
              spaces_planograms_view: 'Permitir visualizar menu de planogramas',
              spaces_view: 'Habilitar menu geral de espaços',
              vendor_view: 'Visualizar menu de fornecedores',
              admin_security: 'Permissões na gestão de Funções e Usuários',
              spaces_planograms_delete: 'Habilita o usuário a inativar todos os planogramas criados.',
            },
          },
          NEW_ROLE_BTN: 'Novo Função',
        },
      },
      PROFILE: {
        CHANGE_PASS: 'Redifinir Contra-senha',
        PROFILE_TITLE: 'Meu Perfil',
        PERMISSIONS: 'Permissões',
      },
    },
    PROMO: {
      CAMPAIGNS: {
        ROUTE: 'Promoções',
        TITLE: 'Campanha',
        NEW_CAMPAING_BTN: 'Nova Campanha',
        NAME: 'Nome',
        CODE: 'Código',
        ACTIONS: '# Ações',
        SINCE: 'Desde',
        TO: 'Até',
        STATE: 'Estado',
        USER: 'Usuário',
        LAST_MOD: 'Última Modif.',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reduzir',
        EXPORT_TTIP: 'Exportar',
        IMPORT_TTIP: 'Importar',
        PRINT_TTIP: 'Imprimir',
        TITLE_FORM: 'Nova Campanha',
        SAVE_BTN: 'Salvar',
        BASIC_INFORMATION_TAB: 'Dados Principais',
        CODE_INPUT: 'Código',
        NAME_INPUT: 'Nome',
        SINCE_INPUT: 'Desde',
        TO_INPUT: 'Até',
        DESCRIPTION_INPUT: 'Descrição',
        STATUS_STORES: 'Todos',
        STATUS_HISTORY: 'Histórica',
        STATUS_VIGENT: 'Vigente',
        STATUS_FUTURE: 'Futura',
        STATUS_NOTVALID: 'Inválido',
        STATUS_EMPTY: 'Inválido',
        INVALID_TO_DATE: 'Deve ser maior que a data de início e dentro do intervalo de datas da campanha',
        INVALID_FROM_DATE: 'Deve ser menor que a data de término e dentro do intervalo de datas da campanha',
        INVALID_TO_DATE_CAMPAING: 'Deve ser maior que a data de início',
        INVALID_FROM_DATE_CAMPAING: 'Deve ser menor que a data de término',
        NAME_TTIP: 'Clique aqui para ver as ações relacionadas',
        ACTIONS_TTIP: 'Número de Ações',
        LAST_MOD_TTIP: 'Última Modificação',
      },
      ACTIONS: {
        ROUTE: 'Promoções',
        TITLE: 'Ações',
        FILTER_TTIP: 'Filtrar',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reduzir',
        NAME: 'Nome',
        CODE: 'Código',
        TYPE: 'Tipo',
        STORES: '# Pontos de Venda',
        LIFT: 'Lift %',
        BRAND: 'Marca',
        NONE: 'Nenhum',
        SINCE: 'Desde',
        TO: 'Até',
        SUPPLIES_LAST: 'Sujeito à disponibilidade de estoque',
        USER: 'Usuário',
        LAST_MOD: 'Última Modif.',
        STATE: 'Estado',
        TYPE_OP1: 'Leve A pague B',
        TYPE_OP2: 'Desconto Direto',
        TYPE_OP3: 'Desconto por Quantidade',
        STATUS_STORES: 'Todos',
        STATUS_HISTORY: 'Histórica',
        STATUS_VIGENT: 'Vigente',
        STATUS_FUTURE: 'Futura',
        STATUS_NOTVALID: 'Inválido',
        STATUS_EMPTY: 'Inválido',
        STATUS_CANCELLED: 'Cancelada',
        INVALID_TO_TIME: 'Deve ser maior que a hora inicial',
        INVALID_FROM_TIME: 'Deve ser menor que a hora de término',
        INVALID_TO_DATE: 'Deve ser maior que a data de início e dentro do intervalo de datas das campanhas',
        INVALID_FROM_DATE: 'Deve ser menor que a data de término e dentro do intervalo de datas das campanhas',
        INVALID_TO_DATE_CAMPAING: 'Deve ser maior que a data de início',
        INVALID_FROM_DATE_CAMPAING: 'Deve ser menor que a data de término',
        DEFINE_ACTION_STP1: 'Definir Ação',
        RESTRICTIONS_STP2: 'Restrições',
        PRODUCTS_SCOPE_STP3: 'Escopo dos Produtos',
        STORES_SCOPE_STP4: 'Escopo Pontos de Venda',
        OTHERS_TERMS_STP5: 'Outras condições',
        TITLE_FORM_NEW: 'Nova Ação - ',
        TITLE_FORM_EDIT: 'Editar Ação - ',
        STP1: 'Etapa 1 - Definir Ação',
        CODE_INPUT: 'Código',
        PROMOTION_CODE_INPUT: 'Código Promo Externo',
        NAME_INPUT: 'Nome',
        TYPE_INPUT: 'Tipo',
        QUANTITY_BUY_INPUT: 'Quantidade a comprar',
        QUANTITY_PAY_INPUT: 'Quantidade a pagar',
        DISCOUNT_RATE_INPUT: 'Taxa de Desconto',
        UNIT_DISCOUNT_INPUT: 'Desconto por unidade',
        DISCOUNT_VALUE_INPUT: 'Valor do Desconto',
        QUANTITY_UNITS_INPUT: 'Quantidade de unidades a serem levadas',
        UNIT_DISCOUNT_PER_INPUT: 'Desconto por unidade {{val}}',
        SINCE_INPUT: 'Desde',
        TO_INPUT: 'Até',
        SUPPLIES_LAST_CHECK: 'Enquanto durarem os estoques',
        UNITS_AVAILABLE_INPUT: 'Unidades Disponíveis',
        FRECUENCY_INPUT: 'Frequência',
        DAYS_INPUT: 'Dias',
        TIME_FROM_INPUT: 'Horario Desde',
        TIME_TO_INPUT: 'Horario Até',
        TICKET_MESSAGE_INPUT: 'Mensagem do Ticket',
        OBSERVATION_INPUT: 'Observação',
        NEXT_BTN: 'Próximo',
        MONDAY: 'Segunda',
        TUESDAY: 'Terça',
        WEDNESDAY: 'Quarta',
        THURSDAY: 'Quinta',
        FRIDAY: 'Sexta',
        SATURDAY: 'Sábado',
        SUNDAY: 'Domingo',
        DAILY: 'Diariamente',
        WEEKLY: 'Semanal',
        BIWEEKLY: 'Quincenal',
        MONTHLY: 'Mensal',
        BIMONTHLY: 'Bimestral',
        CUARTERLY: 'Trimestral',
        SEARCH_CONDITIONS: 'Procurar Outras Condições…',
        STP2: 'Etapa 2 - Restrições',
        UNIT_RESTRICTION: 'Restrição de Unidade',
        MINIMUM_AMOUNT_INPUT: 'Quantidade mínima',
        MAXIMUM_AMOUNT_INPUT: 'Quantidade máxima',
        AMOUNT_RESTRICTION: 'Restrição de quantidade',
        OTHERS_INPUT: 'Outras condições',
        PREVIOUS_BTN: 'Anterior',
        EQUAL_BRAND: 'Mesma Marca',
        EQUAL_PRICE: 'Mesmo Preço',
        EQUAL_CATEGORY: 'Mesma Categoria',
        EQUAL_DISCOUNT: 'Mesmo Desconto',
        EQUAL_PRODUCT: 'Mesmo Produto',
        STP3: 'Etapa 3 - Escopo dos Produtos',
        MANUAL_SELECTION_RADIO: 'Seleção Manual',
        CATEGORY_INPUT: 'Categoria',
        SEARCH_CATEGORY: 'Procurar Categoria…',
        EXCLUDE_CATEGORY_INPUT: 'Categoria a excluir',
        SEARCH_EXCLUDE_CATEGORY: 'Procurar Categoria a excluir',
        SUBCATEGORY_INPUT: 'Subcategoria',
        SEARCH_SUBCATEGORY: 'Procurar Subcategoria',
        EXCLUDE_SUBCATEGORY_INPUT: 'Subcategoria a excluir',
        SEARCH_EXCLUDE_SUBCATEGORY: 'Procurar Subcategoria a excluir',
        SUPPLIER_INPUT: 'Fornecedor',
        SEARCH_SUPPLIER: 'Procurar Fornecedor',
        EXCLUDE_SUPPLIER_INPUT: 'Fornecedor a excluir',
        SEARCH_EXCLUDE_SUPPLIER: 'Procurar Fornecedor a exluir',
        BRAND_INPUT: 'Marca',
        SEARCH_BRAND: 'Procurar Marca',
        EXCLUDE_BRAND_INPUT: 'Marca a excluir',
        SEARCH_EXCLUDE_BRAND: 'Procurar Marca a excluir',
        TAGS_INPUT: 'Atributos',
        SEARCH_TAGS: 'Procurar Atributos',
        EXCLUDE_TAGS_INPUT: 'Atributos a excluir',
        SEARCH_EXCLUDE_TAGS: 'Procurar Atributos a excluir',
        ITEMS_INPUT: 'Produtos',
        SEARCH_ITEMS: 'Procurar Produtos',
        EXCLUDE_ITEMS_INPUT: 'Produtos a serem excluídos',
        SEARCH_EXCLUDE_ITEMS: 'Procurar Produtos a excluir',
        MANUAL_LOAD_RADIO: 'Carregamento manual',
        DRAG_FILE: 'Arraste e solte o arquivo aqui ou clique para pesquisar em seu PC',
        STP4: 'Etapa 4 - Escopo dos Pontos de Venda',
        CLUSTER_INPUT: 'Cluster',
        SEARCH_CLUSTER: 'Procurar Cluster…',
        EXCLUDE_CLUSTER_INPUT: 'Cluster a excluir',
        SEARCH_EXCLUDE_CLUSTER: 'Procurar Cluster a excluir',
        FORMAT_INPUT: 'Formato',
        SEARCH_FORMAT: 'Procurar Clientes / Comunidades',
        EXCLUDE_FORMAT_INPUT: 'Formato para excliuir',
        SEARCH_EXCLUDE_FORMAT: 'Procurar Formato a excluir',
        STORE_INPUT: 'Ponto de Venda',
        SEARCH_STORE: 'Procurar Ponto de Venda',
        EXCLUDE_STORE_INPUT: 'Ponto de Venda a excluir',
        SEARCH_EXCLUDE_STORE: 'Procurar Ponto de Venda a excluir…',
        STP5: 'Etapa 5 - Outras Condições',
        CLIENTS_COMMUNITIES: 'Clientes / Comunidades',
        SEARCH_CLIENTS_COMMUNITIES: 'Procurar Clientes / Comunidades',
        PAYMENT_METHODS: 'Médios de Pagamento',
        SEARCH_PAYMENT_METHODS: 'Procurar Métodos de Pagamento',
        BANK_PROMOTIONS: 'Promoções Bancárias',
        SEARCH_BANK_PROMOTIONS: 'Procurar Promoções bancárias',
        CUMULATIVE: 'Cumulativo com outras promoções',
        SAVE_BTN: 'Salvar',
        NEW_ACTION_TITLE: 'Nova Ação',
        LEGEND_OP1:
          'É o desconto em que A representa as unidades a serem compradas pelo cliente e B representa as unidades a serem pagas pelo cliente. Exemplos: 2x1 (leve 2 e pague por 1) ou 3x2 (leve 3 e pague 2)',
        LEGEND_OP2:
          'É a porcentagem ou valor do desconto que será aplicado ao preço atual do produto. Exemplos: "15% de desconto sobre o preço atual" ou "R$ 10 de desconto sobre o preço atual"',
        LEGEND_OP3:
          'É a porcentagem de desconto que será aplicada ao preço atual do produto quando mais de uma unidade for comprada. Exemplo: Compre a segunda unidade com 80% de desconto.',
        CANCEL_BTN: 'Cancelar',
        CREATE_BTN: 'Criar',
        OVERLAP_TITLE: 'Há uma sobreposição',
        OVERLAP_SUBTITLE: 'Os produtos estão sendo divulgados em diferentes ações promocionais.',
        DATAIL_BTN: 'Mostre-me as sobreposições',
        ACCEPT_BTN: 'Aceitar',
        ROUTE2: 'Promoções / Campanha',
        ROUTE2_PROMOTIONAL_PERIOD: 'Promociones / Períodos Promocionais',
        NEW_ACTION_BTN: 'Nova Ação',
        ACTIONS_TITLE: 'Ações',
        VIEW_TTIP: 'Visualizar',
        VIEW_TABLE: 'Apenas Tabela',
        VIEW_CALENDAR: 'Apenas Calendário',
        TITLE_FILTERS: 'Filtros',
        STATE_FILTER: 'Estado',
        STATE_OP1_FILTER: 'Histórica',
        STATE_OP2_FILTER: 'Vigente',
        STATE_OP3_FILTER: 'Futura',
        CAMPAINGS_FILTER: 'Campanha',
        CLEAN_FILTER_BTN: 'Limpar',
        APPLY_FILTER_BTN: 'Aplicar',
        STORES_TTIP: 'Número de Pontos de Venda',
        LAST_MOD_TTIP: 'Última modificação',
        STP1_SUB: 'Nesta etapa você pode definir os principais componentes do tipo de ação selecionada.',
        STP2_SUB: 'Nesta etapa você pode definir as condições de sortimento e as restrições mínimas e máximas para a ação.',
        STP3_SUB: 'Nesta etapa, você pode selecionar os produtos a serem promovidos.',
        STP4_SUB: 'Nesta etapa você pode selecionar as lojas que realizarão esta promoção.',
        STP5_SUB: 'Nesta etapa você pode estabelecer condições que irão limitar a aplicação desta ação promocional.',
        STRATEGY: 'Em seguida, escolha a estratégia de seleção dos produtos filhos',
        STRATEGY_ATTR_TITLE: 'Seleção por atributo',
        STRATEGY_ATTR_MSG: 'Os atributos são selecionados para definir o escopo dos produtos',
        STRATEGY_STORE_ATTR_MSG: 'Os atributos são selecionados para definir o escopo dos Pontos de Venda',
        STRATEGY_PROD_TITLE: 'Seleção por produto',
        STRATEGY_STORE_TITLE: 'Seleção por Ponto de Venda',
        STRATEGY_PROD_MSG: 'Os produtos devem ser selecionados um por um',
        STRATEGY_STORE_MSG: 'Os pontos de venda devem ser selecionados um a um',
        GENERIC_MSG_ERRROR_EXECSUGGESTION: 'Não foi possível gerar sugestões.',
        GENERIC_MSG_NOSUGGESTIONS_EXECSUGGESTION: 'Não há novas sugestões.',
        GENERIC_ACTUAL_FILE: 'Arquivo Atual:',
      },
      HELP: {
        QUANTITY_BUY_INPUT: 'Indica as unidades (A) que o cliente levará. Exemplo: Se a ação for "3 x 2", você deve inserir "3".',
        QUANTITY_PAY_INPUT:
          'Indica as unidades (B) que o cliente pagará. Deve ser sempre menor que (A). Exemplo: Se a ação for "3 x 2" aqui, você deve inserir "2".',
        FRECUENCY_INPUT: 'Indica a frequência da ação.',
        DAYS_INPUT: 'Dias da semana em que a ação será efetiva.',
        TIME_FROM_INPUT: 'Intervalo de tempo em que a ação será efetiva.',
        TIME_TO_INPUT: 'Intervalo de tempo em que a ação será efetiva.',
        TICKET_MESSAGE_INPUT: 'Breve descrição da ação que será refletida na fatura ou ticket.',
        OBSERVATION_INPUT: 'Permite inserir algum tipo de informação ou detalhe da ação.',
        UNIT_DISCOUNT_INPUT: 'Indica o valor do desconto a ser aplicado a cada unidade vendida.',
        DISCOUNT_RATE_INPUT: 'Indica se o desconto será em percentual ou em valor fixo.',
        SUPPLIES_LAST_CHECK: 'Mensagem informativa para fins legais para o ponto de venda.',
        UNITS_AVAILABLE_INPUT:
          'Indica o total de unidades de produtos da empresa disponíveis para a ação. Mensagem informativa para fins legais para o ponto de venda.',
        DISCOUNT_VALUE_INPUT:
          'Valor de desconto. Exemplo: Se a ação for “2ª unidade com 30% de desconto”, aqui devemos inserir o número “30”.',
        UNIT_DISCOUNT_PER_INPUT:
          'Valor de desconto para cada unidade vendida. Exemplo: Se a ação for “2ª unidade com 30% de desconto”, aqui devemos inserir o número “15” que é o percentual que aplicará o desconto a cada unidade vendida.',
        QUANTITY_UNITS_INPUT:
          'Indica o número de unidades que o cliente deve comprar para que o desconto seja aplicado. Exemplo: Se a ação for “2ª unidade com 30% de desconto”, aqui devemos inserir o número “2”.',
        MINIMUM_AMOUNT_INPUT:
          'Indica o número mínimo de unidades que o cliente deve comprar para que o desconto seja aplicado. Exemplo: Se a ação for "desconto de 15% levando 3 unidades", aqui devemos inserir o número "3".',
        MAXIMUM_AMOUNT_INPUT:
          'Indica o número máximo de unidades que o cliente pode comprar com o desconto. Exemplo: Se a ação for “desconto de 15% até 6 unidades”, aqui devemos inserir o número “6”. Se o cliente comprar sete unidades, a 7ª unidade não terá desconto.',
        MINIMUM2_AMOUNT_INPUT:
          'Indica o valor monetário mínimo que o cliente deve gastar para que a promoção se aplique. Exemplo: Se a ação for "10% de desconto nas compras acima de R$ 1.000", aqui devemos inserir o número "1.000". Se esse valor não for atingido, o desconto não se aplica.',
        MAXIMUM2_AMOUNT_INPUT:
          'Indica o valor monetário máximo que o cliente deve gastar para que a promoção se aplique. Exemplo: Se a ação for “10% de desconto em compras até R$ 1.000”, aqui devemos inserir o número “1.000”. Se a compra for R$ 1.500, o desconto ainda será R$ 100 = 1000 x 10%.',
      },
    },
    PROMOV2: {
      CONFIGURATIONS: {
        LIST: {
          TITLE: 'Regras de Promoções',
          NAME: 'Nome',
          CODE: 'Código',
          RULE_TYPE: 'Tipo de Regra',
        },
        FORM: {
          NEW_ITEM_TITLE: 'Nova Regra de Promoção',
          NAME: 'Nome',
          CODE: 'Código',
          RULE_TYPE: 'Tipo de Regra',
          PROMOTIONAL_PROCESS_TYPE: 'Tipo de Processo Promocional',
          PROMOTION_TYPE: 'Mecânica',
          PROMOTION_RULE_TYPE: 'Tipo de Regra',
          LIMITS: 'Limites',
          PRICE_RULES_TITLE: 'O preço deve ser:',
          VALUE: 'Valor {{val}}',
          RULE_MESSAGES: {
            FIXPRICE_MINIMUM: 'Meu {{val}} preço promocional deve ser ${{val2}}.',
            FIXPRICE_MAXIMUM: 'Meu {{val}} preço promocional deve ser ${{val2}}.',
            REGULARPRICE_MINIMUM: 'Meu {{val}} preço promocional deve ser {{val3}}{{val2}} menor que o preço regular.',
            REGULARPRICE_MAXIMUM: 'Meu {{val}} preço promocional deve ser {{val3}}{{val2}} acima do preço regular.',
            SELECT_CONDITION: '(Escolha uma condição para a regra)',
            INSERT_VALUE: '(Insira um valor)',
            SELECT_DISCOUNT_TYPE: '(Escolha o tipo de desconto)',
          },
          REFERENCE_PRICE_TYPES: {
            PLACEHOLDER: 'Tipo de Preço de Referência',
            FIXPRICE: 'Preço Fixo',
            REGULARPRICE: 'Preço Regular',
            TRUCKPRICE: 'Preço do Caminhão',
          },
          RULE_LIMIT_TYPES: {
            PLACEHOLDER: 'Condição',
            MINIMUM: 'Mínimo',
            MAXIMUM: 'Máximo',
          },
          PROMOTION_REGULAR_PRICE_CONDITION: {
            CURRENCY: '$',
            PERCENTAGE: '%',
          },
        },
        RULE_TYPES: {
          PRICE: 'Preço',
        },
        PROMOTIONAL_PROCESS: {
          LIST_TITLE: 'Processos Promocionais',
          NAME: 'Nome',
          CODE: 'Código',
          TASK_QUANTITY: 'Quantidade de tarefas',
          EDIT: 'Editar',
          CANT_EDIT_TOOLTIP: 'O processo promocional não pode ser editado porque possui períodos promocionais atribuídos.',
          CANT_DELETE_TOOLTIP: 'Não é possível excluir o processo promocional, pois ele possui períodos promocionais atribuídos.',
          NEW: 'Novo processo promocional',
          MAIN_DATA_TAB: 'Dados principais',
          TASKS_TAB: 'Tarefas',
          WITHOUT_PERMISSION: 'O usuário não tem permissão para editar.',
          DELETE_ROW: 'Excluir processo promocional',
          DELETE_MODAL_TITLE: 'Deseja excluir o processo promocional{{val}}?',
          TASKS: {
            NAME: 'Nome',
            STARTING_DAY: 'Dia de início',
            BEFORE: 'Antes',
            AFTER: 'Depois',
            TASK_OWNER: 'Responsável',
            CONSULTANTS: 'Consultores',
            DURATION_IN_DAYS: 'Duração',
            REQUIRES_COMPLETION: 'Requer finalização',
            ACTIONABLE: 'Acionável',
            NO_ACTIONABLES: 'Não há acionáveis disponíveis',
            NEW: 'Adicionar tarefa',
            DELETE_MODAL_TITLE: 'Deseja excluir a tarefa {{val}}?',
            CLOSE_ALL: 'Fechar Tudo',
            EXPAND_ALL: 'Expandir Tudo',
            ORDER: 'Ordem',
            ORDER_TOOLTIP: 'Classificar as tarefas pela data de início',
            ACTIONABLES: {
              GeneratePOSAndPeriodReport: 'Publicação automática',
              GeneratePromotionReport: 'Envio automático de saída',
              GeneratePrintingAndLogisticsReport: 'Envio automático de relatório de promoções',
              PublishAllApprovedNewActions: 'Envio automático de listas de produção',
            },
          },
        },
      },
      CAMPAIGNS_LIST: {
        ROUTE: 'Promoções',
        TITLE: 'Campainhas',
        TITLE_PROMOTIONAL_PERIOD: 'Período promocional',
        NEW_CAMPAING_BTN: 'Nova campanha',
        NEW_PROMOTIONAL_PERIOD: 'Novo período promocional',
        NAME: 'Nome',
        CODE: 'Código',
        ACTIONS: null,
        FROM: null,
        TO: null,
        STATE: null,
        USER: null,
        ACTIONSCOUNT: null,
        LAST_MOD: null,
        FULLSCREEN_TTIP: null,
        EXITFULLSCREEN_TTIP: null,
        EXPORT_TTIP: null,
        IMPORT_TTIP: null,
        PRINT_TTIP: null,
        TITLE_FORM: null,
        SAVE_BTN: null,
        BASIC_INFORMATION_TAB: null,
        CODE_INPUT: null,
        NAME_INPUT: null,
        FROM_TTIP: null,
        TO_TTIP: null,
        DESCRIPTION_INPUT: null,
        STATUS_STORES: null,
        STATUS_HISTORY: null,
        STATUS_VIGENT: null,
        STATUS_FUTURE: null,
        STATUS_NOTVALID: null,
        STATUS_EMPTY: null,
        INVALID_TO_DATE: null,
        INVALID_FROM_DATE: null,
        INVALID_TO_DATE_PROMOTIONAL_PERIOD: null,
        INVALID_FROM_DATE_PROMOTIONAL_PERIOD: null,
        INVALID_TO_DATE_CAMPAING: null,
        INVALID_FROM_DATE_CAMPAING: null,
        NAME_TTIP: null,
        ACTIONS_TTIP: null,
        LAST_MOD_TTIP: null,
        SEE_ACTIONS: null,
      },
      CAMPAIGNS_EDIT: {
        SAVE_BTN: null,
        BASIC_INFORMATION_TAB: null,
        CODE_INPUT: null,
        NAME_INPUT: null,
        FROM: null,
        INVALID_TO_DATE_CAMPAING: null,
        INVALID_FROM_DATE_CAMPAING: null,
        TO: null,
        DESCRIPTION_INPUT: null,
        TITLE_FORM: null,
        TITLE_FORM_PROMOTIONAL_PERIOD: null,
      },
      ACTIONS_LIST: {
        EXPORT_TTIP: null,
        FILTER_TTIP: null,
        DELETE_TTIP: null,
        DELETE: null,
        TITLE: null,
        NEW_ACTION: null,
        EDIT_ROW_DISABLED_TOOLTIP: null,
        EDIT_ROW: null,
        DELETE_ROW: null,
        REVALIDATE_ROW: 'Revalidar',
        REVALIDATING_TOOLTIP: 'Revalidar',
        SEE_DELETED_FILTERS: null,
        SEE_CANCELLED_FILTERS: 'Ver Apenas Cancelados',
        SEE_SUGGESTED_FILTERS: 'Ver Apenas Sugestões',
        SEE_SUGGESTED_FILTERS_TOOLTIP: 'Ação Sugerida',
        ACTION_STATUS: 'Estado da Ação:',
        STATUS_VAL: 'Estado e Validação',
        STATUS: 'Estado',
        ACTION_STATUS_VALIDATION: 'Estado de Validação:',
        TYPE_FILTERS: null,
        CREATION_ON: null,
        STORES_FILTER: null,
        ITEM_TAGS: null,
        STORE_TAGS: 'Etiqueta Pontos de Venda',
        ITEM_TYPE_INPUT: null,
        ITEM_TYPE_INPUT_OXXO: 'Processo promocional',
        ITEM_TYPE_MEC_INPUT: 'Mecânica',
        NEW_ITEM_MODAL: null,
        CLONE_ROW: null,
        CANCEL_ROW: null,
        CREATE_BTN: null,
        COPY_ROW: null,
        DISABLED_CLONE_TOOLTIP: null,
        REPORT_TOOLTIP: 'Baixar Relatório',
        REPORT_TOOLTIP_MORE_INFO:
          'Este relatório gera registros com base na configuração da promoção. \nPara gerar os registros, é necessário ter os seguintes campos preenchidos: período, espaços, alcance de lojas e produtos, e configuração do desconto e cobertura aprovada.',
        ACTIONS: {
          ROUTE: null,
          TITLE: null,
          FILTER_TTIP: null,
          FULLSCREEN_TTIP: null,
          EXITFULLSCREEN_TTIP: null,
          NAME: null,
          CODE: null,
          TYPE: null,
          LIFT: null,
          BRAND: null,
          NONE: null,
          TO: null,
          SUPPLIES_LAST: null,
          USER: null,
          LAST_MOD: null,
          STATE: null,
          PROMOTIONAL_SPACES: null,
          PROMOTIONAL_MATERIAL: 'Material Promocional',
          EDIT_PROMOTIONAL_SPACES: 'Editar Material Promocionais',
          EDIT_VALIDATIONS: 'Editar validações de negócios',
          LIST_PROMOTIONAL_SPACES: 'Espaços promocionais selecionados:',
          VALIDATION_STATE: 'Estado de Validação',
          VALID_TO_PUBLISH: null,
          TYPE_OP1: null,
          TYPE_OP2: null,
          TYPE_OP3: null,
          METHODTYPE: null,
          DISCOUNTTYPE: null,
          STOREQUANTITY: null,
          ESTIMATEDSALE: 'Venda estimada',
          FREQUENCYTYPE: null,
          FROM: null,
          FROM_TTIP: null,
          TO_TTIP: null,
          PROMOTINOAL_PERIOD: 'Período Promocional',
          PROMOTIONAL_PERIOD_CODE: 'Código do Período Promocional',
          EXTERNAL_CODE: 'Código Externo',
          PROMOTIONAL_SPACE: 'Espaços promocionais',
          ONLY_ACTIVE_PERIODS: 'Apenas períodos ativos',
          NO_ACTIVE_PERIODS: 'Não há períodos ativos.',
          NO_ACTIVE_PERIODS_IN_SELECTION:
            'Não há períodos ativos no ano e processo promocional selecionado. Verifique no calendário promocional a próxima data para realizar este tipo de atividade.',
          NAVIGATE_TO_CALENDAR: 'Ir para o Calendário',
          CANT_EDIT_VALIDATIONS: 'As validações não podem ser editadas porque o período promocional já foi iniciado/encerrado.',
        },
        TYPE: {
          DISCOUNT: 'Desconto',
          BUYAPAYB: 'Leve A Pague B',
          BUYAGETDISCOUNTINXUNIT: 'Obter Desconto por Unidade',
          SURCHARGE: 'Sobretaxa',
          FIXPRICE: 'Preço Fixo',
          GIFT: 'Presente',
          WHOLESALER: 'Atacadista',
          IMAGECOMMUNICATION: 'Comunicação de Imagem',
        },
        TYPE_DEC: {
          DISCOUNT: null,
          BUYAPAYB: null,
          BUYAGETDISCOUNTINXUNIT: null,
          SURCHARGE: null,
          FIXPRICE: null,
          GIFT: null,
          WHOLESALER: null,
          IMAGECOMMUNICATION: 'Destaque novos produtos ou o preço regular tão atraente',
        },
        METHODTYPE: {
          ACTIVATEONLYDURINGPROMOTION: null,
          ACTIVATEINDEFINITELY: null,
          DONOTACTIVATE: null,
        },
        DISCOUNTTYPE: {
          ABSOLUTEVALUE: null,
          PERCENTAGE: null,
        },
        FREQUENCYTYPE: {
          EVERYDAY: null,
          CERTAINDAYSOFTHEWEEK: null,
          CERTAINDAYSOFTHEMONTH: null,
        },
        CLONE: {
          MODAL_CONTENT: null,
          CREATE_BTN: null,
          CANCEL: null,
        },
        CANCEL: {
          MODAL_CONTENT: null,
          CREATE_BTN: null,
          CANCEL: null,
        },
      },
      ACTIONS_TYPES: {
        Discount: 'Desconto Direto',
        BuyAPayB: 'Leve A Pague B',
        BuyAGetDiscountInXUnit: 'Obter Desconto por Unidade',
        Surcharge: 'Sobretaxa',
        FixPrice: 'Preço Fixo',
        Gift: 'Presente',
        Wholesaler: 'Atacadista',
        ImageCommunication: 'Comunicação de Imagem',
      },
      ACTIONS_EDIT: {
        TEST: null,
        SAVE: null,
        PUBLISH: null,
        CANCEL_ACTION: 'Cancelar',
        OK: 'Ok',
        WARNING_DOWNLOAD: 'É necessário ter apenas um período promocional selecionado nos filtros.',
        WARNING_SAVE: null,
        WARNING_PUBLISH: null,
        NOVALID_PUBLISH_ADVICE: null,
        PROMOTION_EDIT_DISABLED: 'Não é possível editar a promoção porque o período promocional selecionado já começou ou terminou.',
        BASIC_INFORMATION_TAB: null,
        ACTIVATION_TAB: null,
        LOADING: null,
        SCOPE_OF_ITEMS: null,
        PAYMENTMETHODS: null,
        CUSTOMFIELDS: 'Informação Adicional',
        SCOPE_OF_STORES: null,
        GIFT_ITEM: null,
        GIFT_ITEM_TAB: null,
        NEW_ITEM_BTN: null,
        PUBLISH_CONFIRM: null,
        PUBLISH_CONFIRM_TITLE: null,
        PUBLISHING_WARNING: null,
        PUBLISH_LONG_TIME: null,
        INVALID_TO_TIME: null,
        INVALID_FROM_TIME: null,
        STRATEGY_ATTR_TITLE: null,
        STRATEGY_ATTR_MSG: null,
        STRATEGY_PROD_TITLE: null,
        STRATEGY_PROD_MSG: null,
        STRATEGY_ATTR_TITLE_STORE: null,
        STRATEGY_ATTR_MSG_STORE: null,
        STRATEGY_STORE_TITLE_STORE: null,
        STRATEGY_STORE_MSG_STORE: null,
        CLIPBOARD_STORE_TITLE: null,
        CODE_AND_DESCRIPTION_INPUT: null,
        CLIPBOARD_PRODUCT_TITLE: null,
        DELETE_TIME_RANGE: null,
        ADD_TIME_RANGE: null,
        OVERLAP_TIME_RANGE: null,
        SAVE_LONG_TIME: 'Confirmar Salvamento',
        SAVE_LONG_TIME_MSG_A:
          'Salvar esta promoção pode levar alguns minutos. Ela será validada e você será notificado quando o processo for concluído.',
        SAVE_LONG_TIME_MSG_B:
          'Até que o processo de validação seja concluído, a promoção não poderá ser modificada novamente.<br><br><b>Tem certeza de que deseja Salvar?</b>',
        SAVE_PVD_MSG:
          'Eu concordo que minha ação promocional se aplique a todos os meus <br> produtos e pontos de venda. (Estou ciente de que isso pode resultar em um tempo de espera maior no processo de validação.)',
        SAVE_STORE_SCOPE_PVD_MSG:
          'Eu concordo que minha ação promocional se aplique a todos os meus <br> pontos de venda. (Estou ciente de que essa situação pode resultar em um tempo de espera maior no processo de validação.)',
        SAVE_ALL_ITEMS_SELECTED_WARNING:
          'O alcance do produto definido corresponde a 100% dos produtos, defina um escopo específico para salvar.',
        PROCESS_WARNING_TITLE: 'A ação requerida não pode ser completada',
        PROCESS_WARNING_MSGLONGTIME: 'A publicação e/ou salvamento não podem ser concluídos pelos seguintes motivos:',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_1:
          'A promoção está em Processo de Validação, portanto não é possível salvar ou publicar até que seja concluído.',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_2: 'A promoção está em estado de Validação Rejeitada, portanto não é possível publicar.',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_3: 'A promoção está em estado de Validação Cancelada, portanto não é possível publicar.',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_4:
          'A promoção está pendente de Ação Requerida, portanto não é possível publicar até que a ação seja resolvida.',
        COVERAGE_TITLE: 'Cobertura',
        MAIN_DATA: {
          NAME: null,
          CODE: null,
          EXTERNAL_CODE: null,
          MESSAGE: null,
          REGENERATE: 'Regenerar',
          REGENERATE_TOOLTIP: 'Uma mensagem é gerada com os primeiros {{val}} caracteres do nome da ação promocional, excluindo os preços.',
          INTERNAL_CODE: null,
          VALIDATION_CODE2: null,
          SINCE_INPUT: null,
          TO_INPUT: null,
          OBSERVATION: null,
          TITLE_FRECUENCY: null,
          TIME_FROM: null,
          TIME_TO: null,
          CAMPAING: null,
          PROMOTIONAL_PERIOD: null,
          SET_PROMOTIONAL_PERIOD: null,
          PROMOTIONAL_MATERIAL: 'Material Promocional',
          GIFT_ITEM: null,
          ACCUMULATE_ACTIONS: null,
          GIFT_ITEM_POPOVER_MSG: null,
          GIFT_ITEM_NEW: null,
          GIFT_ITEM_NEW_POPOVER_MSG: null,
          SELECTTIMERANGE: null,
          SELECTTIMERANGE_TEXT: null,
          PLANOGRAMS_WARNING_PRODUCT_SCOPE: 'Selecione o Escopo do Produto para poder editar.',
          PLANOGRAMS_WARNING_STORE_SCOPE: 'Selecione o Escopo da Loja para poder editar.',
          PLANOGRAMS_WARNING_SAVE: 'Salve as alterações para poder editar.',
          PLANOGRAMS_WARNING_NO_PLANOGRAMS: 'Não há planogramas no escopo de lojas selecionado.',
          PLANOGRAMS_WARNING_ALL_ITEMS: 'Defina um escopo específico de produtos para obter o material promocional disponível.',
          PLANOGRAMS_WARNING_MAIN_DATA: 'Preencha os campos obrigatórios para poder salvar as alterações e editar.',
          PLANOGRAMS_WITHOUT_POP_MATERIAL: 'Sem material promocional',
          PLANOGRAMS_DELETED: '{{val}} materiais promocionais previamente selecionados não estão mais disponíveis.',
          PERIOD_WARNING_INVALID_FROM_TODAY: 'A data de início é anterior à data atual',
          PERIOD_WARNING_INVALID_FROM_TASK: 'A primeira tarefa de planejamento para o período selecionado deveria ter começado em',
          PERIOD_WARNING_INVALID_FROM_TO: 'A data de início não pode ser posterior à data de término',
          ESTIMATEDSALE: 'Venda estimada',
          TICKET_MESSAGE: {
            MODAL_TITLE: 'Deseja regenerar a mensagem do ticket?',
            PRICE: 'Preços não são permitidos na mensagem.',
            SPECIALCHARACTERS: 'Caracteres especiais não são permitidos na mensagem.',
            DOUBLESPACES: 'Espaços duplos não são permitidos na mensagem.',
            MAXCHARACTER: 'Você atingiu o número máximo de caracteres permitido para a mensagem.',
          },
        },
        VALIDATIONS: {
          VALIDATIONS_TITLE: 'Validações',
          COVERAGE_TOOLTIP:
            'Para aprimorar esta validação, sugerimos ajustar o escopo de lojas ou produtos, verificando a cobertura por meio de etiquetas antecipadamente e analisando o estado atual da cobertura por diferentes conjuntos de pontos de venda.',
          MAX_SLOTS_TOOLTIP:
            'Para aprimorar esta validação, sugerimos ajustar o escopo de lojas ou modificar os materiais promocionais utilizados.',
          FIX_AND_REGULAR_PRICE_TOOLTIP:
            'Para aprimorar os resultados desta validação, sugerimos ajustar o preço promocional a partir da guia de ativação.',
          STORE_AND_PROMOTIONAL_SPACE:
            'Para aprimorar esta validação, sugerimos ajustar o escopo de lojas ou modificar os materiais promocionais utilizados.',
          FIX_PRICE_EXCEPTIONS: 'Para melhorar esta validação, sugerimos ajustar as exceções de preços no importador de exceções.',
          REFERENCE_PRICE: 'Para melhorar esta validação, sugerimos ajustar o preço promocional na aba de ativação.',
          ACTION_BUTTON_DISABLED_TOOLTIP: 'As alterações só são permitidas quando a ação promocional está publicada.',
          TAB_TITLE: 'Validações de Negócio',
          TAB_DESCRIPTION:
            'As validações de negócio são usadas para garantir que a ação promocional atenda aos critérios necessários para execução. No caso de uma validação encontrar erros, ela pode ser aprovada ou rejeitada ao publicar ou modificar preventivamente os dados envolvidos para obter novos resultados. Através de relatórios, podemos obter informações detalhadas sobre as falhas, e se todos os dados necessários não estiverem disponíveis para realizar a validação, ela será relatada como incompleta. É necessário ter todas as validações aprovadas e validadas para obter relatórios produtivos.',
          storesAndPromotionalSpace: {
            VALIDATION_NAME: 'Escopo da Loja vs Material Promocional',
            VALIDATION_DESCRIPTION:
              'Valida se alguma loja configurada para esta promoção não possui os material promocionais selecionados.',
            VALIDATION_ERROR: '{{val}} lojas configuradas para esta promoção não possuem alguns dos material promocionais selecionados.',
            VALIDATION_SUCCESS: 'Todos os material promocionais selecionados estão disponíveis nas lojas da promoção.',
            VALIDATION_INCOMPLETE:
              'Não é possível determinar o impacto nas lojas porque os material promocionais ou o escopo das lojas para esta promoção não foram selecionados.',
          },
          MaxSlots: {
            VALIDATION_NAME: 'Quantidade Máxima de Promoções por Material Promocional',
            VALIDATION_DESCRIPTION:
              'Valida se o limite máximo de promoções suportadas em algum dos materiais promocionais selecionados foi excedido.',
            VALIDATION_ERROR: 'Esta promoção excede a capacidade máxima suportada por {{val}} espaço-lojas.',
            VALIDATION_SUCCESS: 'A promoção foi atribuída com sucesso aos materiais promocionais selecionados.',
            VALIDATION_INCOMPLETE:
              'A quantidade máxima de promoções não foi calculada porque os materiais promocionais não foram selecionados.',
          },
          FixAndRegularPrice: {
            VALIDATION_NAME: 'Preço Promocional vs Preço Regular',
            VALIDATION_DESCRIPTION:
              'Valida que o preço promocional fixado seja menor que o preço regular naquela loja, garantindo que seja verdade para todas as lojas onde esta promoção se aplica.',
            VALIDATION_ERROR: 'O preço promocional foi encontrado maior ou igual ao preço regular em {{val}} produto-lojas.',
            VALIDATION_SUCCESS: 'O preço promocional é menor que o preço regular em todos os produto-lojas desta promoção.',
            VALIDATION_INCOMPLETE:
              'Não é possível determinar se o preço promocional está correto porque produtos ou lojas em seus respectivos escopos não foram selecionados.',
          },
          FixPriceExceptions: {
            VALIDATION_NAME: 'Exceção de preço vs preço regular',
            VALIDATION_DESCRIPTION:
              'Valida que o preço promocional definido no importador de exceções de preço seja menor que o preço regular para cada loja do escopo de lojas definido no importador.',
            VALIDATION_ERROR:
              'O preço promocional é maior ou igual ao preço regular em {{val}} produtos-lojas nas exceções desta promoção.',
            VALIDATION_SUCCESS: 'O preço promocional é menor que o preço regular em todos os produtos-lojas desta promoção.',
            VALIDATION_INCOMPLETE:
              'Para validar esta ação promocional é necessário definir escopo de produtos, escopo de lojas e importar pelo menos 1 exceção de preço corretamente.',
          },
          Coverage: {
            VALIDATION_NAME: 'Cobertura Mínima',
            VALIDATION_DESCRIPTION: 'Valida se a cobertura mínima é alcançada.',
            VALIDATION_ERROR: 'A cobertura mínima definida de {{val}}% não é atingida.',
            VALIDATION_SUCCESS: 'A cobertura mínima definida de {{val}}% é atingida.',
            VALIDATION_INCOMPLETE:
              'A cobertura não foi calculada porque produtos ou lojas em seus respectivos escopos não foram selecionados.',
          },
          ReferencePrice: {
            VALIDATION_NAME: 'Preço promocional vs Preço de caminhão',
            VALIDATION_DESCRIPTION:
              'Valida que o preço promocional cumpre com a regra de preços definida, garantindo que se aplique a todas as lojas onde esta promoção é válida.',
            VALIDATION_ERROR:
              'O preço promocional não cumpre com a regra de preço de caminhão para todos os produtos-lojas desta promoção.',
            VALIDATION_SUCCESS: 'O preço promocional cumpre com a regra de preço de caminhão para todos os produtos-lojas desta promoção.',
            VALIDATION_INCOMPLETE:
              'Não é possível determinar se o preço promocional está correto porque precisa ser carregado na aba de ativação.',
          },
        },
        DOWNLOAD_VALIDATION_REPORT: 'Baixar Relatório de Validações',
        VALIDATION_NO_DATA: 'Não há dados suficientes para validar',
        ACTIVATION: {
          ASSORTMENTMETHOD: null,
          CHANNELCODES: null,
          LOYALTYPROGRAMCODES: null,
          LOYALTYPROGRAMCODES_GLOBAL: 'Restrição para Programas de Fidelidade',
          LOYALTYPROGRAMCODE: 'Programa de Fidelidade',
          LOYALTY_PROGRAM_CODE_EXC: 'Programa de Fidelidade Excepcional',
          LOYALTY_PROGRAM_MODAL_ADVICE_TITLE: 'Deseja excluir as exceções de preço carregadas?',
          LOYALTY_PROGRAM_MODAL_ADVICE_SUBTITLE:
            'A ação promocional possui exceções de preço que serão excluídas caso sejam aplicadas restrições para o programa de fidelidade.',
          BUY_A_QUANT: null,
          BUY_B_QUANT: null,
          BUY_B_QUANT_DESC: null,
          MIN_QUANT: null,
          SAMEITEM: null,
          UNTILSTOCKLAST: null,
          MIN_VALUE: null,
          MIN_VALUE_TICKET: null,
          DISCOUNT: null,
          LOYALTY_DISCOUNT: 'Desconto Excepcional por Fidelidade',
          LOYALTY_FIXED_PRICE: 'Preço Fixo Excepcional por Fidelidade',
          SURCHARGE: null,
          DISCOUNTTYPE: null,
          SURCHARGETYPE: null,
          FIXPRICE: null,
          FIXPRICE_TOOLTIP:
            "Indica o preço promocional para a quantidade total de produtos carregados. Exemplo: Se a ação é '3 x R$40', a Quantidade Mínima de produtos é 3 e o preço fixo a ser aplicado é o final, neste caso, R$40.",
          SHIPPINGDISCOUNT: null,
          MAXIMUMDISCOUNTVALUE: null,
          COMMUNICATIONTYPE: null,
          COMMUNICATIONDISPLAY: 'Ordem de Comunicação',
          COMMUNICATIONDISPLAY_TOOLTIP: 'Ordem de Comunicação: Permite definir a ordem em que o texto é gerado para a lista de produção.',
          EXCEPTIONAL_FIXED_PRICE_TITLE: 'Carga de Preço Fixo Excepcional',
          EXCEPTIONAL_DISCOUNT_TITLE: 'Carga de Desconto Excepcional',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE: 'Você pode carregar exceções de preços através do importador',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE_NO_ID:
            'Você pode carregar exceções de preços através do importador. É necessário salvar as alterações atuais.',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE_NO_ID_ALT:
            'Você pode carregar exceções de preços através do importador. Permite carregar exceções a nível de loja ou etiqueta (somente um dos dois níveis pode ser utilizado para todo o importador). Cada importação substitui a importação anterior. É necessário salvar as alterações atuais.',
          EXCEPTIONAL_DISCOUNT_SBUTITLE: 'Você pode carregar exceções de descontos através do importador',
          EXCEPTIONAL_DISCOUNT_SBUTITLE_ALT:
            'Você pode carregar exceções de descontos através do importador. Permite carregar exceções a nível de loja ou etiqueta (somente um dos dois níveis pode ser utilizado para todo o importador). Cada importação substitui a importação anterior.',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE_ALT:
            'Você pode carregar exceções de preços através do importador. Permite carregar exceções a nível de loja ou etiqueta (somente um dos dois níveis pode ser utilizado para todo o importador). Cada importação substitui a importação anterior.',
          EXCEPTIONAL_DISCOUNT_SBUTITLE_NO_ID:
            'Você pode carregar exceções de descontos através do importador. É necessário salvar as alterações atuais.',
          LOYALTYPROGRAMCODES_GLOBAL_TOOLTIP:
            'Restringir opcionalmente a execução da ativação para o(s) programa(s) de fidelidade selecionado(s).',

          COMMUNICATIONTYPE_TOOLTIP: null,
          LOYALTY_DISCOUNT_TOOLTIP:
            'Permite opcionalmente conceder um valor de desconto diferente e exclusivo para o programa de fidelidade selecionado.',
          LOYALTY_FIXED_PRICE_TOOLTIP:
            'Permite opcionalmente conceder um valor de preço fixo diferente e exclusivo para o programa de fidelidade selecionado.',
          LOYALTY_FIXED_PRICE_TOOLTIP_ALT:
            'Indica o preço promocional para o programa de fidelidade selecionado, respeitando a quantidade total de produtos carregados. Exemplo: Se a ação é "3 x R$30 para o programa de fidelidade", a Quantidade Mínima de produtos é 3 e o preço fixo excepcional por fidelidade a ser carregado é o final, neste caso, R$30. Esse preço se aplica a todas as lojas sem exceção.',

          ActionsV2_communicationType: {
            CommunicateDiscount: null,
            CommunicatePrice: null,
          },
          ActionsV2_communicationDisplay: {
            CommunicateFirstValue: 'Valor Promocional + Nome do Produto',
            CommunicateFirstName: 'Nome do Produto + Valor Promocional',
          },
          COMMUNICATION: {
            PROMOTIONAL_MESSAGE: 'Mensagem promocional',
            PROMOTIONAL_MESSAGE_TOOLTIP:
              'Este campo permite visualizar rapidamente um exemplo da mensagem promocional obtida em relatórios produtivos',
            PROMOTIONAL_MESSAGE_WARNING:
              'A ação promocional não apresenta dados suficientes para gerar a mensagem promocional, complete todos os dados necessários',
            SUPPORT_TEXT: 'Texto de apoio',
            SUPPORT_TEXT_TOOLTIP: '',
          },
          IMPORT_LIST: {
            valuePrice: 'Preço Fixo $',
            valueDiscount: 'Desconto',
            tagValueId: 'Etiqueta {{val}}',
            storeId: 'Loja',
            storeName: 'Loja',
            storeScope: 'Alcance de pontos de venda',
            regularPrice: 'Preço Regular $',
            discount: 'Desconto Estimado %',
            comboId: 'Combo ID',
          },
          COMBOS: {
            DELETE_BLOCK: 'Excluir bloco',
            EDIT_ITEMS: 'Editar produtos',
            EDIT_NAME_STAGGERED: 'Ao editar o nome deste combo, a alteração será aplicada a todos os combos.',
            EDIT_NAME_MIXED: 'Editar nome',
            EDIT_QUANTITY: 'Editar quantidade',
            ADD_COMBO: 'Adicionar combo',
            ADD_STAGGERED_COMBO: 'Adicionar combo escalonado',
            COMBO_ITEMS: 'Produtos{{val}}',
            COMBO_ITEMS_EDIT: 'Editar produtos',
            ADD_BLOCK: 'Adicionar bloco',
            COPY_IDS: 'Copiar IDS',
            COPY_ID: 'Copiar ID',
            EDIT_STAGGERED_COMBO: 'Editar Combo Escalonado',
            DELETE_COMBO: 'Deseja excluir o combo?',
            CREATE_COMBO: 'Criar Combo',
            CREATE_STAGGERED_COMBO: 'Criar Combo Escalonado',
            CLONE_COMBO: 'Clone Combo',
            DELETE_BLOCK_TITLE: 'Você deseja excluir o bloco {{val}}?',
            NAME_INPUT_VALIDATION_MESSAGES: {
              DUPLICATE_NAMES: 'Os blocos não podem ter o mesmo nome.',
              PRICE: 'Preços não são permitidos no nome.',
              SPECIALCHARACTERS: 'Caracteres especiais não são permitidos no nome.',
              DOUBLESPACES: 'Espaços duplos não são permitidos no nome.',
              MAXCHARACTER: 'O máximo de caracteres permitidos para o nome foi atingido.',
              DUPLICATE_ITEMS: 'Itens duplicados não são permitidos entre blocos.',
            },
          },
          DISCOUNT_AND_REGULAR_PRICE: 'Preço regular ${{val}}. Desconto estimado {{val2}}%.',
          DISCOUNT_TOOLTIP_TITLE: 'Desconto estimado sobre preço fixo',
          DISCOUNT_TOOLTIP_DESCRIPTION:
            'Um desconto estimado é definido ao comparar o preço regular e o preço fixo. O preço regular é definido como a moda, o preço mais frequente, existente no alcance de lojas para esta promoção.<br><br> Caso a promoção inclua mais de um produto ou um combo tenha mais de um produto em cada bloco, é utilizado o menor preço moda entre os produtos participantes da ação promocional ou bloco.',
          STORES_WITHOUT_REGULAR_PRICE: 'Não há preço regular nas lojas selecionadas para calcular o desconto.',
          DISCOUNT_WARNING_MESSAGE: 'Para ver o novo desconto aplicado, é necessário salvar.',
          WITHOUT_POP_MATERIAL_TOOLTIP_TITLE: 'Sem material promocional',
          WITHOUT_POP_MATERIAL_TOOLTIP_DESCRIPTION:
            'Ativo: Não é necessário selecionar um material promocional para publicar a ação promocional, e as validações que dependem dessa informação serão automaticamente validadas. O relatório de promoções não incluirá espaço publicitário, e esta ação promocional não fará parte dos resultados dos relatórios relacionados ao material promocional.<br><br> Não ativo: É necessário selecionar pelo menos um material promocional. A ação promocional fará parte dos relatórios relacionados ao material promocional, e as validações que dependem dessa informação deverão ser verificadas.',
        },
        SCOPE_ITEM: {
          IMPORT: null,
          RADIO_SIMPLE_PRODUCT: 'Produto Simples',
          RADIO_MULTIPLE_PRODUCT: 'Produtos Múltiplos',
          RADIO_STAGGERED_PRODUCT: 'Promoções Escalonadas',
          DESCRIPTION_SIMPLE_PRODUCT:
            'Selecione os produtos que participam da promoção. Escolha um único tipo ou combine como desejar. Todos os produtos terão o mesmo preço promocional.',
          DESCRIPTION_MULTIPLE_PRODUCT:
            'Crie um ou mais conjuntos de produtos que participam da promoção. Defina a quantidade e os produtos que fazem parte de cada componente.',
          DESCRIPTION_STAGGERED_PRODUCT:
            'Crie um conjunto de produtos para definir a promoção base e variantes, diferenciando apenas na quantidade de seus componentes. Cada conjunto terá um preço promocional diferente.',
          WARNING_MESSAGE:
            'Ao modificar o escopo dos produtos, o Material Promocional atribuído a esta ação promocional será removido. Você precisará reatribuí-los após salvar as alterações.',
          WARNING_IMPORTANT_MESSAGE: 'Aviso Importante:',
        },
        SCOPE_STORE: {
          IMPORT: null,
          WARNING_MESSAGE:
            'Ao modificar o escopo das lojas, os espaços promocionais atribuídos a esta ação promocional serão excluídos. Você precisará reatribuí-los após salvar as alterações.',
          WARNING_IMPORTANT_MESSAGE: 'Aviso Importante:',
        },
        ActionsV2_FrequencyType: {
          EveryDay: null,
          CertainDaysOfTheWeek: null,
          CertainDaysOfTheMonth: null,
        },
        ActionsV2_AssortmentType: {
          ActivateOnlyDuringPromotion: null,
          ActivateIndefinitely: null,
          DoNotActivate: null,
        },
        ActionsV2_DiscountType: {
          AbsoluteValue: null,
          Percentage: null,
        },
        PAYMENTS: {
          ADD_BLOCK: null,
          PAYMENT_BLOCK: null,
          FINANCIAL_INSTITUTION: 'Instituição Financeira',
          CARD_TYPE: 'Tipo de Cartão',
          PAYMENT_BLOCK_OPT: null,
          BANK: null,
          INSTALLMENTS: null,
          LOYALTYPROGRAMPOINTS: null,
          LOYALTYPROGRAMPOINTSEQUIVALENCEINCASH: null,
          SPONSORSHIP: null,
          PAYMENT_BLOCK_COMBO: null,
          VALUE: null,
          ADDSUBCOMBINATION: null,
          REMOVECOMBINATION: null,
          REMOVESUBCOMBINATION: null,
          TYPE: null,
          ALL: null,
          DEBIT: 'Débito',
          CREDIT: 'Crédito',
          NONE: null,
          ADD_COMBINATION_BLOCK: null,
          HELP_PAYMENT1: null,
          DUPLICATE_WARNING: null,
          DUPLICATE_COMB_WARNING: null,
          PAYMENT_ALL: null,
          PAYMENT_NO_COMB: null,
          PAYMENT_COMB: null,
          PAYMENT_ALL_DESC: null,
          PAYMENT_NO_COMB_DESC: null,
          PAYMENT_COMB_DESC: null,
          NO_ITEMS: null,
          NO_ITEMS_COMB: null,
        },
        GIFT_ITEMS: {
          SIZE: null,
          SIZE_HELP: null,
          GIF_SELECTION_NO_MANUAL_ITEMS_SELECTED: null,
        },
        PUBLISH_CONTENT: null,
      },
      ACTIONS_STATUS: {
        NEW: null,
        ENDORSED: null,
        INFORMED: null,
        DELETED: null,
        MODIFIED: null,
        CURRENT: null,
        CANCELLED: null,
      },
      ACTIONS_VALIDATION_STATUS: {
        APPROVED: 'Aprovado',
        VALIDATING: 'Validando',
        ACTIONREQUIRED: 'Ação Requerida',
        REJECTED: 'Rejeitado',
        PENDING: 'Pendente',
      },
      HELP: {
        QUANTITY_BUY_INPUT: null,
        QUANTITY_PAY_INPUT: null,
        FRECUENCY_INPUT: null,
        DAYS_INPUT: null,
        DATE_FROM_INPUT: null,
        DATE_TO_INPUT: null,
        TIME_FROM_INPUT: null,
        TIME_TO_INPUT: null,
        TICKET_MESSAGE_INPUT: null,
        OBSERVATION_INPUT: null,
        UNIT_DISCOUNT_INPUT:
          'Indique o valor do desconto a ser aplicado sobre o preço regular total da promoção. Exemplo: $5 de desconto ao comprar 2 produtos A, se cada produto tem um preço regular de $10. (2x$10) -$5 de desconto. Preço promocional final $15.',
        DISCOUNT_RATE_INPUT: null,
        SUPPLIES_LAST_CHECK: null,
        UNITS_AVAILABLE_INPUT: null,
        DISCOUNT_VALUE_INPUT: null,
        UNIT_DISCOUNT_PER_INPUT: null,
        QUANTITY_UNITS_INPUT: null,
        MINIMUM_AMOUNT_INPUT: null,
        MAXIMUM_AMOUNT_INPUT: null,
        MINIMUM2_AMOUNT_INPUT: null,
        MAXIMUM2_AMOUNT_INPUT: null,
        ASSORTMENTMETHOD: null,
        MINIMUM_AMOUNT_INPUT_GROUP: null,
        SAME_ITEM: null,
        COMPLETE_COVERAGE:
          'Ativar a cobertura completa gera mudanças no cálculo, indicando que a loja atende à cobertura somente se todos os produtos no escopo desta promoção estiverem ativos no sortimento da loja. Se estiver desativado, mas pelo menos um produto estiver ativo na loja, a cobertura será atendida. <br><br> Se o produto for do tipo combo, esteja ativo ou não, é necessário ter pelo menos 1 variante de cada componente do combo ativa para que a loja atenda à cobertura.',
        COVERAGE_BASED_COMMUNICATION:
          'Ativo: Os mensagens promocionais variam com cada mudança na cobertura da ação promocional nas lojas do escopo. <br><br> Inativo: Independentemente da cobertura, todas as lojas dentro do escopo recebem o mesmo mensagem promocional.',
        COMMUNICATE_REGULAR_PRICE:
          'Se ativado, os relatórios calcularão o preço regular do produto para cada loja dentro do escopo definido.<br> Se desativado, a mensagem a ser comunicada não incluirá um preço.',
      },
      PAY_METHODS: {
        PAY_METHOD_Money: null,
        PAY_METHOD_LoyaltyPoints: null,
      },
      PRODUCT_LIST: {
        TITLE: 'Relatório de imprensa',
        DOWNLOAD: 'Baixar Relatório',
        GENERATE: 'Gerar Relatório',
        LAST: 'Último Relatório Gerado',
        PERIODS: 'Períodos',
        VENDORS: 'Impressoras',
        ERROR_REPORT: 'O relatório não pôde ser gerado corretamente',
      },
      PROMOTION_OUTPUT: {
        TITLE: 'Dados para PDV',
        DOWNLOAD: 'Baixar dados',
        GENERATE: 'Gerar dados',
        LAST: 'Último relatório gerado',
        PERIODS: 'Períodos',
        ERROR_REPORT: 'O relatório não pôde ser gerado corretamente',
      },
      LOGISTIC_REPORT: {
        TITLE: 'Relatório de Logística',
        DOWNLOAD: 'Baixar Relatório',
        GENERATE: 'Gerar Relatório',
        LAST: 'Último Relatório Gerado',
        PERIODS: 'Períodos',
        VENDORS: 'Fornecedores',
        ERROR_REPORT: 'O relatório não pôde ser gerado corretamente',
      },
      PROMOTION_REPORT: {
        TITLE: 'Relatório de Promoções',
        DOWNLOAD: 'Gerar Relatório',
        LAST: 'Último Relatório Gerado',
        PERIODS: 'Períodos',
        VENDORS: 'Fornecedores',
        ERROR_REPORT: 'O relatório não pôde ser gerado corretamente',
      },
      MARKETPLACE_LIST: {
        EXPORT_TTIP: 'exportar',
        FILTER_TTIP: 'Filtrar',
        DELETE_TTIP: 'eliminar',
        DELETE: 'eliminar',
        DELETE_ROW: 'Eliminar',
        EDIT_ROW: 'Editar',
        TITLE: 'Marketplace',
        PROMOTION_TYPE: 'Tipo de Promoção',
        YEAR: 'Ano',
        CLONE_ALL_PERIODS: 'Clonar períodos',
        CLONE_CONTENT: 'Clonar todos os períodos do primeiro campo Tipo de Promoção para o segundo para o ano indicado',
        CLONE_TITLE: 'Clonar períodos por tipo de promoção',
        PROMOTION_TYPE_FROM: 'Clonar períodos do processo promocional de',
        PROMOTION_TYPE_TO: 'Clonar períodos do processo promocional para',
        NEW_PERIOD: 'Novo',
        EDIT_ROW_DISABLED_TOOLTIP: 'Não é possível editar um registro em processo de publicação',
        SEE_DELETED_FILTERS: 'Ver eliminados',
        CREATION_ON: 'Data de criação',
        CLONE: 'Clonar Períodos',
        COPY: 'Copiar Períodos',
        NAME: 'Nome',
        ROUTE: 'Marketplace',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reduzir',
        STORENAME: 'Nome da Loja',
        CODE: 'Código',
        STATUS: 'Status',
        VIEW_MATERIAL_POP: 'Ver material promocional',
        DISABLE_STATUS: 'Filtro desativado por utilizar filtro `Contratos prestes a expirar`',
        STATUS_AVAILABLE: 'Disponível',
        STATUS_RESERVED: 'Reservado',
        STATUS_RENTED: 'Alugado',
        RESERV: 'Reservar',
        RENT: 'Alugar',
        POPMATERIAL: 'Material Promocional',
        CADUCATE: 'Contratos prestes a expirar',
        CADUCATE_1MONTH: 'Dentro de 1 Mês',
        CADUCATE_2MONTH: 'Dentro de 2 Meses',
        CADUCATE_3MONTH: 'Dentro de 3 Meses',
        ADVANCED_SEARCH: 'Filtros Avançados',
        INTERNAL_USE: 'Uso interno',
        CONTRACT: 'Gerenciar Contrato',
        CONTRACT_CONTENT: 'Reservar ou alugar {{val}}. Atribuindo datas e preço para o fornecedor selecionado',
        VENDOR: 'Fornecedor',
        PRICE: 'Preço',
        STORECOUNT: '# Lojas',
        OCUPATION: 'Ocupação',
        SPACE: 'Espaço',
        VALIDFROM: 'Data de início',
        VALIDTO: 'Data de término',
      },
      PERIODS_LIST: {
        EXPORT_TTIP: 'exportar',
        FILTER_TTIP: 'Filtrar',
        DELETE_TTIP: 'eliminar',
        DELETE_MODAL_TITLE: 'Deseja excluir o período promocional{{val}}?',
        CANT_DELETE_TTIP: 'O período promocional não pode ser excluído porque existem ações promocionais que o utilizam.',
        DELETE: 'eliminar',
        DELETE_ROW: 'Eliminar',
        IS_CANCELING: 'excluindo',
        EDIT_ROW: 'Editar',
        TITLE: 'Períodos Promocionais',
        PROMOTION_TYPE: 'Tipo de Promoção',
        PROMOTION_TYPE_PROCESS: 'Promotional process',
        YEAR: 'Ano',
        YEAR_FROM: 'Ano De',
        YEAR_TO: 'Ano Para',
        CLONE_ALL_PERIODS: 'Clonar períodos',
        CLONE_CONTENT: 'Clonar todos os períodos do primeiro campo Tipo de Promoção para o segundo para o ano indicado',
        CLONE_TITLE: 'Clonar períodos por tipo de promoção',
        PROMOTION_TYPE_FROM: 'Clonar períodos de tipo de promoção de',
        PROMOTION_TYPE_TO: 'Clonar períodos de tipo de promoção para',
        NEW_PERIOD: 'Novo período promocional',
        EDIT_ROW_DISABLED_TOOLTIP: 'Não é possível editar um registro em processo de publicação',
        SEE_DELETED_FILTERS: 'Ver eliminados',
        CREATION_ON: 'Data de criação',
        CLONE: 'Clonar Períodos',
        COPY: 'Copiar Períodos',
        ROUTE: 'Períodos Promocionais',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reduzir',
        NAME: 'Nome',
        CODE: 'Código',
        RESPONSIBLEROLEID: 'Responsável',
        OBSERVATIONS: 'Observações',
        CONSULTEDROLEID: 'Consultor',
        STARTINGDAYOFWEEK: 'Dias de início',
        DURATIONINDAYS: 'Duração em dias',
        ORDER: 'Ordem',
        VALIDFROM: 'Data de início',
        VALIDTO: 'Data de término',
        EXTRAPLANNINGDAYS: 'Dias extras de planejamento',
        ISSPECIALEVENT: 'Evento especial',
        VALIDATION_CODE: 'O código {{val}} já existe',
        EDIT_ERROR: 'Erro ao editar: {{val}}',
      },
      CALENDAR_LIST: {
        EXPORT_TTIP: 'exportar',
        FILTER_TTIP: 'Filtrar',
        DELETE_TTIP: 'eliminar',
        DELETE: 'eliminar',
        TITLE: 'Calendário',
        MODIFY: 'Modificar',
        CHANGE_DATE_TITLE: 'Modificar Data',
        CHANGE_DATE_CONTENT: 'Modificar a data desta atividade',
        EDIT_ROW_DISABLED_TOOLTIP: 'Não é possível editar um registro em processo de publicação',
        ONLY_CURRENT_PERIODS: 'Apenas períodos ativos.',
        ACTIONS: {
          NAME: 'Descrição',
          RESPONSIBLEROLEID: 'Responsável',
          CONSULTEDROLEID: 'Consultor',
          OBSERVATIONS: 'Observações',
          STARTTASKXDAYSBEFORE: 'Dia de início',
          DURATIONINDAYS: 'Duração em dias',
        },
      },
      PERIODS_EDIT: {
        SAVE: 'Salvar',
        PUBLISH: 'Publicar',
        WARNING_SAVE: 'As alterações atuais precisam ser salvas.',
        WARNING_PUBLISH: 'Republicar é necessário para que as alterações salvas tenham efeito.',
        NOVALID_PUBLISH_ADVICE: 'A guia a seguir não é válida:',
        BASIC_INFORMATION_TAB: 'Informações Básicas',
        ACTIVATION_TAB: 'Ativação',
        LOADING: 'Carregando...',
        NEW_ITEM_BTN: 'Nova Ação Promocional',
        PUBLISH_CONFIRM:
          'O processo de publicação inclui a criação de Sortimento, Preços, Custos e outros registros relacionados a esta ação.<br><br><b>Você tem certeza de que deseja Publicar?</b>',
        PUBLISH_CONFIRM_TITLE: 'Confirmação de Publicação',
        PUBLISHING_WARNING: 'Item em processo de publicação, por favor, tente novamente em alguns minutos.',
        PUBLISH_LONG_TIME:
          'A publicação deste Produto pode levar alguns minutos, quando terminar você será notificado com uma notificação.',
        MAIN_DATA: {
          VALID_DATE: 'Data de validade',
          ORDER_WARNING:
            'Escolher uma ordem menor que o máximo recalculará as datas dos períodos subsequentes, excluindo eventos especiais',
          VALIDFROMEXCEEED: 'A data de início excede o ano atual',
          PERIOD_IN_USE: 'O período não pode ser editado porque está em uso.',
        },
      },
      COVERAGE: {
        WARNING_IS_NEW: 'Você precisa salvar as alterações para verificar a cobertura.',
        WARNING_STORE_AND_ITEM_SCOPE:
          'Ao modificar os escopos de item e loja, é necessário salvar as alterações para verificar a cobertura.',
        COVERAGE_BASED_COMMUNICATION_TITLE: 'Comunicação Baseada em Cobertura',
        COMPLETE_COVERAGE_TITLE: 'Cobertura Completa',
        COMMUNICATION_REGULAR_PRICE_TITLE: 'Comunicar preço regular',
      },
      REPORTS: {
        AVAILABLE_SPACES: {
          REGION: 'Região',
          AVAILABLE_SLOTS_PERCENTAGE: '% Total disponível',
          AVAILABLE_SLOTS: 'Quantidade total disponível',
          SLOTS_TOTAL: 'Número total de slots',
          SLOTS_TOTAL_IN_USE: 'Número total em uso',
          SLOTS_PERCENTAGE_IN_USE: '% Total em uso',
          SLOTS_EXCEEDED: 'Quantidade de slots excedidos',
        },
        TAG_VALUES: 'Baixar relatório de etiquetas',
      },
    },
    M_SHOPPER: {
      MISSIONS: {
        CHECK_RULE: {
          MODAL_TITLE: 'Você quer mudar a seleção?',
          MODAL_CONTENT_FIRST_STEP: 'Se você confirmar, as etapas a seguir copiarão as configurações das regras de concorrência',
          MODAL_CONTENT:
            'Se confirmadas, as missões afetadas serão substituídas pelas modificações feitas com base na regra de concorrência da primeira etapa.',
          MODAL_BTN_CLOSE: 'Cancelar',
          MODAL_BTN_ACCEPT: 'Confirmar',
          CHECK: 'Não perguntar de novo.',
        },
        TYPE_OPTION: {
          PriceSurvey: 'Pesquisa de Preços',
          ExhibitionSurvey: 'Pesquisa de Exibição',
        },
        FRECUENCY_OPTION: {
          OneTime: 'Uma vez',
          Daily: 'Diariamente',
          Weekly: 'Semanal',
          Biweekly: 'Quincenal',
          Monthly: 'Mensal',
          Biannual: 'Semestral',
          Annual: 'Anual',
        },
        AUDYT_OPTION: {
          Internal: 'Interna',
          External: 'Externa',
        },
        METHODS_OPTION: {
          Strip: 'Strip',
          Ticket: 'Ticket',
          Catalog: 'Catálogo',
          Totem: 'Totem',
        },
        ACTION_OPTION: {
          PriceLabelScan: 'Escaneio de Etiqueta de Preço',
          VoiceCommand: 'Comando de voz',
          ManualEntry: 'Entrada manual',
          Indistinct: 'Indistinto',
        },
        CADUCATE: null,
        TODAY: null,
        DAYTOEXPIRE: null,
        ROUTE: 'Comprador Misterioso',
        TITLE: 'Missões',
        SUBTITLE:
          'Nesta tela você verá todas as missões criadas, com sua porcentagem de progresso, sua frequência e se elas têm ou não regras de concorrência associadas a elas. Estas missões serão executadas pelo shopper através do aplicativo Prisma Mystery Shopper, caso existam relações entre lojas próprias ou da concorrência com o shopper, a missão será disponibilizada a esse utilizador a fim de verificar os seus próprios preços ou os da concorrência.',
        NEW_MISSION_BTN: 'Nova Missão',
        DAILYREPORT: null,
        FILTER_TTIP: 'Filtrar',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reduzir',
        EXPORT_TTIP: 'Exportar',
        IMPORT_TTIP: 'Importar',
        PRINT_TTIP: 'Imprimir',
        DELETE_TTIP: 'Apagar missões selecionadas',
        DELETE: 'Apagar',
        EDIT: null,
        NAME: 'Nome',
        TYPE: 'Tipo',
        AUDIT_METHOD: 'Método de Auditoria',
        AUDIT_METHOD_TTIP: 'Método de Auditoria',
        START_DATE: 'Date de Inicio',
        START_DATE_TTIP: 'Data de Início da Missão',
        FINISH_DATE: 'Data Final',
        FINISH_DATE_TTIP: 'Data de Fim da Missão',
        FRECUENCY: 'Frequência',
        ADVANCED: 'Progresso %',
        SURVEYED_STATUS: 'Status da pesquisa',
        SURVEYED_STATUS_NOTAVAIBLE_TOOLTIP:
          'A missão não está disponível para os usuários porque eles não estão atribuídos ou não têm concorrentes',
        SURVEYED_STATUS_AVAIBLE_TOOLTIP: 'A missão está disponível para os usuários',
        SURVEYED_STATUS_TAKENBYME_TOOLTIP: 'Um usuário aceitou a missão e ficará encarregado de assumi-la',
        SURVEYED_STATUS_COMPLETED_TOOLTIP: 'A missão já foi concluída, baixe o relatório diário para obter os resultados',
        SURVEYED_STATUS_NOTSURVEY_TOOLTIP: 'A missão não foi pesquisada e a data efetiva terminou',
        SURVEYED_STATUS_NOTAVAIBLE: 'Não disponível',
        SURVEYED_STATUS_AVAIBLE: 'Disponível',
        SURVEYED_TAKENBYME: 'Em andamento',
        SURVEYED_STATUS_COMPLETED: 'Concluído',
        SURVEYED_STATUS_NOTSURVEY: 'Não pesquisado',
        DAYS_TO_EXPIRE: 'Dias para expirar',
        SKUS: '# Produtos',
        SKUS_TTIP: 'Quantidade de produtos',
        SITES: '# Pontos de Venda',
        SITES_TTIP: 'Quantidade de pontos de venda',
        USERS_TTIP: 'Quantidade de usuários',
        USERS: '# Usuários',
        RULE: 'Regra',
        RULE_TTIP: 'Missão associada a uma regra de concorrência',
        YES: 'Sim',
        NO: 'No',
        STATUS: null,
        DATE: null,
        STATUS_ACTIVE: null,
        STATUS_OVERDUE: null,
        STORES_FILTER: null,
        DEFINE_MISSION_STP1: 'Definir Missão',
        COMPETITORS_SCOPE_STP2: 'Escopo dos Concorrentes',
        PRODUCT_SCOPE_STP3: 'Escopo Produtos',
        STORE_SCOPE_STP4: 'Escopo Pontos de Venda',
        RESPONSIBLE_STP5: 'Responsável',
        NEW_TITLE_FORM: 'Nova Missão',
        EDIT_TITLE_FORM: 'Editar Missão',
        STP1: 'Etapa 1 - Definir Missão',
        STP1_SUBTITLE: 'Nesta etapa, você será capaz de definir os principais componentes de uma missão',
        NAME_INPUT: 'Nome',
        TYPE_INPUT: 'Tipo',
        AUDIT_METHOD_INPUT: 'Método de Auditoria',
        FRECUENCY_INPUT: 'Frequência',
        ACTION_TYPE_INPUT: 'Tipo de Açāo',
        AUDIT_TYPE_INPUT: 'Tipo de Auditoria',
        VALIDATE_LOCATION_TOOLTIP: 'A missão deve estar dentro do intervalo permitido de distância',
        REQUEST_EVIDENCE_TOOLTIP: 'Será obrigatório carregar imagem de comprovação do produto pesquisado',
        REQUEST_EVIDENCE: 'exige evidência fotográfica',
        VALIDATE_LOCATION: 'Validar Localização',
        SURVEYSAMOUNT: '#Concorrentes',
        SURVEYSAMOUNT_TTIP: 'Quantidade de concorrentes',
        STARTS_INPUT: 'Começa em',
        FINISHED_INPUT: 'Termina em',
        VALIDATE_PROMOTION_CHECK: 'Validar Promoção',
        VALIDATE_DATE_START: 'A data de início deve ser menor ou igual à data de término',
        VALIDATE_DATE_END: 'A data de término deve ser maior ou igual à data de início',
        VALIDATE_STOCK_CHECK: 'Validar Estoque',
        COMPETITIVE_RULE_INPUT: 'Regra',
        COMPETITIVE_RULE_INPUT_IN_USE: 'Esta regra está aplicada em uma missão',
        NEXT_BTN: 'Próximo',
        STP2: 'Etapa 2 - Escopo dos Concorrentes',
        LEGEND_SKIP_STEP: 'Esta etapa não é necessária se o tipo de auditoria for interna',
        SKIP_BTN: 'Pular',
        PREVIUS_BTN: 'Anterior',
        STP3: 'Etapa 3 - Escopo dos Produtos',
        STP3_SUBTITLE:
          'Nesta etapa você poderá definir quais produtos alcançará ou não esta missão. Você pode escolhê-los por seleção por atributo ou seleção individual.',
        STP3_TOGGLE: 'Permitir capturar preços de produtos fora da missão',
        STP3_TOGGLE_TOOLTIP:
          'Um lote de produtos genéricos estará disponível para identificar produtos encontrados que não estejam configurados na missão.',
        VIEW_PRODUCTS_BTN: 'Visualizar Produtos',
        REFRESH_PRODUCTS_BTN: 'Atualizar Produtos',
        STP4: 'Etapa 4 - Escopo dos Pontos de Venda',
        STP4_SUBTITLE:
          'Nesta etapa você poderá definir quais pontos de venda alcançará ou não esta missão. Você pode escolhê-los por seleção por atributo ou seleção individual.',
        AUTOMATIC_ASIGNATION_CHECK: 'Alocação automática',
        ADD_RESPONSIBLE_BTN: 'Adicionar Responsável',
        SAVE_BTN: 'Salvar',
        ADD_RESPONSIBLE_TITLE: 'Adicionar Responsável',
        COMPETITOR_INPUT: 'Concorrente',
        SHOPPER_INPUT: 'Shopper',
        ACCEPT_BTN: 'Aceitar',
        CANCEL_BTN: 'Cancelar',
        ADD_BTN: 'Adicionar',
        STRATEGY_ATTR_TITLE: 'Seleção por atributo',
        STRATEGY_ATTR_MSG: 'Os atributos são selecionados para definir o escopo dos produtos',
        STRATEGY_PROD_TITLE: 'Seleção por produto',
        STRATEGY_PROD_MSG: 'Os produtos devem ser selecionados um por um',
        STRATEGY_ATTR_TITLE_STORE: 'Seleção por atributo',
        STRATEGY_ATTR_MSG_STORE: 'Os atributos são selecionados para definir o escopo dos Pontos de Venda',
        STRATEGY_STORE_TITLE_STORE: 'Seleção por pontos de venda',
        STRATEGY_STORE_MSG_STORE: 'Os pontos de venda devem ser selecionados um a um',
        VIEW_STORES_BTN: 'Visualizar Pontos de Venda',
        REFRESH_STORES_BTN: 'Atualizar Pontos de Venda',
        STP5: 'Etapa 5 - Responsável pela Missão',
        LEGEND_RESPONSIBLE_STEP:
          'A atribuição de usuários à missão será feita automaticamente com base nos relacionamentos carregados na tela Atribuição de Usuários. A missão estará disponível no MS App, para todos os usuários relacionados aos pontos de venda e/ou concorrentes atingidos, e o primeiro usuário a cumprir a missão será aquele que lhe for atribuído',
        PASTE: 'Colar',
        PASTE_TTIP: 'Adicione pontos de venda de uma lista copiada para a sua área de transferência.',
        SEARCH: 'Procurar',
        CODE_AND_DESCRIPTION_INPUT: 'Código / Descrição',
        CLIPBOARD_TITLE: 'Colar lojas desde a área de transferência',
        CLIPBOARD_STEP1: '1) Defina que tipo de código você está usando',
        TYPE_CODE: 'Tipo de Código',
        INTERNAL_CODE_INPUT: 'Código Interno',
        EXTERNAL_CODE_INPUT: 'Código Externo',
        NOT_FOUND_CODE_ITEM: 'Este código de produto não foi encontrado no Catálogo de Ítens',
        NOT_FOUND_CODE_STORE: 'Este código de ponto de venda não foi encontrado no Catálogo de Pontos de Venda',
        CLIPBOARD_STEP2:
          '2) Selecione e copie os códigos de uma planilha ou texto. (Ctrl + C) conforme mostrado na imagem abaixo. Eles também podem ser carregados delimitando cada código com os seguintes caracteres "," ";" "|" "/" "\\\\" (tab) (enter) (espaço) ',
        CLIPBOARD_STEP3: '3) Pressione aqui e cole os códigos (Ctrl + V)',
        CLIPBOARD_MAX: 'A seleção individual suporta até {{val}} produtos.',
        CLIPBOARD_COUNT: 'Códigos foram carregados',
        CLIPBOARD_TITLE_DEFUALT: 'Pressione aqui e cole os códigos (Ctrl + V)',
        CLIPBOARD_NONE: 'Nenhum código foi carregado',
        CLIPBOARD_ACTION: 'Colar',
        PASTE_PRODUCT_TTIP: 'Adicione produtos de uma lista copiada para sua área de transferência.',
        CLIPBOARD_PRODUCT_TITLE: 'Colar produtos desde a área de transferência',
        CLIPBOARD_STORE_TITLE: null,
        FILTER_PROD: 'Produtos Filtrados',
        SELECT_PROD: 'Produtos Selecionados',
        ADD_PROD: 'Adicionar Produtos a uma lista',
        FILTER_STORE: 'Pontos de Venda Filtrados',
        SELECT_STORE: 'Pontos de Vendas Selecionados',
        ADD_STORE: 'Adicionar Pontos de Venda a uma lista',
        PROVINCE_CHIP: 'Província:',
        COUNTRY_CHIP: 'País:',
        PRICE_CHIP: 'Preço:',
        COST_CHIP: 'Custo:',
        LEGEND_SKIP_STEP4:
          'Esta etapa não é necessária uma vez que a opção Seleção de Concorrentes foi escolhida no Escopo de Concorrentes',
        STP2_COMP_BUTTON_TITLE: 'Escolha como você deseja se comparar com seus concorrentes.',
        MAINMARKET_TITLE: 'Marcador',
        ONLY_MARKERS_TITLE: 'Selecione apenas marcadores',
        MAINMARKET_MSG: 'Irá pesquisar o preço dos Marcadores de cada Ponto de Venda.',
        USE_TRADE_AREA_TITLE: 'Área Comercial',
        USE_TRADE_AREA_MSG: 'Irá pesquisar o preço dos Concorrentes associados à Área Comerciala de cada Ponto de Venda.',
        SELECT_COMPETITORS_TITLE: 'Seleção de Concorrentes',
        SELECT_COMPETITORS_MSG: 'Ele irá procurar por concorrentes que atendam aos critérios a serem definidos abaixo',
        HELP: {
          TYPE_INPUT:
            'Pesquisa de Preços: Você vai enviar o shopper para auditar os preços da concorrência e dentro de seus pontos de venda, para informá-lo ou garantir que suas políticas sejam atendidas.',
          TYPE_INPUT2:
            'Pesquisa de exibição: você enviará o comprador para auditar como os produtos são exibidos em seus pontos de venda ou em pontos de venda dos concorrentes.',
          AUDIT_METHOD_INPUT: 'Adhesivo: O preço que aparece na gôndola onde o produto está localizado devem ser auditadas. ',
          AUDIT_METHOD_INPUT2: 'Ticket: O preço que consta no ticket ou na nota fiscal será auditado após a compra dos produtos.',
          AUDIT_METHOD_INPUT3:
            'Catálogo: Os preços que aparecem em um catálogo, revista, folheto ou qualquer publicação de produto impresso serão auditados.',
          AUDIT_METHOD_INPUT4: 'Totem: Preços em totem e / ou painel de produtos devem ser auditados.',
          STARTS_INPUT: 'A partir desta data a missão começa.',
          FINISHED_INPUT: 'A partir desta data, o APP Mystery Shopper não pedirá aos compradores que assumam a missão. Ele será pausado.',
          VALIDATE_PROMOTION_CHECK: 'Os produtos selecionados também devem ser auditados, estejam eles em promoção ou não.',
          VALIDATE_STOCK_CHECK: 'Deve-se confirmar se havia ou não estoque do produto disponível no momento da auditoria.',
          FRECUENCY_INPUT: 'Determine com que freqüência a auditoria será repetida até a data de conclusão.',
          ACTION_TYPE_INPUT:
            'Determine se você precisa auditar de alguma forma específica, o Prisma Mystery Shopper APP permitirá que o comprador faça auditoria apenas usando a metodologia selecionada.',
          AUDIT_TYPE_INPUT: 'Interno: Será auditado em seus estabelecimentos.',
          AUDIT_TYPE_INPUT2: 'Externo: O estabelecimento do concorrente será auditado.',
          COMPETITIVE_RULE_INPUT:
            'Ao escolher uma regra de competição, os seguintes passos serão cumpridos pelo que já foi definido anteriormente na criação da regra.',
        },
        CHANGES_REFLECTED_NEXT_SURVEY: 'As alterações efetuadas serão refletidas no próximo inquérito.',
      },
      USER_ASSIGNMENT: {
        TITLE: null,
        STATUS: {
          ASSIGNED: null,
          NOTASSIGNED: null,
        },
        CLONATION: {
          TITLE: null,
          CONTENT: null,
          CLIPBOARD: null,
          USER_SELECTED: null,
          USER_FOUND: null,
          ADD_USERS: null,
          PASTE_USERS: null,
          USER_NOTFOUND: null,
          HAS_ASSIGNED: null,
          CLIPBOARD_SELECT_STEP: null,
          CLIPBOARD_PASTE_STEP: null,
          SUCCESSFULL: null,
          FAILED: null,
        },
        UNASSIGNED: {
          TITLE_ONE: null,
          CONTENT: null,
          TITLE_MULTIPLE: null,
        },
        LIST: {
          NAME: null,
          EMAIL: null,
          CODE: null,
          STORES_COUNT: null,
          STORES: null,
          COMPETITORS_COUNT: null,
          STATUS: null,
          UNASSIGNED_ROW: null,
          FILTER: null,
        },
        EDIT: {
          SAVE_WITH_CURRENT_COMPETITORS: null,
          WARNING_SAVE: null,
          WARNING_COMPETITORS: null,
          STRATEGY_ATTR_TITLE_STORE: null,
          STRATEGY_ATTR_MSG_STORE: null,
          STRATEGY_STORE_TITLE_STORE: null,
          STRATEGY_STORE_MSG_STORE: null,
          CLIPBOARD_COMPETITORS_TITLE: null,
          STORESCOPE: null,
          COMPETITORSSCOPE: null,
          CHANGE_STORE_SCOPE_TITLE: null,
          CHANGE_STORE_SCOPE_DESCRIPTION: null,
          STORE_SCOPE: {
            SUBTITLE: null,
            VIEW_STORES_BTN: null,
            CODE_AND_DESCRIPTION_INPUT: null,
            PASTE: null,
          },
          COMPETITORS_SCOPE: {
            SUBTITLE: null,
            SEARCH_COMPETITORS: null,
            PASTE: null,
            NOTFOUND: null,
            SAVE_FIRST: null,
          },
        },
      },
    },
    COMP: {
      GENERIC_TABLE: {
        COMPETITORS: {
          COMPETITORS_FOUND: null,
          COMPETITORS_SELECTED: null,
          ADD_COMPETITORS: null,
        },
      },
      PRISMA_TABLE: {
        CONFIG_COLUMNS: 'Configurar colunas',
        DELETE_ACTION: {
          DELETE: 'Desativar',
          DELETE_TTIP: 'Desativar registro',
          UNDELETE: 'Ativar',
          UNDELETE_TTIP: 'Ativar registro',
          DELETE_ROW: 'Desativar registro',
          DELETE_ROWS: 'Desativar, registros',
          DELETE_ROW_CONTENT: 'Seguro que desea Desativar {{val}}?',
          DELETE_ROWS_CONTENT: 'Seguro que desea Desativar {{val}}',
          UNDELETE_ROW: 'Ativar Registro',
          UNDELETE_ROWS: 'Ativar Registros',
          UNDELETE_ROW_CONTENT: null,
          UNDELETE_ROWS_CONTENT: null,
          ACTION_ROW_CONTENT_SUCCESS: null,
          ACTION_ROW_CONTENT_FAILED: null,
        },
        ENTITIES: {
          REPORT: null,
          APPLY: null,
          ITEM_NOT_FOUND: null,
          USEDINCOMBOS: null,
          USEDINMISSIONS: null,
          USEDINPARENTRULES: null,
          USEDINPLANOGRAMS: null,
          USEDINPROMOTIONS: null,
          USEDINRECIPES: null,
        },
        ACTION_FAIL: {
          DESCRIPTION_ERROR: null,
          TYPE: null,
          KEYWORD: null,
          VALUES: null,
          WARNING: null,
          ERROR: null,
          ENTITYNOTFOUND: null,
        },
      },
      INSIGHT: {
        CONTEXT_MENU: {
          SUBSCRIBED: 'Subscrito',
          UNSUBSCRIBED: 'Cancelar subscrição',
          SUBSCRIBE: 'Assinar para receber todos os insights',
          UNSUBSCRIBE: 'Assinar para receber todos os insights',
          TYPE: 'de {{val}}',
          CATEGORY: 'da categoria {{val}}',
          STORE: 'da Loja {{val}}',
          STORETAG: 'da etiqueta {{val}}',
          CONCATENATE_MESSAGE: '{{val}} {{val2}}',
        },
      },
      MODAL: {
        IMPORT_VENDORS: {
          TITLE: 'Resolver conflitos de fornecedores',
          CLOSE: 'Fechar',
        },
        ADD_MODAL_VERIFY: {
          SELECT_ALL: 'Selecionar Todos',
          SELECT_OPTIONS_MSG: 'Selecionar as opções',
          CURRENT_SELECTION: 'Seleção Atual',
          VALIDATE_BTN: 'Validar',
          VALIDATION_TITLE: 'Validação',
          PLACEHOLDER: 'Procurar {{val}}',
          TITLE_EMPTY: 'Não há {{val}} para validar',
          VALIDATION_MESSAGE_OK: 'Todos os fornecedores foram configurados corretamente para o ponto de venda.',
          VALIDATION_MESSAGE_WARNING:
            'O {{val}} selecionado não cobre 100% do sortimento ativo. Nenhum fornecedor foi encontrado para os seguintes produtos',
          VALIDATION_MESSAGE_WARNING_FOOTER: 'Selecione {{val}} até que todo o sortimento seja coberto.',
          VALIDATION_TITLE_WARNING: 'Advertência',
          CLOSE: 'Fechar',
          VALIDATION_MESSAGE_ERROR: 'Mais de um fornecedor tem o mesmo produto',
          VALIDATION_TITLE_ERROR: 'Conflitos ',
          SOLVE: 'Resolver',
          DOWNLOAD_XLSX: 'Baixar Excel',
          XLSX: 'Fornecedor.xlsx',
          BTN_TOOLTIP_ERROR: 'Baixe o excel e preencha a coluna IsPrimary com o provedor principal com um X',
          INIT_VENDORS: 'Inicializar fornecedores',
          SURE_TO_SAVE:
            'Este processo é único e não pode ser modificado nesta tela, certifique-se de que os provedores selecionados são os corretos',
          CANCEL: 'Cancelar',
          SAVE: 'Salvar',
        },
        COPY_VENDORS_BY_STORE: {
          INTRO: 'Os fornecedores serão clonados de acordo com o ponto de venda selecionado.',
          PLACEHOLDER: 'Ponto de Venda',
          SAVE: 'Salvar',
          CLOSE: 'Fechar',
        },
      },
      PICK_COLOR: {
        SELECTION: 'Selecionar',
        CANCEL: 'Cancelar',
        CLEAR: 'Limpar',
        SELECTION_TITLE: 'Selecionar uma cor',
      },
      KPIS: {
        DOUBLE_KPI_SUGGESTION: 'SUGESTÕES ACEITAS',
      },
      MULTIPLE_GREEN_BUTTON: {
        MODAL_TITLE: 'Você quer mudar a seleção?',
        MODAL_CONTENT: 'Se você confirmar, os dados relacionados à seleção podem ser removidos da carga atual',
        MODAL_BTN_CLOSE: 'Cancelar',
        MODAL_BTN_ACCEPT: 'Confirmar',
        CHECK: 'Não perguntar de novo.',
      },
      ALERT_AND_CAP: {
        ALERTACOMPETENCIA:
          'O preço sugerido de R$ {{val}} é R$ {{val2}} do que o limite definido para o concorrente {{val3}}. Seu preço deve estar entre R$ {{val4}} e R$ {{val5}}',
        ALERTACOMPETENCIA_NO_MIN: null,
        ALERTACOMPETENCIA_NO_MAX: null,
        ALERTACOMPETENCIA_NO_RANGE: null,
        ALERTACOMPETENCIAMISSING:
          'O limite do concorrente não pôde ser verificado porque não encontramos preços de mercado válidos {{val}}',
        ALERTAMARGEN: 'O preço sugerido de R$ {{val}} é {{val2}} do que {{val3}} de acordo com o limite da margem.',
        ALERTAMARGENMISSING:
          'Não foi possível verificar o limite de margem porque não encontramos custos para este produto neste ponto de venda.',
        ALERTAVARIACION: 'O preço sugerido de R$ {{val}} é {{val2}} do que {{val3}} de acordo com o limite de variação.',
        CAPCOMPETENCIA:
          'O preço sugerido de {{val}} foi alterado para {{val2}} porque era {{val3}} do que o preço de {{val4}} R$ {{val7}}. Seu preço deve estar entre R$ {{val5}} e R$ {{val6}}',
        CAPCOMPETENCIAMISSING:
          'O limite do concorrente não pôde ser verificado porque não encontramos preços de mercado válidos para {{val}}.',
        CAPMARGE:
          'O preço sugerido de R$ {{val}} foi alterado para R$ {{val2}} porque era {{val3}} do que a margem de {{val4}}. Seu preço deve estar entre R$ {{val5}} e R$ {{val6}}',
        CAPMARGEMISSING:
          'Não foi possível verificar o limite de margem porque não encontramos custos para este produto neste ponto de venda.',
        CAPVARIACION:
          'O preço sugerido de R$ {{val}} foi alterado para R$ {{val2}} porque era {{val3}} do que a variação de {{val4}}. Seu preço deve estar entre R$ {{val5}} e R$ {{val6}}',
        MINIMOMAXIMO: '{{val}} de R${{val2}}',
        MINIMUM: 'mínimo',
        MAXIMUM: 'máximo',
        GREATER: 'Maior',
        ABOVE: 'acima',
        BELOW: 'abaixo',
        ALERT_MARGEN_LIMIT: 'O preço sugerido está dentro de {{val}} de {{val2}} do limite de margem exigido.',
        ALERT_VARIATION_LIMIT: 'O preço sugerido está dentro de {{val}} de {{val2}} do limite de variação exigido.',
        OF: 'de',
        LOWER: 'lower',
        FOR: 'para {{val}}',
      },
      DEFAULT_TBAR: {
        SEARCH: 'Procurar…',
        SEARCH_ITEM: null,
        SEARCH_STORE: null,
      },
      ACTION_TBAR: {
        ACTIONS: 'Ações',
        SELECTED_SINGULAR: 'Selecionado',
        SELECTED_PLURAL: 'Selecionados',
      },
      ITEMS_NO_FOUND: {
        NO_RESULTS_FILTERS: 'Nenhum resultado encontrado.',
        NO_RESULTS_FILTERS_MANDATORY:
          'Não foram encontrados resultados com os filtros selecionados. Por favor, preencha os filtros obrigatórios.',
        NO_RESULTS: 'Nenhum resultado encontrado',
        NO_RESULTS_REPORTS: null,
      },
      ERROR_MESSAGE: {
        TITLE: 'An error has occurred',
        SUBTITLE: 'Entre em contato com a equipe de suporte.',
        ACCEPT_BTN: 'Aceitar',
        DOWNLOAD_LOG: null,
      },
      DINAMIC_TAGS: {
        ADD_ATTRIBUTE_BTN: '+',
        WITHOUT_SELECTION: 'Sem Seleção ...',
        SEARCH: 'Procurar ',
        ELEMENTS_NOTFOUND: 'Nenhuma etiqueta encontrada',
        NO_ELEMENTS_SELECTED: 'Nenhuma etiqueta foi selecionada',
        SELECTION_ALL: 'Selecionar Todos',
      },
      FILTER_CHARGE_COMBO: {
        OTHERS: ', Others',
        OTHER: ', Other',
        SELECTED: 'Selecionados',
        RESULT: 'Resultados encontrados',
        TOOLTIP: 'Opções Selecionadas: ',
      },
      ADD_ATTRIBUTE: {
        TITLE_MODAL: 'Novo Atributo',
        SELECT_ATTRIBUTE: 'Selecionar Atributo',
        SEARCH_ATTRIBUTE: 'Procurar Atributo',
        SELECT_ALL: 'Selecionar Todos',
        CANCEL_BTN: 'Cancelar',
        ADD_BTN: 'Adicionar',
        EXCLUDED: 'Excluído',
      },
      ERASURE_CONFIRMATION: {
        TITLE: 'Apagar',
        SUBTITLE: 'Quer continuar com a remoção?',
        CANCEL_BTN: 'Cancelar',
        DELETE_BTN: 'Apagar',
      },
      DRAG_DROP: {
        TITLE: 'Arraste e solte a imagem aqui ou clique para pesquisar em seu PC',
        SUBTITLE: 'Extensão não permitida: ',
        SUBTITLE_TTIP: 'Extensões permitidas: "jpeg", "jpg", "png", "ico"',
        SUBTITLE_TTIP_EXCEL: 'Extensões permitidas: "xls", "xlsx"',
        SUBTITLE_TTIP_ONLY: null,
      },
      OPERATION_SUCCESS: {
        TITLE: 'Operação bem sucedida',
        SUBTITLE: 'A operação foi completada com sucesso',
        ACCEPT_BTN: 'Aceitar',
        TITLE_EMPTY: 'Operação sem modificações',
        SUBTITLE_EMPTY: 'A operação foi concluída com sucesso, nenhuma alteração foi encontrada pendente de relatório',
        SUBTITLE_WITHOUT_RESULT: 'Sem resultados',
      },
      IAN_IMPORTER: {
        BTN_IMPORT_SWITCH: 'Terminar',
        CLOSE: 'Fechar',
        UPLOAD_FILES_STP1: 'Carregar arquivos',
        VERIFY_FORMAT_STP2: 'Verificar Formato',
        VALIDATE_INFORMATION_STP3: 'Validar Informação',
        UPDATE_TABLES_STP4: 'Atualizar tabelas',
        UPDATE_TABLES_STP_END: 'Finalizar',
        RESTART_TTIP: 'Reinicie o importador para fazer carregamento de novos arquivos',
        STP1: 'Etapa 1 - Carregar Arquivo',
        DRAG_FILE: 'Arraste e solte o arquivo aqui ou clique para procurar em seu PC',
        STP2: 'Etapa 2 - Verifique o formato',
        CHECKING_FORMAT: 'Verificar o formato e a extensão do arquivo para fazer a carga para o servidor',
        STP3: 'Etapa 3 - Validar informação',
        VALIDATING_DATA: 'Validando dados do arquivo para detectar inconsistências',
        STP4: 'Etapa 4 - Atualizar tabelas',
        STP_END: 'Etapa 2 - Finalizar',
        INCORPORATING_INFORMATION: 'As informações processadas estão sendo adicionadas ao Prisma',
        CORRECT_FILE: 'O formato e a extensão do arquivo estão corretos',
        WITHOUT_ERRORS: 'A informação foi adicionada ao Prisma com sucesso, mas com',
        WARNINGS: 'alertas',
        ERRORS: 'A informação não foi adicionada ao Prisma. Os seguintes erros e alertas foram encontrados',
        WITHOUT_ERRORS_WARNINGS: 'A informação foi adicionada ao Prism com sucesso, sem erros ou alertas',
        DOWNLOAD_TEMPLATE: 'Baixar Template',
        TOTAL_ROWS_REGISTRED: 'Total de registros carregados',
        TOTAL_ROWS_REGISTRED_WITH_WARNINGS: 'Total de registros com alertas não carregados',
      },
      GEN_CATEGORYS: {
        CATEGORIES_INPUT: 'Categorias',
        CATEGORIES_INPUT_EXC: 'Categorias Excluídas',
        CATEGORIES_TTIP: 'Você deve selecionar pelo menos uma categoria, para selecionar as subcategorias',
        SEARCH_CATEGORIES: 'Procurar Categorias',
        SUBCATEGORIES_INPUT: 'Subcategorias',
        SUBCATEGORIES_INPUT_EXC: 'Subcategorias Excluídas',
        DEPT_INPUT: 'Departamento',
        DEPT_INPUT_EXC: 'Departamento Excluído',
        SUBCATEGORIES_TTIP: 'Você deve selecionar pelo menos uma subcategoria por categoria selecionada',
        SEARCH_SUBCATEGORIES: 'Procurar Subcategorias',
        SELECT_ALL: 'Selecionar Todos',
        SELECT_ALL_SUBCATEGORY: 'Selecionar todas as Subcateogiarias',
        SEARCH_SUBCATEGORIES_FILTER: 'Procurar Subcategorias',
        SELECT_ALL_CATEGORY: 'Selecionar todas as Categorias',
      },
      GEN_ITEM_LIST: {
        NAME: 'Nome',
        CODE: 'Código',
        BRAND: 'Marca',
        MANUFACTURER: 'Fabricante',
        PRICE: 'Preço R$',
        COST: 'Custo R$',
      },
      GEN_ITEM_STORE: {
        NAME: 'Nome',
        CODE: 'Código',
        BRAND: 'Marca',
      },
      GEN_TAGS: {
        FILTER_TAG_VALUES: 'Opções de filtro que estão relacionadas com os resultados da lista',
        ADD_ATRIBUTE_BTN: 'Adicionar Atributos',
        NO_TAGS: null,
        NONE: 'Nenhum',
        ALL: 'Todos',
        EXCLUDED: 'excluído',
        SEARCH: 'Procurar',
      },
    },
    MAT_PAGINATOR: {
      QUANTITY: 'Número de produtos por página',
      NEXT: 'Próxima página',
      PREVIUS: 'Página anterior',
      FIRST: 'Primeira página',
      LAST: 'Última página',
    },
    SERVICES: {
      CAN_DEACTIVATE: {
        TITLE_MODAL: 'Alterações sem salvar',
        SUBTITLE_MODAL: 'Você quer sair mesmo assim?',
        GO_OUT_BTN: 'Sair',
        FOLLOW_BTN: 'Continue no formulário',
      },
    },
    SPACES: {
      COMMON: {
        SEE_MORE: 'Ver mais',
        STORES_LIST_TITLE: 'Pontos de venda',
        STORES_TABLE_NAME: 'Nome',
        STORES_TABLE_ACTION: 'Ações',
      },
      LIST: {
        ENTITIES: {
          REPORT: null,
          APPLY: null,
          CONTENT_DELETE_LAYOUT: null,
          ITEM_NOT_FOUND: null,
          USEDINLAYAOUTS: null,
          IMPEDIMENT: {
            USEDINLAYAOUTS: null,
          },
        },
        STATUS: {
          New: 'Novo',
          PendingEndorsement: 'Pendente da aprovação',
          Current: 'Publicado',
          Endorsed: 'Aprovado',
          Deleting: null,
          Draft: null,
        },
        TITLE_STRUCTURE: 'Estruturas',
        BTN_STRUCTURE: 'Nova Estrutura',
        TITLE_GENERIC_SPACE: 'Espaços Genéricos',
        BTN_GENERIC_SPACE: 'Novo Espaço Genérico',
        TITLE: 'Planogramas',
        POPOVER_TEXT:
          'Somente são exibidos os planogramas cujos Pontos de Venda estão disponíveis para o usuário. Se um ou mais planogramas não forem exibidos, por favor entre em contato com o administrador do sistema, informando os planogramas ausentes e solicitando a verificação dos PDVs associados ao usuário.',
        ROUTE: 'Planejamento de Espaços',
        BTN: 'Novo Planograma',
        ADMIN: 'Administração de planogramas',
        EXPORT_TTIP: 'Exportar',
        FILTER_TTIP: 'Filtrar',
        FULLSCREEN_TTIP: 'Expadir',
        EXITFULLSCREEN_TTIP: 'Reduzir',
        PRINT_TTIP: 'Imprimir',
        DELETE: 'Apagar',
        DELETE_TTIP: 'Apagar planogramas selecionados',
        RECOVER: 'Recuperar',
        RECOVER_TTIP: 'Recuperar',
        CLONE_STRUCTURE: 'Clonar únicamente a estrutura',
        CLONE_STRUCTURE_AND_PRODUCTS: 'Clonar estrutura e produtos',
        SURE_CLONE:
          'Tem certeza de que deseja clonar este item? apenas a estrutura do planograma será clonada e não a configuração com todos os seus produtos aplicados',
        SURE_CLONE_COMPLETE:
          'Tem certeza de que deseja clonar este item? A estrutura do planograma e a configuração com todos os seus produtos aplicados serão clonados',
        EDIT: 'Editar',
        STATUS_NEW: 'Novo',
        STATUS_EDITED: 'Editado',
        STATUS_ACTUAL: 'Atual',
        STATUS_APPROVED: 'Aprovado',
        STATUS_PENDING_APPROVAL: 'Aprovação pendente',
        STATUS_PEVIEW: 'Preview',
        STATUS_INFORMED: 'Informado',
        STATUS_INFORM: 'Informar',
        REQUEST_APPROVAL: 'Solicitar Aprovação',
        REQUEST_APPROVAL_SMALL: 'Solicitar Aprovação',
        APPROVE: 'Aprovado',
        COLUMN: {
          NAME: 'Nome',
          CODE: 'Código',
          TYPE: 'Tipo',
          LAST_MODIF: 'Última Modif.',
          STATUS: 'Status',
          CAT: '# Categorias',
          SUBCAT: '# Subcategorias',
          GENERATE: 'Geração',
          CHARGE: 'Capacidade (UN)',
          FRONT: 'Comprimento',
          LINEAL_SIZE: 'Espaço linear',
          LAYOUT: '# Layouts',
          STORES: '# Pontos de Venda',
          SKU: '# Produtos',
          SALES: 'Vendas Mês/ Espaço Linear',
          VAR: 'Var. Mês Passado',
          PLANOGRAMTYPE: null,
          STRUCTUREONLY: null,
          STRUCTUREONLY_Structure: null,
          STRUCTUREONLY_Planogram: null,
          STRUCTUREONLY_Generic: null,
          FILTER_PREVIEW: null,
          FILTER_TEMPLATE_GENERATE: null,
          FILTER_TEMPLATE_GENERATE_FOR: 'Geração:',
          MASIVE_ACTIONS: 'Ações em Massa:',
          request_approval_msg: 'Você tem certeza de que deseja solicitar a aprovação de {{val}} registros?',
          approve_msg: 'Você tem certeza de que deseja aprovar {{val}} registros?',
          inform_msg: 'Você tem certeza de que deseja informar {{val}} registros?',
          APPROVE_ALERT: 'Aprovação enviada. Você receberá uma notificação quando o processo for concluído.',
          REQUEST_APPROVAL_ALERT: 'Solicitação de aprovação enviada. Você receberá uma notificação quando o processo for concluído.',
          INFORM_ALERT: 'Informando planogramas. Você receberá uma notificação quando o processo for concluído.',
          INACTIVATE_ALERT: 'Excluindo planogramas. Você receberá uma notificação quando o processo for concluído.',
          request_approval: 'Solicitar Aprovação',
          approve: 'Aprovar',
          inform: 'Informar',
          delete: 'Desativar',
          NO_PERMISSIONS: 'You do not have permissions.',
          AUTOMATIC: 'Automática',
          MANUAL: 'Manual',
        },
        SNACKBAR_REPORT: 'O relatório está sendo gerado',
        SNACKBAR_CLOSE: 'Fechar',
        PLANOGRAM_TYPES: {
          All: null,
          Structure: null,
          Planogram: null,
          GenericSpace: null,
          isGenericSpace: null,
        },
        PLANOGRAM_TYPE: {
          Structure: null,
          Planogram: null,
          GenericSpace: null,
          isGenericSpace: null,
        },
      },
      TABS: {
        TAB1_ICON: 'Configurações',
        TAB1: 'Geral',
      },
      GENERAL: {
        TITLE_NEW: 'Novo Planograma',
        TITLE_NEW_TEMPLATE: null,
        TITLE_NEW_GENERICSPACE: null,
        TITLE_EDIT: 'Editar Planograma',
        TITLE_EDIT_TEMPLATE: null,
        TITLE_EDIT_GENERICSPACE: null,
        SUBTITLE: 'Você está criando um novo planograma, digite um Nome e selecione os Pontos de Venda e categorias relacionadas',
        SAVE_BTN: 'Salvar',
        NAME_INPUT: 'Nome',
        OBSERVATIONS_INPUT: 'Observações',
        ADVANCED_SEARCH: 'Pesquisa Avançada',
        UPDATE: 'Atualizar',
        UPDATE_STRUCTURES: 'Você deve atualizar a lista de estruturas para ver as alterações realizadas.',
        CODE_SEARCH: 'Procurar por códigos',
        VALIDATION_CODE: 'O código {{val}} já existe e está em um planograma funcional ou inativo',
        STORES: 'Pontos de Venda',
        CATEGORIES: 'Categorias',
        DELETE_ALL: 'Excluir tudo',
        CONFIRM_DELETE: 'Tem certeza de que deseja excluir o ponto de venda?',
        CONFIRM_DELETE_ALL: 'Tem certeza de que deseja excluir todos?',
      },
      STRUCTURE: {
        HIDE_PANEL: 'Ocultar Painel',
        OPEN_L_PANEL: 'Abrir o painel esquerdo',
        OPEN_R_PANEL: 'Abrir o painel direito',
      },
      PRODUCTS: {
        TURN_OFF_INDICATOR: 'Desligue o indicador',
        FILTER_TITLE: 'Filtros',
      },
      CONFIRMATION: {
        TITLE: 'Atenção!',
        SUBTITLE: 'Deseja adicionar ao existente ou substituir completamente?',
        REPLACE_BTN: 'Substituir',
        ADD_BTN: 'Adicionar',
      },
      EXTRAS: {
        CATEGORY_TITLE: 'Subcategorias',
        CATEGORY_DESC: 'Selecione as subcategorias relacionadas a este planograma. Você pode modificá-los mais tarde.',
        STORE_TITLE: 'Pontos de Venda',
        STORE_DESC:
          'Selecione as lojas relacionadas a este planograma individualmente ou massivamente. Você pode modificá-los mais tarde. Use (Ctrl + P) para colá-los da área de transferência.',
      },
      MATERIAL_POP: {
        TITLE: 'Material POP',
        ROUTE: 'Planejamento de Espaços',
        NEW_BTN: 'Novo Material POP',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reduzir',
        NAME: 'Nome',
        CODE: null,
        TYPE: 'Tipo',
        LAST_MODIF: 'Última Modif.',
        OBSERVATION: 'Observação',
        CHARGE_UNITS: 'Capacidade (UN)',
        LAYOUTS: '# Layouts',
        STP1_INITIAL_DATA: 'Dados Iniciais',
        STP2_ASSEMBLY: 'Armado',
        STP1: 'Etapa 1 - Dados Iniciais',
        BTN_NEXT: 'Próximo',
        STP2: 'Etapa 2 - Montagem',
        DISTANCE: 'Distancia entre Produtos (cm.)',
        PREVIOUS_BTN: 'Anterior',
        SAVE_BTN: 'Salvar',
        MOVE_UP: 'Mover o produto para cima',
        MOVE_DOWN: 'Mover o produto para baixo',
        DELETE: 'Remover o produto',
        SEARCH_PRODUCT: 'Procurar por produto ou código',
        TITLE_FORM: 'Novo Material POP',
        POP_MATERIAL: 'Material POP / ',
        EMTY_MSG: 'Arraste os produtos aqui',
        VALIDATION_CODE: null,
        PARENT_MATERIAL: 'Material POP Pai',
        LIST: {
          DELETE_ROW: 'Desativar registro',
          DELETE_ROWS: 'Desativar registros',
          DELETE_ROW_IN_PROCESS: 'Solicitação de inativação em andamento. Notificaremos você assim que concluída.',
          CONTENT_DELETE_PLANOGRAM: null,
        },
      },
      EDITOR: {
        IS_PREVIEW: 'É um Preview',
        PREVIEW: 'Pré-visualizar',
        SAVE_FOR_PREVIEW: 'As alterações devem ser salvas para gerar uma pré-visualização.',
        DELETE: 'Remover pré-visualização',
        OPACITY: 'Opacidade',
        NEW_STRUCTURE: 'Nova estrutura',
        NEW_GENERIC_SPACE: 'Novo espaço genérico',
        DOWNLOAD_EXCEL: 'Baixar reporte',
        LOADING_POG: 'Carregando planograma ...',
        NEW_POG: 'Novo Planograma',
        SAVE: 'Salvar',
        VISUALIZATION: 'Visualização',
        NULL_SPACES: 'Vazio bloqueado',
        CLEAN_NI: 'Limpar Vazios bloqueados',
        CLEAN_POG: 'Limpar Planograma',
        NEW_BLOCK_SPACES: 'Adicionar Área',
        EDIT_BLOCK_SPACES: 'Editar Área',
        DELETE_ALL_BLOCK_SPACES: 'Limpar Áreas',
        DELETE_ALL_WARNING: 'Excluir todas as Áreas',
        DELETE_ALL_MSG: 'Esta ação não pode ser desfeita. Deseja continuar?',
        TITLE_BLOCK_SPACE: 'Área Bloqueada',
        NAME_BLOCK_SPACE: 'Nome',
        COUNT_SHELFS: 'Prateleiras selecionadas:',
        WIDTH_BLOCK_SPACE: 'Largura (cm):',
        DST_X_BLOCK_SPACE: 'Dist. inicial do móvel (cm):',
        BARTAB_BLOCK_SPACES: 'Áreas Bloqueáveis',
        MIRROR_POG: 'Planograma espelhado',
        CLEAN_MOD: 'Limpar Módulo',
        MIRROR_MOD: 'Módulo espelho',
        CLEAN_SHELF: 'Limpar Prateleira',
        MIRROR_SHELF: 'Prateleira espelhada',
        AUTOCOMPLETE_SHELF: null,
        SHELF_AVOID_CHECK_SPACE: null,
        AUTOCOMPLETE_MODULE: null,
        TITLE_POG: 'Planograma',
        TITLE_LAYERS: 'Camadas',
        TITLE_HEATMAP: 'Mapa de Calor',
        TITLE_HEATMAP_SING: 'Mapa de Calor',
        TITLE_VARIABLE: 'Variável',
        TITLE_TIMELINE: 'Linha do tempo',
        TITLE_VARIATIONS: 'Variações',
        TITLE_VIS_ATTRS: 'Visualizar Atributos',
        TITLE_VIS_ATTRS_SING: 'Visualizar Atributo',
        TITLE_VIS_ATTRS_CAT: 'Classificação por categoria',
        TITLE_VIS_ATTRS_DEP: 'anking por departamento',
        TITLE_STORES: 'Pontos de Venda',
        TITLE_FILTERS: 'Filtros',
        TITLE_SEARCH_BTN: 'Adicione produtos à um Strip de Impulso',
        TITLE_TYPE: 'Tipo',
        TITLE_SHELF: 'Estante',
        TITLE_OWN: 'Própria',
        TITLE_CAPACITY: 'Capacidade',
        TITLE_CAPACITY_LIN: 'Capacidade linear',
        TITLE_CAPACITY_LIN_FREE: 'Cap. Lin. Livre',
        TITLE_ASSIGNED_TO: 'Designado a',
        TITLE_PRODS: 'Produtos',
        TITLE_FACINGS: 'Frentes',
        TITLE_LEVELS: 'Níveis',
        TITLE_LOAD: 'Capacidade',
        TITLE_STOCK_VALUE: 'Estoque R$',
        TITLE_PERFORMANCE: 'Desempenho',
        TITLE_ACTIVES: 'Ativos',
        TITLE_NO_ACTIVES: 'Não ativo',
        TITLE_ACTIVE: 'Ativo',
        TITLE_NO_ACTIVE: 'Inativo em alguns pontos de venda',
        TITLE_NO_ACTIVE_ALL_STORES: 'Inativo em todos os pontos de venda',
        TITLE_IS_BLOCKED: 'Disponível para vendas em algumas lojas',
        TITLE_NO_BLOCKED: 'Disponível para vendas',
        TITLE_BLOCKED_ALL_STORES: 'Bloqueado para vendas',
        TITLE_SEARCH: 'Procurar',
        TITLE_EXHIBITED: 'Exibidos',
        TITLE_EXHIBITED_SING: 'Exibido',
        TITLE_NO_EXHIBITED: 'Não exibido',
        TITLE_NEW_ONLY: 'Apenas novo',
        TITLE_BLOCKEDFORSALES: 'Bloqueado para vendas',
        TITLE_BLOCKEDFORSALES_NO: 'Disponível para vendas',
        TITLE_ADVANCED_FILTERS: 'Filtros Avançados',
        TITLE_CLEAN_FILTERS: 'Limpar Filtros',
        TITLE_SMALL_COD: 'Código',
        TITLE_SMALL_UNIT_MED: 'Unidade de Medida',
        TITLE_EAN: 'EAN',
        TITLE_BRAND: 'Marca',
        TITLE_SUPLIER: 'Fornecedor',
        TITLE_CAN_STACK: 'Pode Empilhar',
        TITLE_CAN_LAYDOWN: 'Pode Deitar',
        TITLE_CAN_ROTATE: 'Pode Rotar',
        TITLE_SALES_PART_PER: 'Part. % Vendas',
        TITLE_SALES_PART_LIN_ESP: 'Part. % Espaço (m/lin)',
        TITLE_EXH_DAYS: 'Dias em Exhibição',
        TITLE_EXH_STOCK: 'Dias em Estoque',
        TITLE_STOCK: 'Estoque R$',
        TITLE_STOCK_UN: 'Estoque UN',
        TITLE_DATE: 'Data',
        TITLE_USER: 'Usuário',
        TITLE_ANALISYS_TYPE: 'Tipo de Análise',
        TITLE_LOAD_LIMIT: 'Limite de carga',
        TITLE_STACK_LIMIT: 'Limíte de Empilhado',
        TITLE_LIMIT_PLACEHOLDER: 'lim.',
        TITLE_IS_PALLET: 'É um pallet',
        TITLE_IS_PALLET_PLACEHOLDER: 'É um pallet',
        FILTER_POPMATERIALESPECIAL: null,
        FREE_AREA: 'Área Livre',
        TOTAL_AREA: 'Área total',
        TOTAL_PLANOGRAMS: 'Planogramas',
        YES: 'Sim',
        NO: 'Não',
        TTIP_UNDO: 'Desfazer última alteração [ctrl + z]',
        TTIP_REDO: 'Refazer a última alteração [ctrl + y]',
        TTIP_DRAG: 'Arrastar [barra de espaço + mouse]',
        TTIP_FIT: 'Ajustar conteúdo [ctrl+0]',
        TTIP_ZOOM_IN: 'Zoom [+]',
        TTIP_ZOOM_OUT: 'Zoom [-]',
        TTIP_FULL_SCREEN: 'Maximizar a tela',
        TTIP_FULL_PRINT: 'Imprimir [ctrl + p]',
        TTIP_OPTIONS: 'Opções',
        TTIP_DEL: 'Apagar [del]',
        TTIP_CLONE: 'Clonar Módulo',
        TTIP_CLONE_ALT: 'Clonar',
        TTIP_COMMENTS: 'Comentários',
        TTIP_STACK_WARING: 'Este produto não é empilhável',
        PRINT_NAME: 'Nome',
        PRINT_POSITION: 'Posição',
        PRINT_NAME_CAT: 'Nome da Categoria',
        PRINT_NAME_SUBCAT: 'Nome da Subcategoria',
        PRINT_UNIT_MESURE: 'Unidade de Medida',
        PRINT_BRAND: 'Marca',
        PRINT_MANUFACTURE: 'Fabricante',
        PRINT_WIDTH: 'Largura',
        PRINT_HEIGHT: 'Altura',
        PRINT_DEPTH: 'Profundidade',
        PRINT_FACES: 'Faces',
        PRINT_LOAD: null,
        PRINT_LINEAL_SPACE: 'Espaço Linear',
        PRINT_ROTATION: 'Rotação',
        PRINT_STACK: 'Pilhas',
        MSG_SHELF_SHOULD_EMPTY: 'A prateleira deve estar vazia.',
        MSG_CONFIRM_SAVE: 'Tem certeza de que deseja salvar o planograma?',
        MSG_CONFIRM_SAVE_TEMPLATE: null,
        MSG_CONFIRM_SAVE_SPACE: 'Tem certeza de que deseja salvar o espaço genérico?',
        MSG_WIDTH_NI: 'Deve-se atribuir a largura do espaço vazio.',
        MSG_CONFIRM_DEL_PRODS: 'Deseja prosseguir com a remoção de todos os produtos do planograma?',
        MSG_CONFIRM_DEL_NI_PRODS: 'Deseja prosseguir com a remoção de todos os espaços vazios do planograma?',
        MSG_CONFIRM_MIRROR_PRODS: 'Você quer continuar com o planograma de espelhamento?',
        MSG_CONFIRM_DEL_SHELF_PRODS: 'Quer continuar retirando todos os produtos da prateleira?',
        MSG_CONFIRM_MIRROR_SHELF_PRODS: 'Você quer continuar com o espelhamento de todos os produtos na prateleira?',
        MSG_CONFIRM_DEL_PRODS_MOD: 'Quer continuar removendo todos os produtos do módulo?',
        MSG_CONFIRM_MIRROR_PRODS_MOD: 'Você quer continuar com o espelhamento de todos os produtos do módulo?',
        MSG_CONFIRM_DEL_PRODS_MOD_PRD: 'Quer continuar com a remoção do módulo? <br> <b> Todos os produtos serão removidos. </b>',
        MSG_CONFIRM_DEL_PRODS_SHELF_PRD: 'Quer continuar removendo a prateleira? <br> <b> Todos os produtos serão removidos. </b>',
        MSG_CONFIRM_DEL: 'Confirmação de exclusão.',
        MSG_CONFIRM_MIRROR: 'Confirmação de espelhamento.',
        MSG_MODULE_WIDTH_ALERT: 'Existem produtos na prateleira que não cabem na nova largura.',
        MSG_MODULE_DEPTH_ALERT: 'Existem produtos na prateleira que não se encaixam na nova profundidade.',
        MSG_PRD_NO_ENOUGH_SPACE: 'Espaço insuficiente.',
        MSG_PRD_NO_ENOUGH_SPACE_LIMIT: 'Carregamento máximo.',
        MSG_PRD_CATN_ROTATE: 'O produto selecionado não pode ser girado.',
        MSG_PRD_CATN_LAYDOWN: 'O produto selecionado não pode ser tombado.',
        MSG_PRD_CATN_ROTATE_LAYDOWN: 'Produtos caídos não podem ser girados.',
        MSG_PRD_CATN_ROTATE_ROTATEY: 'Produtos caídos não podem ser girados.',
        MSG_PRD_CATN_PALLET: null,
        MSG_SHELF_SHOULD_EMPTY_TYPE: 'Esvazie a prateleira antes de mudar seu tipo.',
        MSG_SHELF_PRD_HEIGTH_WARNING: 'Existem produtos com altura superior à da prateleira',
        MSG_SHELF_PRD_DEPTH_WARNING: 'Existem produtos com uma profundidade maior que a prateleira.',
        BTN_CANCEL: 'Cancelar',
        BTN_DEL: 'Remover',
        BTN_ACEPT: 'Aceitar',
        BTN_PRINT: 'Imprimir',
        BTN_CLEAN: 'Limpar',
        BTN_APPLY: 'Aplicar',
        BTN_SAVE: 'Salvar',
        BTN_CLOSE: 'Fechar',
        ACTION_REORDER_LEFT: 'Reordenar à Esquerda',
        ACTION_REORDER_RIGHT: 'Reordenar à Direita',
        ACTION_ADD_SHELF: 'Adicionar Prateleira',
        ACTION_DEL_PRODS: 'Apagar Produtos',
        ACTION_PRD_ADD_FACING: 'Adicionar uma frente [ctrl+right]',
        ACTION_PRD_REMOVE_FACING: 'Remover Frentes [ctrl + esquerda]',
        ACTION_PRD_STACKSLYINGDOWN: 'Nao tombar frentes',
        ACTION_PRD_NO_STACKSLYINGDOWN: 'Tombar frentes',
        ACTION_PRD_ROTATE_CW_NORMAL: 'Girar para a direita [r]',
        ACTION_PRD_ROTATE_NOCW: 'Girar para a esquerda',
        ACTION_PRD_ROTATE_CW: 'Deitar a direita [d]',
        ACTION_PRD_ROTATE_UP_CW: 'Parar o produto [r]',
        ACTION_PRD_ROTATE_REV: 'Deitar a esquerda',
        ACTION_PRD_ROTATE_REV_Y: 'Vista de Lateral',
        ACTION_PRD_ROTATE_REV_Y2: 'Ver de frente',
        ACTION_SHELF_MOVEUP: 'Subir',
        ACTION_SHELF_MOVEDOWN: 'Mover para baixo',
        ACTION_SHELF_ADD_UP: 'Adicionar Prateleira Acima',
        ACTION_SHELF_ADD_DOWN: 'Adicionar Prateleira Embaixo',
        ACTION_SHELF_DEL_PRODS: 'Apagar Produtos',
        ACTION_LAYER_TOGGLE_MODS: 'Ocultar / Ativar Módulos',
        ACTION_LAYER_HIDE_MODS: 'Ocultar Módulos',
        ACTION_LAYER_SHOW_MODS: 'Mostrar Módulos',
        ACTION_LAYER_HIDE_PRODS: 'Ocultar Produtos',
        ACTION_LAYER_HIDE_IMG: 'Ocultar imagens',
        ACTION_LAYER_SHOW_IMG: 'Mostrar imagens',
        ACTION_LAYER_SHOW_PRODS: 'Mostrar Produtos',
        ACTION_LAYER_HIDE_POP: 'Ocultar Material POP',
        ACTION_LAYER_SHOW_POP: 'Mostrar Material POP',
        ACTION_LAYER_HIDE_PITSHELF: 'Mostrar Freezer de frente',
        ACTION_LAYER_SHOW_PITSHELF: 'Mostrar Freezer 3D',
        ACTION_ADD_CONSTRAINTS: 'Adicionar Restrições',
        SHELF: 'Estante',
        SHELF_FRONT_VIEW: 'Vista frontal da caixa',
        SHELF_NORMAL_TYPE_SHELF: 'Estanterias',
        SHELF_NORMAL_TYPE_REFRIGERATOR: 'Geladeiras',
        SHELF_NORMAL_TYPE_SIMPLEPEG: 'Gancheira',
        SHELF_NORMAL_TYPE_PITSHELF: 'Box / Geladeira',
        SHELF_NORMAL_TYPE_PALLET: 'Palete',
        SHELF_CANT_LEVELS: '# de Níveis',
        MOD_TITLE: 'Módulo',
        BARTAB_GENERAL: 'Geral',
        BARTAB_MODULES: 'Estrutura',
        BARTAB_PROMOTION_MATERIAL: null,
        BARTAB_SCOPES: 'Escopo Produtos',
        BARTAB_MODULES_PL: 'Estruturas',
        BARTAB_PRODS: 'Produtos',
        BARTAB_OTHERS: 'Outros',
        BARTAB_POPMAT: 'Material POP',
        BARTAB_ANALISYS: 'Análise',
        BARTAB_CONSTRAINTS: 'Restrições',
        BARTAB_AUDIT: 'Auditoria',
        BARTAB_MAX_QUANT: 'Quantidade Máxima de Promoções',
        IND_SALES: 'Vendas',
        IND_SALES_MAG: 'Vendas R$',
        IND_SALES_UINIT: 'Vendas UN.',
        IND_SALES_UINIT_PER: 'Vendas UN. %',
        IND_SALES_PER: '% Vendas R$',
        IND_MARGIN: 'Margem',
        IND_MARGIN_UNIT: 'Margem UN.',
        IND_MARGIN_MAG: 'Margem R$',
        IND_MARGIN_PER: 'Mergem %',
        IND_MARGIN_TOT: 'Margem Tot.',
        IND_ESP_MT_LIN: 'Esp. (m/lin) %',
        IND_ESP_MT2_LIN: 'Esp. m2',
        IND_SPACE_SUB: 'Sobespaçado',
        IND_SPACE_OK: 'Equilibrado',
        IND_SPACE_HI: 'Espaço Superestimado',
        SPACE_WARNING: null,
        IND_SPACE_SUB_SMALL: 'Sob',
        IND_SPACE_OK_SMALL: 'Bal.',
        IND_SPACE_HI_SMALL: 'Sobre',
        IND_SPACE_GMROI: 'GMROI',
        IND_SPACE_GMROS: 'GMROS',
        IND_SPACE_PRICE: 'Preço',
        IND_SPACE_NONE: 'Nenhum',
        IND_SPACE_DAYS_EXH: 'Dias em Exhibição',
        IND_SPACE_DAYS_EXH_FULL: 'Dias em Exhibição',
        IND_SALES_PER_PROD: 'Vendas R$ por produto',
        IND_SALES_PER_FACING: 'Vendas por frente',
        IND_SALES_MTS_LIN_RATIO: 'Vendas R$ / Espaço',
        IND_SALES_MTS_LIN_RATIO_USED: 'VendasR$ / Espaço Usado',
        IND_SALES_PRICE_PROM: 'Preço Médio R$',
        IND_SALES_MARGIN_PROM: 'Margem Média R$',
        IND_SALES_MARGIN_PROM_PER: 'Margem Média %',
        IND_SALES_MONTH_UNITS: 'Vendas Mes UN',
        OPEN_IN_ITEM_MASTER: 'Aberto no Item Master',
        IND_SALES_MONTH: 'Vendas Mes R$',
        IND_SALES_UNITS_FACINGS: 'Venda UN / Frentes',
        IND_MARGIN_MONTH: 'Margem Mes',
        IND_SPACE_ASSIGN: 'Alocação de espaço',
        IND_SPACE_ASSIGN_FULL: 'Alocação de espaço',
        IND_LOAD: 'Capacidade',
        IND_STOCK_DAYS: 'Dias em Estoque',
        IND_STOCK_DAYS_FULL: 'Dias em Estoque',
        IND_ATTRS: 'Atributos',
        IND_NONE: 'Nenhum',
        IND_DAYS: 'Días',
        IND_MIN_SMALL: 'Mín.',
        IND_MAX_SMALL: 'Máx.',
        IND_NORMAL: 'Normal',
        IND_BIGGER_THAN_DAYS: 'Maior do que {{val}} dias',
        IND_SMALER_THAN_DAYS: 'Menos de {{val}} dias',
        MAG_DEFAULT: 'cm.',
        MAG_VOL_DEFAULT: 'm³',
        MAG_HEIGHT_WITH_MAG: 'Altura (cm.)',
        MAG_HEIGHT_WITH_MAG_ALLMOD: 'Altura (cm.)',
        MAG_WIDTH_WITH_MAG: 'Largura (cm)',
        MAG_DEPTH_WITH_MAG: 'Profundidade (cm.)',
        MARGIN_LEFT: 'Margem Esquerda (cm.)',
        MARGIN_RIGHT: 'Margem Direita (cm.)',
        MAG_GUIDES: 'Distância das guias (cm)',
        MAG_HEIGHT: 'Alto',
        MAG_WIDTH: 'Largura',
        MAG_DEPTH: 'Profundidade',
        MAG_VOL: 'Vol.',
        PRINT_TITLE_DIALOG: 'Imprimir Planograma',
        PRINT_MODS_SELECTION:
          'Escolha o módulo a ser impresso, se deixar a faixa vazia, todos os módulos serão impressos. (É possível usar intervalos, ex: 2-4)',
        PRINT_MODS_TO_PRINT: 'Módulos para imprimir',
        PRINT_PRINT_TYPES: 'Tipos de impressão',
        PRINT_PRINT_TYPE_IMAGES: 'Imagens',
        PRINT_PRINT_TYPE_GROUP: 'Código de Grupos',
        PRINT_PRINT_TYPE_INDIV: 'Códigos Individuais',
        PRINT_PRINT_TYPE_ALL_PLANOGRAM_IMAGE: 'Planograma Completo',
        PRINT_PRINT_TYPE_POP: 'Material POP',
        PRINT_REPORT: null,
        PRINT_WARN_NO_RANGE: 'Não há módulos para imprimir dentro do intervalo selecionado.',
        AUDIT_STATUS: 'Status',
        AUDIT_LAST_PUBLISH: 'Últ. Pub.',
        AUDIT_NO_IMAGE: 'Sem Imagem',
        AUDIT_IMG_UPLOAD: 'Carregar Imagem',
        AUDIT_IMG_VIEW: 'Visualizar Imagem',
        AUDIT_TITLE: 'Auditoria',
        AUDIT_WARNING_NO_SAVED: 'Para alterar o status do planograma, não deve haver alterações não salvas.',
        AUDIT_AUDIT_IMG: 'Imagem de auditoria',
        AUDIT_NEW_AUDIT_IMG: 'Nova imagem de auditoria',
        AUDIT_NEW_AUDIT_IMG_OK: 'Imagem salva com sucesso',
        CONSTRAINTS_TITLE: 'Restrições',
        TLINE_TITLE: 'Linha do tempo',
        TLINE_ACTUAL_TIME: 'Atual (Hoje)',
        TLINE_TOTAL_MARG: 'Margem Total',
        TLINE_SALES_UNITS: 'Vendas UN',
        TLINE_LAST_PUB: 'Anterior [ctrl + esquerda]',
        TLINE_NEXT_PUB: 'Próxima [ctrl + direita]',
        TLINE_CLOSE_TL: 'Fechar Linha do Tempo',
        TLINE_OPEN_TL: 'Abris linha do tempo',
        TYPES: {
          'normalShelf-module': 'Estante',
          'simplePeg-module': 'Gancheira',
          'pitShelf-module': 'Freezer Horizontal',
          'refrigerator-module': 'Geladeiras',
          'normalShelf-level': 'Estante',
          'simplePeg-level': 'Gancheira',
          'pitShelf-level': 'Freezer Horizontal',
          'float-pop-impulse-strip-simple': 'Tira de Impulso',
        },
        COMM_TITLE: 'Comentários',
        COMM_DATE: 'Data',
        COMM_COMMENT: 'Comentários',
        COMM_NEW_COMMENT: 'Novo comentário',
        COMM_READ_MORE: '[Ler mais]',
        NEW_NORMALSHELF: 'Nova Prateleira',
        NEW_REFRIGERATOR: 'Nova Geladeira',
        NEW_SIMPLEPEG: 'Nova Gancheira',
        NEW_PITSHELF: 'Nova Caixa / Geladeira',
        CONSTRAINS_TYPES: {
          MIN_FACINGS: 'Frentes mínimas por produto',
          MAX_FACINGS: 'Frentes máximas por produto',
          MIN_LINE_SPACE: 'Espaço linear mínimo',
          MAX_LINE_SPACE: 'Espaço linear máximo',
          MIN_SHARE_SPACE: 'Participação mínima de espaço',
          MAX_SHARE_SPACE: 'Participação máxima de espaço',
        },
        CONSTRAINS_NAME_TITLE: 'Nome',
        CONSTRAINS_APPLY_TO: 'Aplicar a:',
        CONSTRAINS_ALL_THE_PLANOGRAM: 'Todo o Planograma',
        CONSTRAINS_ALL_THE_PLANOGRAM_TTIP: 'Esta regra se aplica a todo o planograma',
        CONSTRAINS_ALL_SHELF_TTIP: 'Esta regra se aplica a toda a prateleira',
        CONSTRAINS_ALL_SHELF_MODULE_TTIP: 'Esta regra se aplicará a todo o módulo',
        CONSTRAINS_SHELF_SELECTED: 'Estante',
        CONSTRAINS_SHELF_MODULE: 'Módulo',
        CONSTRAINS_CONDITION: 'Condição',
        CONSTRAINS_FRONTS_VALUE: 'Frentes',
        CONSTRAINS_LINE_SPACE_VALUE: 'espaço linear (cm.)',
        CONSTRAINS_SHARE_SPACE_VALUE: '% Espaço',
        CONSTRAINS_GROUP_EAN: 'Agrupado por EAN',
        CONSTRAINS_ALL_ITEMS: 'Todos os ítens',
        CONSTRAINS_WARNINGS: 'Alertas',
        CONSTRAINS_ONLY_THIS: 'Visualizar alertas',
        CONSTRAINS_WARNINGS_SIZE: 'Quantidade de Alertas',
        DELETE_DELETE_ITEM: 'Remover iten',
        DELETE_DELETE_ALL_EANS: 'Remover todos os itens agrupados por EAN',
        CLONE_STRUCTURE: 'Clonar únicamente a estrutura',
      },
      SPACES_TEMPLATES: {
        GENERATION_PLANOGRAMS: 'Planogramas de geração ...',
        GENERATION_PLANOGRAMS_ALT: null,
        TITLE: 'Modelos',
        TITLE_NEW: null,
        TAB_GENERAL: 'em geral',
        TAB_BLOCKS: 'Configuração de Blocos',
        TAB_BLOCKS_TT: 'Define as regras de ordenação e condições comerciais para a geração de planogramas.',
        TAB_PLANOGRAMS: 'Planogramas',
        TAB_STRUCTURES: 'Estruturas',
        TAB_GENERATE: 'Geração',
        DEMO_TITLE_EDIT: 'Template para YOGHURT',
        DEMO_TITLE_CODE_EDIT: 'YOG1',
        PREVIEW: 'Visualização',
        CLOSE_PREVIEW: 'Fechar visualização',
        NEW_DIVISION: 'Nova Divisão',
        DIVISION: 'Divisão',
        LEVELS: 'Níveis',
        LEVEL_BLOCK: 'Nível de Bloco',
        TABLE: {
          NAME: 'Nome',
          FRONT: 'Largura',
          HEIGHT: 'Alto',
          SHELVES: 'Prateleiras',
          STORES: 'Pontos de Venda',
          BLOCK_AREAS: 'Atribuir Espaços Bloqueados',
          STORES_VIEW: 'Ver Pontos de Venda',
          STRUCTURE_VIEW: 'Ver Estrutura',
          DELETE: 'Apagar',
          SAVED_AREAS: 'Espaços Bloqueados',
        },
        LIST: {
          TITLE: 'Lista de modelos',
          PLANOGRAMS: 'Planogramas',
          STRUCTURES: 'Estruturas',
          GenericSpace: null,
          DIVISIONS: 'Divisões',
          NO_DIVISIONS: 'Adicione divisões para começar a definir seus blocos.',
          NO_BLOCKS: 'Atualmente não há produtos disponíveis para a configuração selecionada.',
          TAG_SCOPE: 'Hit Tags',
          CATEGORY_SCOPE: 'Categorias alcançadas',
          EXCEED_TOLERANCE: 'Excesso tolerado',
          MIN_LINEAR_SPACE: 'Espaço linear mínimo',
          MAX_LINEAR_SPACE: 'Espaço linear máximo',
          LINEAR_SPACE_CALC_TYPE: 'Tipo de Cálculo Linear',
          LEVELS: 'Níveis',
          CODE: 'Código',
          NAME: 'inhame',
          EDIT_ROW: 'Editar',
          COPY_ROW: 'Clone',
          DELETE_ROW: 'Excluir',
          ITEM_TAGS: 'Tags de produto',
          NEW_ITEM: 'Novo modelo',
          DELETE: 'Excluir',
          DELETE_TTIP: 'Excluir um modelo',
          FILTER: 'Filtro avançado',
          FILTER_TTIP: 'Habilite ou desabilite o Filtro Avançado',
          EXPORT: 'Exportar',
          EXPORT_TTIP: 'Exportar um modelo',
          DELETE_ROW_IN_PROCESS: 'Solicitação de inativação em andamento. Notificaremos você assim que concluída.',
          DELETE_ROWS: 'Desativar registros',
        },
        PLANOGRAMS: {
          TITLE: 'Planogramas Associados',
          INTRO: 'Atribua as estruturas para vinculá-las ao modelo',
          PLANOGRAMS: 'Planogramas',
          STRUCTURES: 'Estruturas',
        },
        LINEAR_SPACES: {
          SALES: 'Vendas $',
          UNITS: 'Unidades de Vendas',
          MARGIN: 'Margem $',
        },
        MAIN_TAB: {
          TITLE: 'Dados gerais',
          CODE: 'Código',
          CODE_EXIST: 'Código já existe',
          NAME: 'inhame',
          LEVELS: 'Níveis',
          LINEAR_SPACE_CALC: 'Cálculo de frentes baseado em',
          MIN_LINEAR_SPACE: 'Mínimo de espaço linear',
          MAX_LINEAR_SPACE: 'Espaço linear máximo',
          LEVELS_PER_PRODUCT: 'Níveis por produto',
          ORDER_TYPE: 'Tipo de ordenamento',
          FILL_SPACES: 'Otimizar espaço',
          FACINGS_LIMIT: 'Usar limites por item',
          FILL_SPACES_MSG:
            'Ao habilitar esta opção, os espaços vazios nos planogramas serão automaticamente preenchidos, maximizando o uso do espaço disponível e evitando a perda de oportunidades de negócios',
          FACINGS_LIMIT_MSG:
            'Ao habilitar esta opção, os limites mínimos e máximos configurados para cada SKU serão aplicados, garantindo visibilidade e evitando excesso de estoque',
          EXCEED_BLOCK_TOLERANCE: 'Tolerância para exceder a largura do bloco',
          SCOPE_ITEMS: 'Escopo do Produto',
          SCOPE_ITEMS_COUNT: 'Produtos selecionados',
        },
        GENERATE: {
          TITLE: 'Geração de Planograma',
          INTRO:
            'Selecione o critério para gerar seus planogramas de maneira eficiente. Otimize a apresentação dos seus produtos nos pontos de venda de acordo com suas necessidades específicas.',
          BTN1_TITLE: 'Geração por Estrutura',
          BTN1_MSG: 'Desenvolva um planograma para cada estrutura, usando a média dos dados de todas as lojas com essa estrutura.',
          BTN2_TITLE: 'Geração por Ponto de Venda',
          BTN2_MSG: 'Crie um planograma específico para cada loja, usando dados detalhados de cada ponto de venda.',
          BTN3_TITLE: 'Geração por Cluster',
          BTN3_MSG:
            'Gera planogramas para clusters de lojas que compartilham comportamentos semelhantes, usando a média dos dados de cada grupo.',
          TEXT1:
            'Um planograma será gerado para cada estrutura associada ao Template; o desempenho de cada produto será a média dos pontos de venda associados à estrutura.',
          TEXT2:
            'Um planograma será gerado para cada Ponto de Venda associado às Estruturas do Template; o desempenho de cada produto será correspondente a cada Ponto de Venda. Importante: Se um ou mais Pontos de Venda estiver presente em mais de uma estrutura, então será gerado um planograma para cada dupla Estrutura-Ponto de Venda.',
          TEXT3:
            'Um planograma será gerado para cada Cluster selecionado; o desempenho de cada produto será a média em cada Cluster. Importante: Se houver múltiplas estruturas, e estas estiverem associadas a Pontos de Venda de um mesmo Cluster, então serão gerados planogramas para cada dupla Cluster-Estrutura.',
          INTRO2: 'Este processo pode demorar vários minutos',
          PLANOGRAM: 'Gerar Planograma',
          PREVIEW: 'Gerar visualização',
          MODAL_TITLE_PREVIEW: 'Gerar visualização',
          MODAL_INTRO_PREVIEW: 'Selecione o ponto de venda para gerar a visualização',
          MODAL_TITLE_GENERATE: 'Gerar planogramas',
          MODAL_INTRO_GENERATE: 'Selecione as saídas associadas para gerar os planogramas relacionados',
          SELECT_PLANOGRAM: 'Planogramas',
          SELECT_STORE: 'Lojas',
          SELECT_AREA: 'Espaços Bloqueados',
          SELECT_STRUCTURE: 'Estruturas',
          SELECT_CLUSTER: null,
          ACCEPT: 'Aceitar',
          CANCEL: 'Cancelar',
          ALERT: null,
          WARNING: null,
          WARNING_ALT: null,
          WARNING_ALT_PDV: 'Você deve salvar as alterações para ver os pontos de venda.',
          CREATEVERSIONFOR: null,
          PREVIEW_MODE: null,
          CREATEVERSIONFOR_OPTIONS: {
            PerStructure: null,
            PerStore: null,
            PerClusterStructure: null,
          },
          NAME_COLUMN: 'Nome',
          PLANOGRAM_EXIST_TITLE: 'Planogramas Existentes',
          PLANOGRAM_EXIST_SUBTITLE:
            'Tem certeza de que deseja continuar com a geração dos planogramas? Este processo sobrescreverá os elementos da seguinte lista:',
        },
        BLOCKS: {
          SELECT_PARENT: 'Selecione Bloco Pai',
          SELECT_VARIABLE: 'Selecione a Variável',
          SELECT_VARIABLE_OPTS: {
            Department: null,
            Category: 'Categoria',
            SubCategory: 'Subcategoria',
            Tag: 'Rótulo',
            Price: 'Preço',
            Size: 'Tamanho',
            category: null,
            subcategory: null,
            tag: null,
            price: null,
            size: null,
          },
          SELECT_ORIENTATION_OPTS_NEW: {
            Vertical: null,
            Horizontal: null,
          },
          SELECT_CALCULATIONTYPE: {
            Sales: null,
            Units: null,
            Margin: null,
            Price: null,
            Size: null,
            Custom: null,
          },
          SELECT_ORDERTYPE: {
            Descending: null,
            Ascending: null,
            Custom: null,
          },
          COLOR: 'Cor',
          SELECT_DIVISIONTYPE_ROOT: null,
          SELECT_DIVISIONTYPE: null,
          SELECT_DIVISIONTYPE_ITEM: null,
          SELECT_ORDER_TYPE: null,
          SELECT_ORDER_TYPE_ITEMS: null,
          SELECT_ORDER_TYPE_BLOCKS: null,
          SELECT_DIVISIONTYPE_OPTS: {
            Horizontal: null,
            Vertical: null,
          },
          MAX_DIVISIONS: 'Divisões máximas',
          MAX_DIVISIONS_ALT: null,
          MIN_DIVISIONS_ALT: null,
          DIVISION_INDEX: null,
          DIVISION_WIDTH_ALT: null,
          ALLOCATION_CRITERIA: 'Critérios para Alocação de Espaço',
          ALLOCATION_CRITERIA_OPTS: {
            '0': 'Prop. Para vendas $',
            '1': 'Sai A.',
            '2': 'Margem $',
          },
          ORDER: 'Pedido',
          ORDER_OPTS: {
            '0': 'Ascendente',
            '1': 'descendente',
            '2': 'Custom',
          },
          ADD_DIVITION: 'Adicionar Divisão',
          PRODUCTS: 'Produtos',
          UNITS: 'Unidades',
          MARGIN: 'Margem',
          SALES: 'Vendas',
          ADD_DIVITION_EXCEPTION: null,
          ADD_DIVITION_EXCEPTION_SIZE: null,
          DIVISION_WIDTH: null,
          DIVISION: 'Divisão',
          DIVISIONS: 'Divisões',
          DEMO_BRAND: 'Marca',
          DEMO_FLAVOR: 'Sabor',
          DEMO_SIZE: 'Tamanho',
          DEMO_PRESENTATION: 'Apresentação',
          SELECT_ORIENTATION: 'Orientação',
          SELECT_ORIENTATION_OPTS: {
            horizontal: 'Horizontal',
            vertical: 'Vertical',
          },
          VALIDATIONS: {
            blockCustomSize_invalidWidth: null,
            blockCustomSize_invalidWidth_max: null,
            blockCustomSize_invalidtagValueOrCategoryId: null,
            blockCustomOrder_invalidtagValueOrCategoryId: null,
            blockCustomOrder_invalidOrder: null,
            blockCustomSize_invalidtagValueOrCategoryId_dup: null,
            blockCustomOrder_invalidtagValueOrCategoryId_dup: null,
            blockCustomDivisionType_invalidHorizontal: 'Tipo de divisão inválido no nível superior',
            divisionVariable_duplicate: 'Variável duplicada',
            tagId_duplicate: 'Tag duplicada',
          },
        },
      },
      FLOOR_PLANS: {
        LIST: {
          NEW_ITEM: 'Criação do layout',
          TITLE: 'Lista de layouts',
          NAME: 'Nome',
          CODE: 'Código',
          SIZE_PLANOGRAMS: 'Nro Planogramas',
          SIZE_LEVELS: 'Níveis',
          EDIT_ROW: 'Editar',
          COPY_ROW: 'Clonar',
          DELETE_ROW: 'Excluir',
          ITEM_TAGS: 'Etiquetas de produtos',
          DELETE: 'Excluir',
          DELETE_TTIP: 'Excluir um modelo',
          FILTER: 'Filtro avançado',
          FILTER_TTIP: 'Ativar ou desativar o filtro avançado',
          EXPORT: 'Exportar',
          EXPORT_TTIP: 'Exportando um modelo',
          DELETE_CONFIRM: 'Você quer continuar com a remoção do Layout? ',
        },
        EDITOR: {
          TAB_GENERAL: 'Geral',
          TAB_LAYOUTS: 'Layouts',
          TAB_ANALISYS: 'Análise',
          TITLE_EDIT: 'Layouts',
          TAB_LEVELS: 'Níveis',
          LEVEL: 'Nível',
          LEVEL_LABEL_NAME: 'Nível',
          TITLE_NEW: 'Novo Layout',
          SUBTITLE: 'Você está criando um novo Layout, selecione o Nome, os pontos de venda ou a categoria.',
          BACK_OPACITY: 'Opacidade do Fundo',
          ROTATION: null,
          BACK_BW: 'Imagem monocromática',
          DELETE_ALL_MODULES: 'Módulos limpos',
          OPEN_PLANOGRAM: 'Planograma aberto',
          MSG_CONFIRM_DEL_MODULES: 'Você quer continuar com a remoção de todos os planogramas do layout?',
          ADD_LEVEL: 'Adicionar nível',
          EDIT_LEVEL: 'Nível de edição',
          NEW_LEVEL: 'Novo nível',
          WIDTH: 'Largura',
          DEPTH: 'Profundidade (m.)',
          TOTALSPACE: 'Área total (m².)',
          SELLINGAREASPACE: 'Área de vendas (m².)',
          BLUEPRINT_PHOTO: 'Imagem de referência do Plano',
          DELETE_LEVEL: 'Remover nível',
          MOVELEVELTOP: 'Mover para cima (reordenar)',
          MOVELEVELBOTTOM: 'Descer (reordenar)',
          MSG_CONFIRM_DEL_LEVEL: 'Você deseja continuar com a remoção do nível {{val}}?',
          NAME: 'Nome',
          CODE: 'Código',
          CATEGORY: 'Categoria',
          NO_LEVELS: 'Sem níveis',
          PLANOGRAMS: 'Planogramas',
        },
      },
    },
    TAGS_MODAL: {
      TITLE: 'Seleçāo avançada',
      DESC: 'Aplique filtros à sua pesquisa para obter o resultado desejado. Esta opção será desmarcada por filtros.',
      FIRST_TABLE_BTN: 'Aplicar',
      FIRST_TABLE_HEADER: 'Filtros',
      SECOND_TABLE_BTN: 'Selecionar',
      THIRD_TABLE_HEADER: 'Seleção Atual',
      THIRD_TABLE_EMPTY: 'Selecione Opções',
      CANCEL: 'Cancelar',
      APPLY: 'Aplicar',
    },
    REPORTS: {
      GENERAL_TITLE: null,
      PREFIX_TITLE: null,
      TYPE_FILTERS: null,
      DOWNLOAD: 'Gerar e baixar Relatório',
      GENERATE: null,
      FORMAT: null,
      GENERATE_FORMAT: null,
      GO_TO_REPORT_LIST: null,
      OPEN_REPORT_LIST: null,
      ASYNC_START_MSG: null,
      FILTERS_TITLE: null,
      TABLE: {
        filename: null,
        lastModified: null,
        reportType: null,
        username: null,
      },
      REPORT_TYPE_KEYS: {
        COMMENTARIO_DOCU: null,
      },
      LABEL_KEYS: {
        INCLUDEISDELETED: null,
        SEARCH: null,
        PRICE: null,
        PERCENT: null,
        DATE: null,
        DATEFROM: null,
        DATETO: null,
        FROMTODATES: null,
        ITEMTAGVALUES: null,
        STORETAGVALUES: null,
        CATEGORYVALUES: null,
      },
    },
    SELLING: {
      PRICING: {
        TITLE: 'Preços',
        SUBTITLE: 'Obtenha o preço certo com IA.',
        SEE_MORE_BTN: 'Ver mais',
        FEATURE1_TITLE: 'Atingir o seu posicionamento competitivo desejado',
        FEATURE1_SUBTITLE:
          'Os algoritmos de localização identificam seus concorrentes em cada zona de preço e o ajudam a ajustar seus preços para alcançar seu posicionamento.',
        FEATURE2_TITLE: 'Analise o impacto de suas decisões',
        FEATURE2_SUBTITLE: 'Prisma estima um impacto na margem e na imagem do preço que ajudará no seu processo de tomada de decisão.',
        CTA_TITLE: 'Descubra como podemos ajudá-lo!',
        CTA_BTN: 'Vamos falar',
      },
      CTA_MODAL: {
        TITLE: 'Contacte-nos para mais informações',
        NAME_INPUT: 'Nome',
        COMPANY_INPUT: 'Companhia',
        PHONE_INPUT: 'Telefone',
        SEND_BTN: 'Enviar',
      },
      ASSORTMENT: {
        TITLE: 'Sortimentos',
        SUBTITLE: 'OTIMIZA SEU SORTIMENTO E MAXIMIZA SEUS LUCROS.',
        SEE_MORE_BTN: 'Ver mais',
        FEATURE1_TITLE: 'Catalogação',
        FEATURE1_SUBTITLE:
          'Atribua produtos à seus pontos de venda: * Segmente seus produtos. * Agrupe seus pontos de venda em clusters e relacione a segmentação de seus produtos em cada cluster. ',
        FEATURE2_TITLE: 'Optimização de Sortimento',
        FEATURE2_SUBTITLE:
          'Regras de Pareto com IA: Suas regras de negócios podem funcionar em conjunto com a IA para exibir em suas lojas os produtos que seus clientes desejam. Adicione produtos com melhor desempenho no mercado ou em outras lojas. Remova facilmente os produtos que não estão funcionando conforme o esperado.',
        CTA_TITLE: 'Descubra como podemos ajudá-lo!',
        CTA_BTN: 'Vamos falar',
      },
      PROMOTIONS: {
        TITLE: 'Promoções',
        SUBTITLE: 'AUMENTE SUA INTELIGÊNCIA PROMOCIONAL.',
        SEE_MORE_BTN: 'Ver mais',
        FEATURE1_TITLE: 'Crie promoções incríveis',
        FEATURE1_SUBTITLE: 'Encante seus clientes implementando diferentes mecânicas promocionais.',
        FEATURE2_TITLE: 'Mede os resultados em detalhes',
        FEATURE2_SUBTITLE:
          'Leva em consideração o efeito de canibalização, as vendas pré e pós-promocionais e o investimento em marketing para calcular a lucratividade incremental de cada promoção.',
        CTA_TITLE: 'Descubra como podemos ajudá-lo!',
        CTA_BTN: 'Vamos falar',
      },
      ITEM_MASTER: {
        TITLE: 'Catálogo de Produtos.',
        SUBTITLE: 'SIMPLIFIQUE SEU PROCESSO DE GERENCIAMENTO DE PRODUTOS COM INTELIGÊNCIA ARTIFICIAL.',
        SEE_MORE_BTN: 'Ver mais',
        FEATURE1_TITLE: 'Combinação de produtos com IA',
        FEATURE1_SUBTITLE: 'Encontre produtos correspondentes para comparar preços e sortimento e detectar tendências de mercado.',
        FEATURE2_TITLE: 'Melhoria contínua da árvore de categorias',
        FEATURE2_SUBTITLE: 'Aumente a precisão e rapidez no processo de classificação de produtos.',
        CTA_TITLE: 'Descubra como podemos ajudá-lo!',
        CTA_BTN: 'Vamos falar',
      },
      SPACES: {
        TITLE: 'Planejamento de Espaços',
        SUBTITLE: 'Localiza seus produtos em uma eficiente, otimizada e visualmente atraente para o cliente.',
        SEE_MORE_BTN: 'Ver mais',
        FEATURE1_TITLE: 'Preenchimento Automático Inteligente',
        FEATURE1_SUBTITLE:
          'Use o recurso de preenchimento automático definindo a meta de dias de exibição e o Prisma irá sugerir frentes para minimizar a falta de estoques.',
        FEATURE2_TITLE: 'Use elementos móveis e diferentes tipos de prateleiras',
        FEATURE2_SUBTITLE:
          'Adicione produtos como ganchos, gavetas ou cabides nas prateleiras dos planogramas. Congeladores para congelados ou lácteos, cestas e mesas gratuitas, para poder localizar as suas promoções.',
        CTA_TITLE: 'Descubra como podemos ajudá-lo!',
        CTA_BTN: 'Vamos falar',
      },
      SUPPLY: {
        TITLE: 'Abastecimento Inteligente',
        SUBTITLE: 'INTEGRAR SEUS DADOS NA CADEIA DE ABASTECIMENTO',
        SEE_MORE_BTN: 'Ver mais',
        FEATURE1_TITLE: 'Reduzir falta de estoque e dias de estoque',
        FEATURE1_SUBTITLE:
          'Faça uma estimativa do comportamento de cada SKU em cada uma das lojas para calcular uma distribuição sugerida. Usando informações de vendas, preços e promoções para ajustar a sugestão.',
        FEATURE2_TITLE: 'Projete sua cadeia de abastecimento',
        FEATURE2_SUBTITLE:
          'Defina depósitos por loja e projete uma rede de abastecimento omnicanal onde fornecedores e depósitos estejam envolvidos em cada parte da Cadeia de Abastecimento.',
        CTA_TITLE: 'Descubra como podemos ajudá-lo!',
        CTA_BTN: 'Vamos falar',
      },
      PROMOTIONSV2: {
        TITLE: 'Promoções',
        SUBTITLE: 'AUMENTE SUA INTELIGÊNCIA PROMOCIONAL.',
        SEE_MORE_BTN: 'Ver mais',
        FEATURE1_TITLE: 'Crie promoções incríveis',
        FEATURE1_SUBTITLE: 'Encante seus clientes implementando diferentes mecânicas promocionais.',
        FEATURE2_TITLE: 'Mede os resultados em detalhes',
        FEATURE2_SUBTITLE:
          'Leva em consideração o efeito de canibalização, as vendas pré e pós-promocionais e o investimento em marketing para calcular a lucratividade incremental de cada promoção.',
        CTA_TITLE: 'Descubra como podemos ajudá-lo!',
        CTA_BTN: 'Vamos falar',
      },
    },
    GLOBAL_WARNINGS: {
      MODAL_WARNING_TITLE: 'Alerta de publicação - Confirmação',
      MODAL_WARNING_TITLE_ONLY_ALERT: 'Alerta de publicação',
      MODAL_FILE_REPORT_TITLE: 'Baixar relatório',
      TEST: null,
      ITEM_UNCOVERED_COST: null,
      STORE_UNCOVERED_COST: null,
      ASSORTMENT_UNCOVERED_COST: null,
      CLUSTER_UNCOVERED_COST_ONLYALERT: null,
    },
    INTERFACE_LOG_MSG_KEYS: {
      PromotionNotFound: null,
      ItemNotFound: null,
      ItemDoesNotExists: null,
      UomNotFound: null,
      NoDataProcessed: null,
      HeaderIsInvalid: null,
      ItemCodeMustNotBeNull: null,
      ItemCostDoesNotBeNull: null,
      ItemCostGretherThanZero: null,
      DiscountGretherThanZero: null,
      Cost2GretherThanZero: null,
      Cost3GretherThanZero: null,
      TaxGretherThanZero: null,
      Tax2GretherThanZero: null,
      Tax3GretherThanZero: null,
      StoreCodeMustNotBeNull: null,
      StoreDoesNotExists: null,
      TagValueDoesNotExists: null,
      VendorDoesNotExists: null,
      ItemCannotBeSupply: null,
      ItemCannotBeIngredient: null,
      ItemCannotBeNew: null,
      ItemBaseOnComponents: null,
      ItemPriceGretherThanZero: null,
      ItemPriceStoreAndSegmentationSameTime: null,
      ItemAndSegmentationSameTime: null,
      ValidFromGreaterThanTodayAndLessThen: null,
      DiscountGretherThanOrEqualZero: null,
      DiscountLowerThanToItemCost: null,
      keyword: null,
      type: null,
      values: null,
      'GatewayTime-out': null,
      NULL: null,
      ItemNameMustNotBeNull: null,
      SalesTaxRateGreaterThanOrEqualToZero: null,
      CategoryCodeMustNotBeNull: null,
      CategoryDoesNotExists: null,
      CompetitorCodeMustNotBeNull: null,
      WrongAssortmentBehavior: null,
      StoreAssortmentGeneratedByPromotion: null,
      StoreAssortmentNotFound: null,
      ItemCodeTypeNotFound: null,
      DuplicateItemCode: null,
      ItemCostGretherThanRegulated: null,
      InvalidUnits: null,
      InvalidDate: null,
      MonthlySaleAlreadyExist: null,
      TagsRequiredNotCompleted: null,
      InvalidVendors: null,
      WrongItemCode: null,
      InvalidCombo: null,
      InvalidRecipe: null,
      ItemCannotPublish: 'O item não pode ser publicado automaticamente.',
      ItemCostGreaterThanRegulated: null,
      DiscountGreaterThanOrEqualZero: null,
      ItemPriceGreaterThanZero: null,
      ItemCostGreaterThanZero: null,
      DiscountGreaterThanZero: null,
      Cost2GreaterThanZero: null,
      Cost3GreaterThanZero: null,
      TaxGreaterThanZero: null,
      Tax2GreaterThanZero: null,
      Tax3GreaterThanZero: null,
      ValidFromGreaterThanToday: null,
      DuplicateRecord: null,
      CostCodeDoesNotExists: null,
      TaxCodeDoesNotExists: null,
      VendorItemDoesNotExist: null,
      VendorCodeMustNotBeNull: null,
      TaxGreaterThanOrEqualZero: null,
      Tax2GreaterThanOrEqualZero: null,
      Tax3GreaterThanOrEqualZero: null,
      ItemCostGreatherThanRegulated: null,
      InvalidSalesUnits: null,
      ItemHasNotSellingUnit: null,
      InvalidCompetitorPriceSource: null,
      CodeMustNotEmpty: null,
      NameMustNotEmpty: null,
      ItemIsDeleted: null,
      StoreCostValueGreaterThanZero: null,
      WrongStoreCode: null,
      InvalidSaleUnits: null,
      ValueShouldBeGreaterThanZero: null,
      MalFormedFieldTagValues: null,
      TagNotFound: null,
      TaskFailedAfterRetries: null,
      UnitsLowerOrEqualToZero: null,
      DateTimeGreaterThanTodayAndLessThen: null,
      DailySaleAlreadyExist: null,
      InvalidVendorSegmentation: null,
      DocumentTypeDoesNotExists: null,
      DailyInventoryAlreadyExist: null,
      InvalidMonth: null,
      DuplicateTagValueForOneOrMoreStores: null,
      SalesTotalCannotBeEmpty: null,
      NetSalesCannotBeEmpty: null,
      SalesTotalCannotBeEmptyOrEqualToZero: null,
      NetSalesCannotBeEmptyOrEqualToZero: null,
      ComboCodeDoesNotExists: null,
      TagValueWrongType: null,
      QuantityGreaterThanOrEqualOne: null,
      QuantityMustBeGreaterThanOrEqualOne: null,
    },
    CUSTOM_ERRORS: {
      '1': 'Test Custom Error Trad PORT val: {{val}}',
      BaseUnitOfMeasureNotFound: 'Nenhuma unidade de medida básica foi encontrada para este produto. Será usado 1 como conversão.',
      UnitOfMeasureNotFound: 'Unidade de medida não encontrada',
      CalculationTypeCostNotFound: '{{val}} não encontrado para a Loja {{val2}} para calcular o custo ou imposto do produto {{val3}} ',
      MissingInitialPriceMethod:
        'Um método de inicialização de preços não foi encontrado. Certifique-se de indicar um preço inicial para cada unidade de medida de venda e de ter selecionado um método de inicialização na guia Preços.',
      MissingSellingUnitOfMeasure: 'Você deve criar pelo menos uma unidade de medida de venda para este tipo de produto',
      NotGeneratedPriceMissingSellingUom: 'Os preços não foram gerados. Unidade de medida de venda ausente',
      CannotDeleteRegistryInUse: 'O registro já está em uso',
      NoCodeSpecified: 'Registro nao encontrado',
      ItemNotFound: 'Iten não encontrado: {{val}}',
      ItemIsPublishingCannotUpdate: 'Este item não pode ser editado porque está sendo publicado.',
      CodeAlreadyExists: 'O código já existe {{val}}.',
      CLONED_PROCESS_FAILURE_TITLE: 'O processo de clonagem falhou',
      CLONED_PROCESS_FAILURE_MSG: 'A clonagem não foi realizada ou foi parcialmente realizada sem classificação.',
      InvalidCodeTypes: 'O tipo de código não existe {{val}}.',
      ParamCannotBeNull: 'O parâmetro {{val}} não pode ser nulo.',
      ParentCannotBeUse:
        'O pai selecionado não pode ser usado para a regra porque já foi usado como filho em outra regra de relacionamento',
      AtLeastOneChildWasFound: 'Foi encontrado pelo menos um produto filho que já foi usado como filho em outra regra.',
      NoNeedToRepublish: 'Este item não precisa ser republicado.',
      ItemHasNotStoreAssortment: null,
      StoreIsPublishingCannotUpdate: 'Este ponto de venda não pode ser editado porque está sendo publicado.',
      UNAUTHORIZED_ACCESS: null,
      INVALID_STORE_SCOPE: null,
      INVALID_MANUAL_STORE_SCOPES: null,
      AnyRoleInUse: null,
      NoRecordSelected: null,
      EntityCreated: 'Registro atualizado com sucesso',
      EntityUpdated: 'Registro atualizado com sucesso',
      CodeCompetitorCannotBeNull: 'O código do concorrente não pode estar vazio',
      CompetitorBadGeoData: 'Os dados de latitude ou longitude estão incorretos',
      BrandCompetitorCannotBeNull: 'A bandeira não pode estar vazia',
      NameCompetitorCannotBeNull: 'O nome do concorrente não pode estar vazio',
      FileIsTooBig: 'O tamanho do arquivo é muito grande',
      ItemForecastDataIsNull: 'Dados de Previsão do Item Nulos',
      ReferencedItemIsAlreadyInUse: 'O Item referenciado já está em uso',
      ErrorOnAddingItemForecast: 'Erro ao Adicionar a Previsão do Item',
      ErrorOnRemovingItemForecast: 'Erro ao Remover a Previsão do Item',
      ReferencedNotFound: 'Referência Não Encontrada',
      ExpiredPromotion: 'A data de início da ação promocional e do período promocional é anterior à data atual.',
    },
  },
};
