import { FuseNavigation, FuseNavigationItem } from '@fuse/types';
import { _isDev } from '@shared/aux_helper_environment';

const _devTest = false;

const _navigationNew: Array<FuseNavigation> = [
  {
    id: 'home',
    title: !_devTest ? 'Main' : 'XXXXXXXX',
    translate: 'NAV.HOME.TITLE',
    type: 'group',
    forceGroupUrl: '/home' /*propiedad que fuerza la url para un group, ver: id:If8oLkW589 */,
    children: [
      {
        id: 'home-welcome',
        title: 'Home',
        translate: 'NAV.HOME.TITLE',
        icon: 'home',
        type: 'item',
        url: '/home/welcome',
      },
      {
        id: 'data-entry-adaptations',
        title: 'Adaptaciones de entrada de datos',
        translate: 'NAV.DATA_ENTRY_ADAPTATIONS.TITLE',
        icon: 'edit',
        type: 'item',
        url: '/data-entry/adaptations',
      },
      {
        id: 'reports',
        title: 'Reportes',
        translate: 'NAV.REPORTS.TITLE',
        icon: 'dashboard',
        type: 'collapsable',
        children: [
          {
            id: 'general-reports',
            title: 'Reportes Generales',
            translate: 'NAV.REPORTS.LIST.TITLE',
            type: 'item',
            url: '/reports/general-reports',
          },
          {
            id: 'reports-dashboard',
            title: !_devTest ? 'Dashboard' : 'XXXXXXXX',
            translate: 'NAV.HOME.DASHBOARD',
            type: 'item',
            url: '/home/dashboard',
          },
        ],
      },
      {
        id: 'prices',
        title: 'Precios', // 'Prices',
        translate: 'NAV.PRICES.TITLE',
        icon: 'loyalty',
        type: 'collapsable',
        nonCollapsable: true,
        children: [
          {
            id: 'prices-setup',
            title: 'Configuración', // 'Setup',
            translate: 'NAV.PRICES.SETUP.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'prices-setup-competitors',
                title: 'Competidores', // 'Competitors',
                translate: 'NAV.PRICES.SETUP.COMPETITORS',
                type: 'item',
                url: '/prices/setup/competitors',
              },
              {
                id: 'prices-setup-sensitivities',
                title: 'Sensibilidades',
                translate: 'NAV.PRICES.SETUP.SENSITIVITIES',
                type: 'item',
                url: '/prices/setup/sensitivities',
              },
              {
                id: 'prices-setup-rounding',
                title: 'Reglas de Redondeo',
                translate: 'NAV.PRICES.SETUP.ROUNDING',
                type: 'item',
                url: '/prices/setup/rounding',
              },
              {
                id: 'prices-setup-prices-rules',
                title: 'Reglas de Precios', // 'Price Rules',
                translate: 'NAV.PRICES.SETUP.PRICESRULES',
                type: 'item',
                url: '/prices/setup/price-rules',
              },
              {
                id: 'prices-setup-brands',
                title: 'Banderas',
                translate: 'NAV.PRICES.SETUP.BRANDS',
                type: 'item',
                url: '/prices/setup/brands',
              },
              {
                id: 'prices-setup-guidelines-and-strategies',
                title: 'Lineamientos y Estrategias',
                translate: 'NAV.PRICES.SETUP.GUIDELINESANDSTRATEGIES',
                type: 'item',
                url: '/prices/setup/guidelines-and-strategies',
              },
            ],
          },
          {
            id: 'prices-admin',
            title: 'Administración', // 'Admin',
            translate: 'NAV.PRICES.ADMIN.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'prices-admin-taxes',
                title: 'Impuestos', // 'Taxes',
                translate: 'NAV.PRICES.ADMIN.TAXES.TITLE',
                type: 'collapsable',
                children: [
                  {
                    id: 'prices-admin-taxes-iibb-tax',
                    title: 'IIBB',
                    translate: 'NAV.PRICES.ADMIN.TAXES.IIBB',
                    type: 'item',
                    url: '/prices/admin/taxes/iibb',
                  },
                  {
                    id: 'prices-admin-taxes-co2-tax',
                    title: 'CO2',
                    translate: 'NAV.PRICES.ADMIN.TAXES.CO2',
                    type: 'item',
                    url: '/prices/admin/taxes/co2',
                  },
                  {
                    id: 'prices-admin-taxes-icl-tax',
                    title: 'ICL',
                    translate: 'NAV.PRICES.ADMIN.TAXES.ICL',
                    type: 'item',
                    url: '/prices/admin/taxes/icl',
                  },
                ],
              },
              {
                id: 'prices-admin-importers',
                title: 'Importadores', // 'importadores',
                translate: 'NAV.PRICES.ADMIN.IMPORTERS.TITLE',
                type: 'collapsable',
                children: [
                  {
                    id: 'prices-admin-import-parity',
                    title: 'Import parity',
                    translate: 'NAV.PRICES.ADMIN.IMPORTERS.IMPORTPARITY',
                    type: 'item',
                    url: '/prices/admin/import-parity',
                  },
                  {
                    id: 'prices-admin-import-fletes-comisiones',
                    title: 'Fletes y Comisiones',
                    translate: 'NAV.PRICES.ADMIN.IMPORTERS.IMPORTFLETESCOMISIONES',
                    type: 'item',
                    url: '/prices/admin/import-ferryes-and-comisions',
                  },
                  false /* DES-814 */
                    ? {
                        id: 'prices-admin-import-costs-and-taxes',
                        title: 'Costos e Impuestos',
                        translate: 'NAV.PRICES.ADMIN.IMPORTERS.IMPORTCOSTSANDTAXES',
                        type: 'item',
                        url: '/prices/admin/import-costs-and-taxes',
                      }
                    : null,
                  {
                    id: 'prices-admin-import-vendor-costs',
                    title: 'Costos por Proveedor',
                    translate: 'NAV.PRICES.ADMIN.IMPORTERS.IMPORTVENDORCOSTS',
                    type: 'item',
                    url: '/prices/admin/import-vendor-costs',
                  },
                  {
                    id: 'prices-admin-import-store-taxes',
                    title: 'Impuestos por Punto de Venta',
                    translate: 'NAV.PRICES.ADMIN.IMPORTERS.IMPORTSTORETAXES',
                    type: 'item',
                    url: '/prices/admin/import-store-taxes',
                  },
                  {
                    id: 'prices-admin-import-store-costs',
                    title: 'Costos por Punto de Venta',
                    translate: 'NAV.PRICES.ADMIN.IMPORTERS.IMPORTSTORECOSTS',
                    type: 'item',
                    url: '/prices/admin/import-store-costs',
                  },
                  {
                    id: 'prices-admin-import-new-prices',
                    title: 'Nuevos Precios',
                    translate: 'NAV.PRICES.ADMIN.IMPORTERS.IMPORTNEWPRICES',
                    type: 'item',
                    url: '/prices/admin/import-new-prices',
                  },
                  {
                    id: 'prices-admin-import-regulated-prices',
                    title: 'Precios Regulados',
                    translate: 'NAV.PRICES.ADMIN.IMPORTERS.REGULATEDPRICES',
                    type: 'item',
                    url: '/prices/admin/import-regulated-prices',
                  },
                  {
                    id: 'prices-admin-import-competitor-prices',
                    title: 'Precios de competidores',
                    translate: 'NAV.PRICES.ADMIN.IMPORTERS.COMPETITORSPRICES',
                    type: 'item',
                    url: '/prices/admin/import-competior-prices',
                  },
                ].filter(el => el != null),
              },
            ],
          },
          {
            id: 'prices-decisions',
            title: 'Decisiones', // 'Decisions',
            translate: 'NAV.PRICES.DECISIONS.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'prices-decisions-summary',
                title: 'Resumen de decisiones', // 'Decisions Summary',
                translate: 'NAV.PRICES.DECISIONS.DECISIONSSUMMARY',
                type: 'item',
                url: '/prices/decisions/decisions-summary',
              },
              {
                id: 'prices-decisions-price-decisions',
                title: 'Decisiones de precios', // 'Price Decisions',
                translate: 'NAV.PRICES.DECISIONS.PRICE_DECISIONS',
                type: 'item',
                url: '/prices/decisions/price-decisions',
              },
              {
                id: 'prices-decisions-inform-erp',
                title: 'Publicar Decisiones', // 'Inform the ERP',
                translate: 'NAV.PRICES.DECISIONS.INFORM_ERP',
                type: 'item',
                url: '/prices/decisions/inform-to-erp',
              },
            ],
          },
          {
            id: 'prices-reports',
            title: 'Reportes', // 'Reports',
            translate: 'NAV.PRICES.REPORTS.TITLE',
            type: 'collapsable',
            children: [
              /* AGREGAR DESPUES DE PRESENTAR A YPF */ {
                id: 'prices-reports-price-changes',
                title: 'Price changes',
                translate: 'NAV.PRICES.REPORTS.PRICE_CHANGES',
                type: 'item',
                url: '/prices/reports/price-changes',
              },
              {
                id: 'prices-reports-price-reports',
                title: 'Price Reports',
                translate: 'NAV.PRICES.REPORTS.PRICE_REPORTS',
                type: 'item',
                url: '/prices/reports/prices-reports',
              },
              {
                id: 'prices-reports-suggestion-reports',
                title: 'Suggestion Reports',
                translate: 'NAV.PRICES.REPORTS.SUGGESTION_REPORTS',
                type: 'item',
                url: '/prices/reports/suggestion-reports',
              },
              {
                id: 'prices-reports-cost-reports',
                title: 'Cost Reports',
                translate: 'NAV.PRICES.REPORTS.COST_REPORTS',
                type: 'item',
                url: '/prices/reports/cost-reports',
              },
              {
                id: 'prices-reports-ypf-reports',
                title: 'Reportes YPF', // 'Ypf Reports',
                translate: 'NAV.PRICES.REPORTS.YPF_REPORTS',
                type: 'item',
                url: '/prices/reports/ypf-reports',
              },
              {
                id: 'prices_reports-map-prices',
                title: 'Mapa de precios', // 'Ypf Reports',
                translate: 'NAV.PRICES.REPORTS.PRICES_MAP',
                type: 'item',
                url: '/prices/reports/prices-map',
              },
              {
                id: 'prices-reports-inconsistency-report',
                title: 'Inconsistencia de precios',
                translate: 'NAV.PRICES.REPORTS.PRICE_INCONSISTENCY_REPORT',
                type: 'item',
                url: '/prices/reports/price-inconsistency-report',
              },
              {
                id: 'prices-reports-daily-margin',
                title: 'Márgenes diarios',
                translate: 'NAV.PRICES.REPORTS.DAILY_MARGIN',
                type: 'item',
                url: '/prices/reports/daily-margin',
              },
              {
                id: 'prices-reports-competitor-prices',
                title: 'Precios de Competidores',
                translate: 'NAV.PRICES.REPORTS.COMPETITOR_PRICES',
                type: 'item',
                url: '/prices/reports/competitor-prices',
              },
              {
                id: 'prices-reports-fuels-dashboard',
                title: 'Fuels Dashboard',
                type: 'item',
                url: '/prices/reports/fuels/dashboard',
              },
            ],
          },
        ],
      },
      {
        id: 'assortment',
        title: 'Surtidos',
        translate: 'NAV.ASSORTMENT.TITLE',
        icon: 'category',
        type: 'collapsable',
        nonCollapsable: true,
        children: [
          {
            id: 'assortment-setup',
            title: 'Configuración',
            translate: 'NAV.ASSORTMENT.SETUP.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'assortment-setup-clustering',
                title: 'Clusterizaciones',
                translate: 'NAV.ASSORTMENT.SETUP.CLUSTERING',
                type: 'item',
                url: '/assortment/setup/clustering',
              },
              {
                id: 'assortment-setup-categorys-select',
                title: 'Árbol de Categorías',
                translate: 'NAV.ASSORTMENT.SETUP.TREEOFCATEGORYS',
                type: 'item',
                url: '/assortment/setup/categorys-select',
              },
              {
                id: 'assortment-setup-roles',
                title: 'Roles',
                translate: 'NAV.ASSORTMENT.SETUP.ROLES',
                type: 'item',
                url: '/assortment/setup/roles',
              },
              {
                id: 'assortment-setup-strategies',
                title: 'Estrategias',
                translate: 'NAV.ASSORTMENT.SETUP.STRATEGIES',
                type: 'item',
                url: '/assortment/setup/strategies',
              },
              {
                id: 'category-setup',
                title: 'Configuración de Categorias',
                translate: 'NAV.ASSORTMENT.SETUP.CATEGORY_SETUP',
                type: 'item',
                url: '/assortment/setup/category-setup',
              },
              {
                id: 'assortment-setup-segment-assigment',
                title: 'Asignación de Segmentos',
                translate: 'NAV.ASSORTMENT.SETUP.SEGMENTASSIGNMENTS',
                type: 'item',
                url: '/assortment/setup/segment-assignment',
              },
              {
                id: 'assortment-setup-market-information',
                title: 'Productos de Mercado',
                translate: 'NAV.ASSORTMENT.SETUP.MARKET_INFORMATION',
                type: 'item',
                url: '/assortment/setup/market-information',
              },
            ],
          },
          {
            id: 'assortment-decisions',
            title: 'Decisiones',
            translate: 'NAV.ASSORTMENT.DECISIONS.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'assortment-decisions-assortment-asignation',
                title: 'Decisiones de Surtido',
                translate: 'NAV.ASSORTMENT.DECISIONS.ASSORTMENTASIGNATION',
                type: 'item',
                url: '/assortment/decisions/assortment-asignation',
              },
              {
                id: 'assortment-decisions-assortment-inform-to-erp',
                title: 'Publicar Decisiones',
                translate: 'NAV.ASSORTMENT.DECISIONS.INFORM_ERP',
                type: 'item',
                url: '/assortment/decisions/assortment-inform-to-erp',
              },
            ],
          },
          {
            id: 'assortment-reports',
            title: 'Reportes',
            translate: 'NAV.ASSORTMENT.REPORTS.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'assortment-reports-cluster-analysis',
                title: 'Análisis de Cluster',
                translate: 'NAV.ASSORTMENT.REPORTS.CLUSTERANALYSIS',
                type: 'item',
                url: '/assortment/reports/cluster-analysis',
              },
              {
                id: 'assortment-reports-decisions',
                title: 'Reporte General',
                translate: 'NAV.ASSORTMENT.REPORTS.REPORT_DECISIONS',
                type: 'item',
                url: '/assortment/reports/report',
              },
            ],
          },
          {
            id: 'assortment-admin',
            title: 'Administración', // 'Admin',
            translate: 'NAV.ASSORTMENT.ADMINISTRATION.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'assortment-admin-importers',
                title: 'Importadores', // 'importadores',
                translate: 'NAV.ASSORTMENT.ADMINISTRATION.IMPORTERS.TITLE',
                type: 'collapsable',
                children: [
                  {
                    id: 'assortment-admin-import-market-information',
                    title: 'Información de Mercado',
                    translate: 'NAV.ASSORTMENT.ADMINISTRATION.IMPORTERS.MARKET_INFORMATION',
                    type: 'item',
                    url: '/assortment/admin/import-market-information',
                  },
                  {
                    id: 'assortment-admin-import-margin-with-vendor',
                    title: 'Margen por Acuerdos',
                    translate: 'NAV.ASSORTMENT.ADMINISTRATION.IMPORTERS.MARGIN_WITH_VENDOR',
                    type: 'item',
                    url: '/assortment/admin/import-margin-with-vendor',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'promotionsV2',
        title: 'Promociones',
        translate: 'NAV.PROMOTIONSV2.TITLE',
        icon: 'card_giftcard',
        type: 'collapsable',
        nonCollapsable: true,
        children: [
          {
            id: 'promotionsV2-configurations',
            title: 'Configuraciones',
            translate: 'NAV.PROMOTIONSV2.CONFIGURATIONS.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'promotionsV2-configurations-rules',
                title: 'Reglas de promociones',
                translate: 'NAV.PROMOTIONSV2.CONFIGURATIONS.RULES.TITLE',
                type: 'item',
                url: '/promotionsV2/configurations/rules',
              },
              {
                id: 'promotionsV2-configurations-reference-prices',
                title: 'Precios de referencia',
                translate: 'NAV.PROMOTIONSV2.CONFIGURATIONS.REFERENCE_PRICES.TITLE',
                type: 'item',
                url: '/promotionsV2/configurations/reference-prices',
              },
              {
                id: 'promotionsV2-configurations-promotional-process',
                title: 'Procesos promocionales',
                translate: 'NAV.PROMOTIONSV2.CONFIGURATIONS.PROMOTIONAL_PROCESS.TITLE',
                type: 'item',
                url: '/promotionsV2/configurations/promotional-process',
              },
            ],
          },
          {
            id: 'promotionsV2-calendar',
            title: 'Calendario',
            translate: 'NAV.PROMOTIONSV2.CALENDAR.TITLE',
            type: 'item',
            url: '/promotionsV2/calendar',
          },
          {
            id: 'promotionsV2-periods',
            title: 'Períodos promocionales',
            translate: 'NAV.PROMOTIONSV2.PERIODS.TITLE',
            type: 'item',
            url: '/promotionsV2/periods',
          },
          {
            id: 'promotionsV2-actions',
            title: 'Acciones Promocionales',
            translate: 'NAV.PROMOTIONSV2.ACTIONS.TITLE',
            type: 'item',
            url: '/promotionsV2/actions',
          },
          {
            id: 'promotionsV2-reports',
            title: 'Reportes',
            translate: 'NAV.PROMOTIONSV2.REPORTS.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'promotionsV2-production-reports',
                title: 'Reportes operativos',
                translate: 'NAV.PROMOTIONSV2.PRODUCTION_REPORTS.TITLE',
                type: 'item',
                url: '/promotionsV2/production-reports',
              },
              {
                id: 'promotionsV2-productionList',
                title: 'Lista de Producción',
                translate: 'NAV.PROMOTIONSV2.PRODUCTIONLIST.TITLE',
                type: 'item',
                url: '/promotionsV2/production/list',
              },
              {
                id: 'promotionsV2-promotionOutput',
                title: 'Output de promociones',
                translate: 'NAV.PROMOTIONSV2.PROMOTION_OUTPUT.TITLE',
                type: 'item',
                url: '/promotionsV2/promotion-output',
              },
              {
                id: 'promotionsV2-logisticsReport',
                title: 'Reporte de logística',
                translate: 'NAV.PROMOTIONSV2.LOGISTICS_REPORT.TITLE',
                type: 'item',
                url: '/promotionsV2/logistics-report',
              },
              {
                id: 'promotionsV2-availableSpaces',
                title: 'Espacios disponibles',
                translate: 'NAV.PROMOTIONSV2.AVAILABLE_SPACES.TITLE',
                type: 'item',
                url: '/promotionsV2/available-spaces',
              },
              {
                id: 'promotionsV2-promotionReport',
                title: 'Reporte de Promociones',
                translate: 'NAV.PROMOTIONSV2.PROMOTION_REPORT.TITLE',
                type: 'item',
                url: '/promotionsV2/promotion-report',
              },
            ],
          },
        ],
      },
      {
        id: 'supply',
        title: 'Abastecimiento',
        translate: 'NAV.SUPPLY.TITLE',
        icon: 'local_shipping',
        type: 'collapsable',
        nonCollapsable: true,
        children: [
          {
            id: 'supply-setup',
            title: 'Configuraciones',
            translate: 'NAV.SUPPLY.SETUP.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'supply-setup-general',
                title: 'General',
                translate: 'NAV.SUPPLY.SETUP.GENERAL',
                type: 'item',
                url: '/supply/supply-setup/general',
              },
              {
                id: 'supply-setup-order-schedule',
                title: 'Agenda de Pedidos',
                translate: 'NAV.SUPPLY.SETUP.ORDER_SCHEDULE',
                type: 'item',
                url: '/supply/supply-setup/order-schedule',
              },
              {
                id: 'supply-setup-holidays',
                title: 'holidays',
                translate: 'NAV.SUPPLY.SETUP.HOLIDAYS',
                type: 'item',
                url: '/supply/supply-setup/holidays',
              },
              {
                id: 'supply-setup-capacities',
                title: 'Capacidades',
                translate: 'NAV.SUPPLY.SETUP.CAPACITIES',
                type: 'item',
                url: '/supply/supply-setup/Capacities',
              },
            ],
          },
          {
            id: 'supply-decisions',
            title: 'Decisiones',
            translate: 'NAV.SUPPLY.DECISIONS.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'supply-decisions-order-control',
                title: 'Ordenes',
                translate: 'NAV.SUPPLY.DECISIONS.ORDER_CONTROL',
                type: 'item',
                url: '/supply/supply-decisions/order-control',
              },
            ],
          },
          {
            id: 'supply-administration',
            title: 'Administración',
            translate: 'NAV.SUPPLY.ADMINISTRATION.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'supply-administration-importers',
                title: 'Importadores',
                translate: 'NAV.SUPPLY.ADMINISTRATION.IMPORTERS',
                type: 'item',
                url: '/supply/supply-administration/importers',
              },
            ],
          },
        ],
      },
      {
        id: 'item-master',
        title: 'Item Master',
        translate: 'NAV.ITEMMASTER.TITLE',
        icon: 'view_list',
        type: 'item',
        url: '/item-master',
      },
      {
        id: 'admin',
        title: 'Administración',
        translate: 'NAV.ADMIN.TITLE',
        icon: 'settings',
        type: 'collapsable',
        nonCollapsable: true,
        children: [
          {
            id: 'admin-master',
            title: 'Maestros',
            translate: 'NAV.ADMIN.MASTER.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'admin-master-stores',
                title: 'Puntos de venta',
                translate: 'NAV.ADMIN.MASTER.STORES',
                type: 'item',
                url: '/admin/master/stores',
              },
              {
                id: 'admin-master-stores-OLD',
                title: 'Puntos de venta OLD',
                // translate: 'NAV.ADMIN.MASTER.STORES',
                type: 'item',
                url: '/admin/master/stores-OLD',
              },
              {
                id: 'admin-master-supplier',
                title: 'Proveedores',
                translate: 'NAV.ADMIN.MASTER.SUPPLIER',
                type: 'item',
                url: '/admin/master/supplier',
              },
              {
                id: 'admin-master-supplier-OLD',
                title: 'Proveedores OLD',
                // translate: 'NAV.ADMIN.MASTER.SUPPLIER',
                type: 'item',
                url: '/admin/master/supplier-OLD',
              },
              {
                id: 'admin-master-tags',
                title: 'Etiquetas',
                translate: 'NAV.ADMIN.MASTER.TAGS',
                type: 'item',
                url: '/admin/master/tags',
              },
              {
                id: 'admin-master-insights',
                title: 'Insights',
                translate: 'NAV.ADMIN.MASTER.INSIGHTS',
                type: 'item',
                url: '/admin/master/insights',
              },
              {
                id: 'admin-master-equipments',
                title: 'Tipos de Equipamiento',
                translate: 'NAV.ADMIN.MASTER.EQUIPMENTS',
                type: 'item',
                url: '/admin/master/equipments',
              },
              {
                id: 'admin-master-setup-categories-select',
                title: 'Árbol de Categorías',
                translate: 'NAV.ASSORTMENT.SETUP.TREEOFCATEGORYS',
                type: 'item',
                url: '/admin/master/categories-select',
              },
              {
                id: 'admin-master-dynamic-entities',
                title: 'Entidades Dinámicas',
                translate: 'NAV.ADMIN.MASTER.DYNAMIC_ENTITIES',
                type: 'item',
                url: '/admin/master/dynamic-entities',
              },
            ],
          },
          {
            id: 'admin-security',
            title: 'Seguridad',
            translate: 'NAV.ADMIN.SECURITY.TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'admin-security-users',
                title: 'Usuarios',
                translate: 'NAV.ADMIN.SECURITY.USERS',
                type: 'item',
                url: '/admin/security/users',
              },
              {
                id: 'admin-security-roles',
                title: 'Roles',
                translate: 'NAV.ADMIN.SECURITY.ROLES',
                type: 'item',
                url: '/admin/security/roles',
              },
            ],
          },
          {
            id: 'admin-general-importer',
            title: 'Importador General',
            translate: 'NAV.ADMIN.MASTER.GENERAL_IMPORTER',
            type: 'item',
            url: '/admin/general-importer',
          },
          {
            id: 'admin-interfaces-log',
            title: 'Log de Interfaces',
            translate: 'NAV.ADMIN.MASTER.INTERFACES_LOG',
            type: 'item',
            url: '/admin/interfaces-log',
          },
        ],
      },
      {
        id: 'mystery-shopper',
        title: 'Mystery Shopper',
        translate: 'NAV.MYSTERYSHOPPER.TITLE',
        icon: 'phone_android',
        type: 'collapsable',
        nonCollapsable: true,
        children: [
          {
            id: 'mystery-shopper-missions',
            title: 'Misiones',
            translate: 'NAV.MYSTERYSHOPPER.MISSIONS.TITLE',
            type: 'item',
            url: '/mystery-shopper/missions',
          },
          {
            id: 'mystery-shopper-user-assignment',
            title: 'Asignación de Usuarios',
            translate: 'NAV.MYSTERYSHOPPER.USER_ASSIGNMENT.TITLE',
            type: 'item',
            url: '/mystery-shopper/user-assignment',
          },
          {
            id: 'mystery-shopper-reports',
            title: 'Reportes', // 'Reports',
            translate: 'NAV.PRICES.REPORTS.TITLE',
            type: 'collapsable',
            children: [],
          },
        ],
      },
      {
        id: 'spaces-v2',
        title: 'Espacios',
        translate: 'NAV.SPACES.TITLEV2',
        icon: 'view_module',
        type: 'collapsable',
        nonCollapsable: true,
        children: [
          {
            id: 'spaces-v2-config',
            title: 'Configuración',
            translate: 'NAV.SPACES.CONFIG_TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'spaces-v2-templates',
                title: 'Templates',
                translate: 'NAV.SPACES.SPACES_TEMPLATES.TITLE',
                type: 'item',
                url: '/spaces/templates',
              },
              {
                id: 'spaces-v2-material-promotion',
                title: 'Material Promocional',
                translate: 'NAV.SPACES.MATERIAL_PROM_TITLE',
                type: 'collapsable',
                children: [
                  {
                    id: 'spaces-v2-planograms-pop-material',
                    title: 'Material POP',
                    translate: 'NAV.SPACES.POPMATERIAL.TITLE',
                    type: 'item',
                    url: '/spaces/popmaterial',
                  },
                ],
              },
            ],
          },
          {
            id: 'spaces-v2-admin',
            title: 'Administración',
            translate: 'NAV.SPACES.ADMIN_TITLE',
            type: 'collapsable',
            children: [],
          },
          {
            id: 'spaces-v2-decisions',
            title: 'Decisiones',
            translate: 'NAV.SPACES.DECISIONS_TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'spaces-v2-marketplace',
                title: 'Marketplace',
                translate: 'NAV.PROMOTIONSV2.MARKETPLACE.TITLE',
                type: 'item',
                url: '/spaces/marketplace/list',
              },
              {
                id: 'spaces-v2-planograms',
                title: 'Planogramas',
                translate: 'NAV.SPACES.PLANOGRAMS.TITLE',
                type: 'item',
                url: '/spaces/planograms',
              },
              {
                id: 'spaces-v2-structure',
                title: 'Estructuras',
                translate: 'NAV.SPACES.STRUCTURE.TITLE',
                type: 'item',
                url: '/spaces/structures',
              },
              {
                id: 'spaces-v2-generic-space',
                title: 'Espacio Genérico',
                translate: 'NAV.SPACES.GENERIC_SPACE.TITLE',
                type: 'item',
                url: '/spaces/generic-space',
              },
              {
                id: 'spaces-v2-floor-plans',
                title: 'Layouts',
                translate: 'NAV.SPACES.FLOORPLANS.TITLE',
                type: 'item',
                url: '/spaces/floorplans',
              },
            ],
          },
          {
            id: 'spaces-v2-reports',
            title: 'Reportes',
            translate: 'NAV.SPACES.REPORTS_TITLE',
            type: 'collapsable',
            children: [
              {
                id: 'spaces-v2-inconsistencies',
                title: 'Inconsistencias',
                translate: 'NAV.SPACES.INCONSISTENCIES.TITLE',
                type: 'item',
                url: '/spaces/inconsistencies',
              },
            ],
          },
        ],
      },
    ].filter(el => el != null) as FuseNavigationItem[],
  },
];

export const navigation: Array<FuseNavigation> = _navigationNew;
